import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from 'styled-components'
import { Icon } from '../../components/Icon';
import Layout from '../../components/Layout/Layout'
import { ButtonPrimary } from '../../components/components'
import useStore from '../../useStore'

const Option = () => {
  // @ts-ignore
  const { t } = useI18n()
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { darkMode } = useStore()

  return (
    <Layout>
      <StyledContainer className="container">
        <StyledPanel darkMode={darkMode}>
          <h4 className='title'>
            {t("selectcollection.title")}
          </h4>

          <div className="flex center mt3">
            {/* <div className="col-6">
              <StyledCard style={{ backgroundColor: "#36C781" }}>
                <h4 className='text-center'>
                  {t("selectcollection.title11")}<br></br><span style={{ fontSize: '1.2rem' }}>{t("selectcollection.title12")}</span>
                </h4>

                <div className="flex center">
                  <Icon icon="Import" className='icon' />
                </div>

                <p style={{ height: '100px', textAlign: "center" }}>
                  {t("selectcollection.content1")}
                </p>

                <div className="flex center">
                  <ButtonPrimary onClick={() => { navigate("/create-collection/import") }}
                    style={{ width: '200px', backgroundColor: 'white', color: "#36C781" }}
                  >
                    {t("selectcollection.import")}
                  </ButtonPrimary>
                </div>
              </StyledCard>
            </div> */}

            <div className="col-6">
              <StyledCard style={{ backgroundColor: theme.buttonPrimary }}>
                <h4 className='text-center'>
                  {t("selectcollection.title21")}<br></br><span style={{ fontSize: '1.2rem' }}>{t("selectcollection.title22")}</span>
                </h4>

                <div className="flex center">
                  <Icon icon="Create" className='icon' />
                </div>

                <p style={{ height: '100px', textAlign: "center" }}>
                  {t("selectcollection.content2")}
                </p>

                <div className="flex center ">
                  <ButtonPrimary onClick={() => { navigate("/create-collection") }}
                    style={{ width: '200px', backgroundColor: 'white', color: theme.buttonPrimary }}
                  >
                    {t("selectcollection.create")}
                  </ButtonPrimary>
                </div>
              </StyledCard>
            </div>
          </div>
        </StyledPanel>
      </StyledContainer>
    </Layout>
  )
}

export default Option;

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledCard = styled.div`
	padding: 1rem  1.5rem 2rem;
	border-radius: 1.5rem;
	margin: 4px auto;
	max-width: 250px;
	width: 100%;
	@media (max-width: 1024px) {
		padding: 1rem 8px;
	}
	@media (max-width: 768px) {
		h4 {
			font-size: 0.9rem;
		}
		padding: 1rem 4px;
        max-width: 280px;
		font-size: 0.8rem;
		margin: 4px 0;
	}
	.icon {
		width: 70px!important;
		height: 70px!important;
		@media (max-width: 768px) {
			width: 35px!important;
			height: 35px!important;
		}
	}
`

const StyledPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 1.5rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 1rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	@media (max-width: 768px) {
		padding: 1rem 0;
	}
`