import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';

import useStore from '../useStore';
import logo from '../assets/img/logo.svg'

const Header = () => {
	const { t } = useI18n()
	const { lang, isMetamask, logined } = useStore()

	return (
		<>
			<StyledFooter>
				<div className="container">
					<div className="row cotainer-row">
						<div className="col-xl-3 col-md-12">
							{window.innerWidth > 480 && (
								<>
									<div className='logo'>
										<img src={logo} alt="logo" />
									</div>

									{/* <div className="darktheme">
										<div className='flex middle' onClick={() => {update({lang: "en"});  }}>
											<p style={{color: darkMode ? theme.text : "#FAFAFA", margin: 0, marginRight: 24}}>EN</p>
										</div>
										<Switch switched={lang != "en"} onChange={() => {update({lang: lang === "en" ? "jp": "en"}); }}/>
										
										<div className='flex middle' onClick={() => {update({lang: "jp"});  }}>
											<p style={{color: darkMode ? theme.text : "#FAFAFA", margin: 0, marginLeft: 24}}>JP</p>
										</div>
									</div> */}

									<div className="line"></div>

									{/* <div className="darktheme">
										<div className='flex middle' style={{color: darkMode ? theme.text : "#FAFAFA"}} onClick={() => {update({darkMode: !darkMode});  }}>
											<Icon icon="Dark" margin = {24} height={25} fill={darkMode ? theme.text : "#FAFAFA"}/>
											<p style={{color: darkMode ? theme.text : "#FAFAFA", margin: 0, marginRight: 24}}>{t("footer.darktheme")}</p>
										</div>
										<Switch switched={!darkMode} onChange={() => {update({darkMode: !darkMode}); }}/>
									</div> */}
								</>
							)}
						</div>

						<div className="col-xl-9 col-md-12">
							<div className="row footer-row">
								<div className="col-md-2 col-sm-6 col-6">
									<StyledLinkTitle>NFT</StyledLinkTitle>

									<StyledLinkList>
										<StyledLink href="/search-nft">{t("footer.searchNFT")}</StyledLink>
										<StyledLink href="/profile">{t("footer.sellNFT")}</StyledLink>
										<StyledLink href={isMetamask ? "/items/create" : "/items/select-options"}>{t("footer.createNFT")}</StyledLink>
									</StyledLinkList>
								</div>

								<div className="col-md-2 col-sm-6 col-6">
									<StyledLinkTitle>{t('footer.collection')}</StyledLinkTitle>

									<StyledLinkList>
										<StyledLink href="/search-collection">{t("footer.searchCollection")}</StyledLink>
										<StyledLink href="/collections/select-options">{t("footer.createCollection")}</StyledLink>
									</StyledLinkList>
								</div>

								<div className="col-md-2 col-sm-6 col-6">
									<StyledLinkTitle>{t("footer.mypage")}</StyledLinkTitle>

									<StyledLinkList>
										{/* {logined && (
                      <StyledLink onClick={() => { logOut() }}>{t("footer.logout")}</StyledLink>
                    )} */}

										{!logined && (
											<StyledLink href='/login'>{t("footer.login")}</StyledLink>
										)}

										<StyledLink href="/profile/wallet">{t("footer.wallet")}</StyledLink>
										<StyledLink href="/profile">{t("footer.editProfile")}</StyledLink>
									</StyledLinkList>
								</div>

								<div className="col-md-3 col-sm-6 col-6">
									<StyledLinkTitle>{t("home.support")}</StyledLinkTitle>

									<StyledLinkList>
										<StyledLink target='_blank' href={`https://support.mecha.fun/whatnft/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.whatnft")}</StyledLink>
										<StyledLink target='_blank' href={`https://support.mecha.fun/create/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howcreate")}</StyledLink>
										<StyledLink target='_blank' href={`https://support.mecha.fun/purchase/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howpurchase")}</StyledLink>
										<StyledLink target='_blank' href={`https://support.mecha.fun/sell/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howsell")}</StyledLink>
									</StyledLinkList>
								</div>

								<div className="col-md-3 col-sm-6 col-6">
									<StyledLinkTitle>{t("footer.link")}</StyledLinkTitle>

									<StyledLinkList>
										<StyledLink href={`https://support.mecha.fun/` + (lang == "jp" ? 'ja' : 'en')} target='_blank'>{t("footer.support")}</StyledLink>
										<StyledLink href="/privacy">{t("footer.policy")}</StyledLink>
										<StyledLink href="/terms">{t("footer.terms")}</StyledLink>
									</StyledLinkList>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StyledFooter>

			<StyledFooterBar>
				<div className="container">
					<div className="copyright">
						{/* {window.innerWidth > 480 && (
              <p>{t("footer.secure")}</p>
            )} */}

						<p></p>

						<p>{t("footer.copyright")}</p>
					</div>
				</div>
			</StyledFooterBar>
		</>
	)
}

export default Header;

const StyledFooter = styled.div`
	background-color: ${({ theme }) => theme.footerPanel};
	padding: 3rem 0;
	.logo{
		img{
			width: 240px;
			max-width: 70%;
			
			@media (min-width: 1920px) {
				width: 300px;
			}

			@media (max-width: 1600px) {
				max-width: 80%;
			}
			
			@media (max-width: 1360px) {
				max-width: 90%;
				width: 240px;
			}

			@media (max-width: 768px) {
				margin: 0;
				width: 120px;
				padding: 0;
			}
		}
	}

	.darktheme{
		display: flex;
		flex-direction: row;
		margin: 1.5rem 0;
	}

	.line{
		top: 0;
		right: 15%;
		height: 100%;
		position: absolute;
		border-right: 1px solid #f7f7f7;

		@media (max-width: 1600px) {
			right: 10%;
		}

		@media (max-width: 1360px) {
			right: 5%;
		}
		
		@media (max-width: 1200px) {
			display: none;
		}

		@media (max-width: 768px) {
			border-bottom: 1px solid #f7f7f7;
			border-right: 0;
			display: block;
			width: 100%;
			height: 0;
			top: 95%;
			left: 0;
		}
	}

	@media (max-width: 1200px) {
		.cotainer-row{
			gap: 30px;

			.line {
				margin-top: 15px;
			}
		}
	}

	@media (max-width: 768px) {
		padding: 1rem;
	}

	.footer-row {
		@media (max-width: 768px) {
			>div:nth-child(5) {
				margin-top: -1rem;
			}
		}
	}
`
const StyledFooterBar = styled.div`
	background-color: ${({ theme }) => theme.footerBar};
	padding: 0 0 1rem;
	.copyright{
		display: flex;
		margin: 0!important;
		justify-content: space-between;
		color: #a8a8a8;
	}
	@media (max-width: 768px) {
		div{
			text-align: center;
			display: block!important;
		}
	}
`
const StyledLinkTitle = styled.p`
	font-weight: bold;
	color: #fafafa;
	margin: 0 0 1.5rem;
	@media (max-width: 768px) {
		margin-top: 1rem;
		margin: 0 ;
	}
`
const StyledLink = styled.a`
	cursor: pointer;
	color: #9b9b9b;
	margin: 1rem 0;
	text-decoration: none;
	display: block;
	&:hover{
		color: white;
		text-decoration: underline;
	}
	@media (max-width: 500px) {
		font-size: 0.8rem;
	}
`
const StyledLinkList = styled.div`
	display: block;
	@media (max-width: 768px) {
		a {
			margin: 8px 8px 8px 0;
		}
	}
`