import { ethers } from 'ethers';
import Config from "../config/config.json";
import { Network } from 'alchemy-sdk';

const supportChainId = Number(Config.CHAINID || 80001);

export const RPCS = {
    1: "https://eth-mainnet.g.alchemy.com/v2/fhCdKI9G1b1r60Iwo-Pf3DQDtVf_JCsN",
    5: "https://rpc.ankr.com/eth_goerli",
    97: "https://bsc-testnet.public.blastapi.io",
    250: "https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac",
    4002: "https://fantom-testnet.public.blastapi.io",
    421613: "https://goerli-rollup.arbitrum.io/rpc",
    11155111: "https://ethereum-sepolia.blockpi.network/v1/rpc/public",
    80001: "https://polygon-mumbai-pokt.nodies.app",
    137: "https://rpc.ankr.com/polygon",
}

const providers: any = {
    1: new ethers.providers.JsonRpcProvider(RPCS[1]),
    5: new ethers.providers.JsonRpcProvider(RPCS[5]),
    97: new ethers.providers.JsonRpcProvider(RPCS[97]),
    250: new ethers.providers.JsonRpcProvider(RPCS[250]),
    4002: new ethers.providers.JsonRpcProvider(RPCS[4002]),
    421613: new ethers.providers.JsonRpcProvider(RPCS[421613]),
    11155111: new ethers.providers.JsonRpcProvider(RPCS[11155111]),
    80001: new ethers.providers.JsonRpcProvider(RPCS[80001]),
    137: new ethers.providers.JsonRpcProvider(RPCS[137]),
}

const networkNames = {
    1: "Ethereum",
    5: "Ethereum Goerli",
    97: "Binance Testnet",
    250: "Fantom",
    4002: "Fantom Testnet",
    421613: "Arbitrum Goerli",
    42161: 'Arbitrum',
    11155111: 'Sepolia Testnet',
    80001: "Polygon Testnet",
    137: "Polygon Mainnet",
}

const alchemyNetworks = {
    1: Network.ETH_MAINNET,
    5: Network.ETH_GOERLI,
    421613: Network.ARB_GOERLI,
    11155111: Network.ETH_SEPOLIA,
    80001: Network.MATIC_MUMBAI,
    137: Network.MATIC_MAINNET,
}

const provider = providers[supportChainId];
const network = networkNames[supportChainId];
const alchemyNetwork = alchemyNetworks[supportChainId];

export { supportChainId, provider, network, alchemyNetwork };
