import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import '../../assets/style/slider.scss';
import Icon from '../../components/Icon';
import Layout from '../../components/Layout/Layout';
import { ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { ellipsis } from '../../util';
import { GET_NFTS, GET_TOP_CREATOR, GET_PRICES, GET_TOP_SELL_NFT, GET_MAIN_NFTS, GET_POPULAR_COLLECTION } from '../../graphql/index';

import news1 from '../../assets/img/news/1.png';
import news2 from '../../assets/img/news/2.png';
import news3 from '../../assets/img/news/3.png';
import news4 from '../../assets/img/news/4.png';
import banner from '../../assets/img/banner.webp';
import collectionImage from '../../assets/img/collection.webp';
import verifiedImg from '../../assets/img/verifiedImg.png';

interface HomeInterface {
  nfts: NFTSearchResult[]
  width: number
  prices: Prices[]
  creators: TopCreator[]
  topSellNFTs: NFTSearchResult[]
  mainNFTs: NFTSearchResult[]
  collectionSwitch: number
  popularCollection: Collection[]
  popularCollectionLoading: boolean
  creatorsLoading: boolean
  mainNFTLoading: boolean
  topSellLoading: boolean
}

const Home = () => {
  const theme = useContext(ThemeContext);
  const { darkMode, lang, isMetamask } = useStore()
  const navigate = useNavigate()
  const { t } = useI18n()

  const [creatorCount, setCreatorCount] = useState<number>(1);
  const [solidorCount, setSolidorCount] = useState<number>(2);
  const largeMatches = useMediaQuery('(min-width: 1360px)');
  const mediumMatches = useMediaQuery('(min-width: 1280px)');
  const smallMatches = useMediaQuery('(min-width: 1024px)');
  const extraSmallMatches = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    let tempCreator = 1;
    let tempSolidor = 2;

    if (largeMatches) {
      tempCreator = 4;
      tempSolidor = 5;
    } else if (mediumMatches) {
      tempCreator = 3;
      tempSolidor = 4;
    } else if (smallMatches) {
      tempCreator = 2;
      tempSolidor = 3;
    } else if (extraSmallMatches) {
      tempCreator = 2;
      tempSolidor = 2;
    }

    setCreatorCount(tempCreator);
    setSolidorCount(tempSolidor);
  }, [largeMatches, mediumMatches, smallMatches, extraSmallMatches])


  const [status, setStatus] = React.useState<HomeInterface>({
    nfts: [],
    prices: [],
    width: window.innerWidth,
    creators: [],
    topSellNFTs: [],
    mainNFTs: [],
    collectionSwitch: 30,
    popularCollection: [],
    popularCollectionLoading: true,
    creatorsLoading: true,
    mainNFTLoading: true,
    topSellLoading: true,
  })

  const updateStatus = (params: Partial<HomeInterface>) => setStatus({ ...status, ...params })

  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (priceLoading || priceLoading) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    updateStatus({ prices: data })
  }, [priceInfo, priceLoading, priceError])

  const { data: creatorInfo, loading: creatorLoading, error: creatorError } = useQuery(GET_TOP_CREATOR, {
    fetchPolicy: 'network-only',
    pollInterval: 10000
  })

  React.useEffect(() => {
    if (creatorLoading || creatorLoading) return;
    const data = creatorInfo?.getTopCreator as TopCreator[];
    if (!data) return updateStatus({ creatorsLoading: false });
    updateStatus({ creators: data, creatorsLoading: false })
  }, [creatorInfo, creatorLoading, creatorError])


  const { data: topSellInfo, loading: topSellLoading, error: topSellError } = useQuery(GET_TOP_SELL_NFT, {
    fetchPolicy: 'network-only',
    pollInterval: 10000
  })

  React.useEffect(() => {
    if (topSellLoading || topSellLoading) return;
    const data = topSellInfo?.getTopSellNFT as NFTSearchResult[];
    if (!data) return updateStatus({ topSellLoading: false });
    updateStatus({ topSellNFTs: data, topSellLoading: false })
  }, [topSellInfo, topSellLoading, topSellError])

  const { data: popularCollectionInfo, loading: popularCollectionLoading, error: popularCollectionError } = useQuery(GET_POPULAR_COLLECTION,
    {
      variables: {
        days: status.collectionSwitch
      },
      fetchPolicy: 'network-only',
      pollInterval: 10000
    }
  )

  React.useEffect(() => {
    if (popularCollectionLoading || popularCollectionLoading) return;
    const data = popularCollectionInfo?.getPopularCollection as Collection[];
    if (!data) return updateStatus({ popularCollectionLoading: false });
    updateStatus({ popularCollection: data, popularCollectionLoading: false })
  }, [popularCollectionInfo, popularCollectionLoading, popularCollectionError])


  const { data: mainNftInfo, loading: mainNftLoading, error: mainNftError } = useQuery(GET_MAIN_NFTS, {
    fetchPolicy: 'network-only',
    pollInterval: 10000
  })

  React.useEffect(() => {
    if (mainNftLoading || mainNftLoading) return;
    const data = mainNftInfo?.getMainNFTs as NFTSearchResult[];
    if (!data) return updateStatus({ mainNFTLoading: false });
    console.log("mainNftInfo", data);
    updateStatus({ mainNFTs: data, mainNFTLoading: false })
  }, [mainNftInfo, mainNftLoading, mainNftError])

  const { data: nftInfo, loading: infoLoading, error: infoError } = useQuery(GET_NFTS, {
    variables: {
      query: '',
      nftcollection: '',
      sort: "latest",
      price1: 0,
      price2: 0,
      owner: '',
      searchsymbol: '',
      salestatus: '',
      page: 1,
      limit: 10,
      symbols: ''
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000
  });

  React.useEffect(() => {
    if (infoLoading || infoError) return;
    const data = nftInfo?.getNFTs as NFTSearchResult[];
    if (!data) return;
    updateStatus({ nfts: data })
  }, [nftInfo, infoLoading, infoError])

  return (
    <Layout>
      {/* menu bar */}
      <StyledMenuBar>
        <a className="menu" target='_blank' style={{ display: 'block' }}
          href={`https://mechaikenft.gitbook.io/mechaikenft/hajimeni/nfttoha` + (lang == "jp" ? 'ja' : 'en')}
        >
          {t("home.support")}
        </a>

        <Link className="menu" to="/profile/wallet/deposit">{t('home.wallet')}</Link>
        <Link className="menu active" to="/search-nft">{t("home.findNFT")}</Link>
        <Link className="menu" to="/search-collection">{t("home.findCollection")}</Link>
        <Link className="menu" to={isMetamask ? "/items/create" : "/items/select-options"} >{t("home.createNFT")}</Link>
      </StyledMenuBar>

      {/* collection panel*/}
      <StyledCollectionPanel>
        <div className="first-row">
          {!status.popularCollectionLoading && status.popularCollection.map((collection, index) => {
            if (index < 9) {
              return (
                <Link className="collection-card" key={"topcollection" + index}
                  to={"/collection/" + (collection.url || collection.address)}
                >
                  <img src={collection.metadata?.coverImage || collection.metadata?.image || collectionImage} alt="collection" />
                </Link>)
            }
          })}

          {status.popularCollectionLoading && (
            <>
              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor} >
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>
            </>
          )}
        </div>

        <div className="second-row">
          {!status.popularCollectionLoading && status.popularCollection.map((collection, index) => {
            if (index >= 9) {
              return (
                <Link className="collection-card" key={"second-collection" + index}
                  to={"/collection/" + (collection.url || collection.address)}
                >
                  <img src={collection.metadata?.coverImage || collection.metadata?.image || collectionImage} alt="collection" />
                </Link>
              )
            }

            return null;
          })}

          {status.popularCollectionLoading && (
            <>
              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor} >
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>

              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                <Skeleton count={1} style={{ margin: '8px' }}
                  width={window.innerWidth < 768 ? 140 : 240}
                  height={window.innerWidth < 768 ? 84 : 120}
                />
              </SkeletonTheme>
            </>
          )}
        </div>
      </StyledCollectionPanel>

      {/* top creator panel*/}
      <StyledPickCreatorPanel darkMode={darkMode}>
        <div className="container">
          <h1>{t("home.pickCreator")}</h1>

          <CreatorsCardsWrapper>
            {status.creators.slice(0, creatorCount).map((creator, key) => (
              <Link className="nft-card" to={"/" + creator?.address} key={key}>
                <div className='img'>
                  <img src={creator?.banner_img || banner} alt="nft" />
                </div>

                <div className="name">
                  <p style={{ margin: '14px 0' }}>{creator?.name || ellipsis(creator?.address, 6, false)}</p>
                  {creator?.verified === "verified" && (
                    <img src={verifiedImg} alt="" />
                  )}
                </div>

                <div className='price'>
                  <p className='eth mt0'>{Number(creator?.volume?.toFixed(4)) || 0} USD</p>
                  <p className='items mt0 ml2'>{creator?.items || 0} Items</p>
                </div>
              </Link>
            ))}

            {status.creatorsLoading && (
              [1, 2, 3, 4].map(element => (
                <div key={"creator-loading" + element}>
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 300}
                      height={window.innerWidth < 768 ? 84 : 150}
                    />
                  </SkeletonTheme>

                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 70 : 150}
                      height={window.innerWidth < 768 ? 10 : 20}
                    />
                  </SkeletonTheme>

                  <div className="flex">
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 30 : 60}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 30 : 60}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              ))
            )}
          </CreatorsCardsWrapper>
        </div>
      </StyledPickCreatorPanel>

      {/* recently sold panel*/}
      <StyledSoldNftPanel darkMode={darkMode}>
        <div className="container">
          <h1>{t("home.soldNFT")}</h1>

          <RecentlySoldCardsWrapper>
            {status.topSellNFTs.slice(0, solidorCount).map((nft, key) => (
              <RecentlySoldNFTCard key={key}
                collectionName={nft?.collectionname}
                className='nft-card' nftName={nft?.name}
                count={status.topSellNFTs.length > solidorCount ? solidorCount : status.topSellNFTs.length}
              >
                <div className='img' onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                  <img src={nft?.coverImage} alt="nft" />
                </div>

                <Link className="collection" to={"/collection/" + nft?.nftCollection}>
                  <p className='m0 mt1 mb1 collection-name'>
                    {nft?.collectionname}
                  </p>

                  {nft?.collectionverified === "verified" && (
                    <img src={verifiedImg} alt="" />
                  )}
                </Link>

                <div className='name' onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                  <p className='mt0 nft-name' style={{ width: ((nft.price > 0 && nft.saleType !== "") ? 'calc(100% - 80px)' : '100%') }}>
                    {nft?.name}
                  </p>

                  {(nft.price > 0 && nft.saleType !== "") && (
                    <p className='price mt0 ml1'>{nft.price} {nft.acceptedToken}</p>
                  )}
                </div>

                <div className="hr m0" style={{ borderColor: "#3A3A3A" }} />

                <div className="fav" onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                  <Icon icon="Favorite" size={20} height={20} />
                  <p className='m0 ml2' >{nft.favs}</p>
                </div>
              </RecentlySoldNFTCard>
            ))}

            {status.topSellLoading && (
              <React.Fragment>
                <div>
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 260}
                      height={window.innerWidth < 768 ? 140 : 260}
                    />
                  </SkeletonTheme>

                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 250}
                      height={window.innerWidth < 768 ? 10 : 20}
                    />
                  </SkeletonTheme>

                  <div className="flex">
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>
                  </div>
                </div>

                <div>
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 260}
                      height={window.innerWidth < 768 ? 140 : 260}
                    />
                  </SkeletonTheme>

                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 250}
                      height={window.innerWidth < 768 ? 10 : 20}
                    />
                  </SkeletonTheme>

                  <div className="flex">
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>
                  </div>
                </div>

                <div>
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 260}
                      height={window.innerWidth < 768 ? 140 : 260}
                    />
                  </SkeletonTheme>

                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 250}
                      height={window.innerWidth < 768 ? 10 : 20}
                    />
                  </SkeletonTheme>

                  <div className="flex">
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>
                  </div>
                </div>

                <div>
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 260}
                      height={window.innerWidth < 768 ? 140 : 260}
                    />
                  </SkeletonTheme>

                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <Skeleton count={1} style={{ margin: '8px' }}
                      width={window.innerWidth < 768 ? 140 : 250}
                      height={window.innerWidth < 768 ? 10 : 20}
                    />
                  </SkeletonTheme>

                  <div className="flex">
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 50 : 100}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </React.Fragment>
            )}
          </RecentlySoldCardsWrapper>
        </div>
      </StyledSoldNftPanel>

      {/* trending NFT panel*/}
      <StyledRecommendNftPanel>
        <div className="container">
          <div className='ml-auto mr-auto col-lg-10 col-sm-12' style={{ overflow: 'hidden' }}>
            <h1>{t("home.pickNFT")}</h1>

            <div className="nft-row">
              {status.mainNFTs.map((nft, key) => {
                if (nft.name && nft.coverImage) {
                  return (
                    <StyledNFTCard className='nft-card' key={key}
                      collectionName={nft?.collectionname} nftName={nft?.name}
                    >
                      <div className='img' onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                        <img src={nft?.coverImage} alt="nft" />
                      </div>

                      <Link className="collection" to={"/collection/" + nft?.nftCollection}>
                        <p className='m0 mt1 mb1 collection-name' >
                          {nft?.collectionname}
                        </p>

                        {nft?.collectionverified === "verified" && (
                          <img src={verifiedImg} alt="" style={{ zIndex: 1000 }} />
                        )}
                      </Link>

                      <div className='name' onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                        <div style={{ width: ((nft.price > 0 && nft.saleType !== "") ? 'calc(100% - 74px)' : '100%'), overflow: 'hidden' }}>
                          <p className='mt0 nft-name' >
                            {nft?.name}
                          </p>
                        </div>

                        {(nft.price > 0 && nft.saleType !== "") && (
                          <p className='price mt0 ml1'>{nft.price} {nft.acceptedToken}</p>
                        )}
                      </div>

                      <div className="hr m0" style={{ borderColor: "#3A3A3A" }} />

                      <div className="fav" onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
                        <Icon icon="Favorite" size={20} height={20} />
                        <p className='m0 ml2' >{nft.favs}Fav</p>
                      </div>
                    </StyledNFTCard>
                  )
                }

                return null;
              })}

              {status.mainNFTLoading && (
                <>
                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>

                  <div>
                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 140 : 220}
                        height={window.innerWidth < 768 ? 140 : 220}
                      />
                    </SkeletonTheme>

                    <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                      <Skeleton count={1} style={{ margin: '8px' }}
                        width={window.innerWidth < 768 ? 100 : 200}
                        height={window.innerWidth < 768 ? 10 : 20}
                      />
                    </SkeletonTheme>

                    <div className="flex">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>

                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton count={1} style={{ margin: '8px' }}
                          width={window.innerWidth < 768 ? 50 : 100}
                          height={window.innerWidth < 768 ? 10 : 20}
                        />
                      </SkeletonTheme>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex center">
              <div className="flex center mt5">
                <ButtonPrimary style={{ backgroundColor: 'transparent', color: darkMode ? theme.white : theme.black, border: '1px solid #777777', width: '200px' }} onClick={() => { navigate("/search-nft") }}>
                  {t("home.seeMore")}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </StyledRecommendNftPanel>

      {/* popular collection panel*/}
      <StyledPopularCollectionPanel className='mt5' darkMode={darkMode}>
        <div className="container">
          <div className='ml-auto mr-auto col-sm-12' style={{ overflow: 'hidden' }}>
            <div className="flex justify wrap">
              <h1>{t("home.popularCollection")}</h1>

              <div className='flex day-panel'>
                <div style={{ backgroundColor: 'transparent', padding: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRadius: '8px' }}>
                  <div style={{ padding: '8px', width: '80px', textAlign: 'center', margin: '0 4px', cursor: 'pointer', fontWeight: 'bold', borderRadius: '20px', color: status.collectionSwitch === 1 ? theme.bgColor2 : theme.text, backgroundColor: status.collectionSwitch === 1 ? theme.text : 'transparent' }} onClick={() => { updateStatus({ collectionSwitch: 1 }) }}>{t("home.1day")}</div>

                  <div style={{ padding: '8px', width: '80px', textAlign: 'center', margin: '0 4px', cursor: 'pointer', fontWeight: 'bold', borderRadius: '20px', color: status.collectionSwitch === 7 ? theme.bgColor2 : theme.text, backgroundColor: status.collectionSwitch === 7 ? theme.text : 'transparent' }} onClick={() => { updateStatus({ collectionSwitch: 7 }) }}>{t("home.7day")}</div>

                  <div style={{ padding: '8px', width: '80px', textAlign: 'center', margin: '0 4px', cursor: 'pointer', fontWeight: 'bold', borderRadius: '20px', color: status.collectionSwitch === 30 ? theme.bgColor2 : theme.text, backgroundColor: status.collectionSwitch === 30 ? theme.text : 'transparent' }} onClick={() => { updateStatus({ collectionSwitch: 30 }) }}>{t("home.30day")}</div>
                </div>
              </div>
            </div>

            <div className="nft-row">
              {status.popularCollection.map((collection, key) => {
                const collection1 = status.popularCollection[key];
                const collection2 = status.popularCollection[key + 1];
                const collection3 = status.popularCollection[key + 2];
                if (key % 3 == 0) {
                  return (
                    <div key={key}>
                      {collection1 && (
                        <StyledCollectionCard key={key}
                          onClick={() => { navigate("/collection/" + (collection1.url || collection1.address)) }}
                        >
                          <div>
                            <p className='p1'><b>{key + 1}</b></p>
                          </div>

                          <div className='img'>
                            <img src={collection1.metadata?.image || collection1.metadata?.coverImage || collectionImage} alt="nft" />

                            {collection1.verified?.status === "verified" && (
                              <img src={verifiedImg} alt="" className='verified' />
                            )}
                          </div>

                          <div className='name ml2'>
                            <p className='m0'>
                              {collection1.metadata?.name}
                            </p>

                            <p className='price m0'> {Number(collection1.volume?.toFixed(6))} MATIC</p>
                          </div>
                        </StyledCollectionCard>
                      )}

                      {collection2 && (
                        <StyledCollectionCard key={key + 1}
                          onClick={() => { navigate("/collection/" + (collection2.url || collection2.address)) }}
                        >
                          <div>
                            <p className='p1'><b>{key + 2}</b></p>
                          </div>

                          <div className='img'>
                            <img src={collection2.metadata?.image || collection2.metadata?.coverImage || collectionImage} alt="nft" />

                            {collection2.verified?.status === "verified" && (
                              <img src={verifiedImg} alt="" className='verified' />
                            )}
                          </div>

                          <div className='name ml2'>
                            <p className='m0'>{collection2.metadata?.name}</p>
                            <p className='price m0'>{Number(collection2.volume?.toFixed(6))} MATIC</p>
                          </div>
                        </StyledCollectionCard>
                      )}

                      {collection3 && (
                        <StyledCollectionCard key={key + 2}
                          onClick={() => { navigate("/collection/" + (collection3.url || collection3.address)) }}
                        >
                          <div>
                            <p className='p1'><b>{key + 3}</b></p>
                          </div>

                          <div className='img'>
                            <img src={collection3.metadata?.image || collection3.metadata?.coverImage || collectionImage} alt="nft" />

                            {collection3.verified?.status === "verified" && (
                              <img src={verifiedImg} alt="" className='verified' />
                            )}
                          </div>

                          <div className='name ml2'>
                            <p className='m0'>{collection3.metadata?.name}</p>
                            <p className='price m0'>{Number(collection3.volume?.toFixed(6))} MATIC</p>
                          </div>
                        </StyledCollectionCard>
                      )}
                    </div>
                  )
                }

                return null;
              })}

              {status.popularCollectionLoading && (
                <>
                  <div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                    <div className="flex middle">
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton width={70} height={70} count={1} style={{ margin: '8px' }} />
                      </SkeletonTheme>
                      <div>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <Skeleton width={window.innerWidth < 768 ? 100 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px' }} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </StyledPopularCollectionPanel>

      <StyledNewsPanel darkMode={darkMode}>
        <div className="container">
          <h1 className='title'>{t("home.news")}</h1>

          <div className='ml-auto mr-auto col-xl-12 col-sm-12' style={{ overflow: 'hidden' }}>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <a target='_blank' style={{ textDecoration: 'unset', color: 'white' }}
                  href='https://mechaikenft.gitbook.io/mechaikenft/hajimeni/nfttoha'
                >
                  <div className="card">
                    <div className='img'><img src={news1} alt="" /></div>
                    <h3>{t("footer.whatnft")}</h3>
                  </div>
                </a>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <a target='_blank' style={{ textDecoration: 'unset', color: 'white' }}
                  href='https://mechaikenft.gitbook.io/mechaikenft/hajimeni/akaunto'
                >
                  <div className="card">
                    <div className='img'><img src={news2} alt="" /></div>
                    <h3>{t("footer.howcreate")}</h3>
                  </div>
                </a>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <a target='_blank' style={{ textDecoration: 'unset', color: 'white' }}
                  href='https://mechaikenft.gitbook.io/mechaikenft/nftno/nonftno'
                >
                  <div className="card">
                    <div className='img'><img src={news3} alt="" /></div>
                    <h3>{t("footer.howpurchase")}</h3>
                  </div>
                </a>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <a target='_blank' style={{ textDecoration: 'unset', color: 'white' }}
                  href='https://mechaikenft.gitbook.io/mechaikenft/nftno-1/nftno-1'
                >
                  <div className="card">
                    <div className='img'><img src={news4} alt="" /></div>
                    <h3>{t("footer.howsell")}</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </StyledNewsPanel>
    </Layout >
  )
}

export default Home;

const StyledNewsPanel = styled.div<{ darkMode: boolean }>`
	background-color:  ${({ darkMode, theme }) => darkMode ? theme.footerPanel : '#EBEBEB'};
	padding: 2rem 0;
	margin: 2rem 0 4rem;
	color:   ${({ theme }) => theme.text};

	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}

	@media (max-width: 768px) {
		padding: 0 0 1rem 0;
	}

	.title {
		text-align: center;
		margin-bottom: 3rem;
		@media (max-width: 768px) {
			margin-bottom: 1rem;
			transform: translateY(-1.2rem);
		}
	}

	.card {
		transition: 0.2s;
		cursor: pointer;
		padding: 1rem 0;
		user-select: none;
    max-width: 280px;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 250px;
    }

		.img {
			border-radius: 1rem;
			overflow: hidden;
			width: 100%;
			height: auto;
			padding: 0;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				overflow: hidden;
			}
		}
    
		&:hover {
			@media (min-width: 768px) {
				transform: translateY(-4px);
			}
		}
		@media (max-width: 768px) {
			padding: 8px;
		}
	}
`

const StyledCollectionCard = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	transition: 0.2s;
	min-width: 280px;
	margin: 1rem  1rem 1rem 0;
	padding: 8px;
	border-radius: 4px;
	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}
	&:hover {
		background-color: rgba(120, 120, 120, 0.1);
	}
	.img {
		position: relative;
		img {
			border-radius: 8px;
			width: 75px;
			height: 75px;
		}
		.verified {
			width: 26px;
			height: 26px;
			position: absolute;
			bottom: -6px;
			right: -10px;
		}
	}
	.name {
		max-width: 200px;
		width: 100%;
		p {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;	
		}
		.price {
			color: #78C4A0;
		}
	}
`

const StyledPopularCollectionPanel = styled.div<{ darkMode: boolean }>`
	margin: 2rem auto;
  max-width: 100vw;

	.nft-row {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		overflow-x: auto;
		flex-wrap: no-wrap;

		::-webkit-scrollbar-thumb {
			background-color: ${({ darkMode }) => darkMode ? 'rgba(10, 10, 10, 0.9)' : 'rgba(200, 200, 200, 0.8)'};
			border-radius: 6px;
		}

		::-webkit-scrollbar {
			height: 8px;
		}
		
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}

	.day-panel{
		margin-left: auto;
		@media (max-width: 768px) {
			margin-right: auto;
		}
	}
`

const StyledRecommendNftPanel = styled.div`
	margin: 2rem auto;
	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}
	.nft-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.nft-card {
			margin: 1rem 0;
			width: 20%;
			padding: 6px;
			.img {
				width: 100%;
				height: auto;
				aspect-ratio: 1 / 1;
			}
			@media (max-width: 1360px) {
				width: 25%;
			}
			@media (max-width: 1280px) {
				width: 33%;
			}
			@media (max-width: 1024px) {
				width: 50%;
			}
			@media (max-width: 768px) {
				width: 50%;
				margin: 4px 0;
				padding: 4px 2px;
				.img {
					width: 100%;
				}
			}
		}
		
		.nft-card {
			@media (max-width: 480px) {
				width: 48%;
				margin: 8px auto;
			}
			.img {
				@media (max-width: 360px) {
					width: 100%;
					height: auto;
				}
			}
		}	
	}
`

const StyledSoldNftPanel = styled.div<{ darkMode: boolean }>`
	max-width: 100vw;
	margin: 2rem auto;

	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}
`

const StyledPickCreatorPanel = styled.div<{ darkMode: boolean }>`
	max-width: 100vw;
	margin: 1rem auto;

	h1 {
		font-size: 40px;
		font-weight: bold;
		margin: 40px 0;
		@media (max-width: 768px) {
			font-size: 24px;
			margin: 16px 0;
		}
	}

	a {
		text-decoration: none;
		color: ${({ theme }) => theme.text};
	}
`

const StyledCollectionPanel = styled.div`
	border-top: 1px solid #3A3A3A;
	padding: 1rem 0;
	margin: 8px auto;
	width: 100%;
	overflow: hidden;
	@media (max-width: 768px) {
		margin: 0 auto;
		padding: 8px 0;
	}
	.first-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 110%;
		margin: 0 -5%;
		flex-wrap: no-wrap;
		@media (max-width: 1024px) {
			/* flex-wrap: wrap; */
			margin: 0;
			width: 100%;
		}
	}
	.second-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 90%;
		margin: 0 5%;
		@media (max-width: 1024px) {
			display: none;
		}
	}
	.collection-card {
		max-width: 240px;
		max-height: 120px;
		border-radius: 8px;
		width: 240px;
		height: 120px;
		min-width: 240px;
		cursor: pointer;
		overflow: hidden;
		margin: 8px;	
		user-select: none;
		position: relative;
		@media (max-width: 1024px) {
			/* width: 45%;
			min-width: 40%;
			height: 84px; */
		}
		img {
			object-fit: cover;
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
			border-radius: 8px;
			width: 100%;
			height: 100%;
			max-height: 120px;
		}
		&:hover {
			img {
				@media (min-width: 768px) {
					transform: scale(1.2);
				}
			}
		}
	}
`

const StyledMenuBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 1rem auto;
	max-width: 1200px;
	@media (max-width: 768px) {
		display: none;
	}
	.menu{
		cursor: pointer;
		text-decoration: none;
		color: ${({ theme }) => theme.text};
		font-size: 1rem;
		font-weight: bold;
		padding: 0 1rem;
		&:hover {
			color: ${({ theme }) => theme.textGrey};
		}
	}
`

const CreatorsCardsWrapper = styled.div({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  overflow: 'hidden',

  '.nft-card': {
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',

    '&:hover': {
      '.img': {
        'img': {
          '@media (min-width: 768px)': {
            transform: 'scale(1.2)'
          }
        }
      }
    },

    '.img': {
      borderRadius: '12px',
      width: '300px',
      height: '150px',
      overflow: 'hidden',
      minWidth: '250px',

      img: {
        borderRadius: '12px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s',
      }
    },

    '.name': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',

      img: {
        width: '26px',
        height: '26px',
      }
    },

    '.price': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '12px',

      '.eth': {
        color: '#78C4A0',
      },

      '.items': {
        color: '#F56F63',
      }
    }
  }
})

const RecentlySoldCardsWrapper = styled.div({
  gap: 15,
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',

  '.nft-card': {
    '@media (max-width: 768px)': {
      width: "48%",
      margin: "0px auto",
    }
  }
})

const StyledNFTCard = styled.div<{ collectionName?: string, nftName?: string }>`
	cursor: pointer;
	transition: 0.2s ease-in;
	text-decoration: none;
	color: ${({ theme }) => theme.text};

	@media (max-width: 480px) {
		width: 180px;
	}

	.img {
		border-radius: 1rem;
		width: 246px;
		height: 246px;
		overflow: hidden;
		aspect-ratio: 1 / 1;
    max-width: 100%;

		@media (max-width: 480px) {
			width: 180px;
			height: 180px;
		}

		img {
			border-radius: 1rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
			aspect-ratio: 1 / 1;
		}

		&:hover {
			img {		
				@media (min-width: 768px) {
					transform: scale(1.2);
				}
			}
		}
	}

	.fav {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #9A9FBB;
		margin-top: 14px;
	}

	.collection {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			width: 26px;
			height: 26px;
			z-index: 1000;
		}

		color: #9A9FBB;
		text-decoration: none;

		&:hover {
			color: #379cc3;
			.collection-name {
				// text-overflow: initial;
				// white-space: nowrap;
				// animation: ${({ collectionName }) => (collectionName || "")?.length > 22 ? "marquee 3s linear infinite alternate" : 'none'};
			}
		}

		overflow: hidden;
	}

	.collection-name {
		max-width: calc(100% - 32px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 14px 0;
		// &.marquee {
		// 	animation: ${({ collectionName }) => (collectionName || "")?.length > 22 ? "marquee 3s linear infinite alternate" : 'none'};
		// }
		font-size: 14px;
	}

	.nft-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		&.marquee {
			animation: ${({ nftName }) => (nftName || "")?.length > 22 ? "marquee 3s linear infinite alternate" : 'none'};
		}
	}

	.name {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		font-size: 14px;
		.price {
			color: #78C4A0;
			font-size: 12px;
			width: 70px;
			text-align: right;
		}

		@media (max-width: 480px) {
			font-size: 0.85rem;
			.price {
				font-size: 0.8rem;
			}
		}
    
		&:hover {
			color: #379cc3;
			.nft-name {
				text-overflow: inherit;
				width: ${({ nftName }) => {
    if (nftName && nftName?.length > 20) {
      return `${(nftName?.length || 0) * 7}px!important;`
    }
    else {
      return 'auto'
    }
  }};
				animation: ${({ nftName }) => (nftName || "")?.length > 22 ? "marquee 3s linear infinite alternate" : 'none'};
			}
		}
	}
	
	@-webkit-keyframes marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
   }
	
   @-moz-keyframes  marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
   }
   
	@keyframes marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
	}
`


const RecentlySoldNFTCard = styled(StyledNFTCard)<{ count: number }>(({ count }) => ({
  maxWidth: `calc((100% - ${15 * (count - 1)}px)/${count})`,

  // '@media (max-width: 1360px)': {
  //   maxWidth: `calc((100% - ${15 * (count - 1)}px)/${count})`,
  // },

  // '@media (max-width: 1280px)': {
  //   maxWidth: `calc((100% - ${15 * (count - 1)}px)/${count})`,
  // },

  // '@media (max-width: 1024px)': {
  //   maxWidth: `calc((100% - ${15 * (count - 1)}px)/${count})`,
  // },

  // '@media (max-width: 768px)': {
  // },
}))