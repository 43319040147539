import React from 'react'
import { ethers } from "ethers";
import hottoast from 'react-hot-toast';
import { toast } from 'react-toastify';
import config from './config/config.json';
import Icon from '../src/components/Icon'
import alertImg from './assets/img/alert.png'
import waiting from './assets/img/waiting.svg'

export const proxy = config.REACT_APP_PROXY || 'http://127.0.0.1:5000/';

export const showToast = (darkMode: boolean, img: string | any, text: string, callback?: Function) => {
	hottoast.custom((t) => {
		return <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} flex middle center`} style={{ backgroundColor: 'rgb(53 78 114 / 49%)', zIndex: 100000, padding: '1rem', borderRadius: '8px', cursor: 'pointer' }}
			onClick={() => {
				toast.dismiss(t.id);
				callback && callback();
			}}>
			<img style={{ width: '70px', height: '70px', borderRadius: '8px', marginRight: '1rem' }} src={img || alertImg} alt="img" />
			<p style={{ color: darkMode ? 'white' : 'black' }}>
				<b> {text} </b>
			</p>
		</div>
	},
		{
			duration: 10000
		}
	)
}


export const showWaiting = (darkMode: boolean, text: string, callback?: Function) => {
	hottoast.custom((t) => (
		<div
			className={`${t.visible ? 'animate-enter' : 'animate-leave'} flex middle center`}
			style={{
				backgroundColor: 'rgb(53 78 114 / 49%)', zIndex: 100000, padding: '1rem', borderRadius: '8px', cursor: 'pointer'
			}}
		// onClick={() => {toast.dismiss(t.id); callback && callback()}}
		>
			<img style={{ width: '70px', height: '70px', borderRadius: '8px', marginRight: '1rem' }} src={waiting} alt="img" />
			<p style={{ color: darkMode ? 'white' : 'black' }}> <b> {text} </b></p>
		</div>
	),
		{
			duration: 1000000
		}
	)
}

export const tips = (type = "success", html: string, lang = "en") => {
	let background = "#3772FF";
	let icon = <></>;
	switch (type) {
		case "success": background = "#3772FF"; icon = <Icon icon="Info" size={40} height={32} />; break;
		case "info": background = "#139D61"; icon = <Icon icon="Info" size={40} height={32} />; break;
		case "warning": background = "#F56F63"; icon = <Icon icon="Warning" size={40} height={32} />; break;
		case "error": background = "#F56F63"; icon = <Icon icon="Error" size={40} height={32} />; break;
	}
	toast(
		<div className='flex middle'>
			<div style={{ margin: '0 8px' }}>{icon}</div>
			<p className='p0 m0'>{html}</p>
		</div>,
		{
			position: "top-right",
			// autoClose: 8000,
			closeButton: <Icon icon="Close" size={20} />,
			// hideProgressBar: true,
			progressStyle: {
				background: 'rgba(255,255,255, 0.6)',
			},
			draggable: true,
			style: {
				backgroundColor: background,
				color: 'white',
				zIndex: 1000,
				fontWeight: 'bold',
				borderRadius: '12px'
			}
		}
	);
}

export const isMobile = () => {

}

export const Now = () => Math.round(new Date().getTime() / 1000);

export const TF = (time: number, offset: number = 2) => {
	// let iOffset = Number(offset);
	let date = new Date(time);
	let y = date.getUTCFullYear();
	let m = date.getUTCMonth() + 1;
	let d = date.getUTCDate();
	let hh = date.getUTCHours();
	let mm = date.getUTCMinutes();
	let ss = date.getUTCSeconds();
	let dt = ("0" + m).slice(-2) + "-" + ("0" + d).slice(-2);
	let tt = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2) + ":" + ("0" + ss).slice(-2);
	return y + '-' + dt + ' ' + tt;
}

export const NF = (num: number, p: number = 2) => num.toLocaleString('en', { maximumFractionDigits: p });

export const validateEmail = (email: string): boolean => email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;

export const validateUsername = (username: string): boolean => username.match(/^[a-zA-Z\-\_0-9]{3,20}$/) !== null;
export const validateSymbol = (symbol: string): boolean => symbol.match(/^[a-zA-Z\-\_0-9]{1,20}$/) !== null;
export const validateSlugUrl = (slugUrl: string): boolean => slugUrl.match(/^[a-zA-Z\-0-9]{3,50}$/) !== null;

export const validateNumber = (number: string): boolean => parseFloat(number) > 0;

export const validateName = (username: string): boolean => username.match(/^[a-zA-Z]{2,20}$/) !== null;
export const validateCvv = (number: string): boolean => number.match(/^[0-9]{3}$/g) !== null;
export const validateCardNumber = (number: string) => {
	const visaPattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
	const mastercardPattern = /^5[1-5][0-9]{14}$/;
	if (visaPattern.test(number)) {
		return "VISA";
	}
	if (mastercardPattern.test(number)) {
		return "Master";
	}
	return false;
}
export const validateExpiryDate = (string: number) => {
	const now = new Date().getTime();
	if (string - now > 0) {
		return true;
	}
	return false
}

export const validatePhone = (number: string): boolean => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(number);

export const validatePassword = (number: string): boolean => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&#]{8,30}$/g.test(number);

export const validateUrl = (str: string = ''): boolean => {
	var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	var regex = new RegExp(expression);
	return str.match(regex) ? true : false
}

export const toLocaleSting = (time) => {
	let date = new Date(time);
	let y = date.getUTCFullYear();
	let m = date.getUTCMonth() + 1;
	let d = date.getUTCDate();
	// let hh = date.getUTCHours();
	// let mm = date.getUTCMinutes();
	// let ss = date.getUTCSeconds();
	let dt = ("0" + m).slice(-2) + "-" + ("0" + d).slice(-2);
	// let tt = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2) + ":" + ("0" + ss).slice(-2);
	return y + '-' + dt // + 'T' + tt;

}

export const copyToClipboard = (text: string) => {
	var textField = document.createElement('textarea')
	textField.innerText = text
	document.body.appendChild(textField)
	textField.select()
	document.execCommand('copy')
	textField.remove()
	if (text.length > 40) {
		text = text.substring(0, 15) + "..." + text.substring(text.length - 5);
	}
	tips("info", "Copied: " + text);
};

export const toBigNum = (value, d) => {
	return ethers.utils.parseUnits(Number(value).toFixed(d), d);
}

export const fromBigNum = (value, d) => {
	return parseFloat(ethers.utils.formatUnits(value, d));
}

export const toBalance = (value) => {
	var f = parseFloat(ethers.utils.formatEther(value));
	if (f < 0) { f = 0; }
	return NF(f, 4);
}

export const ellipsis = (address: string, start: number = 6, end = true as boolean) => {
	if (!address || address === null) return ''
	const len = (start) + 7;
	if (end) {
		return address.length > len ? `${address?.slice(0, start + 7)}...` : address
	}
	return address.length > len ? `${address?.slice(0, start)}...${address?.slice(-4)}` : address
}

// const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MONTH_NAMES = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

function getFormattedDate(date, prefomattedDate = false as any, hideYear = false) {
	const year = date.getFullYear();
	const month = MONTH_NAMES[date.getMonth()];

	let day = date.getDate();
	let hours = date.getHours();
	let minutes = date.getMinutes();

	if (minutes < 10) minutes = `0${minutes}`;
	if (hours < 10) hours = `0${hours}`;
	if (day < 10) day = `0${day}`;

	if (prefomattedDate) {
		return `${prefomattedDate} at ${hours}:${minutes}`;
	}

	// if (hideYear) {
	// 	return `${day}. ${month} at ${hours}:${minutes}`;
	// }

	// 10. January 2017. at 10:20
	// return `${day}. ${month} ${year}. at ${hours}:${minutes}`;

	return `${year}/${month}/${day} ${hours}:${minutes}`
}

export function timeAgo(dateParam) {
	if (!dateParam) {
		return null;
	}
	const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
	const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
	const today: any = new Date();
	const yesterday = new Date(today - DAY_IN_MS);
	const seconds = Math.round((today - date) / 1000);
	const minutes = Math.round(seconds / 60);
	const isToday = today.toDateString() === date.toDateString();
	const isYesterday = yesterday.toDateString() === date.toDateString();
	const isThisYear = today.getFullYear() === date.getFullYear();

	if (seconds < 0) {
		return new Date(dateParam).toLocaleDateString()
	} else if (seconds < 5) {
		return 'Now';
	} else if (seconds < 60) {
		return `${seconds} seconds ago`;
	} else if (seconds < 90) {
		return 'About a minute ago';
	} else if (minutes < 60) {
		return `${minutes} minutes ago`;
	} else if (isToday) {
		return getFormattedDate(date, 'Today'); // Today at 10:20
	} else if (isYesterday) {
		return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
	} else if (isThisYear) {
		return getFormattedDate(date, false, true); // 10. January at 10:20
	}

	return getFormattedDate(date); // 10. January 2017. at 10:20
}

export function getValidHttpUrl(data: string) {
	let url;
	let IPFS_BASEURL = "https://ipfs.io/ipfs/";

	try {
		url = new URL(data);
	} catch (_) {
		// const cid = new CID(data);
		// ipfs hash : Qm...
		return data;
	}

	if (url.protocol === "http:" || url.protocol === "https:") return data;
	if (url.protocol === "ipfs:") {
		return IPFS_BASEURL + data.replaceAll("ipfs://", "");
	}

	return data;
}