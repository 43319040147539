import React from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "./Icon";
import verifiedImg from '../assets/img/verifiedImg.png'
import NFTImage from '../assets/img/collection.webp'

interface SwitchInterface {
  id: string
  nftCollection: string
  img: any
  tokenId: string
  name: string
  owner: string
  balance: string | number
  balanceUsd: string
  symbol: string
  props?: any
  nft: NFTSearchResult
}

export default function NFTItem({ nft, img, owner, name, id, tokenId, nftCollection, balance, symbol, balanceUsd, props }: SwitchInterface) {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  return (
    <Link to={"/assets/" + nftCollection + "/" + tokenId} style={{ color: theme.text, textDecoration: 'none' }}>
      <StyledNFTCard collectionName={nft?.collectionname} nftName={nft?.name} >
        <div className='img' >
          <img src={nft?.coverImage || NFTImage} alt="nft" />
        </div>

        <Link className="collection" to={"/collection/" + nft?.nftCollection}>
          <p className='m0 mt1 mb1 collection-name'>
            {nft?.collectionname}
          </p>

          {nft?.collectionverified === "verified" && (
            <img src={verifiedImg} alt="" />
          )}
        </Link>

        <div className='name' >
          <div style={{ width: ((nft.price > 0 && nft.saleType !== "") ? 'calc(100% - 78px)' : '100%'), overflow: 'hidden' }}>
            <p className='mt0 nft-name' >
              {nft?.name}
            </p>
          </div>

          {(nft.price > 0 && nft.saleType !== "") && (
            <p className='price mt0 ml1'>{nft.price} {nft.acceptedToken}</p>
          )}
        </div>

        <div className="hr m0" style={{ borderColor: "#3A3A3A" }} />
        <div className="fav" onClick={() => { navigate("/assets/" + nft.nftCollection + "/" + nft.tokenid) }}>
          <Icon icon="Favorite" size={20} height={20} />
          <p className='m0 ml2' >{nft.favs}Fav</p>
        </div>
      </StyledNFTCard>
    </Link>
  )
}

const StyledNFTCard = styled.div<{ collectionName?: string, nftName?: string }>`
	cursor: pointer;
	transition: 0.2s ease-in;
	margin: 1rem 0;
	text-decoration: none;
	color: ${({ theme }) => theme.text};
	width: 100%;
	.img {
		border-radius: 24px;
		width: 100%;
		height: auto;
		overflow: hidden;
		aspect-ratio: 1 / 1;
		img {
			border-radius: 24px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
			aspect-ratio: 1 / 1;
		}
		@media (max-width: 768px) {
			border-radius: 12px;
			img {
				border-radius: 12px;
			}
		}
		&:hover {
			img {		
				@media (min-width: 768px) {
					transform: scale(1.2);
				}
			}
		}
	}
	.fav {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #9A9FBB;
		margin-top: 14px;
	}
	.collection {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 26px;
			height: 26px;
			z-index: 1000;
		}
		color: #9A9FBB;
		text-decoration: none;
		&:hover {
			color: #379cc3;
			.collection-name {
				// text-overflow: initial;
				// white-space: nowrap;
				// animation: ${({ collectionName }) => (collectionName || "")?.length > 22 ? "marquee 3s linear infinite alternate" : 'none'};
			}
		}
		overflow: hidden;
	}
	.collection-name {
		max-width: calc(100% - 38px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 14px 0;
		// &.marquee {
		// 	animation: ${({ collectionName }) => (collectionName || "")?.length > 20 ? "marquee 3s linear infinite alternate" : 'none'};
		// }
		font-size: 14px;
	}
	.nft-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		&.marquee {
			animation: ${({ nftName }) => (nftName || "")?.length > 20 ? "marquee 3s linear infinite alternate" : 'none'};
		}
	}
	.name {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		font-size: 14px;
		.price {
			color: #78C4A0;
			font-size: 12px;
			width: 70px;
			text-align: right;
		}
		@media (max-width: 480px) {
			font-size: 0.85rem;
			.price {
				font-size: 0.8rem;
			}
		}
		&:hover {
			color: #379cc3;
			.nft-name {
				text-overflow: inherit;
				width: ${({ nftName }) => {
    if (nftName && nftName?.length > 21) {
      return `${(nftName?.length || 0) * 7}px!important;`
    }
    else {
      return 'auto'
    }
  }};
				animation: ${({ nftName }) => (nftName || "")?.length > 20 ? "marquee 3s linear infinite alternate" : 'none'};
			}
		}
	}
	
	@-webkit-keyframes marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
   }
	
   @-moz-keyframes  marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
   }
   
	@keyframes marquee {
		0%   { -webkit-transform: translateX(4px); }
		100% { -webkit-transform: translateX(-100%); }
	}
`
