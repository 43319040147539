import React from "react";
import dayjs from "dayjs";
import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../assets/style/DateTimePicker.scss'

interface InputInterface {
  value: string
  label?: string
  errorLabel?: string
  infoLabel?: string
  placeholder?: string
  onChange: Function
  onValueChange?: Function
  type?: string
  min?: number
  max?: number
  defaultDate?: number
  child?: any
  required?: boolean
  inputStyle?: any
}

interface TextFieldProps {
  value: string
  label: string
  linkType: string
  errorLabel: string
  infoLabel: string
  placeholder: string
  required: boolean
  onChange: any
  children: any
}

const InputField = ({ value, label, type, errorLabel, infoLabel, inputStyle, child, placeholder, required, defaultDate = 0, min, max, onChange, onValueChange, ref, ...props }: InputInterface | any) => {
  const theme = useContext(ThemeContext)

  const d = new Date();
  d.setDate(d.getDate() + defaultDate);
  const defaultValue = d.valueOf();
  const [selectedDay, setSelectedDay] = React.useState(defaultValue);

  React.useEffect(() => {
    onValueChange && onValueChange(d.getTime() + 300000)
  }, [])

  return (
    <div style={{ margin: '1.5rem 0' }}>
      <fieldset className="InputField" style={{ border: `1px solid ${theme.strokeWhite}`, position: 'relative', padding: (type === "date" ? '0 0.5rem' : '0.5rem 1.2rem 1rem') }} {...props}>
        {label && (
          <legend>
            {label} {required && <span style={{ color: value === '' ? 'red' : theme.strokeWhite, fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>}
          </legend>
        )}

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {type && type == "textarea" ? (
            <textarea style={{ color: theme.text, ...inputStyle }} onChange={onChange} placeholder={placeholder || ""} rows={5} value={value}></textarea>
          ) : (
            type !== "date" ? (
              type !== "numbner" ? (
                <input type={type || "text"}
                  min={min || 0} max={max}
                  placeholder={placeholder || ""}
                  onChange={onChange} value={value}
                  onWheel={(e) => { e.currentTarget.blur() }}
                  style={{ color: theme.text, padding: '0 8px 0 0', ...inputStyle }}
                />
              ) : (
                <input type={type || "number"}
                  min={min || 0} max={max}
                  placeholder={placeholder || ""}
                  onChange={onChange} value={value}
                  onWheel={(e) => { e.currentTarget.blur() }}
                  style={{ color: theme.text, padding: '0 8px 0 0', ...inputStyle }}
                />
              )
            ) : (
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DateTimePicker']} sx={{ width: "100%" }}>
                  <DateTimePicker
                    defaultValue={dayjs(selectedDay)}
                    onChange={(date) => {
                      const time = new Date(date['$y'] + "-" + (date['$M'] + 1) + "-" + date['$D'] + " " + date['$H'] + ":" + date['$m']).valueOf();
                      onValueChange(time)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>

              // <input type={type || "date"}
              //   min={min || 0} max={max}
              //   placeholder={placeholder || ""}
              //   onChange={onChange} value={value}
              //   onWheel={(e) => { e.currentTarget.blur() }}
              //   style={{ color: theme.text, padding: '0 8px 0 0', ...inputStyle }}
              // />
            ))}

          {child}
        </div>
      </fieldset>

      {(errorLabel && errorLabel != "") && (
        <p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>{errorLabel}</p>
      )}

      {(infoLabel && infoLabel != "") && (
        <p style={{ color: theme.text, fontSize: '0.8rem', margin: '4px 8px' }}>{infoLabel}</p>
      )}
    </div>
  )
}

const LinkInputField = (props: Partial<TextFieldProps>) => {
  const { value = "", label = "", onChange, linkType = "" } = props;
  const { errorLabel = "", infoLabel = "", placeholder = "", required = false } = props;

  return (
    <LinkInputWrapper>
      <LinkInputContainer className="InputField" value={value}>
        {label && (
          <legend>
            {label} {required && <span className="required">&nbsp;*</span>}
          </legend>
        )}

        <InputContainer>
          {linkType && (
            <div className="linktype">{linkType}</div>
          )}

          <input type="text" value={value}
            onChange={({ target }: any) => { onChange(target.value) }}
            onWheel={(e) => { e.currentTarget.blur() }}
            placeholder={placeholder}
          />
        </InputContainer>
      </LinkInputContainer>

      {(errorLabel && errorLabel != "") && (
        <p className="error">{errorLabel}</p>
      )}

      {(infoLabel && infoLabel != "") && (
        <p className="info">{infoLabel}</p>
      )}
    </LinkInputWrapper>
  )
}

const LinkInputWrapper = styled.div(({ theme }) => ({
  margin: "1.5rem 0px",

  '.error': {
    color: '#ff0000',
    fontSize: '0.8rem',
    margin: '4px 8px',
  },

  '.info': {
    color: theme.text,
    fontSize: '0.8rem',
    margin: '4px 8px',
  }
}))

const LinkInputContainer = styled.fieldset<{ value: string }>(({ theme, value }) => ({
  position: "relative",
  border: `1px solid ${theme.strokeWhite}`,

  borderRadius: "1rem",
  padding: "0.5rem 1.2rem 1rem",
  userSelect: "none",

  '.required': {
    margin: 0,
    padding: 0,
    lineHeight: 0,
    fontSize: '1.4rem',
    color: !value ? 'red' : theme.strokeWhite,
  },

  legend: {
    padding: '0 0.8rem',
    marginLeft: '0.5rem',
    backgroundColor: "transparent",
    fontSize: '0.9rem',

    "@media (max-width: 1024px)": {
      fontSize: "0.75rem",
      padding: "0rem 2px 0.5rem",
    },
  }
}))

const InputContainer = styled.div({
  gap: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  input: {
    flex: 1,
    color: '#878787',
    padding: '0 8px 0 0',

    border: "none",
    fontSize: '1rem',
    backgroundColor: "transparent",

    "&:focus, &:hover, &:active": {
      border: "none",
      boxShadow: "none",
      outline: 'none',
    }
  },

  '.linktype': {

  },
})

export { InputField, LinkInputField }