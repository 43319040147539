import React from 'react';
import decode from 'jwt-decode';
import { useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useI18n } from 'react-simple-i18n';
import { ThemeContext } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';

import Modal from './Modal';
import { ButtonPrimary } from './components';

import useStore from '../useStore';
import { Now, tips } from '../util';
import config from '../config/config.json';
import networks from '../blockchain/networks.json';
import metamask from '../assets/img/metamask.webp';

interface MetamaskInterface {
  step: "install" | "network" | "connect" | "sign"
}

const Metamask = ({ show, onClose }: { show: boolean; onClose: Function }) => {
  const { t } = useI18n()
  const theme = useContext(ThemeContext)
  const wallet = useWallet()
  const navigate = useNavigate()
  const { isMetamask, redirectPage, logined, lang, call, update } = useStore()

  const [status, setStatus] = React.useState<MetamaskInterface>({
    step: "install",
  })
  const updateStatus = (params: Partial<MetamaskInterface>) =>
    setStatus({ ...status, ...params })

  const detect = async () => {
    const provider = await detectEthereumProvider()
    if (provider) {
      updateStatus({ step: "connect" })
    } else {
      updateStatus({ step: "install" })
    }
  }

  const switchNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      console.log(wallet.ethereum)
      const ret = await wallet.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + Number(chainId).toString(16) }],
      })
      update({ loading: false })
    } catch (error) {
      console.log(error)
      addNetwork(config.CHAINID)
      update({ loading: false })
    }
  }

  const addNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      const network = networks.find((data) => data.chainId === chainId)
      await wallet.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
            chainName: network?.name,
            nativeCurrency: {
              name: network?.name,
              symbol: network?.symbol,
              decimals: network?.decimals,
            },
            rpcUrls: [network?.rpc],
            blockExplorerUrls: [network?.explorer],
          },
        ],
      })
      switchNetwork(chainId)
      update({ loading: false })
    } catch (err) {
      console.log(err)
      tips('warning', t('action.metamask.selectethereum'))
      update({ loading: false })
    }
  }

  const sign = async () => {
    try {
      update({ loading: true })
      const signature = await wallet.ethereum?.request({
        method: 'personal_sign',
        params: [
          `Welcome to MechaikeNFT! \n Click to sign in and accept the Terms of Service. \n This request will not trigger a blockchain transaction or cost any gas fees. \n Wallet address: ${wallet.account}`,
          wallet.account,
        ],
      })

      const result = await call('/api/login', {
        address: wallet.account,
        sign: signature,
        metamask: true,
      })

      if (result) {
        switch (result['message']) {
          case 'success': {
            const token = result['token']
            var data = decode(token) as any;
            console.log(data)
            update({
              currentAccountMail: data.email,
              token: token,
              currentAccountName: data.name,
              currentAccountAvatar: data.avatar_img,
              currentAccountAddress: data.address,
              logined: true,
              isMetamask: data.metamask,
              isWalletConnect: wallet.ethereum?.qrcodeModal ? true : false,
              balances: [],
              lasttime: Now(),
            })
            tips('success', t('action.login.success'))
            navigate(redirectPage ? redirectPage : '/')
            update({ redirectPage: "" })
            break
          }

          case 'invalid signature':
            tips('error', t('action.invalidsignature'))
            break

          case 'internal error':
            tips('error', t('action.servererror'))
            break

          case 'account closed!':
            tips('error', t('action.accountClosed'))
            break
        }
      }

      update({ loading: false })
    } catch (err) {
      console.log(err)
      update({ loading: false })
    }
  }

  React.useEffect(() => {
    if (show) {
      detect()
    }
  }, [show])

  const connectWalletAccount = async () => {
    update({ loading: true })

    if (window.innerWidth < 768) {
      localStorage.setItem("walletconnect", "")
      await wallet.connect("walletconnect")
    } else {
      await wallet.connect()
    }

    update({ loading: false })
  }


  const logOut = () => {
    update({
      currentAccountMail: '',
      currentAccountName: '',
      currentAccountAvatar: '',
      currentAccountAddress: '',
      token: '',
      logined: false,
      isMetamask: false,
      isWalletConnect: false,
      lasttime: 0,
      balances: []
    })
    wallet.reset()
  }

  React.useEffect(() => {
    console.log(wallet.status)

    if (logined && isMetamask) {
      logOut();
    } else if (!window.ethereum) {
      updateStatus({ step: "install" })
    } else if (wallet.status != 'connected') {
      updateStatus({ step: "connect" })
    } else if (Number(wallet.ethereum?.chainId) !== Number(config.CHAINID)) {
      updateStatus({ step: "network" })
    } else if (wallet.status === "connected") {
      updateStatus({ step: "sign" })
    }
  }, [wallet.ethereum?.chainId, wallet.ethereum, wallet.status, status.step])

  return (
    <Modal onClose={() => { onClose() }} show={show}>
      {status.step === "install" && ( //install
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.installtitle')}
          </h1>

          <p
            className="text-center ml-auto mr-auto"
            style={{ maxWidth: '400px' }}
          >
            {t('metamask.installcontent')}
          </p>

          <div className="flex center mt3">
            <a target="_blank"
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              style={{ textDecoration: 'none', width: '100%', maxWidth: '350px' }}
            >
              <ButtonPrimary
                style={{
                  color: theme.text,
                  width: '100%',
                  maxWidth: '350px',
                  backgroundColor: '#36C781',
                  borderRadius: '12px',
                }}
                onClick={() => { }}
              >
                <img src={metamask}
                  style={{ width: '34px', marginRight: '8px' }}
                />
                {t('metamask.installcontent')}
              </ButtonPrimary>
            </a>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}

      {status.step === "connect" && ( //connect
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.connecttitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.connectcontent')} <br></br>
            {t('metamask.connectcontent2')}
          </p>

          <div className="flex center mt3 mb3">
            <ButtonPrimary style={{ width: '280px' }}
              onClick={() => { connectWalletAccount() }}
            >
              {t('metamask.connecttitle')}
            </ButtonPrimary>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}

      {status.step === "sign" && ( //sign
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.signtitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.signcontent')}
          </p>

          {lang == "jp" ? (
            <p className="text-center ml-auto mr-auto">
              署名をすることで、
              <Link to="/terms" style={{ textDecoration: 'none', color: '#6b9beb' }}>利用規約</Link>と
              <Link to="/privacy" style={{ textDecoration: 'none', color: '#6b9beb' }}>プライバシーポリシー</Link>
              に同意します。
            </p>
          ) : (
            <p className="text-center ml-auto mr-auto">
              By signing, I agree to the
              <Link to="/terms" style={{ textDecoration: 'none', color: '#6b9beb' }}> Terms of Use </Link> and
              <Link to="/privacy" style={{ textDecoration: 'none', color: '#6b9beb' }}> Privacy Policy </Link>
            </p>
          )}

          <div className="flex center mt5">
            <ButtonPrimary onClick={() => { sign() }}
              style={{
                color: theme.text,
                width: '100%',
                maxWidth: '350px',
                backgroundColor: '#36C781',
                borderRadius: '12px',
              }}
            >
              <img src={metamask}
                style={{ width: '34px', marginRight: '8px' }}
              />
              {t('metamask.asksign')}
            </ButtonPrimary>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}

      {status.step === "network" && ( //network
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.switchnetworktitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.switchnetworkcontent')} <br></br>
            {t('metamask.switchnetworkcontent2')}
          </p>

          <div className="flex center mt3">
            <ButtonPrimary onClick={() => { switchNetwork(config.CHAINID) }}
              style={{ color: theme.text, width: '100%', maxWidth: '350px', backgroundColor: '#36C781', borderRadius: '12px' }}
            >
              <img src={metamask}
                style={{ width: '34px', marginRight: '8px' }}
              />
              {t('metamask.switchbtncontent1')} <br></br>
              {t('metamask.switchbtncontent2')}
            </ButtonPrimary>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}
    </Modal>
  )
}

export default Metamask
