import React from 'react'
import axios from 'axios';
import { ethers } from 'ethers';
import { useContext } from 'react'
import { useWallet } from 'use-wallet';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components'

import useStore from '../../useStore';
import { gasPrice } from '../../blockchain/utils';
import { GET_CATEGORY } from '../../graphql/index';
import Layout from '../../components/Layout/Layout';
import { InputField, LinkInputField } from '../../components/InputField';
import BuyTokenModal from '../../components/BuyToken';
import CustomCheckbox from '../../components/Checkbox';
import { ButtonPrimary } from '../../components/components';
import { proxy, tips, validateSlugUrl, validateSymbol, validateUrl } from '../../util';

import config from '../../config/config.json';
import Abis from '../../blockchain/abi/abis.json';
import Bytecode from '../../blockchain/abi/bytecode.json';

import banner from '../../assets/img/banner.webp';
import collection from '../../assets/img/collection.webp';

interface CollectionInterface {
  bannerBlob: null | Blob
  imageBlob: null | Blob
  banner: null | any
  avatar: null | any
  name: string
  errorName: string
  slugurl: string
  symbol: string
  errorSymbol: string
  errorSlugurl: string
  url: string
  errorUrl: string
  description: string
  errorDescription: string
  category: boolean[]
  errorCategory: string,
  site: string
  errorSite: string
  twitter: string
  errorTwitter: string
  discord: string
  errorDiscord: string
  instagram: string
  errorInstagram: string
  fee: number
  errorFee: string
  checked: boolean
  categorylist: string[]
  showBuyModal: boolean
}

const CreateCollection = () => {
  // @ts-ignore
  const theme = useContext(ThemeContext);
  const { darkMode, logined, currentAccountAddress, token, lang, isMetamask, update } = useStore()
  const navigate = useNavigate()
  const wallet = useWallet()
  const { t } = useI18n()

  const [status, setStatus] = React.useState<CollectionInterface>({
    bannerBlob: null,
    imageBlob: null,
    banner: null,
    avatar: null,
    name: '',
    errorName: '',
    slugurl: '',
    symbol: '',
    errorSymbol: '',
    errorSlugurl: '',
    url: '',
    errorUrl: '',
    description: '',
    errorDescription: '',
    category: [],
    errorCategory: "",
    site: '',
    errorSite: '',
    twitter: '',
    errorTwitter: '',
    discord: '',
    errorDiscord: '',
    instagram: '',
    errorInstagram: '',
    fee: 0.5,
    errorFee: '',
    checked: false,
    categorylist: [],
    showBuyModal: false
  })

  const updateStatus = (params: Partial<CollectionInterface>) => setStatus({ ...status, ...params })

  const refBanner = React.useRef<HTMLInputElement>(null);
  const refAvatar = React.useRef<HTMLInputElement>(null);

  const { data: categoryInfo, loading: categoryLoading, error: categoryError } = useQuery(GET_CATEGORY, {
    variables: { name: status.name, lang: lang },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (categoryLoading || categoryError) return;
    update({ loading: false })
    const data = categoryInfo?.getCategory as Category[];
    if (!data) return;
    const v = [];
    data.forEach((element: Category) => {
      v.push((lang == "en" ? element.en : element.jp) || '')
    });
    updateStatus({
      categorylist: v
    })
  }, [categoryInfo, categoryLoading, categoryError])

  const changeBanner = async (event) => {
    const file = event.target.files[0];
    updateStatus({ banner: URL.createObjectURL(file), bannerBlob: file })
  }

  const changeAvatar = async (event) => {
    const file = event.target.files[0];
    updateStatus({ avatar: URL.createObjectURL(file), imageBlob: file })
  }

  const createCollection = async () => {
    try {
      const name = status.name.trim();
      const symbol = status.symbol.trim();
      const slugurl = status.slugurl.trim();
      const description = status.description.trim();
      const categori = status.category;
      const check = status.checked;
      const url = status.url.trim();
      const fee = status.fee;

      let twitter = status.twitter.trim().replace("https://twitter.com/", "").replace("https://www.twitter.com/", "");
      let discord = status.discord.trim().replace("https://discord.gg/", "").replace("https://www.discord.gg/", "");;
      let instagram = status.instagram.trim().replace("https://instagram.com/", "").replace("https://www.instagram.com/", "");

      let errorStatus = {
        errorName: "",
        errorSymbol: "",
        errorSlugurl: "",
        errorCategory: "",
        errorDescription: "",
        errorDiscord: "",
        errorFee: "",
        errorInstagram: "",
        errorSite: "",
        errorTwitter: "",
        errorUrl: ""
      }

      if (status.bannerBlob === null || status.imageBlob === null) {
        return tips("error", t("action.collection.selectimage"));
      }

      updateStatus(errorStatus);

      if (name.length < 5 || name.length > 50) {
        return updateStatus({ ...errorStatus, errorName: t("action.collection.errorname") });
      }

      if (symbol.length < 1 || symbol.length > 10) {
        return updateStatus({ ...errorStatus, errorSymbol: t("action.collection.errorsymbol") });
      }

      if (!validateSlugUrl(slugurl)) {
        return updateStatus({ ...errorStatus, errorSlugurl: t("action.collection.errorslugurl") });
      }

      if (description.length > 0 && (description.length < 30 || description.length > 1500)) {
        return updateStatus({ ...errorStatus, errorDescription: t("action.collection.errordiscription") });
      }

      if (categori.length <= 0) {
        return updateStatus({ ...errorStatus, errorCategory: t("action.collection.errorcategory") });
      }

      if (Number(fee) < 0.5 || Number(fee) > 30) {
        return updateStatus({ ...errorStatus, errorFee: t("action.collection.errorfee") });
      }

      if (url.length > 0 && !validateUrl(url)) {
        return updateStatus({ ...errorStatus, errorUrl: t("action.collection.errorurl") });
      }


      if (twitter.length > 0 && !twitter.startsWith("https://twitter.com")) {
        twitter = "https://twitter.com/" + twitter;
      }

      if (instagram.length > 0 && !instagram.startsWith("https://instagram.com")) {
        instagram = "https://instagram.com/" + instagram;
      }

      if (discord.length > 0 && !discord.startsWith("https://discord.gg")) {
        discord = "https://discord.gg/" + discord;
      }

      const cts = [] as number[];
      categori.forEach((element, index) => {
        if (element) cts.push(index);
      });

      update({ loading: true })

      let imageUploadStatus = false;
      let collectionImage = '';
      let coverImage = '';

      //upload image
      var formData = new FormData();
      formData.append("coverImage", status.bannerBlob);
      formData.append("image", status.imageBlob);
      formData.append("slugurl", slugurl);

      var response = await axios.post(proxy + "/api/collection/upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form",
          "Apollo-Require-Preflight": true,
          'authorization': token
        }
      }) as any;

      if (response.status === 200) {
        switch (response.data.message) {
          case "success": {
            imageUploadStatus = true;
            coverImage = response.data?.['coverImage'] || '';
            collectionImage = response.data?.['collectionImage'] || '';
            break;
          }
          case "internal error": {
            imageUploadStatus = false;
            tips("error", "Upload Error");
            console.log(response.data.message);
            break;
          }
          case "exists same slugurl": {
            tips("error", t("action.collection.existsameslugurl"));
            break;
          }
        }
      }

      if (imageUploadStatus) {
        let address = "";
        let hash = null;

        if (isMetamask) {
          const ca = await contractDeploy(name, symbol)
          address = ca?.address;
          hash = ca?.deployTransaction?.hash;
          if (!address) {
            update({ loading: false })
            return tips("error", t("action.collection.deployerror"))
          }
        }

        var formData = new FormData();
        formData.append("coverImage", status.bannerBlob);
        formData.append("image", status.imageBlob);
        formData.append("address", address);
        formData.append("hash", hash);
        formData.append("isMetamask", isMetamask ? '1' : '0');
        formData.append("name", name);
        formData.append("symbol", symbol);
        formData.append("slugurl", slugurl);
        formData.append("description", description);
        formData.append("categori", cts.toString());
        formData.append("pick", check ? '1' : '2');
        formData.append("url", url);
        formData.append("fee", fee.toString());
        formData.append("twitter", twitter);
        formData.append("discord", discord);
        formData.append("instagram", instagram);
        formData.append("creator", currentAccountAddress);
        formData.append("chainId", config.CHAINID.toString());
        formData.append("cover_image", coverImage);
        formData.append("collection_image", collectionImage);

        var response = await axios.post(proxy + "/api/collection/create", formData, {
          headers: {
            "Content-Type": "multipart/form",
            "Apollo-Require-Preflight": true,
            'authorization': token
          }
        }) as any;

        if (response.status === 200) {
          switch (response.data.message) {
            case "success": {
              navigate("/collection/" + (slugurl || address.toUpperCase()))
              tips("success", t("action.collection.createsuccess"));
              break;
            }
            case "gas error": {
              tips("error", t("action.collection.gaserror"));
              updateStatus({ showBuyModal: true })
              break;
            }
            case "exists same slugurl": {
              tips("error", t("action.collection.existsameslugurl"));
              break;
            }
            case "gas estimated": {
              tips("error", t("action.collection.gaserror"));
              updateStatus({ showBuyModal: true })
              break;
            }
            case "No contract creator": {
              tips("error", t("action.collection.notcontractcreator"));
              break;
            }
            case "out of gas": {
              tips("error", t("action.collection.gaserror"));
              updateStatus({ showBuyModal: true })
              break;
            }
            case "No exists user.": {
              tips("error", t("action.collection.noexistuser"));
              break;
            }
            case "internal error": {
              tips("error", t("action.unknownerror"));
              console.log(response.data.message);
              break;
            }
          }
        }
      }

      update({ loading: false });
    } catch (err) {
      if (err.message === "Request failed with status code 403") {
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, loading: false });
        tips("warning", t("action.collection.login"))
        return;
      }
      update({ loading: false })
    }
  }

  const contractDeploy = async (name: string, symbol: string) => {
    try {
      const provider = new ethers.providers.Web3Provider(wallet.ethereum);
      const signer = await provider.getSigner();
      let factory = new ethers.ContractFactory(Abis.NFT, Bytecode.NFT, signer);
      const price = await gasPrice(provider)
      let contract = await factory.deploy(name, symbol,
        { gasPrice: ethers.utils.parseUnits(price, "gwei") }
      )

      await contract.deployed();
      return contract;
    } catch (err) {
      console.log(err.message)
      // tips("error", t("action.networkerror"))
      return null;
    }
  }

  React.useEffect(() => {
    if (!logined) navigate("/login")
  }, [logined])

  return (
    <>
      <Layout>
        <StyledTitle style={{ marginBottom: '1rem' }}>
          {t("createcollection.title")}
        </StyledTitle>

        <StyledCoverImage onClick={() => { refBanner.current?.click() }}>
          <div className="banner">
            <img alt="banner"
              src={status.banner || banner}
              style={{ objectFit: 'cover' }}
            />

            <div className="overlay" />

            <input hidden type="file"
              accept="image/*" ref={refBanner}
              onChange={(e) => { changeBanner(e) }}
            />
          </div>

          <div className="edit-btn">
            <ButtonPrimary style={{ backgroundColor: darkMode ? 'rgb(36, 38, 47)' : theme.boxColor, borderRadius: '1rem', width: '300px', padding: '1rem' }}>
              {t("createcollection.selectcover")}
            </ButtonPrimary>

            <p style={{ fontSize: '0.9rem', textAlign: 'center', color: theme.textGrey }}>
              {t("createcollection.role")}
            </p>
          </div>
        </StyledCoverImage>

        <StyledCollectionPanel className="container">
          <div className="row middle">
            <div className="col-4">
              <div onClick={() => { refAvatar.current?.click() }}
                style={{ borderRadius: '8px', cursor: 'pointer', backgroundColor: theme.boxColor, width: '100%', aspectRatio: '1/1', marginTop: '-20px' }}
              >
                <img src={status.avatar || collection} style={{ width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover' }} />
                <input type="file" accept="image/*" hidden ref={refAvatar} onChange={(e) => { changeAvatar(e) }} />
              </div>
            </div>

            <div className="col-8">
              <p style={{ fontSize: '0.9rem', color: theme.textGrey }}>{t("createcollection.role")}</p>
              <ButtonPrimary onClick={() => { refAvatar.current?.click() }} className='select-img' style={{ backgroundColor: darkMode ? 'rgb(36, 38, 47)' : theme.boxColor, }}>{t("createcollection.selectimg")}</ButtonPrimary>
            </div>
          </div>

          <InputField required label={t("createcollection.name")}
            errorLabel={status.errorName} value={status.name}
            onChange={(e) => { updateStatus({ name: e.target.value }) }}
          />

          <InputField required label={t("createcollection.symbol")}
            errorLabel={status.errorSymbol} value={status.symbol}
            onChange={(e) => { updateStatus({ symbol: e.target.value }) }}
          />

          <LinkInputField required
            value={status.slugurl}
            errorLabel={status.errorSlugurl}
            label={t("createcollection.slugurl")}
            linkType="https://mecha.art/collection/"
            onChange={(v: string) => { updateStatus({ slugurl: v }) }}
          />

          <InputField type="textarea"
            value={status.description}
            errorLabel={status.errorDescription}
            label={t("createcollection.description")}
            onChange={(e) => { updateStatus({ description: e.target.value }) }}
          />

          <fieldset className="InputField mt3 ">
            <legend>
              {t("createcollection.category")}
              <span style={{ color: status.category.length > 0 ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>
                &nbsp;*
              </span>
            </legend>

            <div className="flex wrap">
              {status.categorylist.map((item, index) => (
                <ButtonPrimary key={"category-button-" + index}
                  style={{
                    margin: '4px 4px.5rem', color: "#fefefe",
                    paddingLeft: '1.5rem', paddingRight: '1.5rem',
                    backgroundColor: status.category[index] ? "#36C781" : '#A7A7A7',
                  }}

                  onClick={() => {
                    let cts = status.category; cts[index] = !status.category[index];
                    updateStatus({ category: cts })
                  }}
                >
                  {item}
                </ButtonPrimary>
              ))}
            </div>
          </fieldset>

          <p style={{ color: "red", fontSize: '0.8rem' }} className='mb3'>
            {status.errorCategory}
          </p>

          <div className="flex">
            <CustomCheckbox checked={status.checked}
              changeEvent={(checked) => { updateStatus({ checked: !status.checked }) }}
            />

            <p style={{ color: theme.text, cursor: 'pointer' }}
              onClick={() => { updateStatus({ checked: !status.checked }) }}
            >
              {t("createcollection.pick")}
            </p>
          </div>

          <InputField required type="number"
            label={t("createcollection.fee")}
            infoLabel={t("createcollection.feeinfo")}
            errorLabel={status.errorFee} value={status.fee}
            placeholder={t("createcollection.feeplaceholder")}
            onChange={(e) => { updateStatus({ fee: e.target.value }) }}
            child={<div style={{ backgroundColor: theme.boxColor, color: theme.text, padding: '8px 1rem', fontSize: '0.8rem', borderRadius: '8px' }}>%</div>}
          />

          <InputField value={status.url}
            errorLabel={status.errorUrl}
            label={t("createcollection.website")}
            onChange={(e) => { updateStatus({ url: e.target.value }) }}
          />

          <LinkInputField label="Twitter URL"
            onChange={(v: string) => { updateStatus({ twitter: v }) }}
            errorLabel={status.errorTwitter}
            linkType="https://twitter.com/"
            value={status.twitter}
          />

          <LinkInputField label="Discord URL"
            onChange={(v: string) => { updateStatus({ discord: v }) }}
            errorLabel={status.errorDiscord}
            linkType="https://discord.gg/"
            value={status.discord}
          />

          <LinkInputField label="Instagram URL"
            onChange={(v: string) => { updateStatus({ instagram: v }) }}
            errorLabel={status.errorInstagram}
            linkType="https://instagram.com/"
            value={status.instagram}
          />

          <div className="flex center">
            <ButtonPrimary style={{ width: '250px' }} onClick={createCollection}>
              {t("createcollection.create")}
            </ButtonPrimary>
          </div>
        </StyledCollectionPanel>
      </Layout>

      <BuyTokenModal show={status.showBuyModal}
        onClose={() => { updateStatus({ showBuyModal: false }) }}
      />
    </>
  )
}

export default CreateCollection;

const StyledCoverImage = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	cursor: pointer;
	.banner{
		margin: 0;
		padding: 0;
		height: 300px;
		@media (max-width: 768px) {
			height: 150px;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
		img{
			width: 100%;
			max-height: 300px;
			height: 300px;
			@media (max-width: 768px) {
				height: 150px;
			}
		}
		.overlay{
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	.edit-btn{
		position: absolute;
		left: calc(50% - 150px);
		top: 30%;
		@media (max-width: 768px) {
			top: 10%;
			left: calc(50% - 150px);
		}
	}
`

const StyledTitle = styled.div`
	font-size: 2.4rem;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 1.4rem;
	}
`

const StyledCollectionPanel = styled.div`
	border-radius: 1rem;
	color: ${({ theme }) => theme.text}; 
	max-width: 600px;
	margin: -2px auto 100px;
	.select-img {
		border-radius: 1rem;
		width: 280px;
		padding:1rem;
		@media (max-width: 768px) {
			font-size: 0.8rem;
			width: 100%;
			padding: 1rem 8px;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		margin: 30px auto 50px;
		padding: 1rem;
	}
`