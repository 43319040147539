import { ethers, Contract} from 'ethers';
import { provider } from './providers';
import Abis from "./abi/abis.json";
import { toChecksumAddress } from './utils';

/* ----------- marketplace contract ----------- */

const getTokenContract = (address: string, signer) => {
    return new Contract(toChecksumAddress(address), Abis.TestToken, signer);
}

const getNFTContract = (address: string, signer) => {
    return new ethers.Contract(toChecksumAddress(address), Abis.NFT, signer);
};

const getMarketplaceContract = (address: string, signer) => {
    return new ethers.Contract(toChecksumAddress(address), Abis.Marketplace, signer);
};

export { 
    getTokenContract,
    getMarketplaceContract, 
    getNFTContract,
    provider 
}