import React from "react";
import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function CollectionItem() {
  const theme = useContext(ThemeContext);

  return (
    <StyledCollection >
      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
        <Skeleton width={'100%'} height={'100%'} count={1} style={{ borderRadius: '1rem' }} />
      </SkeletonTheme>

      <div style={{ position: 'absolute', right: '4%', top: '20%' }}>
        <SkeletonTheme highlightColor={theme.modalBg} baseColor={theme.strokeColor}>
          <Skeleton width={70} height={70} count={1} style={{ borderRadius: '8px' }} />
        </SkeletonTheme>
      </div>

      <div className="asset-info">
        <SkeletonTheme highlightColor={theme.modalBg} baseColor={theme.strokeColor}>
          <Skeleton width={'30%'} height={20} count={1} style={{ borderRadius: '8px', position: 'absolute', left: '2%', bottom: '8px' }} />
        </SkeletonTheme>

        <SkeletonTheme highlightColor={theme.modalBg} baseColor={theme.strokeColor}>
          <Skeleton width={'30%'} height={20} count={1} style={{ borderRadius: '8px', position: 'absolute', left: '35%', bottom: '8px' }} />
        </SkeletonTheme>

        <SkeletonTheme highlightColor={theme.modalBg} baseColor={theme.strokeColor}>
          <Skeleton width={'30%'} height={20} count={1} style={{ borderRadius: '8px', position: 'absolute', left: '68%', bottom: '8px' }} />
        </SkeletonTheme>
      </div>
    </StyledCollection>
  )
}

const StyledCollection = styled.div`
	border-radius: 1rem;
	cursor: pointer;
	position: relative;
	min-height: 200px;
	margin: 1rem 0;
	overflow: hidden;
	width: 100%;
	height: 200px;
	@media (max-width: 768px) {
		width: 100%!important;
		margin: 4px 0;
	}
	.asset-info{
		transition: 0.3s;
		width: calc(100% - 8px);
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom: 8px;
		left: 4px;
		background-color: #24262F;
		padding: 1rem 10px;
		border-radius: 1rem;
		color: #FAFAFA;
	}
	
`