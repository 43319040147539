import React from 'react'
import { ethers } from 'ethers';
import { useContext } from 'react'
import { useWallet } from "use-wallet";
import { useQuery } from '@apollo/client';
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from 'styled-components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Icon from './Icon';
import useStore from './../useStore';
import Modal from './../components/Modal';
import { ButtonPrimary } from './components';
import Dropdown from './../components/Dropdown';
import { copyToClipboard, tips } from './../util';
import { formatUnit } from '../blockchain/bigmath';
import { InputField } from './../components/InputField';
import BuyTokenModal from './../components/BuyToken';
import { getTokenContract } from '../blockchain/contract';
import { GET_PAYLABLE_TOKEN, GET_PRICES, GET_USER_BALANCE } from './../graphql';
import { ZeroAddress, getEthBalance, getTokenBalance } from '../blockchain/utils';

import avatar from '../assets/img/avatar.png'
import { Stack, Typography } from '@mui/material';
import { CustomTooltip } from './Tooltip';

interface BalanceModalInterface {
  totalUSD: string
  showDepositModal: boolean
  showWithdrawModal: boolean
  showTransferModal: boolean
  showBuyModal: boolean
  prices: Prices[]
  paylableTokens: PaylableTokens[]
  transferTokenType: string
  withdrawTokenType: string
  transferTokenAmount: number
  withdrawTokenAmount: number
  transferTokenAddress: string
  withdrawTokenAddress: string
  transferTokenAmountError: string
  withdrawTokenAmountError: string
  transferTokenAddressError: string
  withdrawTokenAddressError: string
  withdrawError: string
  transferError: string
  buyError: string
  openWallet: boolean
  modalType: number
  royalty: Balance[]
  totalRoatly: number
}

const BalanceModal = ({ show, onClose }: { show: boolean, onClose: Function }) => {
  const { t } = useI18n()
  const theme = useContext(ThemeContext)
  const wallet = useWallet()

  const { logined, isMetamask, currentAccountAddress, currentAccountMail, token, currentAccountName, lang, currentAccountAvatar, balances, update, call } = useStore()

  const [status, setStatus] = React.useState<BalanceModalInterface>({
    openWallet: window.location.pathname.split("/")[1]?.toString() === "profile" && window.location.pathname.split("/")[2]?.toString() === "wallet",
    totalUSD: '0',
    showDepositModal: window.location.pathname.split("/")[1]?.toString() === "profile" && window.location.pathname.split("/")[2]?.toString() === "wallet" && window.location.pathname.split("/")[3]?.toString() === "deposit",
    showWithdrawModal: false,
    showBuyModal: false,
    showTransferModal: false,
    prices: [],
    paylableTokens: [],
    transferTokenAddress: "",
    transferTokenAmount: 0,
    transferTokenType: "MATIC",
    transferTokenAmountError: "",
    transferTokenAddressError: "",
    withdrawTokenAddress: "",
    withdrawTokenAmount: 0,
    withdrawTokenType: "MATIC",
    withdrawTokenAddressError: "",
    withdrawTokenAmountError: "",
    transferError: "",
    withdrawError: "",
    buyError: "",
    modalType: 1,
    royalty: [],
    totalRoatly: 0
  })

  const updateStatus = (params: Partial<BalanceModalInterface>) => setStatus({ ...status, ...params })

  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (priceLoading || priceError) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    updateStatus({ prices: data })
  }, [priceInfo, priceLoading, priceError])

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only',
    pollInterval: 20000
  })

  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])


  const { data: balanceInfo, loading: balanceLoading, error: balanceError } = useQuery(GET_USER_BALANCE,
    {
      variables: { address: currentAccountAddress },
      pollInterval: 10000,
      fetchPolicy: 'network-only'
    }
  )

  React.useEffect(() => {
    if (balanceLoading || balanceError) return;
    const _balances = balanceInfo?.getUserBalance as Balance[];
    if (!_balances) return;
    if (logined) {
      let total = 0;
      _balances?.forEach((_b: Balance) => {
        const tokenPrice = status.prices.find(v => { return v.address === _b?.address })?.[lang === 'en' ? 'usd' : 'jpy'] || 0;
        const tokenBalance = _b?.balance || 0;
        total += tokenPrice * Number(tokenBalance);
      });
      if (!isMetamask) {
        updateStatus({ totalUSD: Number(Number(total.toFixed(4)).toString()).toString() })
        update({ balances: _balances })
      }
      else if (isMetamask) {
        updateStatus({ royalty: _balances, totalRoatly: total })
      }
    }
  }, [balanceInfo, balanceLoading, balanceError, status.prices])

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkBalance()
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [status.paylableTokens, status.prices, wallet.status, logined])

  React.useEffect(() => {
    updateStatus({ modalType: 1 })
  }, [show])

  const checkBalance = async () => {
    try {
      if (logined && isMetamask && wallet.status === "connected" && wallet.account) {
        const provider = new ethers.providers.Web3Provider(wallet.ethereum);
        const signer = await provider.getSigner();
        if (!signer) return;
        let _balances = [] as Balance[];
        if (status.paylableTokens?.length > 0) {
          for (let i = 0; i < status.paylableTokens.length; i++) {
            const token = status.paylableTokens[i];
            let balance = 0;
            if (token.address === ZeroAddress) {
              balance = await getEthBalance(provider, currentAccountAddress)
            }
            else {
              balance = await getTokenBalance(token.address, signer)
            }
            _balances.push({
              address: token.address,
              name: token.name,
              symbol: token.symbol,
              balance: (formatUnit(balance, token.decimals)),
              decimals: token.decimals,
              icon: token.icon,
            })
          }
          update({ balances: _balances })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  React.useEffect(() => {
    let total = 0, totalRoatly = 0;
    balances?.forEach((balance: Balance) => {
      const tokenPrice = status.prices.find(v => {
        return v.address === balance?.address
      })?.[lang === 'en' ? 'usd' : 'jpy'] || 0;
      const tokenBalance = balance?.balance || 0;
      total += tokenPrice * Number(tokenBalance);
    });
    status.royalty?.forEach((balance: Balance) => {
      const tokenPrice = status.prices.find(v => {
        return v.address === balance?.address
      })?.[lang === 'en' ? 'usd' : 'jpy'] || 0;
      const tokenBalance = balance?.balance || 0;
      totalRoatly += tokenPrice * Number(tokenBalance);
    });
    updateStatus({ totalUSD: Number(total.toFixed(4)).toString(), totalRoatly: totalRoatly })
  }, [balances, status.prices, lang])

  const sendWithdrawRequest = async () => {
    try {
      const type = status.withdrawTokenType;
      const amount = status.withdrawTokenAmount;
      const to = status.withdrawTokenAddress;
      updateStatus({ withdrawTokenAddressError: "", withdrawTokenAmountError: "" });
      const tokenAddress = status.paylableTokens.find((token) => { return token.symbol === type })?.address || "";
      const tokenDecimals = status.paylableTokens.find((token) => { return token.symbol === type })?.decimals || 18;;
      const mybalance = Number(balances?.find((token) => { return token.address === tokenAddress })?.balance || 0);
      if (amount > mybalance) return updateStatus({ withdrawTokenAmountError: t("action.exceedbalance"), withdrawTokenAddressError: "" });
      if (amount <= 0) return updateStatus({ withdrawTokenAmountError: t("action.required"), withdrawTokenAddressError: "" });
      if (!tokenAddress) return tips("warning", t("action.selecttoken"));
      try {
        if (!ethers.utils.isAddress(to)) {
          return updateStatus({ withdrawTokenAddressError: "	", withdrawTokenAmountError: "" });
        }
        if (to === currentAccountAddress) return updateStatus({ withdrawTokenAddressError: t("action.invalidvalue"), withdrawTokenAmountError: "" });
      } catch (err) {
        console.log(err)
        return updateStatus({ withdrawTokenAddressError: t("action.invalidvalue"), withdrawTokenAmountError: "" });
      }

      update({ loading: true })
      if (!isMetamask) {
        const result = await call("/api/balance/withdraw", {
          email: currentAccountMail,
          from: currentAccountAddress,
          to: to,
          tokenAddress: tokenAddress,
          tokenName: type,
          amount: amount
        }, { authorization: token })
        switch (result['message']) {
          case 'exceed balance':
            {
              tips("wraning", t("action.exceedbalance"));
              break;
            }
          case 'success': {
            updateStatus({ showWithdrawModal: false, withdrawTokenAddressError: "", withdrawTokenAmountError: "", withdrawTokenAddress: "", withdrawTokenAmount: 0 })
            tips("success", t("action.withdraw.success"));
            break;
          }
        }
      } else {
        const provider = new ethers.providers.Web3Provider(wallet.ethereum);
        const signer = await provider.getSigner();
        if (tokenAddress === ZeroAddress) {
          let tx = {
            to: to,
            value: ethers.utils.parseEther(amount.toString())
          }
          const txObj = await signer.sendTransaction(tx);
          await txObj.wait()
          updateStatus({ showWithdrawModal: false, withdrawTokenAddressError: "", withdrawTokenAmountError: "", withdrawTokenAddress: "", withdrawTokenAmount: 0 })
          tips("success", t("action.withdraw.success"));
          checkBalance()
        } else {
          const erc20 = getTokenContract(tokenAddress, signer);
          let txObj = await erc20.transfer(to, ethers.utils.parseUnits(amount.toString(), tokenDecimals));
          await txObj.wait()
          updateStatus({ showWithdrawModal: false, withdrawTokenAddressError: "", withdrawTokenAmountError: "", withdrawTokenAddress: "", withdrawTokenAmount: 0 })
          tips("success", t("action.withdraw.success"));
        }
      }
      update({ loading: false })
    } catch (err) {
      update({ loading: false })
    }
  }

  const sendTransferRequest = async () => {
    try {
      const type = status.transferTokenType;
      const amount = status.transferTokenAmount;
      const to = status.transferTokenAddress;
      updateStatus({ transferTokenAddressError: "", transferTokenAmountError: "" });
      const tokenAddress = status.paylableTokens.find((token) => { return token.symbol === type })?.address || "";
      const tokenDecimals = status.paylableTokens.find((token) => { return token.symbol === type })?.decimals || 18;;
      const mybalance = Number(balances?.find((token) => { return token.address === tokenAddress })?.balance || 0);
      if (amount > mybalance) return updateStatus({ transferTokenAmountError: t("action.exceedbalance"), transferTokenAddressError: "" });
      if (amount <= 0) return updateStatus({ transferTokenAmountError: t("action.required"), transferTokenAddressError: "" });
      if (!tokenAddress) return tips("warning", t("action.selecttoken"));
      try {
        if (!ethers.utils.isAddress(to)) {
          return updateStatus({ transferTokenAddressError: t("action.invalidvalue"), transferTokenAmountError: "" });
        }
        if (to === currentAccountAddress) return updateStatus({ transferTokenAddressError: t("action.invalidvalue"), transferTokenAmountError: "" });
      } catch (err) {
        console.log(err)
        return updateStatus({ transferTokenAddressError: t("action.invalidvalue"), transferTokenAmountError: "" });
      }
      update({ loading: true })
      const result = await call("/api/balance/transfer", {
        email: currentAccountMail,
        from: currentAccountAddress,
        to: to,
        tokenAddress: tokenAddress,
        tokenName: type,
        amount: amount
      }, { authorization: token })
      const message = result['message'];
      switch (message) {
        case 'exceed balance':
          {
            tips("wraning", t("action.exceedbalance"));
            break;
          }
        case 'external wallet':
          {
            //metamask -> 
            // tips("wraning",  t("action.checkwalletconnectstatus"));
            const provider = new ethers.providers.Web3Provider(wallet.ethereum);
            const signer = await provider.getSigner();
            if (tokenAddress === ZeroAddress) {
              let tx = {
                to: to,
                value: ethers.utils.parseEther(amount.toString())
              }
              const txObj = await signer.sendTransaction(tx);
              await txObj.wait()
              updateStatus({ showTransferModal: false, transferTokenAddressError: "", transferTokenAmountError: "", transferTokenAddress: "", transferTokenAmount: 0 })
              tips("success", t("action.transfer.success"));
              checkBalance()
            } else {
              const erc20 = getTokenContract(tokenAddress, signer);
              let txObj = await erc20.transfer(to, ethers.utils.parseUnits(amount.toString(), tokenDecimals));
              await txObj.wait()
              updateStatus({ showTransferModal: false, transferTokenAddressError: "", transferTokenAmountError: "", transferTokenAddress: "", transferTokenAmount: 0 })
              tips("success", t("action.transfer.success"));
            }
            break;
          }
        case 'success': {
          updateStatus({ showTransferModal: false, transferTokenAddressError: "", transferTokenAmountError: "", transferTokenAddress: "", transferTokenAmount: 0 })
          tips("success", t("action.transfer.success"));
          break;
        }
      }
      update({ loading: false })
    } catch (err) {
      console.log(err)
      update({ loading: false })
    }
  }

  const forwardRoyalty = async () => {
    try {
      update({ loading: true })
      const signature = await wallet.ethereum?.request({
        method: 'personal_sign',
        params: [
          `Welcome to MechaikeNFT! \n Click to sign in and accept the Terms of Service. \n Could you claim royalty? \n Wallet address: ${wallet.account}`,
          wallet.account,
        ],
      })
      const result = await call("/api/claim-royalty", { sign: signature }, { authorization: token })
      switch (result['message']) {
        case 'exceed balance':
          {
            tips("error", 'Auth Error');
            break;
          }
        case 'invalid signature': {
          tips("error", 'Signature Error');
          break;
        }
        case 'success': {
          tips("success", t("Success"));
          checkBalance()
          break;
        }
      }
      update({ loading: false })
    } catch (err) {
      console.log(err.message)
      tips("Error", "Error")
      update({ loading: false })
    }
  }

  return (
    <>
      {show && (
        <StyledModal>
          <div className="overlay" onClick={() => { onClose() }} />

          <div className="modal-container">
            <div className="flex middle top">
              <img src={currentAccountAvatar || avatar}
                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '1rem', objectFit: 'cover' }}
              />

              <h3>
                {currentAccountName || "User"}
              </h3>
            </div>

            {status.modalType === 1 && (
              <>
                <StyledBorderPanel>
                  <Stack mt={{ xs: 1, lg: 2 }} gap={1} direction="row" alignItems="center" justifyContent="center">
                    <p className='text-center' style={{ margin: "unset" }} color={theme.textGrey}>
                      {t("header.totalPrice")}
                    </p>

                    <CustomTooltip title={<QuestionTitle title={t("header.questionTitle")} />}>
                      <HelpOutlineIcon fontSize="medium" color="error" cursor="pointer" />
                    </CustomTooltip>
                  </Stack>

                  <h1 className='text-center'>
                    {lang === 'en' ? '$' : '￥'}
                    {status.totalUSD}
                    <span style={{ color: theme.buttonPrimary }}>
                      {lang === 'en' ? 'USD' : 'JPY'}
                    </span>
                  </h1>

                  <div className="row center">
                    <div className="col-md-6 col-6 flex center">
                      <ButtonPrimary style={{ width: '100%', maxWidth: '250px', margin: '8px 0' }}
                        onClick={() => {
                          if (!logined) return tips("info", "action.needlogin")
                          updateStatus({ showDepositModal: true })
                        }}
                      >
                        {t("header.deposit")}
                      </ButtonPrimary>
                    </div>

                    <div className="col-md-6 col-6  flex center">
                      <ButtonPrimary style={{ width: '100%', maxWidth: '250px', margin: '8px 0' }}
                        onClick={() => {
                          tips("info", "Coming soon")
                          // if(!logined) return tips("info", "action.needlogin")
                          // updateStatus({ showWithdrawModal: true })
                        }}
                      >
                        {t("header.withdraw")}
                      </ButtonPrimary>
                    </div>

                    {/* card_buy_disable */}
                    <div className="col-md-6 col-6  flex center">
                      <ButtonPrimary style={{ width: '100%', maxWidth: '250px', margin: '8px 0' }}
                        onClick={() => {
                          if (!logined) return tips("info", "action.needlogin")
                          updateStatus({ showBuyModal: true })
                        }}
                      >
                        {t("header.buy")}
                      </ButtonPrimary>
                    </div>

                    <div className="col-md-6 col-6  flex center">
                      <ButtonPrimary style={{ width: '100%', maxWidth: '250px', margin: '8px 0' }}
                        onClick={() => {
                          if (!logined) return tips("info", "action.needlogin")
                          updateStatus({ showTransferModal: true })
                        }}
                      >
                        {t("header.transfer")}
                      </ButtonPrimary>
                    </div>

                    <div className="col-md-6 col-6  flex center"></div>
                  </div>

                  {(logined && isMetamask) && (
                    <div className="row center mt1">
                      <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { updateStatus({ modalType: 2 }) }}>
                        {t("header.showWalletModal2")}
                      </div>
                    </div>
                  )}
                </StyledBorderPanel>

                <div style={{ height: '40%', maxHeight: '300px', overflowY: 'auto' }}>
                  {balances?.map((balance, index) => (
                    <StyledBorderPanel key={"balance-row" + index}>
                      <div className="flex justify middle">
                        <div className="flex middle">
                          <img src={balance?.icon}
                            style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '1rem' }}
                          />

                          <div>
                            <p className='m0'>{balance?.symbol}</p>
                            <p className='m0' style={{ color: theme.textGrey, fontSize: '0.8rem' }}>Polygon</p>
                          </div>
                        </div>

                        <div>
                          <p className='m0' style={{ textAlign: 'right' }}>
                            {Number(Number(balance?.balance || 0).toFixed(4))}
                          </p>

                          <p className='m0' style={{ color: theme.textGrey, textAlign: 'right', fontSize: '0.8rem' }}>
                            {lang === 'en' ? '$' : '￥'}
                            {Number((Number(balance?.balance || 0) * (status.prices.find(
                              v => { return v.address === balance?.address }
                            )?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(4))}
                            {lang === 'en' ? 'USD' : 'JPY'}
                          </p>
                        </div>
                      </div>
                    </StyledBorderPanel>
                  ))}
                </div>
              </>
            )}

            {status.modalType === 2 && (
              <>
                <StyledBorderPanel>
                  <p className='text-center' color={theme.textGrey}>
                    {t("header.totalRoyalty")}
                  </p>

                  <h1 className='text-center'>
                    {lang === 'en' ? '$' : '￥'}
                    {Number(Number(status.totalRoatly).toFixed(4))}
                    <span style={{ color: theme.buttonPrimary }}>
                      {lang === 'en' ? 'USD' : 'JPY'}
                    </span>
                  </h1>

                  <div className="row center mt1">
                    <ButtonPrimary onClick={() => { forwardRoyalty() }}
                      style={{ width: '100%', maxWidth: '250px', margin: '8px 0' }}
                      disabled={status.totalRoatly === 0 || status.royalty.length === 0}
                    >
                      {t("header.transferRoyalty")}
                    </ButtonPrimary>
                  </div>
                </StyledBorderPanel>

                <div style={{ height: '40%', maxHeight: '300px', overflowY: 'auto' }}>
                  {status.royalty?.map((balance, index) => (
                    <StyledBorderPanel key={"balance-roalty" + index}>
                      <div className="flex justify middle">
                        <div className="flex middle">
                          <img src={balance?.icon}
                            style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '1rem' }}
                          />

                          <div>
                            <p className='m0'>{balance?.symbol}</p>
                            <p className='m0' style={{ color: theme.textGrey, fontSize: '0.8rem' }}>{balance?.name}</p>
                          </div>
                        </div>

                        <div>
                          <p className='m0' style={{ textAlign: 'right' }}>
                            {Number(Number(balance?.balance || 0).toFixed(4))}
                          </p>

                          <p className='m0' style={{ color: theme.textGrey, textAlign: 'right', fontSize: '0.8rem' }}>
                            {lang === 'en' ? '$' : '￥'}
                            {Number((Number(balance?.balance || 0) * (status.prices.find(
                              v => { return v.address === balance?.address }
                            )?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(4))}
                            {lang === 'en' ? 'USD' : 'JPY'}
                          </p>
                        </div>
                      </div>
                    </StyledBorderPanel>
                  ))}
                </div>
              </>
            )}

            <div className='close-btn'>
              <ButtonPrimary onClick={() => { status.modalType === 2 ? updateStatus({ modalType: 1 }) : onClose() }}
                style={{ backgroundColor: theme.boxColor, margin: '0 auto', width: '100%', maxWidth: '250px', padding: '1rem 2rem' }}
              >
                開じる
              </ButtonPrimary>
            </div>
          </div>
        </StyledModal>
      )}

      <Modal onClose={() => { updateStatus({ showDepositModal: false }) }} show={status.showDepositModal}>
        <h1 className='text-center'>
          {t("depositmodal.title")}
        </h1>

        <div className='ml-auto mr-auto mt5' style={{ maxWidth: '500px' }}>
          <InputField type="text" label={t("depositmodal.address")} value={currentAccountAddress} readOnly
            child={
              <div onClick={() => { copyToClipboard(currentAccountAddress || "") }}
                style={{
                  backgroundColor: theme.boxColor,
                  color: theme.text,
                  padding: '12px',
                  fontSize: '0.8rem',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  width: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon icon='Copy' size={20} />
              </div>
            }
          />

          <p className='text-center ml-auto mr-auto' style={{ maxWidth: '300px' }}>
            {t("depositmodal.content", status.paylableTokens.map((token) => { return token.symbol }).toString())}
          </p>

          <div className="flex center wrap mt5">
            <ButtonPrimary onClick={() => { updateStatus({ showDepositModal: false }) }}
              style={{ width: '280px', margin: '8px 1rem', backgroundColor: theme.boxColor }}
            >
              {t("depositmodal.close")}
            </ButtonPrimary>
          </div>
        </div>
      </Modal>

      <Modal show={status.showWithdrawModal}
        onClose={() => {
          updateStatus({
            showWithdrawModal: false,
            withdrawTokenAddressError: "",
            withdrawTokenAmountError: "",
            withdrawTokenAddress: "",
            withdrawTokenAmount: 0
          })
        }}
      >
        <h1 className='text-center m0'>出金ボタン</h1>
        <div className='ml-auto mr-auto mt3' style={{ maxWidth: '450px' }}>
          <Dropdown selectedKey={status.withdrawTokenType}
            onChange={(key) => { updateStatus({ withdrawTokenType: key }) }}
            props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
            values={status.paylableTokens.filter((token) => { return { name: token.symbol, key: token.name, img: token.icon } })}
          />

          <InputField required type="number"
            label={t("withdrawmodal.amount")}
            value={status.withdrawTokenAmount}
            onChange={(e) => { updateStatus({ withdrawTokenAmount: e.target.value }) }} errorLabel={status.withdrawTokenAmountError}
          />

          <InputField required
            label={t("withdrawmodal.address")}
            value={status.withdrawTokenAddress}
            onChange={(e) => { updateStatus({ withdrawTokenAddress: e.target.value }) }}
            errorLabel={status.withdrawTokenAddressError}
          />

          {status.withdrawError && (
            <p style={{ color: "red", textAlign: 'center' }}>{status.withdrawError}</p>
          )}
        </div>

        <div className="flex center wrap mt5">
          <ButtonPrimary onClick={() => { sendWithdrawRequest() }}
            style={{ width: '210px', margin: '8px 1rem' }}
          >
            {t("withdrawmodal.withdraw")}
          </ButtonPrimary>

          <ButtonPrimary style={{ width: '210px', margin: '8px 1rem', backgroundColor: theme.boxColor }}
            onClick={() => {
              updateStatus({
                showWithdrawModal: false,
                withdrawTokenAddressError: "",
                withdrawTokenAmountError: "",
                withdrawTokenAddress: "",
                withdrawTokenAmount: 0,
              })
            }}
          >
            {t("withdrawmodal.close")}
          </ButtonPrimary>
        </div>
      </Modal>

      <Modal show={status.showTransferModal}
        onClose={() => {
          updateStatus({
            showTransferModal: false,
            transferTokenAddressError: "",
            transferTokenAmountError: "",
            transferTokenAddress: "",
            transferTokenAmount: 0
          })
        }}
      >
        <h1 className='text-center m0'>
          {t("transfermodal.title")}
        </h1>

        <div className='ml-auto mr-auto mt3' style={{ maxWidth: '450px' }}>
          <Dropdown selectedKey={status.transferTokenType}
            onChange={(key: any) => { updateStatus({ transferTokenType: key }) }}
            props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
            values={status.paylableTokens.filter((token) => ({ name: token.symbol, key: token.name, img: token.icon }))}
          />

          <InputField required type="number"
            label={t("transfermodal.amount")}
            value={status.transferTokenAmount}
            onChange={(e) => { updateStatus({ transferTokenAmount: e.target.value }) }} errorLabel={status.transferTokenAmountError}
          />

          <InputField required
            label={t("transfermodal.address")} value={status.transferTokenAddress}
            onChange={(e) => { updateStatus({ transferTokenAddress: e.target.value }) }} errorLabel={status.transferTokenAddressError}
          />

          {status.transferError && (
            <p style={{ color: "red", textAlign: 'center' }}>
              {status.transferError}
            </p>
          )}
        </div>

        <div className="flex center wrap mt5">
          <ButtonPrimary style={{ width: '210px', margin: '8px 1rem' }} onClick={() => { sendTransferRequest() }}>
            {t("transfermodal.transfer")}
          </ButtonPrimary>

          <ButtonPrimary style={{ width: '210px', margin: '8px 1rem', backgroundColor: theme.boxColor }}
            onClick={() => {
              updateStatus({
                showTransferModal: false,
                transferTokenAddressError: "",
                transferTokenAmountError: "",
                transferTokenAddress: "",
                transferTokenAmount: 0
              })
            }}
          >
            {t("transfermodal.close")}
          </ButtonPrimary>
        </div>
      </Modal>

      <BuyTokenModal show={status.showBuyModal}
        onClose={() => { updateStatus({ showBuyModal: false }) }}
      />
    </>
  )
}

const QuestionTitle = ({ title }) => {
  return (
    <QuestionStyle>
      <Typography variant="subtitle2">
        {title}
      </Typography>
    </QuestionStyle>
  )
}

export default BalanceModal;

const StyledModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	top: 0;
	left: 0;
	flex-direction: row;
	justify-content: flex-end;
	
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.top {
		margin-top: 100px;
		@media (max-width: 768px) {
			margin-top: 0;
		}
	}
	.close-btn {
		@media (max-width: 768px) {
			position: fixed;
			bottom: 30px;
			left: 0;
			right: 0;
		}
	}
	.overlay{
		position: fixed;
		background-color: ${({ theme }) => theme.modalOverlay};
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		opacity: ${({ theme }) => theme.modalOpacity};
	}
	.modal-container{
		display: block;
		height: 100vh;
		background-color: ${({ theme }) => theme.modalBg};
		color: ${({ theme }) => theme.text};
		padding: 1rem 2rem 2rem;
		position: absolute;
		max-width: 600px;
		min-width: 300px;
		margin-left: auto;
		width: 50%;
		z-index: 10002;
		max-height: 100vh;
		overflow-y: auto;
		@media (max-width: 768px) {
			margin: 0;
			width: 100%;
			border-radius: 0;
			position: fixed;
			padding: 1rem 1.5rem;
			max-width: 100vw;
			// margin-top: 60px;
			height: calc(100vh);
			z-index: 10001;
		}
	}
	@media (max-width: 768px) {
		z-index: 10001;
	}
`
const StyledBorderPanel = styled.div`
	border-radius: 8px;
	border: 1px solid  ${({ theme }) => theme.text};
	padding: 1rem;
	margin: 1rem 0;
`

const QuestionStyle = styled.div`
  max-width: 200px;
  text-align: center;
`