import React, { useContext } from "react";
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from "styled-components";

import Icon from "./Icon";
import Dropdown from "./Dropdown";
import useStore from "../useStore";

interface SearchInterface {
	value?: string
	onValueChange?: Function
	onSelectChange?: Function
	onSearch?: Function
	resetValue?: Function
	onEnter?: Function
	props?: any
	values?: any
	hideDropdown?: boolean
	placeholder?: string
}

export default function Search({ value, values, onValueChange, hideDropdown, placeholder, onSelectChange, resetValue, onSearch, onEnter, props }: SearchInterface) {
	const { t } = useI18n();
	const { lang } = useStore();
	const theme = useContext(ThemeContext);

	return (
		<SearchBar	{...props}>
			<div className="search-input">
				<input type="text"
					value={value || ""}
					style={{ fontSize: '20px' }}
					onChange={(e) => { onValueChange && onValueChange(e.target.value) }}
					onKeyDown={(e) => { if (e.key === "Enter") { onEnter && onEnter() } }}
					placeholder={placeholder ? placeholder : (lang == "jp" ? "アイテムを検索" : 'Keyword')}
				/>
				<div className="search-btn"
					onClick={() => { value === "" ? (onSearch && onSearch()) : resetValue && resetValue() }}
				>
					<Icon icon={value === "" ? "Search" : "Close"} size={20} />
				</div>

				{!hideDropdown && (
					<div className="dropdown">
						<Dropdown selectedKey="matic" values={values || []}
							onChange={(key) => { onSelectChange && onSelectChange(key) }}
							props={{ style: { backgroundColor: 'transparent', fontSize: '1.2rem' } }}
							panelProps={{ style: { border: `1px solid ${theme.strokeColor}`, borderRadius: '4px' } }}
							itemProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '4px' } }}
						/>
					</div>
				)}

				{/* <ButtonPrimary className= "search-btn-right" style={{borderRadius: '8px'}} onClick={() => {onSearch && onSearch()}}>{t("search")}</ButtonPrimary> */}
			</div>
		</SearchBar>
	)
}

const SearchBar = styled.div`
	width: 60%;
	border-bottom: 1px solid ${({ theme }) => (theme.strokeColor)};
	background-color: transparent;;
	position: relative;
	padding: 8px 1rem;
	margin: 1rem;	
	max-width: 680px;
	min-width: 290px;
	.search-btn{
		position: absolute;
		cursor: pointer;
		left: 1rem;
		top: 14px;	
		&:hover{
			color:	${({ theme }) => (theme.textGrey)};
			+.search-result-panel{
				display: block;
			}
		}
	}
	.search-btn-right{
		position: absolute;
		right: 4px;
		top: 0.25rem;
		width: 80px;
		padding: 8px 1rem;
	}
	input{
		border: none;
		padding: 4px 0 4px 2rem;
		width: calc(100% - 10px);
		background-color: transparent;
		user-select: none;
		color:	${({ theme }) => (theme.text)};
		&:hover, &:focus, &:active{
			border: none;
			outline: none;
			box-shadow: none;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	.dropdown{
		width: 200px;
		position: absolute;
		right: 100px;
		top: 2px;
		border-left: 1px solid	${({ theme }) => theme.strokeColor};
		@media (max-width: 768px) {
			display: none;
		}
	}
	
	@media (max-width: 768px) {
		width: 98%!important; 
		margin: 8px 4px;
	}
`