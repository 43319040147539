import React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '../Footer';
import Header from './Header';
import top from '../../assets/img/top.png'

const Layout = ({ children }: any) => {
  const location = useLocation();
  const [toTop, setTop] = useState(false);

  const onScroll = () => {
    if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
      setTop(true)
    } else {
      setTop(false)
    }
  }

  useEffect(() => {
    window.onscroll = onScroll;
  }, [])

  useEffect(() => {
    window.scroll({ top: 0, left: 0 })
  }, [location]);

  return (
    <StyledMain>
      <Header />

      <StyledBody>
        {children}

        <StyledTop style={{ display: toTop ? 'block' : 'none' }}
          onClick={() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' }) }}
        >
          <img src={top} alt='to-top' />
        </StyledTop>
      </StyledBody>
      <Footer></Footer>
    </StyledMain>
  )
}

export default Layout;

const StyledBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
	padding-top: 110px;
	@media (max-width: 768px) {
		padding-top: 64px;
	}
`

const StyledTop = styled.div`
	position: fixed;
	cursor: pointer;
	bottom: 2rem;
	right: 2rem;
	z-index: 10001;
	transition: 0.4s;
	&:hover {
		transform: translateY(-5px);
	}
	img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 50%;
		@media (max-width: 768px) {
			width: 35px;
			height: 35px;
		}
	}
`

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color:  ${({ theme }) => (theme.text)};
	background-color:  ${({ theme }) => (theme.bgColor2)};
`