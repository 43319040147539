import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { UseWalletProvider } from 'use-wallet';
import { configureStore } from '@reduxjs/toolkit';

import App from './App';
import Slice from './reducer';
import StyledThemeProvider from './theme';
import reportWebVitals from './reportWebVitals';
import config from './config/config.json';

const store = configureStore({ reducer: Slice.reducer });

ReactDOM.render(
  <UseWalletProvider
    // autoConnect={true}
    connectors={{
      injected: { chainId: [1, 2, 3, config.CHAINID, 5, 97, 250, 4002, 42161, 421613, 11155111] },
      walletconnect: {
        rpc: {
          1: "https://mainnet.infura.io/v3/a0d8c94ba9a946daa5ee149e52fa5ff1",
          4: "https://rinkeby.infura.io/v3/a0d8c94ba9a946daa5ee149e52fa5ff1",
          5: "https://rpc.ankr.com/eth_goerli",
          97: "https://bsc-testnet.public.blastapi.io",
          250: "https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac",
          4002: "https://fantom-testnet.public.blastapi.io",
          421613: "https://goerli-rollup.arbitrum.io/rpc",
          11155111: "https://rpc.sepolia.org"
        },
        bridge: "https://bridge.walletconnect.org",
        pollingInterval: 7000
      },
      walletlink: {
        chainId: 1,
        url: "https://mainnet.eth.aragon.network/"
      }
    }}
  >
    <Provider store={store}>
      <React.StrictMode>
        <StyledThemeProvider>
          <App />
        </StyledThemeProvider>
      </React.StrictMode>
    </Provider>
  </UseWalletProvider>,
  document.getElementById('root')
);

reportWebVitals();
