import React from 'react';
import styled from 'styled-components'
import Layout from '../../components/Layout/Layout'

const Terms = () => {
  return (
    <Layout>
      <div className="container">
        <StyledContainer>
          <h1 className='text-center m0 p0'>TERMS AND CONDITIONS </h1>
          <p className='text-center'>(Last updated on 22 April 2023)</p>

          <p className='mt5'>
            These Terms and Conditions of Use (<b>“T&C”</b>) apply to the <a href='https://mecha.art' target='_blank'>Mechaike</a>  website located at <a href='https://mecha.art' target='_blank'>https://mecha.art</a> and all associated websites linked to the Site, by Greater Asia Communications Pte. Ltd (<b>“Greater Asia”</b>), its subsidiaries and affiliates. The Site is the property of Greater Asia and its licensors. By signing up to use an account through  <a href='https://mecha.art' target='_blank'>mecha.art</a>, or any associated websites, or mobile applications (collectively the <b>“Site”</b>), you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, and the Privacy Policy, which are separately available on the Site and are incorporated by reference into, and shall be part of, this Agreement.
          </p>

          <p>
            OUR SERVICES ARE NOT OFFERED TO ANY PERSONS WHO ARE CITIZENS, RESIDENTS OR ENTITIES INCORPORATED IN THE UNITED STATES OF AMERICA (COLLECTIVELY “US PERSONS”). MOREOVER, NONE OF OUR OTHER SERVICES ARE OFFERED TO ANY PERSONS WHO ARE CITIZENS, RESIDENTS OR ENTITIES INCORPORATED IN ANY RESTRICTED TERRITORY (COLLECTIVELY “RESTRICTED PERSONS”) THE USE OF VIRTUAL PRIVATE NETWORK (“VPN”) TO CIRCUMVENT THE RESTRICTIONS SET FORTH HEREIN IS PROHIBITED.
          </p>

          <p>
            PLEASE DO NOT USE THE SITE OR PROVIDE INFORMATION (OR ALLOW OTHERS TO DO SO ON YOUR BEHALF) IF YOU (AND YOUR PRINCIPAL IF YOU ARE ACTING AS AGENT) OR DO NOT AGREE WITH ALL OF THE TERMS AND CONDITIONS.
          </p>

          <p>
            Please carefully review the disclosures and disclaimers in their entirety before using the platform developed by Greater Asia.
          </p>

          <h3 className='m0 mt5 p0'>
            Modification to Terms and Conditions
          </h3>

          <p>
            By accessing or using the Site, you agree to these Terms and Conditions, including, but not limited to, the Privacy Policy, as amended periodically. We reserve the right, in our sole discretion, to modify these Terms periodically. Your continued use of the Site and Services following the posting of changes will mean that you accept and agree to these changes. It is your responsibility to check these T&Cs periodically for changes.  If you disagree with any of the amended terms, you must stop using the Site and Services.
          </p>

          <h3 className='m0 mt5 p0'>
            Use of Services
          </h3>

          <p>
            As a condition to using the Service or the Site, you represent and warrant to Greater Asia the following:
          </p>

          <ul>
            <li>
              <p>
                That you are of legal age in the jurisdiction in which you reside and have the legal capacity to enter into these Terms and be bound by them, or as an employee or agent of a legal entity on their behalf, you represent and warrant that you have all the necessary rights and authorizations to act on their behalf.
              </p>
            </li>

            <li>
              <p>
                You are not a USA Citizen.
              </p>
            </li>

            <li>
              <p>
                You are not a “Restricted Person”, namely, a resident or citizen of Antigua and Barbuda, Algeria, Bangladesh, Bolivia, Belarus, Burundi, Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Ecuador, Iran, Iraq, Ivory Coast, Liberia, Libya, Magnitsky, Mali, Morocco, Myanmar, Nepal, North Korea, Somalia, Sudan, Syria, Venezuela, Yemen, Zimbabwe or any countries to which the United States, the United Kingdom or the European Union embargoes goods or imposes similar sanctions (Collectively “Restricted Territories”).
              </p>
            </li>

            <li>
              <p>
                You will not use or attempt to use any privacy tool nor Virtual Private Network “VPN” to circumvent any restrictions that apply to the Services.
              </p>
            </li>

            <li>
              <p>
                By accessing and using Greater Asia Services, you have not been included in any trade embargoes or economic sanctions list (such as the United Nations Security Council Sanctions List). The 	list of specially designated nationals maintained by OFAC (The Office of Foreign Assets Control of the U.S. Department of Treasury), or the Denied Persons or Entity List of the U.S. Department of Commerce.
              </p>
            </li>

            <li>
              <p>
                Your use of the Service is not prohibited and does not violate any and all laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.
              </p>
            </li>
          </ul>

          <h3 className='m0 mt5 p0'>
            Disclosures & Disclaimers
          </h3>

          <p>
            <a href='https://mecha.art' target='_blank'>https://mecha.art</a> is an NFT marketplace (the “Platform”) and does not operate as a Digital Asset Exchange or Derivative Exchange platform or offer trade execution or clearing services.
          </p>

          <p>
            The Platform permits the users to The Service allows you to create non-fungible tokens on the Polygon Blockchain and upload user-created content to our servers and other third-party affiliates, manage existing non-fungible tokens made on The Service, or made on other third-party services, browse non-fungible tokens created on the Polygon Blockchain, and transact between two parties by selling non-fungible tokens. The services described are hereafter referred to as (“Services”).
          </p>

          <ul>
            <li>
              <p>
                Neither Greater Asia nor the Platform is registered as a broker or dealer, a national securities change, a capital market service provider or an alternative trading system in any jurisdiction.
              </p>
            </li>

            <li>
              <p>
                All transactions between users of the Platform are executed peer to peer between the users' MATIC addresses through smart contracts. Therefore, Greater Asia has no oversight, involvement, or control concerning your transaction using the Services. You are responsible for complying with all Applicable Laws and shall bear your own risk by accessing and using the Site and the Platform.
              </p>
            </li>
          </ul>

          <h3 className='m0 mt5 p0'>
            Publishing and Ownership of the Content
          </h3>

          <p>
            All of the content featured or displayed on the Site, including, without limitation, all Market Data, text, graphics, photographs, images, video, and other materials ( “Content”), and the selection, coordination and arrangement of such Content, is owned by us, our licensors or other providers of such material (collectively, our “Content Providers”) and is protected by copyright, trademark, patent and other intellectual property or proprietary rights laws. All rights not expressly granted are reserved by us.
          </p>

          <h3 className='m0 mt5 p0'>
            Trademark
          </h3>

          <p>
            Other product and service names referenced herein may be trademarks and service marks of their respective companies and are the exclusive property of such respective owners, and may not be used publicly without the express written consent of the owners and/or holders of such trademarks and service marks.
          </p>

          <ul>
            <li>
              <p>
                You acknowledge and agree that we either own or have been authorized by relevant third-party intellectual property owners to use all trademarks, copyright, patents, design and intellectual property of any nature and form found on the Site and the Services.
              </p>
            </li>

            <li>
              <p>
                All of the marks, logos, domains, and trademarks that you find on the Site and Platform may not be used by you or your agents and representatives publicly except with express written permission from the Platform, and may not be used in any manner that is likely to cause confusion among consumers, or in any manner that disparages or discredits the Platform.
              </p>
            </li>
          </ul>

          <h3 className='m0 mt5 p0'>
            Use of the Content
          </h3>

          <p>
            Content on the Site is protected by copyright law. Except as expressly permitted under copyright law or these Terms and Conditions, you may not reproduce, distribute, modify, frame or incorporate into another website, store or transmit any Content without the express permission and consent of our Content Providers. Content may not be sold or used for any commercial purpose under any circumstances without the express permission of us and our Content Providers.
          </p>

          <h3 className='m0 mt5 p0'>
            Risks
          </h3>

          <ul>
            <li>
              <p>
                You understand that the Platform is not registered or licensed by any financial regulatory authority and no financial regulatory authority and/or self-regulatory institution has reviewed or approved the use of the Platform.
              </p>
            </li>

            <li>
              <p>
                You understand that the Platform is not an investment adviser and does not owe any fiduciary duties to you if you visit its Site or use its services.
              </p>
            </li>

            <li>
              <p>
                You acknowledge that none of the Market Data provided on the Site constitutes a recommendation or solicitation by the Platform that you enter into any particular transaction or that any particular transaction is suitable or appropriate for you.
              </p>
            </li>

            <li>
              <p>
                You acknowledge that Greater Asia has no duty or obligation to verify, correct, complete or update any Market Data displayed on the Site. Market Data, including without limitation, price quotations, news and research, may be prepared by information providers that are independent of the Platform.
              </p>
            </li>

            <li>
              <p>
                The Platform does not warrant that Market Data or other content will be accurate, complete or refreshed in a timely manner. You should conduct further research and analysis or consult an investment advisor before making investment decisions. Any use of or reliance on Market Data or other Content on the Site is at your own risk. The Platform is not obligated to inform you of technical difficulties experienced by the Platform concerning access to it.
              </p>
            </li>

            <li>
              <p>
                To the maximum extent permitted under Applicable Laws, the Site and the Services including any of their contents or functionality provided by on behalf of us are on “AS IS” and “AS AVAILABLE” basis, and we expressly claim, and you hereby waive, any representations, conditions, or warranties of any kind whether express or implied.
              </p>
            </li>

            <li>
              <p>
                We do not represent or warrant that the Site or the Services (including Market Data) will be uninterrupted, available at any particular time, or free from error. Further, we do not warrant that errors in the Site or the Service are correctable or will be correctable.
              </p>
            </li>
          </ul>

          <h3 className='m0 mt5 p0'>
            Accessing the Platform
          </h3>

          <p>
            To access some areas of the Site, you may need to connect your “Wallet”. You are solely responsible for the safety of your own wallet. You should keep your public and private keys confidential and do not share their details with anyone.
          </p>

          <h3 className='m0 mt5 p0'>
            Link to Other Resources
          </h3>

          <p>
            Links to other websites and resources provided by third parties contained on the Site are provided for your convenience only and do not indicate any endorsement by us. We have no control over the contents of those websites or resources and you agree that we have no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any such third-party website, you do so entirely at your own risk and subject to any terms and conditions and privacy policies posted therein.
          </p>

          <ul>
            <li>
              <p>
                You hereby agree to defend and hold us harmless from and against any and all claims, liabilities, damages or losses that may result from your use of links that may appear on the Site or via the Platform.
              </p>
            </li>

            <li>
              <p>
                We reserve the right to terminate any link or linking program at any time.
              </p>
            </li>
          </ul>

          <h3 className='m0 mt5 p0'>Improper Use of the Site</h3>
          <p>You agree not to:</p>

          <ol>
            <li>
              <p>use the Site in any way that violates any applicable law or regulation;</p>
            </li>

            <li>
              <p>
                duplicate any part of our Site or the Content contained therein or other information received via the Services;
              </p>
            </li>

            <li>
              <p>
                engage in any conduct that disables, overburdens, damages, impairs, restricts or interferes with the Site or anyone’s use or enjoyment of the Site, or which may harm Greater Asia or users of the Site or expose them to liability;
              </p>
            </li>

            <li>
              <p>
                introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful to the Site;
              </p>
            </li>

            <li>
              <p>
                place pop-up windows over pages of the Site, or otherwise affect the display of pages of the Site;
              </p>
            </li>

            <li>
              <p>
                use the Site or Services, or any of the Content contained therein, for any public display, public performance, sale or rental;
              </p>
            </li>

            <li>
              <p>
                re-distribute any of the Content or other information received through the Services;
              </p>
            </li>

            <li>
              <p>
                re-distribute any of the Content or other information received through the Services;
              </p>
            </li>

            <li>
              <p>
                remove any copyright or other proprietary notices from the Site or any of the Content contained therein;
              </p>
            </li>

            <li>
              <p>
                frame or utilize any framing techniques in connection with the Site or any of the Content contained therein;
              </p>
            </li>

            <li>
              <p>
                circumvent any encryption or other security tools used anywhere on the Site or in conjunction with the Services (including the theft of public and private key or using another person's public and private key in order to gain access to a restricted area of the Site);
              </p>
            </li>

            <li>
              <p>
                use any data mining, bots, scrapers or similar data gathering and extraction tools on the Site or in conjunction with the Services;
              </p>
            </li>

            <li>
              <p>
                sell, rent, lease, license, sublicense, transfer, distribute, re-transmit, time-share, use as a service bureau or otherwise assign to any third party the Content or Services or any of your rights to access the Platform and use the Content or Services as granted specifically herein;
              </p>
            </li>

            <li>
              <p>
                use our Services to impersonate any other user or person;
              </p>
            </li>

            <li>
              <p>
                otherwise use any Content or information on the Site or provided by our Services in any manner that infringes any copyright, trademark, patent, trade secret, publicity or other proprietary right of any party;
              </p>
            </li>

            <li>
              <p>
                upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation, except in those areas that we may designate for such purpose;
              </p>
            </li>

            <li>
              <p>
                falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source material that is uploaded or otherwise provided by you;
              </p>
            </li>

            <li>
              <p>
                restrict or inhibit any other user from using and enjoying the Services. Moreover, you agree not to stalk, harass or harm another individual, impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity, or collect or store personal data about other users or visitors to the Site.
              </p>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Availability of Site & Services
          </h3>

          <p>
            Electronic and computer-based facilities and systems such as the Platform are inherently vulnerable to disruption, delay or failure and may become unavailable to you as a result of events both foreseeable and unforeseeable. You agree that we are not liable for any loss and damage arising from any such interruption and you agree to hold us harmless against any such interruption of or inability to access the Site, the Platform or Services.
          </p>

          <h3 className='m0 mt5 p0'>
            Indemnification
          </h3>

          <p>
            You agree to indemnify, defend and hold us and our Content Providers harmless, from and against any and all losses, damage, liability and costs of every nature incurred by any of us or the Content Providers in connection with any claim, damage or loss related to or arising out of and use of the Site or any breach or alleged breach by you of these Terms and Conditions. You agree to cooperate fully in defense of any of the foregoing.
          </p>

          <h3 className='m0 mt5 p0'>
            No Warranties
          </h3>

          <p>
            Neither the Platform nor any of its affiliates make any warranty or representation with respect to the completeness, security, reliability, quality, accuracy or availability of the Site or the Content.
          </p>

          <p>
            THE SITE AND ALL ITS CONTENT, AND ALL SERVICES PROVIDED BY THE SITE ARE SUBJECT TO CHANGE AND PROVIDED BY US OR OUR CONTENT PROVIDERS “AS IS” WITHOUT ANY WARRANTY OR CONDITION, AND WITHOUT THE UNDERTAKING OF ANY DUTY, OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY (IF ANY) WARRANTIES OR CONDITIONS OF MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR PURPOSE.
          </p>

          <h3 className='m0 mt5 p0'>
            Limitation on Liability
          </h3>

          <p>
            TO THE FULL EXTENT ALLOWED BY LAW, YOU AGREE THAT NEITHER US NOR ANY OF OUR CONTENT PROVIDERS WILL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES, DAMAGES FOR LOST PROFITS, FOR LOSS OF PRIVACY OR SECURITY, FOR LOSS OF REPUTATION, FOR FAILURE TO MEET ANY DUTY, OR FOR ANY OTHER SIMILAR DAMAGES.
          </p>

          <h3 className='m0 mt5 p0'>
            Termination
          </h3>

          <p>
            We reserve the right to suspend or terminate your use of any or all of the Site, or terminate or cancel this agreement, at any time with or without prior notice. You agree that we shall not be liable to you or any third party for termination of your access to the Site.
          </p>

          <h3 className='m0 mt5 p0'>
            Arbitration
          </h3>

          <p>
            Both you and Greater Asia agree that any dispute arising out of or related to these Terms and Conditions is personal between you and Greater Asia and that any dispute will be resolved solely through individual action, and will not be brought as a class arbitration, class action, or any other type of representative proceeding.
          </p>

          <h3 className='m0 mt5 p0'>
            Miscellaneous
          </h3>

          <p>
            These T&C  including the Privacy Policy constitute the entire agreement between you and us with respect to the Site, Platform and Services. Any failure on our part to assert a right or provision shall not constitute a waiver of such right or provision. If any provision of these Terms and Conditions is held to be invalid, illegal or unenforceable, then such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and effect.
          </p>
        </StyledContainer>
      </div>
    </Layout>
  )
}

const StyledContainer = styled.div`
	margin-top: 2rem;
	padding-bottom: 5rem;
	padding: 2rem 1rem 4rem;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	a{
		color:  ${({ theme }) => theme.text};
		font-weight: bold;
	}
	ul {
		padding: 0 1rem;
	}
	ol {
		padding: 0 1rem;
	}
	li {
		p{
			margin: 0;
		}
	}
`

export default Terms;