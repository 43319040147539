import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { ThemeContext } from "styled-components";
import { useEffect, useState, useContext } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../assets/style/DateTimePicker.scss'

interface InputInterface {
	onValueChange: Function
	defaultDate: number
	minDate: number
	maxDate: number
	value: number

	label: string
	required: boolean
	errorLabel: string
	infoLabel: string
	child: any
}

const getDateValue = (date: number) => {
	let tempDate = new Date();
	tempDate.setDate(tempDate.getDate() + date)
	tempDate.setHours(tempDate.getHours() + 1);
	tempDate.setMilliseconds(0);
	tempDate.setSeconds(0);
	tempDate.setMinutes(0);

	return tempDate.valueOf();
}

const CustomDtePicker = (props: Partial<InputInterface>) => {
	const { label, errorLabel, infoLabel, child, required } = props;
	const { value, defaultDate = 0, minDate = 0, onValueChange } = props;

	const theme = useContext(ThemeContext);
	const [selectedDay, setSelectedDay] = useState<number>(+ new Date());
	const [minDay, setMinDay] = useState<number>(0)

	useEffect(() => {
		if (minDay !== minDate) setMinDay(minDate)
	}, [minDate])

	useEffect(() => {
		setSelectedDay(defaultDate)
		if (onValueChange) onValueChange(defaultDate)
	}, [])

	const onChangeDate = (date: Dayjs) => {
		const time = new Date(`${date['$y']}-${date['$M'] + 1}-${date['$D']} ${date['$H']}:${date['$m']}`).valueOf();
		if (onValueChange) onValueChange(time)
		setSelectedDay(time)
	}

	return (
		<div style={{ margin: '1.5rem 0' }}>
			<fieldset className="InputField"
				style={{
					border: `1px solid ${theme.strokeWhite}`,
					position: 'relative', padding: '0 0.5rem'
				}}>
				{label && (
					<legend>
						{label} {required && <span style={{ color: !value ? 'red' : theme.strokeWhite, fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>}
					</legend>
				)}

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DateTimePicker']} sx={{ width: "100%" }}>
							<DateTimePicker value={dayjs(selectedDay)}
								minDateTime={dayjs(minDate)}
								onChange={onChangeDate}
							/>
						</DemoContainer>
					</LocalizationProvider>

					{child}
				</div>
			</fieldset>

			{(errorLabel && errorLabel != "") && (
				<p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>{errorLabel}</p>
			)}

			{(infoLabel && infoLabel != "") && (
				<p style={{ color: theme.text, fontSize: '0.8rem', margin: '4px 8px' }}>{infoLabel}</p>
			)}
		</div>
	)
}

export { CustomDtePicker, getDateValue };