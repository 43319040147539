import React from "react";
import { useState } from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface CustomTooltipProps {
  title: any
  children: any
  [key: string]: any
}

interface ExtendTooltipProps extends TooltipProps {
  className?: any
}

const CustomTooltip = ({ title, children, ...props }: CustomTooltipProps) => {
  const [open, setOpen] = useState(false);
  const onTooltipOpen = () => { setOpen(true) };
  const onTooltipClose = () => { setOpen(false) };

  return (
    <HtmlTooltip arrow placement="top"
      title={title} open={open}
      onClose={onTooltipClose}
      onOpen={onTooltipOpen}
    >
      <Stack onClick={onTooltipOpen} {...props}>
        {children}
      </Stack>
    </HtmlTooltip>
  )
}

const HtmlTooltip = styled(({ className, ...props }: ExtendTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  zIndex: 10005,
  cursor: "pointer",
  "& :first-letter": {
    textTransform: "uppercase",
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: "#060200e3",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    color: "#ccad45",
    backgroundColor: "#060200e3",
    padding: "15px 20px",
  }
})

export { CustomTooltip };