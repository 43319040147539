import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Icon from "./Icon";

interface SwitchInterface {
	values?: any
	selectedKey?: string
	onChange: Function
	props?: any
	itemProps?: any
	panelProps?: any
	hideImg?: boolean
}

export default function Dropdown({ values, selectedKey, hideImg, props, panelProps, itemProps, onChange }: SwitchInterface) {
	const panelRef = React.useRef(null);
	const theme = useContext(ThemeContext);
	const [showMenu, setShowMenu] = React.useState(false);
	const [status, setStatus] = React.useState({ selectedKey: selectedKey })

	return (
		<StyledDropdownPanel {...props} onMouseUp={() => { setShowMenu(true) }} onBlur={() => { setShowMenu(false) }}>
			{status.selectedKey}
			<div className="drop-icon">
				<div style={{ transition: '0.12s', transform: showMenu ? 'rotate(180deg)' : 'none' }} >
					<Icon icon="Down" height={20} fill={theme.strokeColor} />
				</div>
			</div>

			<div className="menu-panel" ref={panelRef} style={{ display: showMenu ? 'block' : 'none', ...panelProps?.style }}>
				{values && values.map((item, index) => (
					<div key={"menu" + index}
						className="item flex middle"
						{...itemProps}
						onBlur={() => { setShowMenu(false) }}
						onClick={() => {
							setStatus({ selectedKey: item.symbol || item.name });
							onChange(item.key || item.symbol);
							setShowMenu(false)
						}}
					>
						{(!hideImg && (item.img || item.icon)) && (
							<img src={item.img || item.icon}
								style={{ width: '25px', height: '25px', marginRight: '10px', borderRadius: '50%' }}
							/>
						)}
						{item.symbol || item.name}
					</div>
				))}
			</div>

			{showMenu && (
				<div className="background" onClick={() => { setShowMenu(false) }} />
			)}
		</StyledDropdownPanel>
	)
}

const StyledDropdownPanel = styled.div`
	background-color: ${({ theme }) => theme.propertyBoxColor};
	color: ${({ theme }) => theme.text};
	padding: 1rem 1rem;
	border-radius: 8px;
	cursor: pointer;
	position: relative;
	min-width: 100px;
	font-size: 0.9rem;
	user-select: none;
	.background{
		width: 100vw;
		height: 100vh;
		background-color: transparent;
		position: fixed;
		left: 0;
		top: 0;

	}
	.drop-icon{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		right: 10px;
		top: 0;
		transition: 0.2s;
	}
	.menu-panel{
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		overflow: hidden;
		width: 100%;
		z-index: 1001;
		max-height: 50vh;
		overflow-y: auto;
		border-radius: 8px;
		animation:  fadeIn-dropdown 0.3s linear;
		background-color: ${({ theme }) => theme.propertyBoxColor};
		.item{
			width: 100%;
			background-color: ${({ theme }) => theme.propertyBoxColor};
			padding: 1rem;
			transition: 0.2s all;
			color: ${({ theme }) => theme.text};
			&:hover{
				background-color: ${({ theme }) => theme.modalBg}!important;
			}
		}
	}
	
	@-webkit-keyframes fadeIn-dropdown {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
	
   @-moz-keyframes  fadeIn-dropdown {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
   
	@keyframes fadeIn-dropdown {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
	}
`