import React from 'react';
import { useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useQuery } from '@apollo/client';
import { useI18n } from 'react-simple-i18n';
import LoadingBar from "react-top-loading-bar";
import { Link, useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import Icon from '../Icon';
import useStore from '../../useStore';
import Modal from '../../components/Modal';
import { ButtonPrimary } from '../components';
import MetamaskProvider from '../MetamaskProvider'
import { Now, showToast, timeAgo } from '../../util'
import { GET_ALERT, GET_PAYLABLE_TOKEN, GET_SEARCH } from '../../graphql';
import BalanceModal from '../BalanceModal'
import CustomCheckbox from '../../components/Checkbox';

import logo from '../../assets/img/logo.svg';
import avatar from '../../assets/img/avatar.png'
import walletimg from '../../assets/img/wallet.svg'
import logoblack from '../../assets/img/logoblack.png';
import collectionImg from '../../assets/img/collection.webp'
import alertImg from '../../assets/img/alert.png';
import balanceAlert from '../../assets/img/coin.png';

interface HeaderInterface {
  showWalletModal: boolean
  showAlertModal: boolean
  showSearchModal: boolean
  showLanguageModal: boolean
  showMenuModal: boolean
  alertCount: number
  keyword: string
  alerts: Alert[]
  paylableTokens: Prices[]
  searchData: {
    collection: Collection[],
    item: NFTSearchResult[],
    user: UserDataObject[]
  }
  openDrop: boolean
}

const Header = () => {
  const { t } = useI18n();
  const wallet = useWallet();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { darkMode, logined, currentAccountAddress, currentAccountAvatar, lang, updated, update, call, isMetamask, token } = useStore();

  const [showUserMenu, setShowUserMenu] = React.useState(false)
  const [progress, setProgress] = React.useState(0);

  const [status, setStatus] = React.useState<HeaderInterface>({
    showWalletModal: window.location.pathname.split("/")[1]?.toString() === "profile" && window.location.pathname.split("/")[2]?.toString() === "wallet",
    showAlertModal: false,
    showLanguageModal: false,
    showSearchModal: false,
    showMenuModal: false,
    alertCount: 0,
    keyword: new URL(window.location.href).searchParams.get('query') || '',
    alerts: [],
    paylableTokens: [],
    searchData: { item: [], collection: [], user: [] },
    openDrop: false
  })

  const updateStatus = (params: Partial<HeaderInterface>) => setStatus({ ...status, ...params })

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: alertInfo, loading: alertLoading, error: alertError } = useQuery(GET_ALERT, {
    variables: {
      address: currentAccountAddress
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (alertLoading || alertError) return;
    const data = alertInfo?.getAlert as Alert[];
    if (!data) return;
    if (data.length > 0) {
      update({ updated: updated + 1 })
    }
    // console.log(data);
    // data.forEach(element => {
    // 	if( Now()  - Number(element?.created || 0) < 20 || element.status === "pending") {
    // 		const type = element?.type;
    // 		if(type === "nft" && element?.collectionid && element?.tokenId) {
    // 			showToast(darkMode, element?.img || notification, element.title, () => {
    // 				navigate("/assets/" + element?.collectionid +"/" + element?.tokenId);
    // 			})
    // 			// closeAlert(element._id);
    // 		}
    // 		else if(type === "balance") {
    // 			showToast(darkMode, coin, element.title, () => {})
    // 			// closeAlert(element._id);
    // 		}
    // 	}
    // });
    updateStatus({ alerts: data, alertCount: data.length })
  }, [alertInfo, alertLoading, alertError])


  const { data: searchInfo, loading: searchLoading, error: searchError } = useQuery(GET_SEARCH, {
    variables: {
      keyword: status.keyword.trim()
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    const data = searchInfo?.getSearch as { collection: Collection[], item: NFTSearchResult[], user: UserDataObject[] };
    updateStatus({ searchData: data, openDrop: data?.collection?.length > 0 || data?.item?.length > 0 || data?.user?.length > 0 })
  }, [searchInfo, searchLoading, searchError])

  const logOut = () => {
    update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", balances: [], currentAccountAddress: "", token: "", logined: false, isMetamask: false, lasttime: 0 });
    navigate("/");
    wallet.reset()
  }

  const search = () => {
    const pathname = new URL(window.location.href).pathname;
    if (pathname === "/search-nft") {
      navigate("/search-nft?query=" + status.keyword);
    } else if (pathname === "/search-collection") {
      navigate("/search-collection?query=" + status.keyword);
    } else if (pathname.toString().indexOf("/collection/") > -1) {
      const path = new URL(window.location.href).pathname.split("/");
      navigate("../collection/" + path[path.length - 1] + "?query=" + status.keyword);
    } else {
      navigate("../search-nft?query=" + status.keyword);
    }
    updateStatus({ keyword: "" })
  }

  const closeAlert = async (id) => {
    const result = await call("/api/alert/close", {
      id: id,
      address: currentAccountAddress
    }, { authorization: token })
    if (result && result["message"] === "success") {
      let newAlerts = []
      status.alerts.forEach(element => {
        if (element._id !== id) newAlerts.push(element)
      });
      updateStatus({ alerts: newAlerts, alertCount: status.alertCount - 1 })
    }
  }

  const closeAllAlert = async () => {
    const result = await call("/api/alert/closeAll", {
      address: currentAccountAddress
    }, { authorization: token })
    if (result && result["message"] === "success") {
      updateStatus({ alerts: [], alertCount: 0, showAlertModal: false })
    }
  }

  const onFocus = () => {
    if (status.keyword.trim().length === 0 && status.searchData?.collection?.length === 0 && status.searchData?.item?.length === 0 && status.searchData?.user?.length === 0) {
      updateStatus({ openDrop: false })
    }
    else {
      updateStatus({ openDrop: true })
    }
  }

  React.useLayoutEffect(() => {
    setProgress(100);
  }, []);

  return (
    <>
      <StyledHeader darkMode={darkMode}>
        <LoadingBar height={3}
          color="rgb(42 108 143)"
          progress={progress}
          waitingTime={500}
          loaderSpeed={500}
          style={{ userSelect: 'none' }}
        />

        <div className="container">
          <header>
            <div className="flex middle">
              <Link to="/">
                <img src={darkMode ? logo : logoblack} className="logo" alt="logo" />
              </Link>

              <div className="icon-menu-bar">
                <div className='icon-menu' onClick={() => { updateStatus({ showSearchModal: true }) }}>
                  <Icon icon="Search" size={20} />
                </div>
              </div>
            </div>

            <div className="search-bar"
              onBlur={() => { setTimeout(() => { updateStatus({ openDrop: false }) }, 1000) }}
            >
              <div className="search-input">
                <input type="text" placeholder={t("header.searchPlaceholder")} value={status.keyword}
                  onChange={(e) => { updateStatus({ keyword: e.target.value }) }}
                  onFocus={() => { onFocus() }}
                  onKeyDown={(e) => {
                    onFocus()
                    if (e.key === "Enter") {
                      search()
                    }
                  }}
                />

                <div className="search-btn" onClick={() => { status.keyword === "" ? search() : updateStatus({ keyword: "" }) }}>
                  <Icon icon={status.keyword === "" ? "Search" : "Close"} size={20} />
                </div>

                {/* <ButtonPrimary className="search-btn-right" style={{ borderRadius: '8px' }} onClick={search}>
                  {t("header.search")}
                </ButtonPrimary> */}

                <div className="search-result-panel" style={{ display: status.openDrop ? 'block' : 'none' }}>
                  {(status.searchData?.collection?.length === 0 && status.searchData?.item?.length === 0 && status.searchData?.user?.length === 0) && (
                    <p style={{ textAlign: 'center' }}>No search data</p>
                  )}

                  {status.searchData?.item?.length > 0 && (
                    <div className="title">NFT</div>
                  )}

                  {status.searchData?.item?.map((item, key) => (
                    <div className="item" key={key}
                      onClick={() => {
                        updateStatus({ keyword: "" });
                        navigate("/assets/" + item.nftCollection + "/" + item.tokenid)
                      }}
                    >
                      <div className="flex middle">
                        <img src={item.image || avatar} alt="asset" />

                        <div className="asset-info">
                          <div className="flex">
                            <p>{item.name}</p>
                            {item?.collectionverified === "verified" && <Icon icon="Photo" size={24} />}
                          </div>

                          <p>{item.views} View</p>
                        </div>
                      </div>

                      <div className="balance-info">
                        <p style={{ textAlign: 'right' }}>
                          {item.price > 0 && item.price + item.acceptedToken}
                        </p>

                        <p style={{ textAlign: 'right' }}>
                          {(item.price || 0) > 0 &&
                            (item.price * (status.paylableTokens.find(v => {
                              return v.symbol === item.acceptedToken
                            })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2) + (lang === 'en' ? '$' : '￥')}
                        </p>
                      </div>
                    </div>
                  ))}

                  {status.searchData?.collection?.length > 0 && (
                    <div className="title">Collection</div>
                  )}

                  {status.searchData?.collection?.map((collection, key) => (
                    <div className="item" key={key}
                      onClick={() => {
                        updateStatus({ keyword: "" });
                        navigate("/collection/" + collection.address)
                      }}
                    >
                      <div className="flex middle">
                        <img src={collection?.metadata?.image || collectionImg} alt="asset" />

                        <div className="asset-info">
                          <div className="flex">
                            <p>{collection?.metadata?.name}</p>

                            {collection?.verified?.status === "verified" && (
                              <Icon icon="Photo" size={24} />
                            )}
                          </div>

                          <p>{collection.items} Items</p>
                        </div>
                      </div>

                      {/* <div className="balance-info">
                        {Number(collection.volume) > 0 && (
                          <p>{Number(Number(collection.volume).toFixed(2))} Volume</p>
                        )}
                      </div> */}
                    </div>
                  ))}

                  {status.searchData?.user?.length > 0 && (
                    <div className="title">User</div>
                  )}

                  {status.searchData?.user?.map((user, key) => (
                    <div className="item" key={key}
                      onClick={() => { updateStatus({ keyword: "" }); navigate("/" + user.address) }}
                    >
                      <div className="flex middle">
                        <img alt="asset" src={user?.avatar_img || avatar}
                          style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }}
                        />

                        <div className="asset-info">
                          <div className="flex">
                            <p>{user?.name}</p>

                            {user?.verified?.status === "verified" && (
                              <Icon icon="Photo" size={24} />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="balance-info">
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='login-btn flex middle'>
              <div className='flex  middle'>
                {logined && (
                  <StyledNFTCreateBtn onClick={() => { navigate(isMetamask ? "/items/create" : "/items/select-options") }}>
                    {t("header.createNFT")}
                  </StyledNFTCreateBtn>
                )}

                {window.innerWidth >= 480 && (
                  <div className="alert" style={{ margin: '0 8px' }}
                    onClick={() => { updateStatus({ showLanguageModal: true }) }}
                  >
                    <Icon icon='Language' size={24} />
                  </div>
                )}

                {logined && (
                  <div className="alert" onClick={() => { updateStatus({ showAlertModal: true }) }}>
                    <Icon icon='Alert' size={24} />
                    {status.alertCount > 0 && (
                      <div className="mark">{status.alertCount}</div>
                    )}
                  </div>
                )}

                {logined && (
                  <StyledWalletBtn onClick={() => { updateStatus({ showWalletModal: true }) }}>
                    <img src={walletimg} className='wallet-btn' />
                  </StyledWalletBtn>
                )}

                {logined && (
                  <StyledProfileBar darkMode={darkMode}>
                    <div className='avatar'
                      onClick={() => { setShowUserMenu(true) }}
                      onMouseUp={() => { setShowUserMenu(true) }}
                      onBlur={() => { setShowUserMenu(false) }}
                    >
                      <img src={currentAccountAvatar || avatar} />
                    </div>

                    <div className="drop-panel" style={{ display: showUserMenu ? 'block' : 'none' }}>
                      <Link to="/profile" className='menu'
                        onBlur={() => { setShowUserMenu(false) }}
                        onClick={() => { setShowUserMenu(false) }}
                      >
                        {t("header.profile")}
                      </Link>

                      <div className="hr" style={{ opacity: 0.3 }} />
                      <Link to="/collections/select-options" className="menu"
                        onBlur={() => { setShowUserMenu(false) }}
                        onClick={() => { setShowUserMenu(false) }}
                      >
                        {t("header.createCollection")}
                      </Link>

                      <div className="hr" style={{ opacity: 0.3 }} />
                      <div className="menu"
                        onBlur={() => { setShowUserMenu(false) }}
                        onClick={() => { logOut(); setShowUserMenu(false) }}
                      >
                        {t("header.logout")}
                      </div>
                    </div>

                    {showUserMenu && (
                      <div className="background" onClick={() => { setShowUserMenu(false) }} />
                    )}
                  </StyledProfileBar>
                )}
              </div>

              {(!logined && window.innerWidth >= 480) && (
                <Link to="/login" style={{ textDecoration: 'none', color: theme.text }}>
                  <ButtonPrimary className='login-btn'>
                    {t("header.loginregister")}
                  </ButtonPrimary>
                </Link>
              )}

              <div className="icon-menu-bar">
                <div className='icon-menu' onClick={() => { updateStatus({ showMenuModal: !status.showMenuModal }); }}>
                  {status.showMenuModal ? <Icon icon="Close" size={24} /> : <Icon icon="Menu" size={20} />}
                </div>
              </div>
            </div>
          </header>
        </div>

        <Modal onClose={() => { updateStatus({ showSearchModal: false }) }} show={status.showSearchModal} >
          <h2 className='mt5 text-center'>
            {t("header.searchPlaceholder")}
          </h2>

          <div className="flex center middle" >
            <div className="search-bar min" style={{ height: '55vh' }}>
              <div className="search-input">
                <input type="text" value={status.keyword}
                  placeholder={t("header.searchPlaceholder")}
                  onChange={(e) => { updateStatus({ keyword: e.target.value }) }}
                  onKeyDown={(e) => { onFocus(); if (e.key === "Enter") { search() } }}
                  onFocus={() => { onFocus() }}
                />

                <div className="search-btn" onClick={() => { status.keyword === "" ? search() : updateStatus({ keyword: "" }) }}>
                  <Icon icon={status.keyword === "" ? "Search" : "Close"} />
                </div>

                <div className="search-result-panel" style={{ display: status.openDrop ? 'block' : 'none' }}>
                  {(status.searchData?.collection?.length === 0 && status.searchData?.item?.length === 0 && status.searchData?.user?.length === 0) && (
                    <p style={{ textAlign: 'center' }}>No search data</p>
                  )}

                  {status.searchData?.item?.length > 0 && (
                    <div className="title">NFT</div>
                  )}

                  {status.searchData?.item?.map((item, key) => (
                    <div className="item" key={key}
                      onClick={() => {
                        updateStatus({ keyword: "" });
                        navigate("/assets/" + item.nftCollection + "/" + item.tokenid)
                      }}

                    >
                      <div className="flex middle">
                        <img src={item.image || avatar} alt="asset" />

                        <div className="asset-info">
                          <p>{item.name}</p>
                          <p>{item.views} View</p>
                        </div>
                      </div>

                      <div className="balance-info">
                        <p style={{ textAlign: 'right' }}>
                          {item.price > 0 && item.price + item.acceptedToken}
                        </p>

                        <p style={{ textAlign: 'right' }}>
                          {(item.price || 0) > 0 &&
                            (item.price * (status.paylableTokens.find(v => {
                              return v.symbol === item.acceptedToken
                            })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2) + (lang === 'en' ? '$' : '￥')}
                        </p>
                      </div>
                    </div>
                  ))}

                  {status.searchData?.collection?.length > 0 && (
                    <div className="title">Collection</div>
                  )}

                  {status.searchData?.collection?.map((collection, key) => (
                    <div className="item" key={key}
                      onClick={() => {
                        updateStatus({ keyword: "" });
                        navigate("/collection/" + collection.address)
                      }}
                    >
                      <div className="flex middle">
                        <img alt="asset"
                          src={collection?.metadata?.image || collectionImg}
                        />

                        <div className="asset-info">
                          <p>{collection?.metadata?.name}</p>
                          <p>{collection.items} Items</p>
                        </div>
                      </div>
                    </div>
                  ))}

                  {status.searchData?.user?.length > 0 && (
                    <div className="title">User</div>
                  )}

                  {status.searchData?.user?.map((user, key) => (
                    <div className="item" key={key}
                      onClick={() => {
                        updateStatus({ keyword: "" });
                        navigate("/" + user.address)
                      }}
                    >
                      <div className="flex middle">
                        <img alt="asset" src={user?.avatar_img || avatar}
                          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                        />

                        <div className="asset-info">
                          <p>{user?.name}</p>
                        </div>
                      </div>

                      <div className="balance-info" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal onClose={() => { updateStatus({ showLanguageModal: false }) }} show={status.showLanguageModal} >
          <h1 className='text-center'>Language</h1>

          <div className="flex center middle" >
            <div className="flex">
              <CustomCheckbox checked={lang === 'en'}
                changeEvent={() => { update({ lang: 'en' }) }}
              />

              <p onClick={() => { update({ lang: 'en' }) }}
                style={{ cursor: 'pointer', color: theme.text, width: '80px' }}
              >
                English
              </p>
            </div>

            <div className="flex">
              <CustomCheckbox checked={lang === 'jp'}
                changeEvent={() => { update({ lang: 'jp' }) }}
              />

              <p onClick={() => { update({ lang: 'jp' }) }}
                style={{ cursor: 'pointer', color: theme.text, width: '80px' }}
              >
                日本語
              </p>
            </div>
          </div>
        </Modal>
      </StyledHeader>

      {status.showAlertModal && (
        <StyledModal>
          <div className="container" style={{ position: 'relative' }}>
            <div className="overlay" onClick={() => { updateStatus({ showAlertModal: false }) }} />

            <div className="modal-container">
              <StyledAlertPanel>
                {status.alerts && status.alerts.map(alert => (
                  <StyledAlert key={"alert-" + alert.created} onClick={() => { closeAlert(alert._id); }}>
                    <div className='flex middle'>
                      <div style={{ margin: '0 8px 0 0' }} className='flex middle'>
                        <img src={alert?.img == "balance" ? balanceAlert : (alert?.img == "alert" ? alertImg : alert?.img || alertImg)} />
                      </div>

                      <div>
                        <p style={{ fontSize: '12px', margin: 0 }}>{timeAgo(Number(alert.created) * 1000)}</p>
                        <h4 className='m0'>{alert.title}</h4>
                        {/* <p style={{padding: 0, margin: 0, wordBreak:"break-all"}}>{alert.content}</p> */}
                      </div>
                    </div>

                    <div>
                      <Icon icon="Close" marginLeft={10} size={22} />
                    </div>
                  </StyledAlert>
                ))}

                {status.alerts?.length > 0 && (
                  <div className="flex middle center mt2 mb2" >
                    <ButtonPrimary style={{ width: '180px' }} onClick={closeAllAlert}>
                      {t("header.readall")}
                    </ButtonPrimary>
                  </div>
                )}

                {status.alerts?.length == 0 && (
                  <div className='flex center middle'>
                    <p className='mt3 mb3'>
                      {t("header.noalerts")}
                    </p>
                  </div>
                )}
              </StyledAlertPanel>
            </div>
          </div>
        </StyledModal>
      )}

      {status.showMenuModal && (
        <StyledMenuModal>
          <div className="overlay" onClick={() => { updateStatus({ showMenuModal: false }) }} />

          <div className="modal-container">
            <div className="row ">
              <div className="col-md-3 col-sm-12">
                <StyledLinkTitle>NFT</StyledLinkTitle>

                <StyledLinkList>
                  <StyledLink href="/search-nft">{t("footer.searchNFT")}</StyledLink>
                  <StyledLink href="/profile">{t("footer.sellNFT")}</StyledLink>
                  <StyledLink href={isMetamask ? "/items/create" : "/items/select-options"}>{t("footer.createNFT")}</StyledLink>
                </StyledLinkList>
              </div>

              <div className="col-md-3 col-sm-12">
                <StyledLinkTitle>{t('footer.collection')}</StyledLinkTitle>

                <StyledLinkList>
                  <StyledLink href="/search-collection">{t("footer.searchCollection")}</StyledLink>
                  <StyledLink href="/collections/select-options">{t("footer.createCollection")}</StyledLink>
                </StyledLinkList>
              </div>

              <div className="col-md-3 col-sm-12">
                <StyledLinkTitle>{t("footer.mypage")}</StyledLinkTitle>

                <StyledLinkList>
                  {logined && (
                    <StyledLink onClick={() => { logOut(); updateStatus({ showMenuModal: false }) }}>
                      {t("footer.logout")}
                    </StyledLink>
                  )}

                  {!logined && (
                    <StyledLink href='/login'>{t("footer.login")}</StyledLink>
                  )}

                  <StyledLink href="/profile/wallet">{t("footer.wallet")}</StyledLink>
                  <StyledLink href="/profile">{t("footer.editProfile")}</StyledLink>
                </StyledLinkList>
              </div>

              <div className="col-md-3 col-sm-12">
                <StyledLinkTitle>{t("home.support")}</StyledLinkTitle>

                <StyledLinkList>
                  <StyledLink target='_blank' href={`https://support.mecha.fun/whatnft/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.whatnft")}</StyledLink>
                  <StyledLink target='_blank' href={`https://support.mecha.fun/create/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howcreate")}</StyledLink>
                  <StyledLink target='_blank' href={`https://support.mecha.fun/purchase/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howpurchase")}</StyledLink>
                  <StyledLink target='_blank' href={`https://support.mecha.fun/sell/` + (lang == "jp" ? 'ja' : 'en')} >{t("footer.howsell")}</StyledLink>
                </StyledLinkList>
              </div>

              <div className="col-md-3 col-sm-12">
                <StyledLinkTitle>{t("footer.link")}</StyledLinkTitle>

                <StyledLinkList>
                  <StyledLink href={`https://support.mecha.fun/` + (lang == "jp" ? 'ja' : 'en')} target='_blank'>
                    {t("footer.support")}
                  </StyledLink>
                  <StyledLink href="/privacy">{t("footer.policy")}</StyledLink>
                  <StyledLink href="/terms">{t("footer.terms")}</StyledLink>
                </StyledLinkList>
              </div>
            </div>

            {/* <div className="darktheme">
              <div className='flex middle' onClick={() => { update({ darkMode: !darkMode }); }}>
                <Icon icon="Dark" margin={16} height={25} fill={theme.text} />
                <p style={{ color: theme.text, margin: 0, marginRight: 24 }}>{t("footer.darktheme")}</p>
              </div>

              <Switch switched={!darkMode} onChange={() => { update({ darkMode: !darkMode }); }} />
            </div> */}

            <div className="darktheme">
              <div className='flex middle' onClick={() => { update({ lang: lang == "en" ? 'jp' : 'en' }) }}>
                <Icon icon='Language' size={24} height={28} />
                <p className='ml2'>
                  {lang == "en" ? "English" : "日本語"}
                </p>
              </div>

              {/* <div className='flex middle' onClick={() => { update({ lang: "en" }); }}>
                <p style={{ color: theme.text, margin: 0, marginRight: 24 }}>English</p>
              </div>

              <Switch switched={lang != "en"}
                onChange={() => { update({ lang: lang === "en" ? "jp" : "en" }); }}
              />

              <div className='flex middle' onClick={() => { update({ lang: "jp" }); }}>
                <p style={{ color: theme.text, margin: 0, marginLeft: 24 }}>日本語</p>
              </div> */}
            </div>
          </div>
        </StyledMenuModal>
      )}

      <BalanceModal show={status.showWalletModal}
        onClose={() => { updateStatus({ showWalletModal: false }) }}
      />

      <MetamaskProvider />
    </>
  )
};

export default Header;

const StyledLinkTitle = styled.p`
	font-weight: bold;
	color: ${({ theme }) => theme.text};
	margin: 0 0 1.5rem;
	@media (max-width: 768px) {
		margin: 8px 8px 0;
	}
`
const StyledLink = styled.a`
	cursor: pointer;
	color: #9b9b9b;
	margin: 1rem 0;
	text-decoration: none;
	display: block;
	&:hover{
		color: ${({ theme }) => theme.text};
		text-decoration: underline;
	}
	@media (max-width: 500px) {
		font-size: 0.8rem;
	}
`
const StyledLinkList = styled.div`
	display: block;
	@media (max-width: 768px) {
		display: flex;
		flex-wrap: wrap;
		a {
			margin: 8px;
		}
	}
`
const StyledMenuModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	top: 0;
	left: 0;
	flex-direction: row;
	justify-content: flex-end;
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.overlay{
		position: fixed;
		background-color: transparent;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		opacity: ${({ theme }) => theme.modalOpacity};
	}
	.modal-container{
		display: block;
		height: calc(100vh - 60px);
		background-color: ${({ theme }) => theme.bgColor2};
		color: ${({ theme }) => theme.text};
		padding: 1rem 2rem 2rem;	
		position: absolute;
		max-width: 600px;
		min-width: 300px;
		margin-left: auto;
		width: 50%;
		z-index: 10002;
		max-height: 100vh;
		overflow-y: auto;
		@media (max-width: 768px) {
			margin: 0;
			width: 100%;
			max-width: 100vw;
			border-radius: 0;
			position: fixed;
			top: 54px;
			max-height: 100vh;
			padding: 1rem;
			height: calc(100vh - 54px);
		}
		.darktheme{
			display: flex;
			flex-direction: row;
			margin: 1.5rem 8px;
		}
	}
`
const StyledModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10001;
	top: 0;
	left: 0;
	flex-direction: row;
	justify-content: flex-end;
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.overlay{
		position: fixed;
		background-color: transparent;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		opacity: 0;
	}
	.modal-container{
		display: block;
		height: auto;
		background-color:  ${({ theme }) => theme.dropColor};
		color: ${({ theme }) => theme.text};
		padding: 0;
		position: absolute;
		border-radius: 12px;
		max-width: 360px;
		min-width: 300px;
		margin-left: auto;
		width: 50%;
		z-index: 10002;
		max-height: 100vh;
		overflow-y: auto;
		top: 100px;
		right: 1rem;
		@media (max-width: 768px) {
			margin: 0;
			width: 100%;
			border-radius: 0;
			top: 60px;
			right: 8px;
			max-width: 95vw;
		}
	}
`
const StyledHeader = styled.div<{ darkMode: boolean }>`
	background-color:  ${({ theme }) => (theme.bgColor2)};
	padding: 1.5rem 0;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10000;
	width: 100%;
	@media (max-width: 768px) {
		padding: 0.5rem 0;
		.login-btn {
			font-size: 0.8rem;
		}
	}
	header {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: space-between;
		padding: 0 0;
		@media (max-width: 768px) {
			padding: 4px 0;
		}
		.logo{
			cursor: pointer;
			width: 276px;
			height: auto;
			padding: 4px;
			@media (min-width: 1920px) {
				width: 300px;
			}
			@media (max-width: 1360px) {
				width: 240px;
			}
			@media (max-width: 768px) {
				margin: 0;
				width: 120px;
				padding: 0;
			}
		}
		.alert{
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			padding: 0 1rem;
			&:hover{
				color:  ${({ theme }) => (theme.textGrey)};
			}
			.mark{
				position: absolute;
				top: -5px;
				right: -10px;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background-color: #36C781;
				display: flex;
				justify-content: center;
				align-items:center;
				color: black;
				animation: mark-animation 1s infinite;
			}
			@keyframes mark-animation {
				0% {
					background-color: #36C781;
				}
				50% {
					background-color: yellow;
				}
			}
			@media (max-width: 768px) {
				padding: 0 6px;
			}
		}
		.login-btn{
			text-align: right;
			button{
				width: 200px;
				@media (max-width: 768px) {
					width: 100%;
					padding: 8px;
				}
			}
		}
		.icon-menu-bar{
			display: none;
			.icon-menu{
				cursor: pointer;
				height: 30px;
				color: ${({ theme }) => (theme.text)};
				text-align: center;
				display: flex;
				align-items: center;
				margin: 0 4px;
				align-content: center;
				&:hover{
					color: ${({ theme }) => (theme.textGrey)};
				}
				@media (max-width: 768px) {
					margin: 0 8px;
				}
			}
			@media (max-width: 768px) {
				display: flex;
			}
		}
	}
	.search-bar{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin: 0 2rem;
		max-width: 900px;
		flex:1;
		.search-input{
			width: 100%;
			border-bottom: 1px solid #707070;
			background-color: transparent;;
			border-radius: 0;
			max-width: 600px;
			position: relative;
			padding: 12px 1rem;
			@media (max-width: 1280px) {
				width: 100%;
				max-width: 100vw;
			}
			&:hover{
				color: ${({ theme }) => (theme.textGrey)};
				.search-result-panel{
					// display: block;
				}
			}
			.search-btn{
				position: absolute;
				cursor: pointer;
				left: 1rem;
				top: 1rem;	
			}
			.search-btn-right{
				position: absolute;
				right: 4px;
				top: 6px;
				width: 80px;
				padding: 12px 1rem;
			}
			input{
				border: none;
				padding: 2px 0 2px 2rem;
				width: calc(100% - 10px);
				background-color: transparent;
				user-select: none;
				color:  ${({ theme }) => (theme.text)};
				font-weight: bold;
				font-size: 16px;
				&:hover, &:focus, &:active{
					border: none;
					outline: none;
					box-shadow: none;
				}
			}
			.search-result-panel{
				padding: 1rem;
				width: 100%;
				max-height: 600px;
				position: absolute;
				overflow-y: auto;
				top: calc(100% + 20px);
				left: 0;
				background-color:  ${({ theme }) => (theme.dropColor)};
				transition: 0.2s all;
				z-index: 1001;
				display: none;
				border-radius: 12px;
				animation:  fadeInPanel 0.5s linear;
				.title{
					font-weight: bold;
					margin: 0 1rem 1rem;
					&:not(:nth-child(1)) {
						border-top: 1px solid  ${({ theme }) => (theme.strokeColor)};
						margin: 8px 1rem;
						padding-top: 8px;
					}
				}
				.item{
					display: flex;
					align-items: center;
					align-content: center;
					justify-content: space-between;
					cursor: pointer;
					border-bottom: 1px solid transparent;
					padding: 1rem;
					transition: 0.2s all;
					img {
						width: 50px;
						height: 50px;
						border-radius: 4px;
						margin-right: 1rem;
					}
					.asset-info, .balance-info{
						p{
							margin: 0;
							&:nth-child(1){
								font-weight: 600;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							&:nth-child(2){
								font-size: 13px;
								color:  ${({ darkMode }) => (darkMode ? '#d1cfcf' : '#333')}; 
							}
						}
					}
					.asset-info {
						width: 100%;
						margin-right: 50px;
					}
					.balance-info {
						width: 100px;
						text-align: right;
					}
					&:hover{
						background-color:  ${({ darkMode }) => (darkMode ? '#1C1F27' : 'rgba(240, 240, 240, 0.97)')}; 
					}
				}
			}
		}
		
		@media (max-width: 1024px) {
			display: none;
		}
		@media (max-width: 768px) {
			display: none;
		}
		&.min{
			@media (max-width: 768px) {
				display: block;
				margin: 0;
			}
		}
	}
	
	@-webkit-keyframes fadeInPanel {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
	
   @-moz-keyframes  fadeInPanel {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
   
	@keyframes fadeInPanel {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
	}
`
const StyledNFTCreateBtn = styled.div`
	margin: 0 2rem;
	text-align: center;
	padding: 8px 1.2rem;
	background-color: #ef9090;
	background: linear-gradient(144deg, 
		#ef9090 0.00%, 
		#f9f592 20.92%, 
		#a7f195 36.40%, 
		#74f9d0 51.46%, 
		#5e87f1 66.10%, 
		#e59afb 83.67%, 
		#f95959 100.00%);
	border-radius: 8px;
	color: black;
	font-weight: bold;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		transform: translateY(-2px)
	}
	@media (max-width: 768px) {
		display: none;
	}
`
const StyledWalletBtn = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	margin: 0 1rem;
	padding: 0 1rem;
	img {
		width: 30px;
		cursor: pointer;
		&:hover {
			transform: scale(1.04);
		}
		@media (max-width: 768px) {
			width: 25px;
		}
	}
	@media (max-width: 1024px) {
		margin: 4px;
		padding: 0;
	}
`
const StyledProfileBar = styled.div<{ darkMode: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	cursor: pointer;
	padding: 1px;
	border-radius: 50px;
	border: 2px solid ${({ theme }) => (theme.text)};
	position: relative;
	user-select: none;
	@media (max-width: 768px) {
		margin: 4px;
	}
	// &:hover{
	// 	.drop-panel{
	// 		display: block;
	// 	}
	// }
	.background{
		width: 100vw;
		height: 100vh;
		background-color: transparent;
		position: fixed;
		left: 0;
		top: 0;
	}
	.avatar{
		display: flex;
		img{
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin: 1px;
			object-fit: cover;
			@media (max-width: 768px) {
				width: 22px;
				height: 22px;
			}
		}
	}
	.drop-panel{
		display: none;
		position: absolute;
		right: 0;
		top: calc(100% + 20px);
		width: 190px;
		border-radius: 10px;
		background-color:  ${({ theme }) => theme.dropColor};
		z-index: 2;
		animation:  fadeIn-menu 0.2s linear;
		// &::after{
		// 	content: " ";
		// 	position: absolute;
		// 	top: -18px;
		// 	right: 10px;
		// 	margin-left: 12px;
		// 	background-color: none;
		// 	z-index: -1;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 14px solid transparent;
		// 	border-right: 14px solid transparent;
		// 	border-bottom: 20px solid ${({ theme }) => theme.dropColor};
		// }
		.menu{
			cursor: pointer;
			padding: 12px 1rem;
			display: block;
			text-decoration: none;
			text-align: left;
			font-size: 14px;
			font-weight: bold;
			color:  ${({ theme }) => (theme.text)};
			&:hover{
				color:  ${({ theme }) => (theme.textGrey)};
			}
		}
	}
	.hr {
		border-bottom: 1px solid transparent;
	}
	
	@-webkit-keyframes fadeIn-menu {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
	
   @-moz-keyframes  fadeIn-menu {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
   }
   
	@keyframes fadeIn-menu {
		0%   {
			opacity: 0;
			transform: translateY(10px);
		}
		50% {
			opacity: 1;
		}
		100% { 
			transform: translateY(0);
		}
	}
`
const StyledAlertPanel = styled.div`
	animation:  fadeIn-alert 0.2s linear;
	@-webkit-keyframes fadeIn-alert {
		0%   {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
   }
	
   @-moz-keyframes  fadeIn-alert {
		0%   {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
   }
   
	@keyframes fadeIn-alert {
		0%   {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`
const StyledAlert = styled.div`
	border-bottom: 1px solid #3A3A3A;
	padding: 1rem;
	cursor: pointer;
	transition: 0.2s;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	.close {
		position: absolute;
		right: 1rem;
		top: 1rem;
	}
	&:hover {
		background-color: #1a1c23;
		.close {
			transform: scale(1.1);
		}	
	}
	img {
		width: 36px;
		height: 36px;
		border-radius: 5px;
	}
	p, h3,h4 {
		word-break: break-all;
	}
`