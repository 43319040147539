import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { tips, validateEmail, validatePassword } from '../../util';

interface ForgetPasswordInterface {
  email: string
  confirmCode: string
  password1: string
  password2: string
  errorEmail: string
  errorConfirmcode: string
  errorpassword1: string
  errorpassword2: string
  error: string
  step: number
}

const ForgetPassword = () => {
  const navigate = useNavigate()
  const { t } = useI18n()

  const { darkMode, call, lang, logined, update } = useStore()

  const [status, setStatus] = React.useState<ForgetPasswordInterface>({
    email: '',
    confirmCode: '',
    password1: '',
    password2: '',
    errorEmail: '',
    errorConfirmcode: '',
    errorpassword1: '',
    errorpassword2: '',
    error: '',
    step: 1,
  })

  const updateStatus = (params: Partial<ForgetPasswordInterface>) => setStatus({ ...status, ...params })

  const checkEmail = async () => {
    const email = status.email
    if (email.trim() === '' || !validateEmail(email)) {
      return updateStatus({
        error: '',
        errorEmail: t("action.signup.erroremail"),
        errorConfirmcode: '',
        errorpassword1: '',
        errorpassword2: '',
      })
    }

    update({ loading: true })
    const result: any = await call('/api/forget-password/check-email', {
      email: email,
      lang: lang
    })

    if (result) {
      switch (result?.['message']) {
        case 'success':
          updateStatus({ step: 2, error: '' })
          break
        case 'Please enter all required data.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.inputerror"),
          })
          break
        case 'No exists user.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.login.noexistuser"),
          })
          break
        case 'No match old password.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.login.wrongpassword"),
          })
          break
        case 'internal error':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.servererror"),
          })
          break
      }
    }
    update({ loading: false })
  }

  const checkConfirmCode = async () => {
    const code = status.confirmCode
    if (code.length !== 6 || Number(code) < 100000 || Number(code) > 1000000) {
      return updateStatus({
        error: '',
        errorEmail: '',
        errorConfirmcode: t("action.invalidsmscode"),
        errorpassword1: '',
        errorpassword2: '',
      })
    }
    updateStatus({ errorpassword1: '', errorpassword2: '' })
    update({ loading: true })
    const result: any = await call('/api/forget-password/check-code', {
      code: code,
      email: status.email,
    })
    if (result) {
      switch (result?.['message']) {
        case 'success':
          updateStatus({ step: 3, error: '' })
          break
        case 'Please enter all required data.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.inputerror"),
          })
          break
        case 'No exists code.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.noexistcode"),
          })
          break
        case 'No match code.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.invalidsmscode"),
          })
          break
        case 'Endtime':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.codeexpired"),
          })
          break
        case 'internal error':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.servererror"),
          })
          break
      }
    }
    update({ loading: false })
  }

  const resendConfirmCode = async () => {
    if (status.step === 1) return tips('wraning', 'Not confirmed password')
    update({ loading: true })
    const result: any = await call('/api/forget-password/resend-code', {
      email: status.email,
      lang: lang
    })
    if (result && result['message'] === 'success') {
      tips('success', t("action.signup.sentcode"))
    }
    update({ loading: false })
  }

  const resetPassword = async () => {
    const password1 = status.password1
    const password2 = status.password2
    if (password1.trim() === '' || !validatePassword(password1))
      return updateStatus({
        error: '',
        errorEmail: '',
        errorConfirmcode: '',
        errorpassword1: t("action.signup.errorpassword"),
        errorpassword2: '',
      })
    if (password2.trim() === '' || !validatePassword(password2))
      return updateStatus({
        error: '',
        errorEmail: '',
        errorpassword2: t("action.signup.errorpassword"),
        errorpassword1: '',
      })
    if (password1 !== password2)
      return updateStatus({
        error: '',
        errorConfirmcode: '',
        errorpassword1: '',
        errorpassword2: t("action.signup.errorpassword"),
      })
    update({ loading: true })
    const result: any = await call('/api/forget-password/reset-password', {
      password: password1,
      email: status.email,
      code: status.confirmCode,
    })
    if (result) {
      switch (result?.['message']) {
        case 'success':
          tips('success', t("action.signup.passwordchanged"))
          setTimeout(() => {
            navigate('/login')
          }, 500)
          break
        case 'Please enter all required data.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.inputerror"),
          })
          break
        case 'No exists code.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.noexistcode"),
          })
          break
        case 'Not verified code':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.invalidsmscode"),
          })
          break
        case 'No match code.':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.invalidsmscode"),
          })
          break
        case 'Endtime':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.signup.codeexpired"),
          })
          break
        case 'internal error':
          updateStatus({
            errorEmail: '',
            errorpassword1: '',
            errorpassword2: '',
            errorConfirmcode: '',
            error: t("action.servererror"),
          })
          break
      }
    }
    update({ loading: false })
  }

  React.useEffect(() => {
    if (logined) navigate('/')
  }, [])

  return (
    <Layout>
      <div>
        <StyledContainer className="container">
          {status.step === 1 && (
            <StyledForgetPasswordPanel darkMode={darkMode}>
              <h3 className="title">
                {t("forgetpassword.title1")}
              </h3>

              <p className="text-center">
                {t("forgetpassword.content1")}
              </p>

              <p style={{ fontSize: '0.9rem', textAlign: 'center' }}>
                {t("forgetpassword.content2")}
              </p>

              <InputField type="text"
                errorLabel={status.errorEmail}
                label={t("forgetpassword.email")}
                placeholder="" value={status.email}
                onChange={(e: any) => { updateStatus({ email: e.target.value }) }}
              />

              <div className="flex center mt3 mb5">
                <ButtonPrimary onClick={checkEmail}
                  style={{ width: '80%', margin: '8px auto', minWidth: '180px', maxWidth: '300px' }}
                >
                  {t("forgetpassword.checkmail")}
                </ButtonPrimary>
              </div>

              {status.error !== '' && (
                <p style={{ textAlign: 'center', color: 'red' }}>
                  {status.error}
                </p>
              )}

              <p className="text-center">
                {t("forgetpassword.haveaccount")} <Link to="/login">{t("forgetpassword.signin")}</Link>
              </p>
            </StyledForgetPasswordPanel>
          )}

          {status.step === 2 && (
            <StyledForgetPasswordPanel darkMode={darkMode}>
              <h3 className="title">{t("forgetpassword.title2")}</h3>
              <p className="text-center">{t("forgetpassword.content3")}</p>
              <p className="small">{t("forgetpassword.content4", status.email)}</p>

              <InputField placeholder=""
                value={status.confirmCode}
                errorLabel={status.errorConfirmcode}
                label={t("forgetpassword.confirmcode")}
                onChange={(e: any) => { updateStatus({ confirmCode: e.target.value }) }}
              />

              <div className="row center mt3 mb5">
                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={resendConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '180px', maxWidth: '300px', backgroundColor: '#a7a7a7' }}
                  >
                    {t("forgetpassword.resendcode")}
                  </ButtonPrimary>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={checkConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '180px', maxWidth: '300px' }}
                  >
                    {t("forgetpassword.checkcode")}
                  </ButtonPrimary>
                </div>
              </div>

              {status.error !== '' && (
                <p style={{ textAlign: 'center', color: 'red' }}>
                  {status.error}
                </p>
              )}
            </StyledForgetPasswordPanel>
          )}

          {status.step === 3 && (
            <StyledForgetPasswordPanel darkMode={darkMode}>
              <h3 className="title">{t("forgetpassword.title3")}</h3>
              <p className="text-center">{t("forgetpassword.contentnewpassword")}</p>

              <InputField type="password"
                errorLabel={status.errorpassword1}
                label={t("forgetpassword.newpassword")}
                inputLabel={t("forgetpassword.passwordrole")}
                placeholder="Az2@By3&" value={status.password1}
                onChange={(e) => { updateStatus({ password1: e.target.value }) }}
              />

              <InputField type="password" infoLabel=""
                onChange={(e) => { updateStatus({ password2: e.target.value }) }}
                placeholder="Az2@By3&" value={status.password2}
                label={t("forgetpassword.newpassword2")}
                errorLabel={status.errorpassword2}
              />

              <div className="flex center mt3 mb5">
                <ButtonPrimary onClick={resetPassword}
                  style={{ width: '80%', margin: '8px auto', minWidth: '180px', maxWidth: '300px' }}
                >
                  {t("forgetpassword.resetpassword")}
                </ButtonPrimary>
              </div>

              {status.error !== '' && (
                <p style={{ textAlign: 'center', color: 'red' }}>
                  {status.error}
                </p>
              )}
            </StyledForgetPasswordPanel>
          )}
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default ForgetPassword

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledForgetPasswordPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title {
		color: ${({ theme, darkMode }) => (darkMode ? theme.white : theme.black)};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a {
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a {
			display: block;
		}
		.link {
			text-align: center;
		}
	}
`