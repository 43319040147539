import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { Link, useNavigate } from 'react-router-dom';

import useStore from '../../useStore';
import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import CustomCheckbox from '../../components/Checkbox';
import { ButtonPrimary } from '../../components/components';
import { tips, validateEmail, validatePassword, validatePhone, validateUsername } from '../../util';

interface SignupInterface {
  name: string
  email: string
  phone: string
  password: string
  confirmPassword: string
  errorname: string
  erroremail: string
  errorphone: string
  errorpassword: string
  errorpassword2: string
  checkterm: boolean
  error: string
}

const Signup = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { darkMode, logined, call, lang, update } = useStore()

  const [status, setStatus] = React.useState<SignupInterface>({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    error: '',
    erroremail: '',
    errorname: '',
    errorpassword: '',
    errorpassword2: '',
    errorphone: '',
    checkterm: false,
  })

  const updateStatus = (params: Partial<SignupInterface>) => setStatus({ ...status, ...params })

  const signup = async () => {
    try {
      const name = status.name;
      const email = status.email;
      const phone = status.phone.replaceAll(" ", "").replaceAll("+", "");
      const password = status.password;
      const confirmpassword = status.confirmPassword;
      updateStatus({ error: "", erroremail: "", errorname: "", errorphone: "", errorpassword: "", errorpassword2: "" })

      if (name.trim() === "" || !validateUsername(name)) return updateStatus({ errorname: t("action.signup.errorname"), error: "", errorphone: "", errorpassword: "", errorpassword2: "" });
      if (email.trim() === "" || !validateEmail(email)) return updateStatus({ erroremail: t("action.signup.erroremail"), error: "", errorphone: "", errorpassword: "", errorpassword2: "", errorname: "" });
      // if(phone.trim() === "" || !validatePhone(phone)) return updateStatus({erroremail: "", error: "", errorphone: "正しい電話番号をご入力ください", errorpassword: "", errorpassword2: "", errorname: ""}); 
      if (password.trim() === "" || !validatePassword(password)) return updateStatus({ erroremail: "", error: "", errorphone: "", errorpassword: t("action.signup.errorpassword"), errorpassword2: "", errorname: "" });
      if (confirmpassword.trim() === "" || !validatePassword(confirmpassword)) return updateStatus({ erroremail: "", error: "", errorphone: "", errorpassword: "", errorpassword2: t("action.signup.errorpassword"), errorname: "" });
      if (password !== confirmpassword) return updateStatus({ erroremail: "", error: "", errorphone: "", errorpassword: "", errorpassword2: t("action.signup.errorpassword"), errorname: "" });
      update({ loading: true })
      const result = await call("/api/signup/register", {
        name: name,
        email: email,
        password: password,
        phone: phone,
        lang: lang
      })
      if (result) {
        switch (result['message']) {
          case "success": navigate("/check-code/" + email); break;
          case "could not send message to mail": updateStatus({ error: t("action.signup.smserror") }); break;
          case "Please enter all required data.": updateStatus({ error: t("action.signup.inputerror") }); break;
          case "Already exists same name or email or phone.": updateStatus({ error: t("action.signup.existsameuser") }); break;
          case "internal error": updateStatus({ error: t("action.servererror") }); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      update({ loading: false })
      return tips("error", t("action.unknownerror"))
    }
  }

  React.useEffect(() => {
    if (logined) navigate("/profile")
  }, [])

  return (
    <Layout>
      <div>
        <StyledContainer className="container">
          <StyledSignupPanel darkMode={darkMode}>
            <h3 className='title'>
              {t("signup.title")}
            </h3>

            <InputField label={t("signup.name")} value={status.name}
              onChange={(e) => { updateStatus({ name: e.target.value }) }}
              placeholder="Yamada-L_tarou" errorLabel={status.errorname}
            />

            <InputField required label={t("signup.mail")} value={status.email}
              onChange={(e) => { updateStatus({ email: e.target.value }) }}
              errorLabel={status.erroremail} placeholder="AAA@gmail.com"
            />

            {/* <InputField label="電話番号"
							errorLabel={status.errorphone}
							placeholder="8504934344" value={status.phone}
							onChange={(e) => { updateStatus({ phone: e.target.value }) }}
						/> */}

            <InputField required label={t("signup.password")}
              type="password" errorLabel={status.errorpassword}
              placeholder="Az2@By3&" infoLabel={t("signup.passwordrole")}
              onChange={(e) => { updateStatus({ password: e.target.value }) }}
              value={status.password}
            />

            <InputField required label={t("signup.confirmpassword")}
              onChange={(e) => { updateStatus({ confirmPassword: e.target.value }) }}
              type="password" errorLabel={status.errorpassword2}
              placeholder="" value={status.confirmPassword}
            />

            <div className="flex center middle">
              <CustomCheckbox checked={status.checkterm}
                changeEvent={(checked) => { updateStatus({ checkterm: checked }) }}
              />

              {lang == "en" && (
                <p style={{ fontSize: '0.8rem' }} className="link flex " >
                  {" " + t("signup.accept1")} &nbsp;
                  <Link to="/privacy">{t("signup.accept2")}</Link>
                </p>
              )}

              {lang == "jp" && (
                <p style={{ fontSize: '0.8rem' }} className="link flex " >
                  <Link to="/privacy">{t("signup.accept1")}</Link>
                  {" " + t("signup.accept2")}
                </p>
              )}
            </div>

            <div className="flex center mt3">
              <ButtonPrimary style={{ width: '250px' }} onClick={signup} disabled={!status.checkterm}>
                {t("signup.signupbtn")}
              </ButtonPrimary>
            </div>

            {status.error !== "" && (
              <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
            )}

            <p className='text-center flex center'>
              If you have account:&nbsp;
              <Link to="/login">Sign In</Link>
            </p>

            <p className='text-center flex center'>
              {t("signup.resendcode1")}&nbsp;
              <Link to="/signup/resend-code">{t("signup.resendcode2")}</Link>
            </p>
          </StyledSignupPanel>
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default Signup;

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;

	@media (max-width: 1024px) {
		padding: 1rem 0;
	}

	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledSignupPanel = styled.div<{ darkMode: boolean }>`
  width: 90%;
  padding: 2rem 5rem;
  max-width: 600px;
  margin: 3rem auto;

	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};

	.title{
		margin: 0;
		font-size: 2rem;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}

	a{
		text-align: center;
		color: #6b9beb;
	}

	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;

		a{
			display: block;
		}

		.link{
			text-align: center;
		}
	}
`