import styled from 'styled-components'

export const ButtonPrimary = styled.button(({ theme, disabled }) => (`
	padding: 12px 0;
	border-radius: 50px;
	user-select: none;
	font-size: 1rem;
	border: none;
	outline: none;
	font-weight: 700;

	background-color: ${disabled ? theme.disabled : theme.buttonPrimary};
	color: ${disabled ? theme.textGrey : theme.text};
	cursor: ${disabled ? "no-drop" : "pointer"};
	opacity: ${disabled ? "0.5" : "1"};

	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;

	:hover:not(:disabled) {
		filter: brightness(0.8);
	}
	
	@media (max-width: 360px) {
		overflow: hidden;
		font-size: 0.9rem;
	}
`))
export const ButtonSecondary = styled.button`
	padding: 12px 0;
	border-radius: 50px;
	cursor: pointer;
	user-select: none;
	font-size: 1rem;
	border: 1px solid	${({ theme }) => theme.link};
	outline: none;
	font-weight: 700;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	color: ${({ theme }) => theme.text};
	:hover {
		border: 1px solid ${({ theme }) => theme.text};
	}
`
export const ButtonBorder = styled.button`
	color:	${({ theme }) => theme.textGrey};
	cursor: pointer;
	border-radius: 50px;
	border: 1px solid	${({ theme }) => theme.textGrey};
	padding: 8px 1rem;
	user-select: none;
	background-color: transparent;
	&:hover{
		border-color: ${({ theme }) => theme.textGrey};
		color:	${({ theme }) => theme.textGrey};
		background-color: rgba(0, 0, 0, 0.1);
	}
`