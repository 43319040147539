import React from 'react';
import { ethers } from 'ethers';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import { Page404 } from './404Page';
import useStore from '../../useStore';
import Icon from '../../components/Icon';
import NFTItem from '../../components/NftItem';
import Layout from '../../components/Layout/Layout';
import { copyToClipboard, ellipsis } from '../../util';
import { ButtonPrimary } from '../../components/components';
import CollectionItem from '../../components/CollectionItem';
import { GET_COLLECTION_INFO, GET_FAVORITE_NFT, GET_NFTS, GET_PRICES, GET_USER_INFO } from '../../graphql/index';

import banner from '../../assets/img/banner.webp'
import avatar from '../../assets/img/avatar.png'

interface ProfileInterface {
  query: string
  username: string
  currentName: string
  currentMail: string
  currentAddress: string
  bio: string
  twitter: string
  instagram: string
  link: string
  banner: string
  avatar: string
  phone: string
  verified: boolean
  switchType: Number
  collections: Collection[]
  notfound: boolean
  nfts: NFTSearchResult[]
  favouriteNfts: NFTSearchResult[]
  prices: Prices[]
}

const Profile = () => {
  const { t } = useI18n()
  const { lang } = useStore()
  const location = useLocation();
  const theme = useContext(ThemeContext);

  const [status, setStatus] = React.useState<ProfileInterface>({
    query: '',
    username: '',
    currentName: '',
    currentMail: '',
    currentAddress: '',
    bio: '',
    twitter: '',
    instagram: '',
    link: '',
    banner: '',
    verified: false,
    avatar: '',
    phone: '',
    switchType: 1,
    nfts: [],
    favouriteNfts: [],
    collections: [],
    notfound: false,
    prices: []
  })

  const updateStatus = (params: Partial<ProfileInterface>) => setStatus({ ...status, ...params });

  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (priceLoading || priceError) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    updateStatus({ prices: data })
  }, [priceInfo, priceLoading, priceError])


  const { data: userInfo, loading: infoLoading, error: infoError } = useQuery(GET_USER_INFO, {
    variables: {
      username: ethers.utils.isAddress(status.query) ? '' : status.query,
      address: ethers.utils.isAddress(status.query) ? status.query : ''
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (infoLoading || infoError) {
      return;
    }
    if (userInfo.getUsersInfo && userInfo.getUsersInfo.length > 0) {
      const data = userInfo.getUsersInfo[0];
      updateStatus({
        currentName: data.name,
        currentAddress: data.address,
        currentMail: data.email,
        bio: data.bio,
        banner: data.banner_img,
        avatar: data.avatar_img,
        phone: data.phone,
        verified: data.verified?.status === "verified",
        twitter: data.twitter,
        instagram: data.instagram,
        link: data.link,
      })
    }
    else {
      if (status.username) {
        updateStatus({ notfound: true })
      } else {
        const address = status.query
        updateStatus({ currentAddress: address })
      }
    }
  }, [userInfo, infoLoading, infoError]);

  //nfts
  const { data: nftInfo, loading: nftLoading, error: nftError } = useQuery(GET_NFTS, {
    variables: {
      query: '',
      nftcollection: '',
      sort: "latest",
      price1: 0,
      price2: 0,
      searchsymbol: '',
      salestatus: '',
      page: 0,
      limit: 0,
      owner: status.currentAddress,
      symbols: ''
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (nftLoading || nftError) return;
    const data = nftInfo?.getNFTs as NFTSearchResult[];
    if (!data) return;
    updateStatus({ nfts: data })
  }, [nftInfo, nftLoading, nftError])


  //collection
  const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      name: '',
      category: '',
      owner: status.currentAddress
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (collectionLoading || collectionError) return;
    const result = collectionInfo?.getCollectionInfo;
    if (!result) return;
    let _collections = [] as Collection[];
    result.forEach(row => {
      if (!row?.hide) {
        _collections.push(row)
      }
    });
    updateStatus({ collections: _collections })
  }, [collectionInfo, collectionLoading, collectionError])

  //favoriteNFT
  const { data: favoriteNftInfo, loading: favoriteNftLoading, error: favoriteNftError } = useQuery(GET_FAVORITE_NFT, {
    variables: {
      owner: status.currentAddress
    },
    fetchPolicy: 'network-only'
  });
  React.useEffect(() => {
    if (favoriteNftLoading || favoriteNftError) return;
    const result = favoriteNftInfo?.getFavoriteNFT as NFTSearchResult[];
    if (!result) return;
    updateStatus({ favouriteNfts: result })
  }, [favoriteNftInfo, favoriteNftLoading, favoriteNftError])

  const searchNft = (type: number = 1) => {
    updateStatus({ switchType: type });
  }

  React.useEffect(() => {
    const query = location.pathname.replaceAll("/", "");
    updateStatus({ query: query || '' });
    if (!ethers.utils.isAddress(query)) {
      updateStatus({ username: query });
    }
  }, [location]);

  return (
    <Layout>
      {!status.notfound && (
        <>
          <StyledCoverImage>
            <div className="banner">
              <img src={status.banner || banner} alt="banner" style={{ objectFit: 'cover' }} />
            </div>
          </StyledCoverImage>

          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <StyledUserPanel>
                  <div className="avatar" >
                    <img alt='avatar'
                      src={status.avatar || avatar}
                      style={{ borderRadius: '50%', objectFit: 'cover' }}
                    />

                    {status.verified && (
                      <div className="icon">
                        <Icon icon="Photo" size={40} />
                      </div>
                    )}
                  </div>

                  <h2 className='title'>
                    {status.currentName}
                  </h2>

                  <div className="flex middle center">
                    <p className='address'>{ellipsis(status.currentAddress, 16, false)}</p>
                    <div className='cursor' onClick={() => { copyToClipboard(status.currentAddress) }}>
                      <Icon icon="Copy" size={20} marginLeft={12} />
                    </div>
                  </div>

                  <p className='des'>{status.bio}</p>
                  <div className="flex center">
                    {/* <a className='pointer' style={{color: theme.textGrey}} href={status.twitter} target="_blank"><Icon icon="Twitter" ></Icon></a>
											<a className='pointer' style={{color: theme.textGrey}} href={status.twitter} target="_blank"><Icon icon="Instagram"></Icon></a> */}
                    {/* <a className='pointer' style={{color: theme.textGrey}} href={status.link} target="_blank"><Icon icon="Shape"></Icon></a> */}
                    {(status.link && status.link !== "http://") && (
                      <a className='pointer' style={{ color: theme.textGrey, width: '32px' }} href={status.link} target="_blank">
                        <Icon icon="Shape"></Icon>
                      </a>
                    )}
                  </div>
                </StyledUserPanel>
              </div>

              <div className="col-lg-8 col-md-6 col-sm-12 p2">
                <div className="flex mt5 wrap">
                  <ButtonPrimary onClick={() => { searchNft(1) }}
                    style={{ padding: '8px 1.2rem', backgroundColor: status.switchType === 1 ? theme.buttonPrimary : 'transparent' }}
                  >NFT</ButtonPrimary>

                  <ButtonPrimary onClick={() => { searchNft(3) }}
                    style={{ padding: '8px 1.2rem', background: status.switchType === 3 ? theme.buttonPrimary : 'transparent' }}
                  >{t("profile.favorite")}</ButtonPrimary>

                  <ButtonPrimary onClick={() => { searchNft(4) }}
                    style={{ padding: '8px 1.2rem', background: status.switchType === 4 ? theme.buttonPrimary : 'transparent' }}
                  >Collection</ButtonPrimary>
                </div>

                <div className='row p2'>
                  {(status.switchType === 1 && status.nfts.length > 0) && (
                    status.nfts.map((nft, index) => {
                      if (!nft?.hide) {
                        return <div className='col-lg-3 col-md-4 col-sm-6 col-6 p1' key={"mynft-" + index}>
                          <NFTItem nft={nft}
                            id={nft._id}
                            nftCollection={nft.nftCollection}
                            tokenId={nft.tokenid}
                            owner={nft.owner}
                            img={nft.coverImage}
                            name={nft.name}
                            balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                            symbol={nft.acceptedToken}
                            balanceUsd={
                              (lang === 'en' ? '$' : '￥') +
                              (nft.price > 0 && nft.price < 2147483647 ? ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)) : 0)
                            }
                          />
                        </div>
                      }

                      return null;
                    })
                  )}

                  {(status.switchType === 3 && status.favouriteNfts.length > 0) && (
                    status.favouriteNfts.map((nft, index) => {
                      if (!nft?.hide) {
                        return <div className='col-lg-3 col-md-4 col-sm-6 col-6 p1' key={"myfavnft-" + index}>
                          <NFTItem nft={nft}
                            id={nft._id}
                            nftCollection={nft.nftCollection}
                            tokenId={nft.tokenid}
                            owner={nft.owner}
                            img={nft.coverImage}
                            name={nft.name}
                            balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                            symbol={nft.acceptedToken}
                            balanceUsd={
                              (lang === 'en' ? '$' : '￥') +
                              (
                                nft.price > 0 && nft.price < 2147483647 ?
                                  ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2))
                                  :
                                  0
                              )
                            }
                          />
                        </div>
                      }

                      return null;
                    })
                  )}
                </div>

                <div className='row p2 pt0 '>
                  {(status.switchType === 4 && status.collections.length > 0) && (
                    status.collections.map((collection, index) => {
                      if (!collection?.hide) {
                        return <div className='col-lg-4 col-md-4 col-sm-6 col-6 p1 pt0' key={"mycol-" + index}>
                          <CollectionItem collection={collection} />
                        </div>
                      }

                      return null;
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {status.notfound && (
        <Page404 />
      )}
    </Layout>
  )
}

export default Profile;

const StyledCoverImage = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	cursor: pointer;
	.banner{
		margin: 0;
		padding: 0;
		height: 300px;
		@media (max-width: 768px) {
			height: 150px;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
		img{
			width: 100%;
			max-height: 300px;
			height: 300px;
			@media (max-width: 768px) {
				height: 150px;
			}
		}
		.overlay{
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	.edit-btn{
		position: absolute;
		right: 20%;
		top: 40%;
		@media (max-width: 768px) {
			top: 10%;
			left: 20%;
		}
	}
`

const StyledUserPanel = styled.div`
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem;
	border-radius: 2rem;
	text-align: center;
	max-width: 400px;
	z-index: 999;
	margin: 1rem 2rem;
	transform: translateY(-250px);
	@media (max-width: 768px) {
		transform: translateY(0);
		margin: 0 1rem;
		margin-top: -80px;
	}
	.avatar{
		border-radius: 50%;
		width:100px;
		height:100px;
		position: relative;
		margin: 0 auto;
		cursor: pointer;
		img{
			width: 100%;
			height: 100%;
		}
		.overlay{
			display: none;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			z-index: 1000;
			position: absolute;
			left:0;
			top:0;
		}
		.icon{
			border-radius: 50%;
			position: absolute;
			right: -8px;
			top: -8px;
			z-index: 1001;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
	}
	a{
		width: 50px;
		height: 50px;
		margin: 1rem;
		&:hover{
			color: ${({ theme }) => theme.textGrey};
		}
	}
	.title{
		font-size: 2rem;
		@media (max-width: 768px) {
			font-size: 1.2rem;
		}
	}
	.address{
		font-size: 0.8rem;
	}
	.des{
		font-size: 0.8rem;
		opacity: 0.7	;
	}
`

const StyledNFTRow = styled.div`
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 20px;
	justify-content: flex-start;
	@media (max-width: 1480px) {
		grid-template-columns: auto auto auto;
		grid-gap: 20px;
	}
	@media (max-width: 1280px) {
		grid-template-columns: auto auto;
		grid-gap: 8px;
		padding: 8px;
	}
	@media (max-width: 1024px) {
		grid-template-columns: auto auto;
		grid-gap: 20px;
		padding: 8px;
	}
	@media (max-width: 987px) {
		grid-template-columns: auto;
		grid-gap: 10px;
		padding: 8px;
	}
	@media (max-width: 768px) {
		grid-template-columns: auto auto;
		grid-gap: 6px;
		padding: 4px;
	}
`

const StyledCollectionRow = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 20px;
	@media (max-width: 1480px) {
		grid-template-columns: auto auto auto;
		grid-gap: 20px;
		padding: 1rem;
	}
	@media (max-width: 1280px) {
		grid-template-columns: auto auto;
		grid-gap: 8px;
		padding: 8px;
	}
	@media (max-width: 1024px) {
		grid-template-columns: auto;
		grid-gap: 20px;
		padding: 8px;
	}
	@media (max-width: 967px) {
		grid-template-columns: auto;
		grid-gap: 10px;
		padding: 8px;
	}
	@media (max-width: 768px) {
		grid-template-columns: auto auto;
		padding: 8px;
	}
	@media (max-width: 480px) {
		grid-template-columns: auto;
		padding: 8px;
	}
`