import React from 'react';
import { useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import useStore from '../../useStore';
import Layout from '../../components/Layout/Layout';
import MetamaskModal from '../../components/MetamaskLogin';
import { ButtonPrimary } from '../../components/components';

import metamask from '../../assets/img/metamask.webp';
import walletconnect from '../../assets/img/walletconnect.webp';

interface LoginInterface {
  showMetamaskModal: boolean
}

const Login = () => {
  const { t } = useI18n();
  const wallet = useWallet();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { darkMode, logined, update } = useStore();

  const [status, setStatus] = React.useState<LoginInterface>({
    showMetamaskModal: false,
  })

  const updateStatus = (params: Partial<LoginInterface>) =>
    setStatus({ ...status, ...params })

  React.useEffect(() => {
    if (logined) navigate('/profile')
  }, [])

  const signWithMetamask = async () => {
    // wallet?.reset()
    if (wallet.status === "connected") {
      wallet.connect().then(() => {
        updateStatus({ showMetamaskModal: true })
      })
    }
    else {
      updateStatus({ showMetamaskModal: true })
    }
  }

  const walletConnect = async () => {
    try {
      update({ loading: true })
      await wallet?.reset()
      localStorage.setItem("walletconnect", "")
      wallet?.connect('walletconnect').then((res) => {
        console.log(wallet.status)
        updateStatus({ showMetamaskModal: true })
      })
      update({ loading: false })
    } catch (err) {
      update({ loading: false })
    }
  }

  // React.useEffect(() => {
  //   console.log('showMetamaskModal', wallet.status, wallet?.ethereum, window.ethereum)
  //   if (wallet?.status === 'connected') {
  //     updateStatus({ showMetamaskModal: true })
  //   }
  //   console.log(wallet.status)
  // }, [wallet.status])

  return (
    <Layout>
      <div>
        <StyledContainer className="container">
          <StyledLoginPanel darkMode={darkMode}>
            <h3 className="title">{t('login.title')}</h3>

            <div className="flex center mt3">
              {window.innerWidth > 768 && (
                <ButtonPrimary onClick={signWithMetamask}
                  style={{ color: theme.text, width: '300px', backgroundColor: '#36C781', borderRadius: '12px' }}
                >
                  <img src={metamask} style={{ width: '34px', marginRight: '8px' }} />
                  {t('login.connectmetamask')}
                </ButtonPrimary>
              )}
            </div>

            <div className="flex center mt2">
              <ButtonPrimary onClick={() => { walletConnect() }}
                style={{ color: theme.text, width: '300px', backgroundColor: '#36C781', borderRadius: '12px' }}
              >
                <img src={walletconnect}
                  style={{ width: '34px', marginRight: '8px', borderRadius: '50%' }}
                />
                Walletconnect
              </ButtonPrimary>
            </div>

            <div className="flex center mt3">
              <ButtonPrimary onClick={() => { navigate('/login-email') }}
                style={{ color: theme.text, width: '280px' }}
              >
                {t('login.loginwithmail')}
              </ButtonPrimary>
            </div>

            <div className="flex center mt2 mb5">
              <ButtonPrimary onClick={() => { navigate('/signup') }}
                style={{ color: theme.text, width: '280px', backgroundColor: theme.boxColor }}
              >
                {t('login.signup')}
              </ButtonPrimary>
            </div>
          </StyledLoginPanel>
        </StyledContainer>
      </div>

      <MetamaskModal show={status.showMetamaskModal}
        onClose={() => { updateStatus({ showMetamaskModal: false }) }}
      />
    </Layout>
  )
}

export default Login;

const StyledContainer = styled.div`
  padding: 3rem 0 8rem;
  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`

const StyledLoginPanel = styled.div<{ darkMode: boolean }>`
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.modalBg};
  padding: 2rem 5rem;
	width: 90%;
  max-width: 600px;
  color: ${({ theme }) => theme.text};
  margin: 3rem auto;
  .title {
    color: ${({ theme, darkMode }) => (darkMode ? theme.white : theme.black)};
    text-align: center;
    font-size: 2rem;
    margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
  }
  a {
    text-align: center;
    color: #6b9beb;
  }
  @media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
    a {
      display: block;
    }
    .link {
      text-align: center;
    }
  }
`