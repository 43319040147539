import React from 'react';
import { useContext } from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { Link, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from '@mui/material/Accordion';

import useStore from '../../useStore';
import Icon from '../../components/Icon';
import Search from '../../components/Search';
import Layout from '../../components/Layout/Layout';
import { ButtonPrimary } from '../../components/components';
import CollectionItem from '../../components/CollectionItem';
import CollectionItemLoading from '../../components/CollectionItemLoading';
import { GET_CATEGORY, GET_COLLECTION_INFO, GET_PAYLABLE_TOKEN } from '../../graphql/index';

interface CollectionInterface {
  keyword: string
  notfound: boolean
  network: string
  category: boolean[]
  collections: Collection[]
  loading: boolean
  categorylist: string[]
  paylableTokens: PaylableTokens[]
}

const SearchCollection = () => {
  // @ts-ignore
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const { t } = useI18n()

  const { currentAccountAddress, darkMode, lang } = useStore()
  const [showModal, setShowModal] = React.useState(false)

  const [status, setStatus] = React.useState<CollectionInterface>({
    keyword: ' ',
    notfound: false,
    category: [],
    network: 'MATIC',
    paylableTokens: [],
    collections: [],
    loading: true,
    categorylist: []
  })

  const updateStatus = (params: Partial<CollectionInterface>) => setStatus({ ...status, ...params })

  //tokens
  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })
  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  //category
  const { data: categoryInfo, loading: categoryLoading, error: categoryError } = useQuery(GET_CATEGORY, {
    variables: { lang: lang },
    fetchPolicy: 'network-only'
  });
  React.useEffect(() => {
    if (categoryLoading || categoryError) return;
    const data = categoryInfo?.getCategory as Category[];
    if (!data) return updateStatus({ notfound: true });
    const v = [];
    data.forEach((element: Category) => {
      v.push((lang == "en" ? element?.en : element?.jp) || '')
    });
    updateStatus({
      categorylist: v
    })
  }, [categoryInfo, categoryLoading, categoryError])

  //collection
  const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      name: status.keyword,
      category: (() => {
        let cts = [] as any;
        status.category.forEach((element, index) => {
          if (element) cts.push(index + 1);
        });
        return cts.toString();
      })()
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  });
  React.useEffect(() => {
    if (collectionLoading || collectionError) return;
    const result = collectionInfo?.getCollectionInfo;
    let _collections = [] as Collection[];
    result.forEach(row => {
      if (!row?.hide) {
        _collections.push(row)
      }
    });
    if (!result) return updateStatus({ notfound: _collections.length == 0, loading: false });
    updateStatus({ collections: _collections, loading: false })
  }, [collectionInfo, collectionLoading, collectionError])

  React.useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    updateStatus({ keyword: query || '' })
  }, [location]);

  const search = async (query: string) => {
    updateStatus({ keyword: query || '' })
  }

  const resetSearch = () => {
    updateStatus({ category: [] })
  }

  return <>
    <Layout>
      <div className="container p1">
        <StyledTitle>
          {t("searchcollection.title")}
        </StyledTitle>

        <div className="row center middle mt3 mb3">
          <div className="col-lg-2 col-6 flex center">
            <StyledActiveButton>
              <Link to="/search-nft">NFT</Link>
            </StyledActiveButton>
          </div>

          <div className="col-lg-3 col-6 flex center">
            <ButtonPrimary style={{ backgroundColor: darkMode ? '#515151' : '#A7A7A7', width: '200px' }} >
              <Link to="/search-collection" style={{ color: theme.text, textDecoration: 'none' }}>
                COLLECTION
              </Link>
            </ButtonPrimary>
          </div>
        </div>

        <div className="flex wrap mt3 mb3 justify middle">
          <StyledFilterButton onClick={() => { setShowModal(true) }}>
            <svg style={{ marginRight: '4px' }} width="7.149" height="12.86" viewBox="189.89 1093 9.149 17.86">
              <path d="M198.665 1110.486a1.276 1.276 0 0 0 0-1.804l-5.85-5.85a1.303 1.303 0 0 1 0-1.804l5.85-5.85a1.275 1.275 0 1 0-1.804-1.805l-5.85 5.85a3.827 3.827 0 0 0 0 5.412l5.85 5.851a1.276 1.276 0 0 0 1.804 0Z" fill={theme.text} fillRule="evenodd" data-name="down" />
            </svg>

            {t("nftsearch.filter")}
          </StyledFilterButton>

          <div></div>

          <Search hideDropdown
            value={status.keyword}
            values={status.paylableTokens}
            onSearch={() => { search(status.keyword) }}
            onValueChange={(value) => { updateStatus({ keyword: value }) }}
            placeholder={lang == "jp" ? " コレクション名で検索" : "Search by collection name"}
            onSelectChange={(key) => { updateStatus({ network: key }) }}
            resetValue={() => { updateStatus({ keyword: "" }) }}
            onEnter={() => { search(status.keyword) }}
          />

          <div></div>
        </div>

        <StyledPanel showModal={showModal}>
          {showModal && (
            <div className="modal-overlay"
              onClick={() => { setShowModal(false) }}
            />
          )}

          <div className="filter-panel" style={{ backgroundColor: theme.bgColor2 }}>
            {!showModal && (
              <div className="hr" style={{ marginTop: '1rem', borderColor: theme.strokeColor }} />
            )}

            <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} defaultExpanded className='search-accordion'>
              <AccordionSummary aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                style={{ justifyContent: 'flex-end' }}
              >
                <p style={{ color: theme.strokeColor, width: '90%', fontSize: '1rem', fontWeight: 'bold', margin: 0 }}>
                  {t("searchcollection.category")}
                </p>
              </AccordionSummary>

              <AccordionDetails className='flex '>
                <div style={{ minWidth: '90%' }}>
                  <div className="flex wrap">
                    {status.categorylist.map((item, key) => (
                      <ButtonPrimary key={key}
                        style={{
                          paddingLeft: '1.5rem',
                          paddingRight: '1.5rem',
                          margin: '4px 4px .5rem',
                          backgroundColor: status.category[key] ? theme.buttonPrimary : '#A7A7A7'
                        }}
                        onClick={() => {
                          let cts = status.category; cts[key] = !status.category[key];
                          updateStatus({ category: cts })
                        }}
                      >
                        {item}
                      </ButtonPrimary>
                    ))}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="hr mt0" style={{ margin: '1rem 0 2rem', borderColor: theme.strokeColor }} />

            <div className="flex justify middle">
              <p>
                {status.collections?.length || 0} {lang == "jp" ? "の結果" : " results"}
              </p>
              <ButtonPrimary onClick={() => { resetSearch() }}
                style={{ backgroundColor: "transparent", border: '2px solid #353945', borderRadius: '24px', width: '50%', marginBottom: '1rem' }}
              >
                {t("nftsearch.reset")}
              </ButtonPrimary>
            </div>

            {showModal && (
              <div className="flex center mb1">
                <ButtonPrimary onClick={() => { setShowModal(false) }}
                  style={{
                    backgroundColor: theme.dropColor,
                    border: '1px solid #777777',
                    borderRadius: '24px',
                    width: '50%',
                    marginTop: '1rem',
                    marginBottom: '1rem'
                  }}
                >
                  {t("nftsearch.closebtn")}
                </ButtonPrimary>
              </div>
            )}
          </div>

          <div className="collection-panel">
            <StyledCollectionPanel>
              {status.loading && (
                <div className='row'>
                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>

                  <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl'>
                    <CollectionItemLoading />
                  </div>
                </div>
              )}

              {status.collections.length > 0 && (
                <div className="row">
                  {status.collections.map((collection, index) => (
                    ((collection.owner === currentAccountAddress) || !collection?.hide) &&
                    <div className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p0 pr pl ' key={"collection-" + index}>
                      <CollectionItem collection={collection} />
                    </div>
                  ))}
                </div>
              )}

              {(status.collections.length === 0 && !status.loading) && (
                <h1 className='text-center' style={{ color: theme.grey, margin: '100px 0' }}>
                  {t("notfound")}
                </h1>
              )}
            </StyledCollectionPanel>
          </div>
        </StyledPanel>
      </div>
    </Layout>
  </>
}

export default SearchCollection;

const StyledFilterButton = styled.div`
	padding: 0.8rem;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => (theme.strokeColor)};
	display: none;
	cursor: pointer;
	margin: 4px;

	@media (max-width: 1024px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

const StyledTitle = styled.div`
	font-size: 2.4rem;
	text-align: center;
	font-weight: bold;
	@media (max-width: 768px) {
		font-size: 1.4rem;
	}
`

const StyledPanel = styled.div<{ showModal: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 50px 0 150px;
	@media (max-width: 768px) {
		margin: 1rem 0 2rem;
	}
	.filter-panel{
		flex: 3;
		padding: 0 1rem;
	}
	.collection-panel{
		flex: 9;
		padding: 0 8px;
		@media (max-width: 768px) {
			padding: 0;
		}
	}

	.modal-overlay{
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 10001;
		width: 100vw;
		height: 100vh;
	}

	@media (max-width: 1024px) {
		.filter-panel{
			position: fixed;
			z-index: 10002;
			padding: 1rem;
			left: 0;
			bottom: 0;
			height: calc(100vh - 60px);
			overflow-y: auto;
			width: 100%;
			display: ${({ showModal }) => showModal ? 'block' : 'none'}
		}
	}
	
`

const StyledActiveButton = styled.div`
	background: -webkit-linear-gradient(45deg, #f9f592, #a7f195, #74f9d0, #5e87f1, #e59afb, #f95959);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1.4rem;
	cursor: pointer;
	text-align: center;
`

const StyledCollectionPanel = styled.div`
	padding: 0 4rem;
	@media (max-width: 1360px) { 
		padding: 0 1rem;
	}
	@media (max-width: 768px) {
		padding: 0 8px;
	}
`