import React from 'react';
import { useQuery } from '@apollo/client';
import { useI18n } from 'react-simple-i18n';
import { ThemeContext } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';

import Modal from './Modal';
import { tips } from '../util';
import Dropdown from './Dropdown';
import useStore from '../useStore';
import { InputField } from './InputField';
import CheckoutForm from './CheckoutForm';
import { ButtonPrimary } from './components';
import { GET_PAYLABLE_TOKEN } from '../graphql';
import config from '../config/config.json';

interface ModalInterface {
  amount: number
  errorAmount: string
  email: string
  errorEmail: string

  token: string
  fiatAmount: number
  paylableTokens: PaylableTokens[]
}

const initState: ModalInterface = {
  amount: 0,
  errorAmount: '',
  email: '',
  errorEmail: '',

  token: "MATIC",
  fiatAmount: 0,
  paylableTokens: [],
}

const BuyTokenModal = ({ show, onClose }: { show: boolean, onClose: Function }) => {

  return (
    <StripeWrapper show={show} onClose={onClose} />
    // <ZakZakWrapper show={show} onClose={onClose} />
  )
}

const ZakZakWrapper = ({ show, onClose }: { show: boolean, onClose: Function }) => {
  const theme = useContext(ThemeContext);
  const { balances, token, lang, call, update } = useStore()
  const { t } = useI18n()

  const [status, setStatus] = React.useState<ModalInterface>(initState)
  const updateStatus = (params: Partial<ModalInterface>) => setStatus({ ...status, ...params })

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) {
      return
    }

    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!!data) updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const validEmail = (tempMail: string = '') => {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(tempMail).search(filter) != -1;
  }

  const onNext = async () => {
    try {
      let maxAmount = 0.01;
      let checkMail = validEmail(status.email)

      if (!checkMail) {
        return updateStatus({
          errorAmount: "",
          errorEmail: "Please Enter email address again",
        })
      } else {
        if (status.token == "MATIC") maxAmount = config.Max_ETH_Buy_Amount;
        if (status.token == "USDT") maxAmount = config.Max_USDT_Buy_Amount;

        if (Number(status.amount) < 0.001 || Number(status.amount) > maxAmount) {
          return updateStatus({
            errorEmail: "",
            errorAmount: "Min: 0.001,  Max: " + maxAmount,
          })
        }
      }

      update({ loading: true })
      updateStatus({ errorAmount: "", errorEmail: "" })

      const result: any = await call("/api/zakzak/creat-deposit", {
        email: status.email,
        buyAmount: status.amount,
        acceptedToken: status.token,
      }, { authorization: token })

      console.log(result)

      onClose()
      update({ loading: false })
      window.open(`/zakzak/${result.reqId}`, "_blank")
    } catch (err) {
      update({ loading: false })
      tips("error", "Please check login status and try again.")
    }
  }

  return (
    <Modal onClose={() => { onClose() }} show={show}>
      <div className='ml-auto mr-auto mt3'>
        <div>
          <h1 style={{ textAlign: 'center' }}>
            {t("buymodal.title")}
          </h1>

          <InputField required type="text"
            label={t("buymodal.zakzakMail")}
            errorLabel={status.errorEmail} value={status.email}
            placeholder='Please enter your zakzak account email'
            onChange={({ target }: any) => { updateStatus({ email: target.value }) }}
          />

          <InputField required type="number"
            label={t("buymodal.amount")}
            errorLabel={status.errorAmount}
            name='amount' min={0.0001} value={status.amount}
            onChange={({ target }: any) => { updateStatus({ amount: target.value }) }}
            child={
              <div style={{ width: '80px', marginRight: '12px' }}>
                <Dropdown hideImg
                  selectedKey={status.token}
                  values={status.paylableTokens}
                  onChange={(key: any) => { updateStatus({ token: key }) }}
                  props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                />
              </div>
            }
          />

          <div className="flex center">
            <p className='text-center'>
              {`${t("buymodal.balance")} ${Number(balances?.find((token: any) => token.symbol.toUpperCase() === status.token?.toUpperCase())?.balance || 0).toFixed(4)} ${status.token}`}
            </p>
          </div>

          <div className='zakzakfooter-wrapper'>
            <div className="flex center">
              <ButtonPrimary style={{ width: '250px', backgroundColor: theme.boxColor }} onClick={() => { onClose() }}>
                {t("buymodal.cancel")}
              </ButtonPrimary>
            </div>

            <div className="flex center">
              <ButtonPrimary onClick={onNext} style={{ color: theme.text, width: '250px' }}>
                {t("buymodal.buy")}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const StripeWrapper = ({ show, onClose }: { show: boolean, onClose: Function }) => {
  // @ts-ignore
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (show) {
      loadStripe(config.STRIPEPUBKEY).then((result: any) => {
        setStripe(result)
      })
    }
  }, [show])

  return (
    <Modal onClose={() => { onClose() }} show={show}>
      <Elements stripe={stripe}>
        <CheckoutForm show={show} onClose={onClose} />
      </Elements>
    </Modal>
  )
}

export default BuyTokenModal