import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { tips, validateEmail } from '../../util';


interface ChangeMailInterface {
  newEmail: string
  confirmCode: string
  errorNewEmail: string
  errorConfirmcode: string
  error: string
  step: number
}

const ChangeMail = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { darkMode, call, token, currentAccountMail, logined, update, lang } = useStore()

  const [status, setStatus] = React.useState<ChangeMailInterface>({
    newEmail: '',
    confirmCode: '',
    errorNewEmail: '',
    errorConfirmcode: '',
    error: '',
    step: 1,
  })

  const updateStatus = (params: Partial<ChangeMailInterface>) => setStatus({ ...status, ...params })

  const checkNewEmail = async () => {
    const newmail = status.newEmail;
    if (newmail.trim() === "" || !validateEmail(newmail)) return updateStatus({ error: "", errorNewEmail: t("changemail.erroremail"), errorConfirmcode: "" });
    update({ loading: true })
    const result = await call("/api/change-mail/check-mail", {
      email: currentAccountMail,
      newEmail: newmail,
      lang: lang
    }, { authorization: token })

    if (result) {
      switch (result['message']) {
        case "success": updateStatus({ step: 2, error: "" }); break;
        case "Please enter all required data.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.errorinput") }); break;
        case "could not send message to new email": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.failedsendmsg") }); break;
        case "No exists user.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.noexistuser") }); logout(); break;
        case "internal error": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("action.servererror") }); break;
      }
    }
    update({ loading: false })
  }

  const resendConfirmCode = async () => {
    if (status.step === 1) return tips("wraning", t("changemail.notconfirmedpwd"));
    update({ loading: true })
    const result = await call("/api/change-mail/resend-code", {
      newEmail: status.newEmail,
      lang: lang
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": tips("success", t("changemail.sentcode")); break;
        case "Please enter all required data.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.errorinput") }); break;
        case "could not send message to new email": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.failedsendmsg") }); break;
        case "No exists user.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.noexistuser") }); logout(); break;
        case "internal error": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("action.servererror") }); break;
      }
    }
    update({ loading: false })
  }

  const logout = () => {
    update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", balances: [], currentAccountAddress: "", token: "", logined: false, isMetamask: false, lasttime: 0 });
    navigate("/");
  }

  const checkConfirmCode = async () => {
    const code = status.confirmCode;
    if (code.length !== 6 || Number(code) < 100000 || Number(code) > 1000000) return updateStatus({ error: "", errorConfirmcode: t("changemail.invalidcode"), errorNewEmail: "" });
    updateStatus({ errorConfirmcode: "", errorNewEmail: "" })
    update({ loading: true })
    const newEmail = status.newEmail;
    const result = await call("/api/change-mail/check-code", {
      code: code,
      newEmail: newEmail
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": {
          update({ currentAccountMail: result['newEmail'], token: result['token'] })
          tips("success", t("changemail.changedmail"));
          setTimeout(() => { navigate("/profile") }, 500);
          break;
        }
        case "Please enter all required data.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.errorinput") }); break;
        case "No exists code.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.noexistcode") }); logout(); break;
        case "No match code.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.nomatchcode") }); break;
        case "Endtime": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.codeexpired") }); break;
        case "internal error": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("action.servererror") }); break;
        case "No exists code.": updateStatus({ errorNewEmail: "", errorConfirmcode: "", error: t("changemail.noexistcode") }); break;
      }
    }
    update({ loading: false })
  }

  React.useEffect(() => {
    if (!logined) navigate("/")
  }, [logined])

  return (
    <Layout>
      <div>
        <StyledContainer className="container">
          {status.step === 1 && (
            <StyledChangeMailPanel darkMode={darkMode}>
              <h3 className='title'>{t("changemail.title")}</h3>
              <p className='text-center' style={{ fontSize: '0.9rem' }}>{t("changemail.content")}</p>

              <InputField type="text"
                label={t("changemail.newemail")}
                errorLabel={status.errorNewEmail}
                placeholder="" value={status.newEmail}
                onChange={(e) => { updateStatus({ newEmail: e.target.value }) }}
              />

              <div className="row center mt3 mb5">
                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={() => { navigate("/profile") }}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px', backgroundColor: '#a7a7a7' }}
                  >
                    {t("changemail.cancel")}
                  </ButtonPrimary>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={checkNewEmail}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px' }}
                  >
                    {t("changemail.reset")}
                  </ButtonPrimary>
                </div>
              </div>

              {status.error !== "" && (
                <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
              )}
            </StyledChangeMailPanel>
          )}

          {status.step === 2 && (
            <StyledChangeMailPanel darkMode={darkMode}>
              <h3 className='title'>{t("changemail.title")}</h3>
              <p className='text-center'>{t("changemail.confirmtitle")}</p>

              <p style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                {t("changemail.confirmtitle2", status.newEmail)}
              </p>

              <p style={{ fontSize: '0.8rem', textAlign: 'center' }}>
                {t("changemail.confirmcontent")}
              </p>

              <InputField label={t("changemail.confirmcode")}
                onChange={(e) => { updateStatus({ confirmCode: e.target.value }) }}
                placeholder="" value={status.confirmCode}
                errorLabel={status.errorConfirmcode}
              />

              <div className="row center mt3 mb5">
                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={resendConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px', backgroundColor: '#a7a7a7' }}
                  >
                    {t("changemail.resend")}
                  </ButtonPrimary>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={checkConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px' }}
                  >
                    {t("changemail.confirm")}
                  </ButtonPrimary>
                </div>
              </div>

              {status.error !== "" && (
                <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
              )}
            </StyledChangeMailPanel>
          )}
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default ChangeMail;

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledChangeMailPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ darkMode, theme }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`