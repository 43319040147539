import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';

// import { Loading } from "./Loading";
import useStore from "../useStore";
import Layout from "./Layout/Layout";
import { ButtonPrimary } from "./components";
import { Page404 } from "../pages/Other/404Page";
import { LoadingImg } from "./Loading";


export const CryptoRampCheckPage = () => {
    const { t } = useI18n();
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [count, setCount] = useState(0);
    const { reqId }: any = useParams();
    const { darkMode, call, update, token } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if (!notFound && !failure && !success) {
                setCount(() => count + 1)
            }
        }, 15000);
        (async () => {
            try {
                const result: any = await call(
                    "/api/cryptoramp/confirmpayment", { order_id: reqId }, { authorization: token }, true
                );
                if (result.state === "notfound") {
                    setLoading(false);
                    setNotFound(true)
                }
                if (result.state === "failure") {
                    setLoading(false);
                    setFailure(true)
                }
                if (result.state === "success") {
                    setLoading(false)
                    setSuccess(true)
                };
            } catch (err: any) {
                console.log(err.message)
                setLoading(false)
                setNotFound(true)
            }
        }
        )()
    }, [count]);

    const goBack = () => {
        navigate('/');
    }

    return (
        <Layout>
            {!notFound && (
                <div>
                    <StyledContainer className="container">
                        <StyledSignupPanel darkMode={darkMode}>
                            {loading && (
                                <>
                                    <h3 className='title'>
                                        {t("rampPaymentCheck.processing")}
                                    </h3>
                                    {/* <div style={{ display: 'flex', zIndex: 10002, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%', padding: "2rem 0" }}>
                                        <LoadingImg />
                                    </div> */}
                                    <p className='text-center flex center'>
                                        {t("rampPaymentCheck.header")}
                                    </p>
                                    <div className="flex center mt3">
                                        <ButtonPrimary style={{ width: '350px' }} onClick={goBack}>
                                            {t("rampPaymentCheck.returnhome")}
                                        </ButtonPrimary>
                                    </div>
                                </>
                            )}
                            {success && (
                                <>
                                    <h3 className='title'>
                                        {t("rampPaymentCheck.completed")}
                                    </h3>
                                    <div className="flex center mt3">
                                        <ButtonPrimary style={{ width: '350px' }} onClick={goBack}>
                                            {t("rampPaymentCheck.returnhome")}
                                        </ButtonPrimary>
                                    </div>
                                </>
                            )}
                            {failure && (
                                <>
                                    <h3 className='title'>
                                        {t("rampPaymentCheck.failed")}
                                    </h3>
                                    <div className="flex center mt3">
                                        <ButtonPrimary style={{ width: '350px' }} onClick={goBack}>
                                            {t("rampPaymentCheck.returnhome")}
                                        </ButtonPrimary>
                                    </div>
                                </>
                            )}
                        </StyledSignupPanel>
                    </StyledContainer>
                </div>
            )}
            {notFound && (
                <Page404 />
            )}
        </Layout >

    )
}

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;

	@media (max-width: 1024px) {
		padding: 1rem 0;
	}

	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledSignupPanel = styled.div<{ darkMode: boolean }>`
  width: 90%;
  padding: 2rem 5rem;
  max-width: 600px;
  margin: 3rem auto;

	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};

	.title{
		margin: 0;
		font-size: 2rem;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}

	a{
		text-align: center;
		color: #6b9beb;
	}

	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;

		a{
			display: block;
		}

		.link{
			text-align: center;
		}
	}
`
