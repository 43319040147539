import React from "react"
import styled from "styled-components"

interface LoadingProps {
	opacity: number
	show: Boolean
}

const Loading = ({ opacity = 0.5, show = false }: LoadingProps) => {
	return (
		<>
			{show && (
				<div style={{ position: 'fixed', zIndex: 10002, top: '0', left: '0', width: '100vw', height: '100vh', backgroundColor: `rgba(7, 26, 34, ${opacity})` }}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
						<LoadingImg />
					</div>
				</div>
			)}
		</>
	)
}

const LoadingImg = () => {
	return (
		<StyledLoading style={{ color: "#000000" }}>
			<div className="loading-container">
				<div className="loader">
					<div className="rainbow">
						<div className="📦"></div>
						<div className="📦"></div>
						<div className="📦"></div>
						<div className="📦"></div>
						<div className="📦"></div>
					</div>
					<div className="n">
						N
					</div>
				</div>
			</div>
		</StyledLoading>
	)
}

export { Loading, LoadingImg }

const StyledLoading = styled.div`
	.loading-container {
		position: relative;
		.loader {
			width: 60px;
			height: 60px;
			border-radius: 12px;
			overflow: hidden;
			z-index: 1000;
			position: relative;
		}
		.n {
			font-size: 28px;
			font-weight: bolder;
			z-index: 1001;
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
		}
		.rainbow{
			position: absolute;
			-webkit-animation: loading-animation 0.8s linear infinite; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: loading-animation 0.8s linear infinite; /* Firefox < 16 */
			-ms-animation: loading-animation 0.8s linear infinite; /* Internet Explorer */
			-o-animation: loading-animation 0.8s linear infinite; /* Opera < 12.1 */
				animation: loading-animation 0.8s linear infinite;
			@keyframes loading-animation {
					from { 
						transform: translateY(-80%);
					}
					to   { 
						transform: translateY(0);
					}
				}
			}
			.📦 {
				width: 60px;
				height: 60px;
				display: block;
			}
			.📦:nth-child(1) {
				background-color: #ffffa7;
			}
			.📦:nth-child(2) {
				background-color: #f0ffaa;
			}
			.📦:nth-child(3) {
				background-color: #79ffc0;
			}
			.📦:nth-child(4) {
				background-color: #30ffff;
			}
			.📦:nth-child(5) {
				background-color: #f9a5fd;
			}
		}
`