import React from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import styled, { ThemeContext } from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import useStore from '../../useStore';
import config from '../../config/config.json';
import { ellipsis, validateUrl } from '../../util';

import Icon from '../../components/Icon';
import Search from '../../components/Search';
import NFTItem from '../../components/NftItem';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/Layout/Layout';
import CustomCheckbox from '../../components/Checkbox';
import NFTCardLoading from '../../components/NftItemLoading';
import { ButtonBorder, ButtonPrimary } from '../../components/components';
import { GET_CATEGORY, GET_COLLECTION_INFO, GET_NFTS, GET_PAYLABLE_TOKEN, GET_PRICES, GET_USER_INFO } from '../../graphql/index';

import { Page404 } from '../Other/404Page';
import banner from '../../assets/img/banner.webp';
import img from '../../assets/img/collection.webp';
import { CircularProgress } from '@mui/material';

interface CollectionInterface {
  collectionid: string
  showModal: boolean
  showImageModal: boolean
  bannerImg: string
  img: string
  name: string
  creator: string
  creatorName: string
  description: string
  category: number[],
  fee: number,
  items: number
  owners: number
  bestoffer: number
  floor: number
  volume: number
  volumeJpy: number
  address: string
  website: string
  twitter: string
  instagram: string
  discord: string
  notfound: boolean
  keyword: string
  network: string
  price1: string
  price2: string
  symbol: string
  saleStatus: string[]
  symbolType: string[]
  sort: string
  nfts: NFTSearchResult[]
  datanotfound: boolean
  categorylist: string[]
  prices: Prices[]
  paylableTokens: PaylableTokens[]
  verified: "rejected" | "pending" | "verified" | "",
  reason: string
  loading: boolean

  pageNum: number
  nftsLen: number
  baseNftLen: number
  loadingSpinner: boolean
}

const initStatus: CollectionInterface = {
  collectionid: '',
  paylableTokens: [],
  showModal: false,
  showImageModal: false,
  bannerImg: '',
  img: '',
  name: '',
  category: [],
  creator: '',
  creatorName: '',
  description: '',
  fee: 0,
  items: 0,
  owners: 0,
  bestoffer: 0,
  floor: 0,
  volume: 0,
  volumeJpy: 0,
  address: '',
  website: "",
  twitter: "",
  instagram: "",
  discord: "",
  notfound: false,
  network: "",
  categorylist: [],
  keyword: "",
  price1: '',
  price2: '',
  symbol: 'MATIC',
  saleStatus: [],
  symbolType: [],
  sort: 'latest',
  nfts: [],
  datanotfound: false,
  prices: [],
  verified: "",
  reason: "",
  loading: true,

  pageNum: 1,
  nftsLen: 0,
  baseNftLen: 30,
  loadingSpinner: false,
}

let tempStatus: CollectionInterface = initStatus;

const NftExplorer = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const { update, currentAccountAddress, lang } = useStore();

  useEffect(() => {
    tempStatus = initStatus;
  }, [])

  const [status, setStatus] = React.useState<CollectionInterface>(initStatus)
  const updateStatus = (params: Partial<CollectionInterface>) => {
    tempStatus = { ...tempStatus, ...params }
    setStatus({ ...tempStatus, ...params })
  }

  const resetSearch = () => {
    updateStatus({
      price1: '',
      price2: '',
      symbol: 'MATIC',
      saleStatus: [],
      symbolType: [],
      sort: 'latest'
    })
  }

  useEffect(() => {
    updateStatus({ sort: lang == "jp" ? "最新順" : 'latest' })

    const onScroll = () => {
      const scrollHeight = window?.scrollY + window?.innerHeight
      const offsetHeight = window?.document?.body?.offsetHeight

      if (offsetHeight && scrollHeight) {
        if (tempStatus.nftsLen >= tempStatus.baseNftLen * tempStatus.pageNum) {
          let scrollRatio = offsetHeight - scrollHeight

          if (scrollRatio < 450) {
            updateStatus({ pageNum: tempStatus.pageNum + 1, loadingSpinner: true })
          }
        }
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => { window.removeEventListener('scroll', onScroll) }
  }, [])

  const { data: categoryInfo, loading: categoryLoading, error: categoryError } = useQuery(GET_CATEGORY, {
    variables: { lang: lang },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (categoryLoading || categoryError) return;
    update({ loading: false })
    const data = categoryInfo?.getCategory as Category[];
    if (!data) return updateStatus({ notfound: true });
    const v = [];
    data.forEach((element: Category) => {
      v.push((lang == "en" ? element.en : element.jp) || '')
    });
    updateStatus({
      categorylist: v,
      notfound: false
    })
  }, [categoryInfo, categoryLoading, categoryError])

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    variables: { nft: status.nfts.length },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (priceLoading || priceError) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    updateStatus({ prices: data })
  }, [priceInfo, priceLoading, priceError])


  const { data: collectionInfo, loading: infoLoading, error: infoError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      address: status.collectionid,
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (infoLoading || infoError) return;
    update({ loading: false })
    const data = collectionInfo?.getCollectionInfo?.[0] as Collection;
    if (!data || (data?.hide && currentAccountAddress !== data?.owner)) return updateStatus({ notfound: true });
    updateStatus({
      notfound: false,
      bannerImg: data?.metadata?.coverImage,
      img: data?.metadata?.image,
      name: data?.metadata?.name,
      address: data?.address,
      description: data?.metadata?.description,
      fee: data?.fee,
      creator: data?.owner,
      creatorName: data?.owner,
      category: data?.category,
      website: data?.metadata?.links.find((link) =>
        link.name === "website"
      )?.link,
      twitter: data?.metadata?.links.find((link) =>
        link.name === "twitter"
      )?.link,
      discord: data?.metadata?.links.find((link) =>
        link.name === "discord"
      )?.link,
      instagram: data?.metadata?.links.find((link) =>
        link.name === "instagram"
      )?.link,
      verified: data?.verified?.status,
      reason: data?.verified?.reason,
      items: data?.items,
      volume: data?.volume,
      volumeJpy: data?.volumeJpy,
      owners: Number(data?.owner || 0),
      floor: data?.floor,
      bestoffer: data?.bestoffer
    })
  }, [collectionInfo, infoLoading, infoError])

  const { data: creatorInfo, loading: creatorLoading, error: creatorError } = useQuery(GET_USER_INFO, {
    variables: { address: status.creator },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (creatorLoading || creatorError) return;
    update({ loading: false })
    const creator = creatorInfo?.getUsersInfo?.[0];
    if (!creator) return;
    updateStatus({
      creatorName: creator?.name || ellipsis(status.creator, 6, false)
    })
  }, [creatorInfo, creatorLoading, creatorError])


  const { data: nftInfo, loading: nftLoading, error: nftError } = useQuery(GET_NFTS, {
    variables: {
      query: status.keyword,
      nftcollection: status.collectionid,
      sort: status.sort,
      price1: Number(status.price1),
      price2: Number(status.price2),
      owner: '',
      searchsymbol: status.symbol,
      salestatus: status.saleStatus.toString(),
      limit: status.baseNftLen * status.pageNum,
      symbols: status.symbolType.toString(),
      page: 0,
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (nftLoading || nftError) return;

    const data = nftInfo?.getNFTs as NFTSearchResult[];
    if (!data) updateStatus({ notfound: true, loading: false, loadingSpinner: false });
    else updateStatus({ nfts: data, loading: false, nftsLen: data.length, loadingSpinner: false })
  }, [nftInfo, nftLoading, nftError])

  const search = async (query: string) => { }

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    const collectionid = location.pathname.split("/")[2].toString()
    updateStatus({ keyword: query || '', collectionid: collectionid })
    search(query || "")
    return;
  }, [location])

  return (
    <Layout>
      {!status.notfound && (
        <>
          <StyledCoverImage>
            <div className="banner">
              <img src={status.bannerImg || banner} alt="banner" style={{ objectFit: 'cover' }} />
            </div>
          </StyledCoverImage>

          <div className="container">
            <StyledAvatarImage onClick={() => { updateStatus({ showImageModal: true }) }}>
              <img alt="image" src={status.img || img}
                style={{ cursor: 'pointer', borderRadius: "8px" }}
              />

              {status.verified === "verified" && (
                <span style={{ position: 'absolute', bottom: '-25px', right: '-25px' }}>
                  <Icon icon="Photo" height={56} size={54} />
                </span>
              )}
            </StyledAvatarImage>

            <div className="row " style={{ marginTop: '-80px' }}>
              <div className="col-lg-4 col-md-6 col-sm-12 p2">
                {status.name === null && (
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <p><Skeleton count={1} height={50} /></p>
                  </SkeletonTheme>
                )}

                {status.name && <h2>{status.name}</h2>}

                <div className="flex wrap">
                  {status.category && status.category.map((element, index) => {
                    if (status.categorylist[element]?.trim()) {
                      return (
                        <ButtonPrimary key={"category-" + index}
                          style={{ backgroundColor: "#36C781", paddingLeft: '1rem', minWidth: '120px', paddingRight: '1rem', margin: '4px 8px 0 0' }}
                        >
                          {status.categorylist[element]}
                        </ButtonPrimary>
                      )
                    }

                    return null
                  })}
                </div>

                {status.creatorName === null && (
                  <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                    <p><Skeleton count={1} height={20} /></p>
                  </SkeletonTheme>
                )}

                {status.creatorName && (
                  <p>
                    <span style={{ color: theme.grey }}>{t("nftexplorer.createdby")} </span>
                    <Link style={{ color: theme.text, fontSize: '1.1rem', textDecoration: 'none' }} to={"../" + status.creator}>
                      {ellipsis(status.creatorName)}
                    </Link>
                  </p>
                )}

                <a href={status.website} target="_blank" style={{ textDecoration: 'none', fontSize: '1.2rem', color: theme.text }}>
                  {ellipsis(status.website, 25)}
                </a>

                <div className="flex wrap mt3 mb1">
                  {validateUrl(status?.twitter) && (
                    <a href={status?.twitter} target="_blank" style={{ textDecoration: 'none', color: theme.text, width: '70px' }}>
                      <Icon icon="Twitter" />
                    </a>
                  )}

                  {validateUrl(status?.instagram) && (
                    <a href={status?.instagram} target="_blank" style={{ textDecoration: 'none', color: theme.text, width: '70px' }}>
                      <Icon icon="Instagram" />
                    </a>
                  )}

                  {validateUrl(status?.discord) && (
                    <a href={status?.discord} target="_blank" style={{ textDecoration: 'none', color: theme.text, width: '70px' }}>
                      <Icon icon="Discord" />
                    </a>
                  )}
                </div>
              </div>

              <div className="col-lg-8 col-md-6 col-sm-12 p2">
                <div className="flex wrap middle mt3" style={{ maxWidth: '600px' }} >
                  <h3 className='m0 p0 mr-auto'>
                    <span style={{ color: '#515562', fontSize: '14px', marginRight: '4px' }}>
                      {t("nftexplorer.items")}
                    </span>

                    {Number(status.items || 0).toFixed(0)}
                    {lang == "jp" && "個"}
                  </h3>

                  <h3 className='m0 p0 mr-auto'>
                    <span style={{ color: '#515562', fontSize: '14px', marginRight: '4px' }}>
                      {t("nftexplorer.volume")}
                    </span>

                    {lang != "jp" && "$"}
                    {Number(Number(lang == "jp" ? Number(status.volumeJpy || 0) : Number(status.volume || 0)).toFixed(4))}
                    {lang == "jp" && "円"}
                  </h3>

                  <h3 className='m0 p0 mr-auto'>
                    <span style={{ color: '#515562', fontSize: '14px', marginRight: '4px' }}>
                      {t("nftexplorer.fee")}
                    </span>

                    {Number(Number(status.fee || 0).toFixed(2))}%
                  </h3>
                </div>

                <p style={{ maxWidth: '800px' }}>
                  {status.description}
                </p>

                {(status.creator === currentAccountAddress || config.admin_wallet.toUpperCase() === currentAccountAddress.toUpperCase()) && (
                  <ButtonPrimary onClick={() => { navigate("/edit-collection/" + status.collectionid) }} style={{ width: '250px' }}>
                    {t("editcollection.edit")}
                  </ButtonPrimary>
                )}
              </div>
            </div>

            <div className="row middle mt5">
              <div className="col-md-4 col-sm-4 p1">
                <div className="justify">
                  <StyledReloadButton>
                    <ButtonBorder onClick={() => { updateStatus({ showModal: !status.showModal }) }}
                      style={{ padding: '0.8rem', margin: '4px', borderRadius: '8px', display: 'flex', alignItems: 'center', borderColor: theme.strokeColor }}
                    >
                      <svg style={{ marginRight: '4px' }} width="7.149" height="12.86" viewBox="189.89 1093 9.149 17.86">
                        <path d="M198.665 1110.486a1.276 1.276 0 0 0 0-1.804l-5.85-5.85a1.303 1.303 0 0 1 0-1.804l5.85-5.85a1.275 1.275 0 1 0-1.804-1.805l-5.85 5.85a3.827 3.827 0 0 0 0 5.412l5.85 5.851a1.276 1.276 0 0 0 1.804 0Z" fill={theme.text} fillRule="evenodd" data-name="down" />
                      </svg>

                      {t("nftsearch.filter")}
                    </ButtonBorder>
                  </StyledReloadButton>

                  <div></div>
                </div>
              </div>

              <div className="col-md-8 col-sm-8">
                <div className="row middle">
                  <div className="col-lg-9 col-md-9 col-sm-12 p2">
                    <Search value={status.keyword}
                      placeholder={lang == "jp" ? 'アイテムを検索' : 'Keyword'}
                      onValueChange={(value) => { updateStatus({ keyword: value }) }}
                      onSearch={() => { search(status.keyword) }}
                      resetValue={() => { updateStatus({ keyword: "" }) }}
                      onEnter={() => { navigate(location.pathname + "?query=" + status.keyword); search(status.keyword) }}
                      props={{ style: { width: '100%', margin: 0 } }}
                      hideDropdown
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-12 mt2">
                    <div>
                      <Dropdown selectedKey={t("nftexplorer.latest")}
                        panelProps={{ style: { borderRadius: '12px', overflow: 'hidden' } }}
                        onChange={(key) => { updateStatus({ sort: key }); console.log(key) }}
                        itemProps={{ style: { backgroundColor: theme.dropColor, color: theme.text } }}
                        values={[
                          { key: "latest", name: t("nftexplorer.latest") },
                          { key: "oldest", name: t("nftexplorer.oldest") },
                          { key: "recent", name: t("nftexplorer.recent") },
                          { key: "sales", name: t("nftexplorer.sales") },
                          { key: "descend", name: t("nftexplorer.descend") },
                          { key: "lowest", name: t("nftexplorer.lowest") },
                        ]}
                        props={{
                          style: {
                            margin: '0 8px',
                            borderRadius: '12px',
                            backgroundColor: 'transparent',
                            border: '1px solid ' + theme.strokeColor
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <StyledPanel showModal={status.showModal}>
              {status.showModal && (
                <div className="modal-overlay" onClick={() => { updateStatus({ showModal: false }) }} />
              )}

              <div className="filter-panel" style={{ backgroundColor: theme.bgColor2 }}>
                <div className="hr" style={{ marginTop: '1rem', borderColor: theme.strokeColor }} />

                <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
                  <AccordionSummary aria-label="Expand"
                    style={{ justifyContent: 'flex-end' }}
                    aria-controls="additional-actions1-content"
                    expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                  >
                    <p style={{ margin: 0, width: '90%', fontSize: '1rem', fontWeight: 'bold', color: theme.strokeColor, }}>
                      {t("nftexplorer.price")}
                    </p>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="flex middle justify">
                      <div style={{ flex: 3 }}>
                        <StyledInput type="number"
                          placeholder={t("minimum")} value={status.price1}
                          onChange={(e) => { updateStatus({ price1: e.target.value }) }}
                          style={{ width: '100%', fontSize: '16px', borderColor: theme.strokeColor }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <p style={{ color: theme.strokeColor, textAlign: 'center' }}>~</p>
                      </div>

                      <div style={{ flex: 3 }}>
                        <StyledInput type="number"
                          placeholder={t("maximum")} value={status.price2}
                          style={{ width: '100%', fontSize: '16px', borderColor: theme.strokeColor }}
                          onChange={(e) => { updateStatus({ price2: e.target.value }) }}
                        />
                      </div>

                      <div style={{ flex: 3, padding: '0 0 0 8px' }}>
                        <Dropdown hideImg={true}
                          selectedKey={status.symbol}
                          values={status.paylableTokens}
                          onChange={(key) => { updateStatus({ symbol: key.toUpperCase() }) }}
                          props={{ style: { height: '100%', padding: '12px', border: '1px solid' + theme.boxColor } }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div className="hr mt0" style={{ marginTop: '1rem', borderColor: theme.strokeColor }} />

                <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
                  <AccordionSummary aria-label="Expand"
                    style={{ justifyContent: 'flex-end' }}
                    aria-controls="additional-actions1-content"
                    expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                  >
                    <p style={{ margin: 0, width: '90%', fontSize: '1rem', fontWeight: 'bold', color: theme.strokeColor, }}>
                      {t("nftexplorer.status")}
                    </p>
                  </AccordionSummary>

                  <AccordionDetails className='flex'>
                    <div style={{ minWidth: '100%' }}>
                      <div className="flex ">
                        <CustomCheckbox checked={status.saleStatus.indexOf("auction") > -1}
                          changeEvent={(checked) => {
                            let list = status.saleStatus;
                            if (checked) list.push("auction")
                            else list.splice(list.indexOf("auction"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        />

                        <p style={{ cursor: 'pointer', color: theme.text, width: '120px', }}
                          onClick={() => {
                            const checked = status.saleStatus.indexOf("auction") === -1;
                            let list = status.saleStatus;
                            if (checked) list.push("auction")
                            else list.splice(list.indexOf("auction"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        >
                          {t("nftexplorer.auction")}
                        </p>
                      </div>

                      <div className="flex ">
                        <CustomCheckbox checked={status.saleStatus.indexOf("regular") > -1}
                          changeEvent={(checked) => {
                            let list = status.saleStatus;
                            if (checked) list.push("regular")
                            else list.splice(list.indexOf("regular"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        />

                        <p style={{ cursor: 'pointer', color: theme.text, width: '120px' }}
                          onClick={() => {
                            const checked = status.saleStatus.indexOf("regular") === -1;
                            let list = status.saleStatus;
                            if (checked) list.push("regular")
                            else list.splice(list.indexOf("regular"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        >
                          {t("nftexplorer.regular")}
                        </p>
                      </div>

                      <div className="flex">
                        <CustomCheckbox checked={status.saleStatus.indexOf("nosale") > -1}
                          changeEvent={(checked) => {
                            let list = status.saleStatus;
                            if (checked) list.push("nosale")
                            else list.splice(list.indexOf("nosale"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        />

                        <p style={{ cursor: 'pointer', color: theme.text, width: '120px' }}
                          onClick={() => {
                            const checked = status.saleStatus.indexOf("nosale") === -1;
                            let list = status.saleStatus;
                            if (checked) list.push("nosale")
                            else list.splice(list.indexOf("nosale"), 1)
                            updateStatus({ saleStatus: list })
                          }}
                        >
                          {t("nftexplorer.nosale")}
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div className="hr mt0" style={{ marginTop: '1rem', borderColor: theme.strokeColor }} />

                <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
                  <AccordionSummary aria-label="Expand"
                    style={{ justifyContent: 'flex-end' }}
                    aria-controls="additional-actions1-content"
                    expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                  >
                    <p style={{ margin: 0, width: '90%', fontSize: '1rem', fontWeight: 'bold', color: theme.strokeColor, }}>
                      {t("nftexplorer.tokens")}
                    </p>
                  </AccordionSummary>

                  <AccordionDetails className='flex'>
                    <div style={{ minWidth: '100%' }}>
                      {status.paylableTokens.map((token, index) => (
                        <div className="flex" key={"checkbox " + index}>
                          <CustomCheckbox checked={status.symbolType.indexOf(token.symbol) > -1}
                            changeEvent={(checked) => {
                              let list = status.symbolType;
                              if (checked) list.push(token.symbol)
                              else list.splice(list.indexOf(token.symbol), 1)
                              updateStatus({ symbolType: list })
                            }}
                          />

                          <p style={{ cursor: 'pointer', color: theme.text, width: '70px' }}
                            onClick={() => {
                              const checked = status.symbolType.indexOf(token.symbol) === -1;
                              let list = status.symbolType;
                              if (checked) list.push(token.symbol)
                              else list.splice(list.indexOf(token.symbol), 1)
                              updateStatus({ symbolType: list })
                            }}
                          >
                            {token.symbol}
                          </p>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div className="hr" style={{ margin: '0 0 2rem', borderColor: theme.strokeColor }} />

                <div className="flex justify middle">
                  <p>{status.nfts?.length || 0} {lang == "jp" ? "の結果" : " results"}</p>
                  <ButtonPrimary onClick={() => { resetSearch() }}
                    style={{ backgroundColor: "transparent", border: '2px solid #353945', borderRadius: '24px', width: '50%', marginBottom: '1rem' }}
                  >
                    {t("nftsearch.reset")}
                  </ButtonPrimary>
                </div>

                {status.showModal && (
                  <div className="flex center mt1 mb100">
                    <ButtonPrimary onClick={() => { updateStatus({ showModal: false }) }}
                      style={{
                        width: '50%',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        backgroundColor: theme.dropColor,
                        border: '1px solid #777777',
                        borderRadius: '24px',
                      }}
                    >
                      {t("nftsearch.closebtn")}
                    </ButtonPrimary>
                  </div>
                )}
              </div>

              <div className="nft-panel">
                <StyledNftPanel>
                  {status.loading && (
                    <div className="row">
                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>

                      <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p1">
                        <NFTCardLoading />
                      </div>
                    </div>
                  )}

                  {(status.nfts && status.nfts?.length === 0 && !status.loading) && (
                    <h2 style={{ color: theme.grey, textAlign: 'center', margin: '200px auto' }}>
                      {t("notfound")}
                    </h2>
                  )}

                  <div className="row">
                    {(status.nfts && status.nfts?.length > 0) && (
                      status.nfts?.map((nft, index) => (((nft.owner === currentAccountAddress) || !nft?.hide) && (
                        <div className=" col-lg-3 col-md-4 col-sm-6 col-6 p0 pl pr" key={"nft-" + index}>
                          <NFTItem nft={nft} id={nft._id}
                            nftCollection={nft.nftCollection}
                            tokenId={nft.tokenid}
                            owner={nft.owner}
                            img={nft.coverImage}
                            name={nft.name}
                            balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                            symbol={nft.acceptedToken}
                            balanceUsd={
                              (lang === 'en' ? '$' : '￥') +
                              (nft.price > 0 && nft.price < 2147483647 ? ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)) : 0)
                            }
                          />
                        </div>
                      )))
                    )}
                  </div>

                  {status.loadingSpinner && (
                    <LoadingSniper>
                      <CircularProgress color='inherit' />
                    </LoadingSniper>
                  )}
                </StyledNftPanel>
              </div>
            </StyledPanel>
          </div>
        </>
      )}

      {status.notfound && (
        <Page404 />
      )}

      {status.showImageModal && (
        <StyledModal>
          <div className="overlay"
            onClick={() => { updateStatus({ showImageModal: false }) }}
          />

          <div className="modal-container">
            <div style={{ padding: '1rem' }}>
              <img alt="image" src={status.img || img}
                style={{ border: `3px solid ${theme.grey}`, width: '100%', height: '100%', borderRadius: '8px' }}
              />
            </div>
          </div>
        </StyledModal>
      )}
    </Layout>
  )
}

export default NftExplorer;

const StyledNFTRow = styled.div`
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 20px;
	justify-content: flex-start;

	@media (max-width: 1360px) {
		grid-template-columns: auto auto auto;
		grid-gap: 20px;
	}

	@media (max-width: 1280px) {
		grid-template-columns: auto auto auto auto;
		grid-gap: 8px;
	}

	@media (max-width: 1024px) {
		grid-template-columns: auto auto auto;
		grid-gap: 20px;
	}

	@media (max-width: 967px) {
		grid-template-columns: auto auto;
		grid-gap: 10px;
	}

	@media (max-width: 768px) {
		grid-template-columns: auto auto;
		grid-gap: px;
	}

	@media (max-width: 320px) {
		grid-template-columns: auto;
	}
`

const StyledModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10001;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
  -ms-animation: fadein 0.2s; /* Internet Explorer */
  -o-animation: fadein 0.2s; /* Opera < 12.1 */
  animation: fadein 0.2s;

	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	.overlay{
		position: fixed;
		background-color: ${({ theme }) => theme.modalOverlay};
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		opacity: ${({ theme }) => theme.modalOpacity};
	}

	.modal-container{
		background-color: ${({ theme }) => theme.modalBg};
		color: ${({ theme }) => theme.text};
		padding: 0;
		position: absolute;
		max-width: 600px;
		min-width: 300px;
		max-height: 600px;
		min-height: 300px;
		margin-left: auto;
		width: 50vw;
		height: 50vw;
		z-index: 10002;
		overflow: hidden;

		@media (max-width: 768px) {
			margin: 0;
			width: 100%;
			border-radius: 0;
			position: fixed;
			bottom: 0;
			max-height: 80vh;
		}
	}
`

const StyledNftPanel = styled.div`
	margin: 0 0 50px;
	padding: 0 4rem;

	@media (max-width: 1360px) { 
		padding: 0 1rem;
	}

	@media (max-width: 768px) {
		padding: 0 8px;
	}
`

const StyledInput = styled.input`
	border: 1px solid ${({ theme }) => theme.text};
	color: ${({ theme }) => theme.text};
	background-color: transparent;
	border-radius: 8px;
	margin: 1rem 0;
	padding: 8px 4px 8px 8px;
`

const StyledPanel = styled.div<{ showModal: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 50px 0 150px;

	@media (max-width: 768px) {
		margin: 1rem 0 2rem;
	}

	.filter-panel{
		flex: 3;
		padding: 0 8px;
	}

	.nft-panel{
		flex: 9;
		padding: 0 8px;
	}

	.modal-overlay{
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 10001;
		width: 100vw;
		height: 100vh;
	}

	@media (max-width: 1280px) {
		.filter-panel{
			position: fixed;
			z-index: 10002;
			padding: 1rem;
			left: 0;
			bottom: 0;
			height: calc(100vh - 60px);
			overflow-y: auto;
			width: 100%;
			display: ${({ showModal }) => showModal ? 'block' : 'none'}
		}
	}
`

const StyledReloadButton = styled.div`
	display: none;
	justify-content: flex-end;

	@media (max-width: 1280px) {
		display: flex;
	}

	@media (max-width: 768px) {
		justify-content: space-around;
	}
`

const StyledCoverImage = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;

	.banner{
		margin: 0;
		padding: 0;
		height: 300px;
		z-index: 1;

		@media (max-width: 768px) {
			height: 150px;
		}

		img{
			width: 100%;
			max-height: 300px;
			height: 300px;

			@media (max-width: 768px) {
				height: 150px;
			}
		}
	}
`

const StyledAvatarImage = styled.div`
	z-index: 100;
	border-radius: 4px;
	transform: translateY(-90px);
	position: relative;
	width: 150px;
	height: 150px;
	margin-bottom: -40px;

	img{
		width: 150px;
		height: 150px;
    object-fit: cover;
	}
  
	@media (max-width: 768px) {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
`

const LoadingSniper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 10px;
`