import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary } from "../../components/components";

export const Page404 = () => {
  const navigate = useNavigate()

  return (
    <StyledNotFoundPanel>
      <h1 style={{ fontSize: '6rem', margin: '0' }}>404 </h1>
      <h2 style={{ fontSize: '3rem', margin: '0' }}>This page is lost</h2>

      <h3>
        We've explored deep and wide,
        but we can't find the page you were looking for.
      </h3>

      <div className="row center mt3">
        <ButtonPrimary style={{ width: '250px' }} onClick={() => { navigate("/") }}>
          Navigate back home
        </ButtonPrimary>
      </div>
    </StyledNotFoundPanel>
  )
}

const StyledNotFoundPanel = styled.div`
	margin: 100px auto;
	text-align: center;
`