import { createSlice } from '@reduxjs/toolkit';
import config from './config/config.json';

const appKey = config.REACT_APP_GTAG + '-config'

const initialState: StoreType = {
	currentPage: '',
	updated: 0,
	loading: false,
	darkMode: true,
	lang: 'en',
	currentAccountAddress: '',
	currentAccountMail: '',
	currentAccountAvatar: '',
	lasttime: 0,
	currentAccountName: '',
	token: '',
	isMetamask: false,
	isWalletConnect: false,
	logined: false,
	balances: [],
	redirectPage: ""
}

const getStore = (initialState: any) => {
	try {
		const buf = window.localStorage.getItem(appKey)
		if (buf) {
			const json = JSON.parse(buf)
			for (let k in json) {
				if (initialState[k] !== undefined) {
					initialState[k] = json[k]
				}
			}
		}
	} catch (err) {
		console.log(err)
	}
	return initialState
}

const setStore = (state: any) => {
	window.localStorage.setItem(appKey, JSON.stringify(state))
}

export default createSlice({
	name: 'coco-frontend',
	initialState: getStore(initialState),
	reducers: {
		update: (state: any, action) => {
			for (const k in action.payload) {
				if (state[k] === undefined) new Error('🦊 undefined account item')
				state[k] = action.payload[k]
			}
			setStore(state)
		}
	}
})