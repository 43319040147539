import React from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from '@mui/material/Accordion';

import useStore from '../../useStore';
import Icon from '../../components/Icon';
import NFTItem from '../../components/NftItem';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/Layout/Layout';
import CustomCheckbox from '../../components/Checkbox';
import NFTCardLoading from '../../components/NftItemLoading';
import { GET_NFTS, GET_PAYLABLE_TOKEN } from '../../graphql/index';
import { ButtonBorder, ButtonPrimary } from '../../components/components';
import { CircularProgress, Stack } from '@mui/material';

interface SearchInterface {
  keyword: string
  price1: string
  price2: string
  symbol: string
  saleStatus: string[]
  symbolType: string[]
  sort: string
  nfts: NFTSearchResult[]
  notfound: boolean
  prices: Prices[]
  loading: boolean
  paylableTokens: PaylableTokens[]

  pageNum: number
  nftsLen: number
  baseNftLen: number
  loadingSpinner: boolean
}

const initStatus: SearchInterface = {
  keyword: ' ',
  paylableTokens: [],
  price1: '',
  price2: '',
  symbol: 'MATIC',
  saleStatus: [],
  symbolType: [],
  sort: 'latest',
  nfts: [],
  notfound: false,
  prices: [],
  loading: true,

  pageNum: 1,
  nftsLen: 0,
  baseNftLen: 30,
  loadingSpinner: false,
}

let tempStatus: SearchInterface = initStatus;

const SearchNFT = () => {
  // @ts-ignore
  const theme = useContext(ThemeContext);
  const [showModal, setShowModal] = React.useState(false)
  const { currentAccountAddress, darkMode, lang } = useStore()

  const { t } = useI18n()
  const location = useLocation()
  const [status, setStatus] = React.useState<SearchInterface>(initStatus)

  useEffect(() => {
    tempStatus = initStatus;
  }, [])

  const updateStatus = (params: Partial<SearchInterface>) => {
    tempStatus = { ...tempStatus, ...params }
    setStatus({ ...tempStatus, ...params })
  }

  useEffect(() => {
    const onScroll = () => {
      const scrollHeight = window?.scrollY + window?.innerHeight
      const offsetHeight = window?.document?.body?.offsetHeight

      if (offsetHeight && scrollHeight) {
        if (tempStatus.nftsLen >= tempStatus.baseNftLen * tempStatus.pageNum) {
          let scrollRatio = offsetHeight - scrollHeight

          if (scrollRatio < 450) {
            updateStatus({ pageNum: tempStatus.pageNum + 1, loadingSpinner: true })
          }
        }
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => { window.removeEventListener('scroll', onScroll) }
  }, [])

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: nftInfo, loading: infoLoading, error: infoError } = useQuery(GET_NFTS, {
    variables: {
      query: status.keyword,
      nftcollection: '',
      sort: status.sort,
      price1: Number(status.price1),
      price2: Number(status.price2),
      owner: '',
      searchsymbol: (Number(status.price1) > 0 || Number(status.price2) > 0) ? status.symbol : '',
      salestatus: status.saleStatus.toString(),
      page: 0,
      limit: status.baseNftLen * status.pageNum,
      symbols: status.symbolType.toString()
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (infoLoading || infoError) return;

    const data = nftInfo?.getNFTs as NFTSearchResult[];
    if (!data) updateStatus({ notfound: true, loading: false, loadingSpinner: false });
    else updateStatus({ nfts: data, loading: false, nftsLen: data.length, loadingSpinner: false, });
  }, [nftInfo, infoLoading, infoError])

  const resetSearch = () => {
    updateStatus({
      price1: '', price2: '', symbol: 'MATIC',
      saleStatus: [],
      symbolType: [],
      sort: 'latest'
    })
  }

  useEffect(() => {
    const query = new URL(window.location.href).searchParams.get('query')
    updateStatus({ keyword: query || '' })
    return;
  }, [location])

  return (
    <Layout>
      <div className="container p1">
        <StyledTitle><span>NFT </span>{t("nftsearch.search")}</StyledTitle>

        <div className="row middle">
          <div className="col-md-2 col-sm-0 m0 p0">
            <div className="justify">
              <StyledReloadButton>
                <ButtonBorder onClick={() => { setShowModal(!showModal) }}
                  style={{ padding: '0.8rem', margin: '1rem', borderRadius: '8px', display: 'flex', alignItems: 'center', borderColor: theme.strokeColor }}
                >
                  <svg style={{ marginRight: '4px' }} width="7.149" height="12.86" viewBox="189.89 1093 9.149 17.86">
                    <path d="M198.665 1110.486a1.276 1.276 0 0 0 0-1.804l-5.85-5.85a1.303 1.303 0 0 1 0-1.804l5.85-5.85a1.275 1.275 0 1 0-1.804-1.805l-5.85 5.85a3.827 3.827 0 0 0 0 5.412l5.85 5.851a1.276 1.276 0 0 0 1.804 0Z" fill={theme.text} fillRule="evenodd" data-name="down" />
                  </svg>

                  {t("nftsearch.filter")}
                </ButtonBorder>
              </StyledReloadButton>
              <div></div>
            </div>
          </div>

          <div className="col-md-8 col-sm-12">
            <div className="row center middle">
              <div className="col-lg-6 col-6 flex" style={{ justifyContent: 'flex-end' }}>
                <ButtonPrimary style={{ backgroundColor: darkMode ? '#515151' : '#A7A7A7', margin: '1rem', minWidth: '120px' }} >
                  <Link to="/search-nft" style={{ color: theme.text, width: '200px', textDecoration: 'none' }}>NFT</Link>
                </ButtonPrimary>
              </div>

              <div className="col-lg-6 col-6 flex" style={{ justifyContent: 'flex-start' }}>
                <StyledActiveButton style={{ margin: '1rem' }}>
                  <Link to="/search-collection">COLLECTION</Link>
                </StyledActiveButton>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-sm-12">
            <Dropdown selectedKey={lang === "en" ? "Latest" : "最新順"}
              values={[
                { key: "latest", name: t("nftsearch.latest") },
                { key: "oldest", name: t("nftsearch.oldest") },
                { key: "recent", name: t("nftsearch.recent") },
                { key: "sales", name: t("nftsearch.sales") },
                { key: "descend", name: t("nftsearch.descend") },
                { key: "lowest", name: t("nftsearch.lowest") },
              ]}
              onChange={(key) => { updateStatus({ sort: key }); }}
              panelProps={{ style: { borderRadius: '12px', overflow: 'hidden' } }}
              itemProps={{ style: { backgroundColor: theme.dropColor, color: theme.text } }}
              props={{ style: { backgroundColor: 'transparent', borderRadius: '12px', margin: '0 8px', border: '1px solid ' + theme.strokeColor } }}
            />
          </div>
        </div>

        <StyledPanel showModal={showModal}>
          {showModal && (
            <div className="modal-overlay" onClick={() => { setShowModal(false) }}></div>
          )}

          <div className="filter-panel" style={{ backgroundColor: theme.bgColor2 }}>
            {!showModal && (
              <div className="hr" style={{ marginTop: '1rem', borderColor: theme.strokeColor }}></div>
            )}

            <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                style={{ justifyContent: 'flex-end' }}
              >
                <p style={{ color: theme.strokeColor, width: '90%', fontSize: '18px', fontWeight: 'bold', margin: 0, }}>{t("nftsearch.price")}</p>
              </AccordionSummary>

              <AccordionDetails>
                <div className="flex middle justify">
                  <div style={{ flex: 3 }}>
                    <StyledInput type="number"
                      placeholder={t("minimum")} value={status.price1}
                      onChange={(e) => { updateStatus({ price1: e.target.value }) }}
                      style={{ width: '100%', fontSize: '16px', borderColor: theme.strokeColor }}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <p style={{ color: theme.strokeColor, textAlign: 'center' }}>~</p>
                  </div>

                  <div style={{ flex: 3 }}>
                    <StyledInput type="number"
                      placeholder={t("maximum")} value={status.price2}
                      style={{ width: '100%', fontSize: '16px', borderColor: theme.strokeColor }}
                      onChange={(e) => { updateStatus({ price2: e.target.value }) }}
                    />
                  </div>

                  <div style={{ flex: 3, padding: '0 0 0 8px' }}>
                    <Dropdown hideImg
                      selectedKey={status.symbol} values={status.paylableTokens}
                      onChange={(key) => { updateStatus({ symbol: key.toUpperCase() }) }}
                      props={{ style: { height: '100%', padding: '12px', border: '1px solid' + theme.boxColor } }}
                    />
                  </div>
                </div>

                {/* <div className="flex center mt1">
										<ButtonPrimary style={{borderRadius: '8px', width: '100%'}} onClick={searchByPrice}>決定</ButtonPrimary>
									</div> */}
              </AccordionDetails>
            </Accordion>

            <div className="hr mt0" style={{ marginTop: '1rem', borderColor: theme.strokeColor }}></div>

            <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                style={{ justifyContent: 'flex-end' }}
              >
                <p style={{ color: theme.strokeColor, width: '90%', fontSize: '18px', fontWeight: 'bold', margin: 0 }}>{t("nftsearch.status")}</p>
              </AccordionSummary>

              <AccordionDetails className='flex'>
                <div>
                  <div className="flex">
                    <CustomCheckbox checked={status.saleStatus.indexOf("auction") > -1}
                      changeEvent={(checked) => {
                        let list = status.saleStatus;
                        if (checked) list.push("auction")
                        else list.splice(list.indexOf("auction"), 1)
                        updateStatus({ saleStatus: list })
                      }}
                    />

                    <p style={{ cursor: 'pointer', color: theme.text, width: '120px' }}
                      onClick={() => {
                        const checked = status.saleStatus.indexOf("auction") === -1;
                        let list = status.saleStatus;
                        if (checked) list.push("auction")
                        else list.splice(list.indexOf("auction"), 1)
                        updateStatus({ saleStatus: list })
                      }}>
                      {t("nftsearch.auction")}
                    </p>
                  </div>

                  <div className="flex">
                    <CustomCheckbox checked={status.saleStatus.indexOf("regular") > -1}
                      changeEvent={(checked) => {
                        let list = status.saleStatus;
                        if (checked) list.push("regular")
                        else list.splice(list.indexOf("regular"), 1)
                        updateStatus({ saleStatus: list })
                      }}
                    />

                    <p style={{ cursor: 'pointer', color: theme.text, width: '120px' }}
                      onClick={() => {
                        const checked = status.saleStatus.indexOf("regular") === -1;
                        let list = status.saleStatus;
                        if (checked) list.push("regular")
                        else list.splice(list.indexOf("regular"), 1)
                        updateStatus({ saleStatus: list })
                      }}>
                      {t("nftsearch.regular")}
                    </p>
                  </div>

                  <div className="flex">
                    <CustomCheckbox checked={status.saleStatus.indexOf("nosale") > -1}
                      changeEvent={(checked) => {
                        let list = status.saleStatus;
                        if (checked) list.push("nosale")
                        else list.splice(list.indexOf("nosale"), 1)
                        updateStatus({ saleStatus: list })
                      }}
                    />

                    <p style={{ cursor: 'pointer', color: theme.text, width: '120px' }}
                      onClick={() => {
                        const checked = status.saleStatus.indexOf("nosale") === -1;
                        let list = status.saleStatus;
                        if (checked) list.push("nosale")
                        else list.splice(list.indexOf("nosale"), 1)
                        updateStatus({ saleStatus: list })
                      }}>
                      {t("nftsearch.nosale")}
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="hr mt0" style={{ marginTop: '1rem', borderColor: theme.strokeColor }} />

            <Accordion style={{ backgroundColor: 'transparent', boxShadow: 'none' }} className='search-accordion'>
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<Icon icon="Down" fill={theme.strokeColor} size={18} height={25} />}
                style={{ justifyContent: 'flex-end' }}
              >
                <p style={{ color: theme.strokeColor, width: '90%', fontSize: '18px', fontWeight: 'bold', margin: 0 }}>{t("nftsearch.tokens")}</p>
              </AccordionSummary>

              <AccordionDetails className='flex'>
                <div>
                  {status.paylableTokens.map((token, key) => (
                    <div className="flex" key={key}>
                      <CustomCheckbox
                        checked={status.symbolType.indexOf(token.symbol) > -1}
                        changeEvent={(checked) => {
                          let list = status.symbolType;
                          if (checked) list.push(token.symbol)
                          else list.splice(list.indexOf(token.symbol), 1)
                          updateStatus({ symbolType: list })
                        }}
                      />

                      <p style={{ cursor: 'pointer', color: theme.text, width: '70px' }}
                        onClick={() => {
                          const checked = status.symbolType.indexOf(token.symbol) === -1;
                          let list = status.symbolType;
                          if (checked) list.push(token.symbol)
                          else list.splice(list.indexOf(token.symbol), 1)
                          updateStatus({ symbolType: list })
                        }
                        }
                      >
                        {token.symbol}
                      </p>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="hr mt0" style={{ margin: '1rem 0 2rem', borderColor: theme.strokeColor }} />

            <div className="flex justify middle">
              <p>{status.nfts?.length || 0} {lang == "jp" ? "の結果" : " results"}</p>

              <ButtonPrimary onClick={() => { resetSearch() }}
                style={{ backgroundColor: "transparent", border: '2px solid #353945', borderRadius: '24px', width: '50%', marginTop: '1rem', marginBottom: '1rem' }}
              >
                {t("nftsearch.reset")}
              </ButtonPrimary>
            </div>

            {showModal && (
              <div className="flex center">
                <ButtonPrimary onClick={() => { setShowModal(false) }}
                  style={{ backgroundColor: theme.dropColor, border: '1px solid #777777', borderRadius: '24px', width: '50%', marginTop: '1rem', marginBottom: '1rem' }}
                >
                  {t("nftsearch.closebtn")}
                </ButtonPrimary>
              </div>
            )}
          </div>

          <div className="nft-panel">
            <StyledNFTPanel>
              {status.loading && (
                <div className="row">
                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>

                  <div className="col-xl-3 col-md-4 col-sm-6 col-6 p1">
                    <NFTCardLoading />
                  </div>
                </div>
              )}

              {(status.nfts && status.nfts?.length === 0 && !status.loading) && (
                <h2 style={{ color: theme.grey, textAlign: 'center', margin: '200px auto' }}>{t("notfound")}</h2>
              )}

              <div className="row">
                {(status.nfts && status.nfts?.length > 0) && (
                  status.nfts?.map((nft, index) => (
                    ((nft.owner === currentAccountAddress) || !nft?.hide) && (
                      <div className="col-xl-3 col-md-4 col-sm-6 col-6  p0 pl pr" key={"nft-" + index}>
                        <NFTItem
                          nft={nft}
                          id={nft._id}
                          nftCollection={nft.nftCollection}
                          tokenId={nft.tokenid}
                          owner={nft.owner}
                          img={nft.coverImage}
                          name={nft.name}
                          balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                          symbol={nft.acceptedToken}
                          balanceUsd={
                            (lang === 'en' ? '$' : '￥') +
                            (nft.price > 0 && nft.price < 2147483647 ? ((nft.price * (status.paylableTokens.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)) : 0)
                          }
                        />
                      </div>
                    )))
                )}
              </div>

              {status.loadingSpinner && (
                <LoadingSniper>
                  <CircularProgress color='inherit' />
                </LoadingSniper>
              )}
            </StyledNFTPanel>
          </div>
        </StyledPanel>
      </div>
    </Layout>
  )
}

export default SearchNFT;

const StyledActiveButton = styled.div`
	background: -webkit-linear-gradient(45deg, #f9f592, #a7f195, #74f9d0, #5e87f1, #e59afb, #f95959);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1.4rem;
	cursor: pointer;
	text-align: center;
	@media (max-width: 768px) {
		margin: 0!important;
	}
`

const StyledNFTPanel = styled.div`
	margin: 0 0 50px;
	padding: 0 4rem;
	@media (max-width: 1360px) { 
		padding: 0 1rem;
	}
	@media (max-width: 768px) {
		padding: 0 8px;
	}
`

const StyledTitle = styled.h2`
	text-align: center;
	font-size: 2.2rem;
	margin: 1rem;
	span{
		font-size: 2.4rem;
		background: -webkit-linear-gradient(45deg, #f9f592, #a7f195, #74f9d0, #5e87f1, #e59afb, #f95959);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`

const StyledInput = styled.input`
	border: 1px solid ${({ theme }) => theme.text};
	color: ${({ theme }) => theme.text};
	background-color: transparent;
	border-radius: 8px;
	margin: 1rem 0;
	padding: 8px 4px 8px 8px;
`

const StyledPanel = styled.div<{ showModal: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 50px 0 150px;
	@media (max-width: 768px) {
		margin: 1rem 0 2rem;
	}
	.filter-panel{
		flex: 3;
		padding: 0 1rem;
		width: 100%;
	}
	.nft-panel{
		flex: 9;
		padding: 0 8px;
		width: 100%;
	}

	.modal-overlay{
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 10001;
		width: 100vw;
		height: 100vh;
	}
	@media (max-width: 1024px) {
		.filter-panel{
			position: fixed;
			z-index: 10002;
			padding: 1rem;
			left: 0;
			bottom: 0;
			height: calc(100vh - 60px);
			overflow-y: auto;
			width: 100%;
			display: ${({ showModal }) => showModal ? 'block' : 'none'}
		}
	}
`

const StyledReloadButton = styled.div`
	display: none;
	justify-content: flex-end;
	@media (max-width: 1024px) {
		display: flex;
	}
	@media (max-width: 768px) {
		justify-content: space-around;
	}
`

const LoadingSniper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 10px;
`