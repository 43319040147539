import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout/Layout';

const Privacy = () => {
  return (
    <Layout>
      <div className="container">
        <StyledContainer>
          <h1 className='m0 p0'>
            THE PRIVACY POLICY
          </h1>

          <ol>
            <li>
              <p>
                This Privacy Policy (the “Policy”) describes how Greater Asia Communications Pte. Ltd. (“Greater Asia”, “we”, “us” or “our”) may collect personal and identifying information, and use or disclose such personal information from users of our website (the ”Site”).
              </p>

              <p>
                Please read this privacy policy (the “Privacy Policy”) carefully.
              </p>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Applicability of this Policy
          </h3>

          <ol start={2}>
            <li>
              <p>
                THE USE OF OUR SITE OR THE PROVISION OF ANY PERSONAL INFORMATION CONSTITUTES AN AGREEMENT TO THIS PRIVACY POLICY. PLEASE DO NOT USE THE SITE OR PROVIDE INFORMATION (OR ALLOW OTHERS TO DO SO ON YOUR BEHALF) IF YOU DO NOT AGREE WITH ALL OF THE TERMS OF USE INCLUDING THIS PRIVACY POLICY.
              </p>
            </li>

            <li>
              <p>
                This Privacy Policy is part of the ‘Terms and Conditions’ (“T&C”) for the use of our Site. This Privacy Policy supplements but does not supersede nor replace any other consents you may have previously provided to Greater Asia in respect of your Personal Data, and your consents herein are additional to any rights which Greater Asia may have at law to collect, use or disclose your Personal Data.
              </p>
            </li>

            <li>
              <p>
                Greater Asia may from time to time update this Privacy Policy to ensure that this Privacy Policy is consistent with our future developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Policy, as updated from time to time on our website. Please check regularly for any updated information on the handling of your Personal Data.
              </p>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Personal Data We May Collect
          </h3>

          <ol start={5}>
            <li>
              <p>
                The personal data we collect includes:
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Contact information</b>: Include but not limited to email addresses.
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Financial information</b>: 	Include but not limited to Blockchain wallet addresses, crypto asset information, transaction history and trading data.
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Transaction information</b>: Any information about the transaction which you've made on our Platform such as the type of transaction, transaction amount and timestamp.
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Communication</b>: Include but not limited to feedbacks, questionnaires, survey responses and information provided to our support team via our official communication channels
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Online identifiers</b>: Include but not limited to geographical location, browser fingerprints, browser type & version, and IP addresses
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Usage data</b>: Include but not limited to user preferences and data collected via cookies or similar technologies
              </p>

              <p>
                <b style={{ textDecoration: 'underline' }}>Information from 3rd party sources</b>: Include but not limited to information about you from any other sources as required or permitted by applicable law, such as public databases. We may use or use a combination of the information collected in order to comply with legal obligations, and to prevent or deter the proliferation of any illegal, illicit or fraudulent activities in relation to the use of such information.
              </p>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            How We Collect Personal Data
          </h3>

          <ol start={6}>
            <li>
              <p>
                Generally, Personal Data may be collected by us in the following ways:
              </p>

              <ol type='a'>
                <li>
                  <p>
                    when you activate an account on the Site in order to get access to the Platform.
                  </p>
                </li>

                <li>
                  <p>
                    when you use our electronic services or interact with us via the Site or use services on the Site.
                  </p>
                </li>

                <li>
                  <p>
                    when you submit any form, including but not limited to application, declaration, proposal, or referral forms.
                  </p>
                </li>

                <li>
                  <p>
                    when you enter into any agreement or provide other documentation or information in respect of your interactions with or transactions on the Platform.
                  </p>
                </li>

                <li>
                  <p>
                    when you request that we contact you, be included in an email or other mailing list; or when you respond to our request for additional Personal Data, our promotions, and other initiatives.
                  </p>
                </li>

                <li>
                  <p>
                    when you are contacted by, and respond to, our marketing representatives and agents and other service providers.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            How We Use Information
          </h3>

          <ol start={7}>
            <li>
              <p>
                Generally, Greater Asia may use or disclose your Personal Data for the following purposes:
              </p>

              <ol type='a'>
                <li>
                  <p>
                    responding to, processing, and handling your complaints, queries, requests, feedback, and suggestions.
                  </p>
                </li>

                <li>
                  <p>
                    verifying your identity.
                  </p>
                </li>

                <li>
                  <p>
                    create and update user accounts.
                  </p>
                </li>

                <li>
                  <p>
                    processing transactions.
                  </p>
                </li>

                <li>
                  <p>
                    managing the administrative and business operations of Greater Asia and the Platform and complying with internal policies and procedures.
                  </p>
                </li>

                <li>
                  <p>
                    facilitating business asset transactions (which may extend to any mergers, acquisitions, or asset sales) involving Greater Asia or any of its affiliates.
                  </p>
                </li>

                <li>
                  <p>
                    matching of any Personal Data held which relates to you for any of the purposes listed herein.
                  </p>
                </li>

                <li>
                  <p>
                    requesting feedback or participation in surveys, as well as conducting market research and/or analysis for statistical, profiling, or other purposes for us to improve our products and services, understand customer behavior, preferences and market trends, and to review, develop and improve the quality of our Platform and Services.
                  </p>
                </li>

                <li>
                  <p>
                    including but not limited to preventing, detecting, and investigating crime, fraud and other illicit activities.
                  </p>
                </li>

                <li>
                  <p>
                    providing media announcements and responses.
                  </p>
                </li>

                <li>
                  <p>
                    organizing promotional events.
                  </p>
                </li>

                <li>
                  <p>
                    in connection with any claims, actions or proceedings (including but not limited to drafting and reviewing documents, transaction documentation, obtaining legal advice, and facilitating dispute resolution), and/or protecting and enforcing our contractual and legal rights and obligations.
                  </p>
                </li>

                <li>
                  <p>
                    complying with any applicable rules, laws and regulations, codes of practice or guidelines or to assist in law enforcement and investigations by relevant authorities; and/or
                  </p>
                </li>

                <li>
                  <p>
                    any other purpose relating to any of the above.
                  </p>
                  <p>
                    These purposes may also apply even if you do not maintain any account(s) with us or have terminated these account(s).
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <ol start={8}>
            <li>
              <p>
                In addition, where permitted under the applicable law, Greater Asia may also collect, use and disclose your Personal Data for the following purposes (which we may describe in our documents and agreements as “Additional Purposes” for the handling of Personal Data):
              </p>

              <ol type='a'>
                <li>
                  <p>
                    providing or marketing services, products, and benefits to you, including promotions, loyalty, and reward programmes
                  </p>
                </li>

                <li>
                  <p>
                    matching Personal Data with other data collected for other purposes and from other sources (including third parties) in connection with the provision or offering of products and services, whether by Greater Asia or other third parties.
                  </p>
                </li>

                <li>
                  <p>
                    administering contests and competitions and personalizing your experience at Greater Asia's touchpoints.
                  </p>
                </li>

                <li>
                  <p>
                    sending you details of products, services, special offers and rewards, either to our customers generally, or which we have identified may be of interest to you; and/or
                  </p>
                </li>

                <li>
                  <p>
                    conducting market research, understanding, and analyzing customer behavior, location, preferences, and demographics for us to offer you products and services as well as special offers and marketing programmes which may be relevant to your preferences and profile.
                  </p>
                </li>

                <li>
                  <p>
                    In relation to products or services or in your interactions with us, we may also have specifically notified you of other purposes for which we collect, use or disclose your Personal Data. If so, we will collect, use and disclose your Personal Data for these additional purposes as well, unless we have specifically notified you otherwise.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Disclosure of Personal Data
          </h3>

          <ol start={9}>
            <li>
              <p>
                Greater Asia may share your information in the following circumstances:
              </p>

              <ol type='a'>
                <li>
                  <p>
                    With your consent: Sharing your personal information with others for their own marketing uses and will be subjected to their privacy policies.
                  </p>
                </li>

                <li>
                  <p>
                    To comply with legal obligations: To cooperate with the investigation of relevant government regulators, government ministries, statutory boards or authorities and/or law enforcement agencies, and other applicable governmental agencies, to comply with any directions, laws, rules, guidelines, regulations or schemes issued or administered by any of them, or we believe in good faith that the disclosure of personal information is necessary to prevent harm to another person, to report suspected illicit activities, to investigate violations of our User Agreement or any other applicable policies.
                  </p>
                </li>

                <li>
                  <p>
                    With 3rd party service providers: Who help to facilitate business and compliance operations such as technology and marketing services.
                  </p>
                </li>

                <li>
                  <p>
                    During a change to our business: Facilitating business asset transactions (which may extend to any mergers, acquisitions, dissolution, bankruptcy or asset sales) involving Greater Asia or any of its affiliates subject to standard confidentiality arrangements.
                  </p>
                </li>

                <li>
                  <p>
                    Anonymized Data: Sharing anonymized data with others for their own uses.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Data Security
          </h3>

          <ol start={10}>
            <li>
              <p>
                We will take reasonable efforts to protect all Personal Data in our possession or its control by making reasonable security arrangements to prevent unauthorized access, collection, use, disclosure, copying, modification, disposal, or similar risks. However, we cannot completely guarantee the security of any Personal Data we may have collected from or about you, or that for example no harmful code will enter the Site (for example viruses, bugs, trojan horses, spyware or adware).
              </p>
            </li>

            <li>
              <p>
                There may be a minimum and reasonable amount of risks associated with using the Site, and you are presumed to have accepted this by using our Site.
              </p>
            </li>

            <li>
              <p>
                We are not responsible for the intentional or criminal acts of third parties such as hackers or “phishers.”
              </p>
            </li>

            <li>
              <p>
                While we strive to protect your Personal Data, we cannot ensure the security of the information you transmit to us via the Internet or when you access the Platform via mobile phones, and we urge you to take every precaution to protect your Personal Data when you access such platforms.
              </p>
            </li>

            <li>
              <p>
                We are not liable for any damages resulting from any security breaches, or unauthorized and/or fraudulent use of your account, although we shall take all measures to deter or prevent such an occurrence.
              </p>
            </li>
          </ol>

          <h3 className='m0 mt5 p0'>
            Age Limitation
          </h3>

          <p>
            To the extent prohibited by applicable laws, we do not allow the use of our Sites and Services by anyone below the age of 18 years old. We will take the necessary steps to close out any such accounts that are found to be in breach of this requirement, and prevent such unauthorized users from using our Services.
          </p>

          <h3 className='m0 mt5 p0'>
            Contact Us
          </h3>

          <ul>
            <li>
              <p>
                Please contact us if you have any questions or feedback relating to your Personal Data or our Privacy Policy or if you want to withdraw your consent to any or all use of your Personal Data granted by you. You may email us at <a href="mailto:hello@mecha.fun">hello@mecha.fun</a>
              </p>
            </li>

            <li>
              <p>
                In the event where you withdraw your consent to any or all use of your Personal Data, depending on the nature of your request, Greater Asia may not be able to continue to provide its products or services to you or administer any contractual relationship already in place. This may also result in the termination of any agreements you have with Greater Asia, and you being in breach of your contractual obligations or undertakings. Greater Asia’s legal rights and remedies in such event are expressly reserved.
              </p>
            </li>
          </ul>
        </StyledContainer>
      </div>
    </Layout>
  )
}

const StyledContainer = styled.div`
	margin-top: 2rem;
	padding-bottom: 5rem;
	padding: 2rem 1rem 4rem;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	a{
		color:  ${({ theme }) => theme.text};
		font-weight: bold;
	}
	ul {
		padding: 0 1rem;
	}
	ol {
		padding: 0 1rem;
	}
	li {
		p{
			margin: 0;
		}
	}
`

export default Privacy;