import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { useContext } from 'react'
import { useWallet } from "use-wallet";
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';


import useStore from '../../useStore';
import Modal from '../../components/Modal';
import Layout from '../../components/Layout/Layout';
import { ButtonPrimary } from '../../components/components';

import { ellipsis, getValidHttpUrl, tips, validateUrl } from '../../util';
import { getNftsForOwner, toChecksumAddress } from '../../blockchain/utils';
import { getMarketplaceContract, getNFTContract } from '../../blockchain/contract';
import Addresses from '../../config/addresses.json';
import networks from '../../blockchain/networks.json';
import config from '../../config/config.json';

import preview from "../../assets/img/preview.png";
import metamask from '../../assets/img/metamask.webp';
import walletconnect from '../../assets/img/walletconnect.webp';
import { Stack, Typography } from '@mui/material';

interface Contract {
  address: string
  deployer: string
  name: string
  symbol: string
  tokenType?: string
  totalSupply?: string
  opensea: {
    collectionName?: string
    description?: string
    discordUrl?: string
    externalUrl?: string
    floorPrice?: string
    imageUrl?: string
    lastIngestedAt?: string
    safelistRequestStatus?: string
    twitterUsername?: string
  }
}

interface Metadata {
  collection: string
  name: string
  description: string
  external_url?: string
  image?: string
  attributes: any
}

interface NFT {
  collection: Contract
  tokenId: string
  tokenUri: string
  metadata: Metadata
}

interface ImportInterface {
  showListModal: boolean
  showConfirmModal: boolean
  nfts: NFT[]
  totalNFTs: number
  walletConnected: boolean
  activeNFT: number
  loading: boolean
  step: string
}

const initStatus: ImportInterface = {
  showListModal: false,
  showConfirmModal: false,
  nfts: [],
  totalNFTs: 0,
  walletConnected: false,
  activeNFT: -1,
  loading: true,
  step: "install",
}

let tempStatus: ImportInterface = initStatus;

const ImportNft = () => {
  const { t } = useI18n()
  const wallet = useWallet()
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { darkMode, logined, isMetamask, currentAccountAddress, update } = useStore();
  const [userAddress, setUserAddress] = useState<string>(currentAccountAddress);
  const [status, setStatus] = React.useState<ImportInterface>(initStatus);

  useEffect(() => {
    tempStatus = initStatus;
  }, [])

  const updateStatus = (params: Partial<ImportInterface>) => {
    tempStatus = { ...tempStatus, ...params };
    setStatus({ ...tempStatus, ...params });
  }

  const getNfts = async () => {
    try {
      if (userAddress) {
        console.log("search", userAddress)
        updateStatus({ showListModal: true, nfts: [], loading: true })

        const nfts = [] as NFT[]
        const result = await getNftsForOwner(userAddress);

        if (result) {
          updateStatus({ totalNFTs: result?.ownedNfts?.length || 0 });

          for (let i = 0; i < result?.ownedNfts?.length; i++) {
            const nft = result?.ownedNfts[i];
            let tokenUri = nft?.tokenUri?.raw || "" as string;

            if (tokenUri && nft?.tokenId) {
              tokenUri = getValidHttpUrl(tokenUri).replace("ipfs.babylonswap.finance", "ipfs.idealbridgex.com");

              let metadata;
              if (tokenUri.startsWith("https://")) {
                try {
                  metadata = await axios.get(tokenUri, { method: "get", timeout: 1500 });
                } catch (err) {
                  console.log(err)
                  continue;
                }

                metadata = metadata?.data;
                let img = String(nft.rawMetadata?.image || metadata?.image);

                img = getValidHttpUrl(img).replace("ipfs.babylonswap.finance", "ipfs.idealbridgex.com")
                if (metadata && validateUrl(img)) {
                  nfts.push({
                    collection: {
                      address: nft.contract?.address || metadata?.collection,
                      deployer: nft.contract?.contractDeployer || currentAccountAddress,
                      name: nft.contract?.name || metadata?.name,
                      symbol: nft.contract?.symbol || "",
                      tokenType: nft.contract?.tokenType || "ERC721",
                      totalSupply: nft.contract?.totalSupply,
                      opensea: {
                        collectionName: nft.contract?.openSea?.collectionName,
                        description: nft.contract?.openSea?.description,
                        discordUrl: nft.contract?.openSea?.discordUrl,
                        externalUrl: nft.contract?.openSea?.externalUrl,
                        floorPrice: nft.contract?.openSea?.floorPrice?.toString(),
                        imageUrl: nft.contract?.openSea?.imageUrl,
                        lastIngestedAt: nft.contract?.openSea?.lastIngestedAt,
                        safelistRequestStatus: nft.contract?.openSea?.safelistRequestStatus,
                        twitterUsername: nft.contract?.openSea?.twitterUsername
                      }
                    },
                    tokenId: nft.tokenId,
                    tokenUri: tokenUri,
                    metadata: {
                      collection: nft.rawMetadata?.collection || metadata?.collection,
                      name: nft.rawMetadata?.name || metadata?.name,
                      description: nft.rawMetadata?.description || metadata?.description,
                      external_url: nft.rawMetadata?.external_url || metadata?.external_url,
                      image: img,
                      attributes: nft.rawMetadata?.attributes || metadata?.attributes
                    }
                  })
                }
              }
            }

            if (nfts.length !== 0 && nfts.length % 5 === 0) {
              updateStatus({ nfts: nfts, loading: false, showListModal: true })
            }
          }
        }

        updateStatus({ nfts: nfts, totalNFTs: nfts.length, loading: false, showListModal: true })
      }
    } catch (err) {
      console.log(err.message)
      // updateStatus({loading: false})
    }
  }

  const connectWallet = async () => {
    if (wallet.status !== "connected") {
      update({ loading: true })
      await wallet.connect();
      update({ loading: false })
    } else if (wallet.status === "connected") {
      await wallet.reset()
    }
  }

  const importNft = async () => {
    try {
      const nft = status.nfts?.[status.activeNFT];
      if (nft) {
        const collection = nft.collection.address;
        const nftid = nft?.tokenId;
        const provider = new ethers.providers.Web3Provider(wallet.ethereum);

        const signer = await provider.getSigner();
        const ca = getNFTContract(collection, signer);
        const owner = await ca.ownerOf(nftid.toString());

        if (owner.toUpperCase() !== userAddress.toUpperCase()) {
          return tips("warning", t("action.nft.notowner"))
        }

        update({ loading: true })

        const tx = await ca.approve(Addresses.market, nftid)
        await tx.wait()

        const ca2 = getMarketplaceContract(Addresses.market, signer)
        console.log(ca2)

        let tx2 = await ca2.importNFT(toChecksumAddress(collection), nftid, userAddress)
        await tx2.wait()

        tips("success", t("action.nft.imported"))
        navigate("/profile")
        update({ loading: false })
      }
    } catch (err) {
      console.log(err)
      tips("error", t("action.nft.importerror"))
      update({ loading: false })
    }
  }

  const walletConnect = async () => {
    if (wallet.status === "connected") {
      wallet?.reset()
    } else {
      localStorage.setItem("walletconnect", "")
      wallet?.reset()
      wallet?.connect('walletconnect')
    }
  }

  const switchNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      await wallet.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + Number(chainId).toString(16) }],
      })

      update({ loading: false })
    } catch (error) {
      console.log(error)
      addNetwork(config.CHAINID)
      update({ loading: false })
    }
  }

  const addNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      const network = networks.find((data) => data.chainId === chainId)
      await wallet.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
            chainName: network?.name,
            nativeCurrency: {
              name: network?.name,
              symbol: network?.symbol,
              decimals: network?.decimals,
            },
            rpcUrls: [network?.rpc],
            blockExplorerUrls: [network?.explorer],
          },
        ],
      })
      switchNetwork(chainId)
      update({ loading: false })
    } catch (err) {
      console.log(err)
      tips('warning', t('action.metamask.selectethereum'))
      update({ loading: false })
    }
  }

  React.useEffect(() => {
    if (status.walletConnected) {
      getNfts()
    }
  }, [userAddress, status.walletConnected])

  React.useEffect(() => {
    if (!logined) navigate("/login");
    const connectedChainId = Number(wallet.ethereum?.chainId);

    if (logined && isMetamask) {
      setUserAddress(currentAccountAddress);
      updateStatus({ walletConnected: true });
    } else if (!window.ethereum) {
      updateStatus({ step: "install" });
    } else if (wallet.status !== 'connected') {
      updateStatus({ step: "connect" });
    } else if (wallet.status === 'connected' && connectedChainId !== config.CHAINID) {
      updateStatus({ step: "network" });
    } else if (wallet.status === 'connected') {
      setUserAddress(wallet.account || "");
      updateStatus({ walletConnected: true });
    }
  }, [logined, currentAccountAddress, isMetamask, wallet.ethereum?.chainId, wallet.account, wallet.status, status.step])

  return (
    <Layout>
      <StyledContainer className="container">
        <StyledPanel darkMode={darkMode}>
          <h4 className='title'>{t("nftimport.title")}</h4>
          {!isMetamask && (
            <>
              {window.innerWidth > 768 && (
                <div className="flex center mt3">
                  {status.step === "install" && (
                    <a target="_blank" style={{ textDecoration: 'none', width: '100%', maxWidth: '300px' }}
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                    >
                      <ButtonPrimary onClick={() => { }}
                        style={{ color: theme.text, width: '100%', maxWidth: '300px', backgroundColor: '#36C781', borderRadius: '12px' }}
                      >
                        <img src={metamask} style={{ width: '34px', marginRight: '8px' }} />
                        {t('metamask.installcontent')}
                      </ButtonPrimary>
                    </a>
                  )}

                  {status.step === "connect" && (
                    <ButtonPrimary onClick={() => { connectWallet() }}
                      style={{ width: '300px', backgroundColor: "#36C781", borderRadius: '12px' }}
                    >
                      <img src={metamask} style={{ width: '34px', marginRight: '8px' }} />

                      {(!wallet.ethereum?.qrcodeModal && wallet.status === "connected") ? (
                        "Metamask disconnect"
                      ) : (
                        wallet.status === "disconnected" ? t("nftimport.connectmetamask") :
                          (!wallet.ethereum?.qrcodeModal && wallet.status == "connecting" ? "Connecting..." : t("nftimport.connectmetamask"))
                      )}
                    </ButtonPrimary>
                  )}

                  {status.step === "network" && (
                    <ButtonPrimary onClick={() => { switchNetwork(config.CHAINID) }}
                      style={{ color: theme.text, width: '100%', maxWidth: '300px', backgroundColor: '#36C781', borderRadius: '12px' }}
                    >
                      <img src={metamask}
                        style={{ width: '34px', marginRight: '8px' }}
                      />
                      {t('metamask.switchbtncontent1')}
                    </ButtonPrimary>
                  )}
                </div>
              )}

              <div className="flex center mt2">
                <ButtonPrimary onClick={() => { walletConnect() }}
                  style={{ color: theme.text, width: '300px', backgroundColor: '#36C781', borderRadius: '12px', }}
                >
                  <img src={walletconnect} style={{ width: '34px', marginRight: '8px', borderRadius: '50%', }} />
                  {(wallet.ethereum?.qrcodeModal && wallet.status === "connected") ? (
                    "Disconnect"
                  ) : ((wallet.ethereum?.qrcodeModal && wallet.status === "disconnected") ? (
                    "Walletconnect"
                  ) : (
                    wallet.ethereum?.qrcodeModal ? "Connecting..." : "Walletconnect"
                  ))}
                </ButtonPrimary>
              </div>
            </>
          )}

          <div className="flex center mt3 mb5">
            <ButtonPrimary style={{ width: '280px', backgroundColor: '#a7a7a7' }} onClick={() => { navigate("/items/select-options") }}>
              {t("nftimport.back")}
            </ButtonPrimary>
          </div>
        </StyledPanel>
      </StyledContainer>

      <Modal onClose={() => { updateStatus({ showListModal: false }) }} show={status.showListModal} style={{ maxWidth: '1024px', width: '100%' }}>
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t("nftimport.title")}
          </h1>

          {(!status.loading && status?.nfts?.length > 0) && (
            <div className="flex center">
              <p className='text-center ' style={{ maxWidth: '450px' }}>
                {t("nftimport.content")}<br />
                {t("nftimport.content2")}
              </p>
            </div>
          )}

          {status.loading && (
            <div className="flex center middle" style={{ height: '250px' }}>
              <StyledLoading className='ring'>
                Loading<span></span>
              </StyledLoading>
            </div>
          )}

          {(!status?.loading && status?.nfts?.length == 0) && (
            <div className="flex center">
              <p className='text-center ' style={{ maxWidth: '450px' }}> {t("notfound")}</p>
            </div>
          )}

          <StyledNftListPanel className='flex wrap'>
            {status.nfts.map((nft, index) => (
              <NftItem key={index}
                nft={nft} index={index}
                updateStatus={updateStatus}
                status={status}
              />
            ))}

            {(!!status.totalNFTs && !!status.nfts.length && status.nfts.length < status.totalNFTs) && (
              <div className='col-xl-3 col-lg-4 col-md-6 col-6 flex center'>
                <Stack gap={1} direction="column" justifyContent="center">
                  <CircularProgress />
                  <Typography variant='h5' fontWeight={600} textAlign="center">
                    {Math.round(status.nfts.length / status.totalNFTs * 100)}%
                  </Typography>
                </Stack>
              </div>
            )}
          </StyledNftListPanel>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px' }}
              onClick={() => {
                if (status.activeNFT === -1) return tips("warning", t("nftimport.selectnft"))
                updateStatus({ showConfirmModal: true })
              }}
            >
              {(logined && !isMetamask) && t("nftimport.importnft")}
              {(logined && isMetamask) && t("nftimport.sellnft")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showConfirmModal: false }) }} show={status.showConfirmModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t("nftimport.importtitle")}
          </h1>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px' }} onClick={importNft}>{t("nftimport.import")}</ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showConfirmModal: false }) }}
            >
              {t("nftimport.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>
    </Layout>
  )
}

const NftItem = ({ nft, index, status, updateStatus }: any) => {
  const [notExistsImg, setNotExistsImg] = useState(false);

  const onErrorImageLoad = () => {
    setNotExistsImg(true);
  }

  return (
    <div className='col-xl-3 col-lg-4 col-md-6 col-6 flex center'  >
      <div onClick={() => { updateStatus({ activeNFT: index }) }}
        className={`list ${status.activeNFT === index ? 'active' : ''}`}
      >
        {!notExistsImg && (
          <img alt="nft image"
            src={nft.metadata?.image}
            onError={onErrorImageLoad}
          />
        )}

        {notExistsImg && (
          <img alt='nft image' src={preview} />
        )}

        <div>
          <h4 className='m0 p0'>{ellipsis(nft?.metadata?.name, 12)}</h4>
          <p>{ellipsis(nft.metadata?.description, 30)}</p>
        </div>
      </div>
    </div>
  )
}

export default ImportNft;

const StyledLoading = styled.div`
	transform:translate(0,0);
	width:150px;
	height:150px;
	background:transparent;
	border:3px solid #3c3c3c;
	border-radius:50%;
	text-align:center;
	line-height:150px;
	font-family:sans-serif;
	font-size:20px;
	color: #10ffde;
	letter-spacing:4px;
	text-transform:uppercase;
	text-shadow:0 0 10px  #10ffde;
	box-shadow:0 0 20px rgba(0,0,0,.5);
	&:before {
		content:'';
		position:absolute;
		top:-3px;
		left:-3px;
		width:100%;
		height:100%;
		border:3px solid transparent;
		border-top:3px solid  #10ffde;
		border-right:3px solid  #10ffde;
		border-radius:50%;
		animation:animateC 2s linear infinite;
	}
	span {
		display:block;
		position:absolute;
		top:calc(50% - 2px);
		left:50%;
		width:50%;
		height:4px;
		background:transparent;
		transform-origin:left;
		animation:animate 2s linear infinite;
		&:before {
			content:'';
			position:absolute;
			width:16px;
			height:16px;
			border-radius:50%;
			background: #10ffde;
			top:-6px;
			right:-8px;
			box-shadow:0 0 20px  #10ffde;
		}
	}
	@keyframes animateC
	{
	0%
	{
		transform:rotate(0deg);
	}
	100%
	{
		transform:rotate(360deg);
	}
	}
	@keyframes animate
	{
	0%
	{
		transform:rotate(45deg);
	}
	100%
	{
		transform:rotate(405deg);
	}
	}
`

const StyledNftListPanel = styled.div`
	width: 100%;
	max-height: 600px;
	overflow-y: auto;
	@media (max-width: 768px) {
		max-height: 50vh;
	}

	.list{
    display: flex;
    flex-direction: column;

		border-radius: 1rem;
		cursor: pointer;
		max-width: 300px;
		width: 100%;
		padding: 8px;

		img {
			width: 100%;
			aspect-ratio: 1/1;
			border-radius: 12px;
			object-fit: cover;
      text-indent: -9999px;
		}

		div {
			overflow: hidden;
			p {
				font-size: 0.9rem;
				margin: 0;
				text-overflow: hidden;
			}
		}

		&:hover {
			background-color:  ${({ theme }) => theme.boxColor};
		}
		&.active {
			background-color:  #36C781;
		}
	}
`

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.modalBg};
	padding: 3rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	@media (max-width: 768px) {
		padding: 3rem 1rem;
	}
`