import React, { useMemo } from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from 'styled-components';
import { useContext, useState, useEffect } from 'react';
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import { tips } from '../util';
import { Loading } from './Loading';
import { InputField } from './InputField';
import { ButtonPrimary } from './components';
import { GET_FEE, GET_PAYLABLE_TOKEN } from '../graphql/index';
import useStore from '../useStore';
import Dropdown from './Dropdown';

import '../assets/style/stripe.scss';
import config from '../config/config.json';

interface ModalInterface {
  step: number
  amount: number
  errorAmount: string
  token: string
  paylableTokens: PaylableTokens[]
  balances: Balance[]
  cardCountry: string
  errorCardCountry: string
  cardOwner: string
  errorCardOwner: string
  fiatAmount: number
  currencyType: string
  errorCardNumber: string
  errorCardExpiration: string
  errorCardCVC: string
  cacheCardNumber: string
  cacheCardPaymentId: string
  cacheCardCustomerId: string
  useCacheCard: boolean
  exchangeFee: number
  onRampSideFee: number
}

export default function CheckOutForm({ show, onClose }: { show: boolean, onClose: Function }) {
  const { t } = useI18n();
  const stripe = useStripe();
  const elements = useElements();

  const [status, setStatus] = useState<ModalInterface>({
    step: 1,
    amount: 0,
    errorAmount: '',
    token: "MATIC",
    paylableTokens: [],
    balances: [],
    cardCountry: '日本',
    errorCardCountry: '',
    errorCardNumber: '',
    errorCardExpiration: '',
    errorCardCVC: '',
    cardOwner: '',
    errorCardOwner: '',
    fiatAmount: 0,
    currencyType: 'USD',
    cacheCardCustomerId: '',
    cacheCardNumber: '',
    cacheCardPaymentId: '',
    useCacheCard: false,
    exchangeFee: 0,
    onRampSideFee: 0,
  })

  const [loading, setLoading] = useState(false);
  const [isCardCVCComplete, setIsCardCVCComplete] = useState(false);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCardExpirationComplete, setIsCardExpirationComplete] = useState(false);

  const updateStatus = (params: Partial<ModalInterface>) => setStatus({ ...status, ...params })
  const { balances, token, lang, call, update } = useStore();
  const theme = useContext(ThemeContext);

  const tokenBalance = useMemo(() => {
    return balances.find((token) => token.symbol.toUpperCase() === status.token?.toUpperCase())?.balance || 0
  }, [balances, status.token])

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: feeInfo, loading: feeLoading, error: feeError } = useQuery(GET_FEE, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (!feeLoading && !feeError && !!feeInfo?.getFee) {
      updateStatus({
        exchangeFee: feeInfo.getFee.exchangeFee,
        onRampSideFee: feeInfo.getFee.onRampSideFee,
      })
    }
  }, [feeInfo, feeLoading, feeError])

  const buyToken = async () => {
    const errorStatus = {
      errorCardNumber: "",
      errorCardCVC: "",
      errorCardExpiration: "",
      errorCardOwner: "",
      errorCardCountry: "",
    }

    updateStatus(errorStatus);
    if (!isCardNumberComplete) return updateStatus({ ...errorStatus, errorCardNumber: t("action.invalidvalue") })
    if (!isCardExpirationComplete) return updateStatus({ ...errorStatus, errorCardExpiration: t("action.invalidvalue") })
    if (!isCardCVCComplete) return updateStatus({ ...errorStatus, errorCardCVC: t("action.invalidvalue") })
    if (!status.cardOwner) return updateStatus({ ...errorStatus, errorCardOwner: t("action.invalidowner") })
    updateStatus({ step: 4 })
  }

  useEffect(() => {
    updateStatus({ step: 1 })
  }, [])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const errorStatus = {
        errorCardCVC: "",
        errorCardNumber: "",
        errorCardExpiration: "",
        errorCardOwner: "",
        errorCardCountry: "",
      }

      if (!isCardCVCComplete) return updateStatus({ ...errorStatus, errorCardCVC: t("action.invalidvalue") })

      let paymentMethod, paymentMethodId;
      if (status.useCacheCard) {
        paymentMethodId = status.cacheCardPaymentId;
      } else {
        paymentMethod = await stripe.createPaymentMethod({ type: "card", card: elements.getElement(CardNumberElement) });
        paymentMethodId = paymentMethod.paymentMethod.id;
      }


      update({ loading: true })

      const res = await call("/api/payment/session-initiate", {
        email: status.cardOwner,
        buyAmount: status.amount,
        currency: 'JPY',
        paymentMethodId: paymentMethodId,
        acceptedToken: status.token,
        customerId: status.cacheCardCustomerId
      }, { authorization: token })

      let result;
      const secret = res['client_secret']
      if (status.useCacheCard) {
        result = await stripe.confirmCardPayment(secret, {
          payment_method: paymentMethodId,
          payment_method_options: {
            card: {
              cvc: elements.getElement(CardCvcElement)
            }
          }
        })
      } else {
        result = await stripe.confirmCardPayment(secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: status.cardOwner,
            },
          }
        })
      }

      if (result.error) {
        tips("error", t("action.paymenterror"))
        update({ loading: false })
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          tips("success", t("action.payment.success"))
          onClose()
          update({ loading: false })
        }
      }
      updateStatus({ step: 1, cardOwner: '' })
    } catch (err) {
      tips("error", t("action.invalidvalue"))
      console.log(err)
      update({ loading: false })
    }
  };

  const showCard = async () => {
    try {
      updateStatus({ errorAmount: "" });

      let maxAmount = 0.01;
      if (status.token == "MATIC") maxAmount = config.Max_ETH_Buy_Amount;
      if (status.token == "USDT") maxAmount = config.Max_USDT_Buy_Amount;
      if (Number(status.amount) < 0.001 || Number(status.amount) > maxAmount) {
        return updateStatus({
          errorAmount: "Min: 0.001,  Max: " + maxAmount
        })
      }

      const res = await call("/api/payment/get-cache-card", {}, { authorization: token })
      const card = res['card']?.[0] as CreditCard;

      if (card) { // show cache card
        updateStatus({
          step: 3,
          cacheCardCustomerId: card.customerid,
          cacheCardPaymentId: card.paymentmethodid,
          cacheCardNumber: card.last4,
          useCacheCard: true
        })
      } else { //input new card info
        updateStatus({ step: 2, useCacheCard: false })
      }
    } catch (err) {
      console.log(err)
      update({ loading: false })
      tips("error", "Please check login status and try again.")
    }
  }

  const rampPay = async () => {
    try {
      setLoading(true)
      updateStatus({ errorAmount: "" })

      const result: any = await call("/api/cryptoramp/payment", {
        buyAmount: status.amount,
        currency: 'USD',
        cryptocurrency: status.token,
      }, { authorization: token })

      if (result?.state === "error") { // error
        throw new Error("get price error");
      }

      if (result?.state === "failure") { // invalid buy amount
        setLoading(false)
        return updateStatus({
          errorAmount: `Min: ${result?.minAmount},  Max: ` + result?.maxAmount
        })
      }

      if (result.state === "success") {
        window.location.replace(result.redirectUrl)
      }
    } catch (err: any) {
      setLoading(false);
      window.location.reload();
      tips("error", t("action.invalidvalue"))
    }
  }

  return (
    <div className='ml-auto mr-auto mt3' style={{ maxWidth: '450px' }} >
      <div style={{ display: status.step === 1 ? 'block' : 'none' }}>
        <h1 style={{ textAlign: 'center' }}>{t("buymodal.title")}</h1>

        <InputField required type="number"
          label={t("buymodal.amount")}
          errorLabel={status.errorAmount}
          name='amount' min={0.0001} value={status.amount}
          onChange={(e) => { updateStatus({ amount: e.target.value }) }}
          child={
            <div style={{ width: '80px', marginRight: '12px' }}>
              <Dropdown hideImg
                selectedKey={status.token}
                values={status.paylableTokens}
                onChange={(key) => { updateStatus({ token: key }) }}
                props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
              />
            </div>
          }
        />

        <BuyTokenBalancesContainer>
          <div className="flex center">
            <span className='text-center'>
              {`${t("buymodal.balance")} ${Number(tokenBalance).toFixed(4)} ${status.token}`}
            </span>
          </div>

          <div className="flex center">
            <span className='text-center'>
              {`${t("buymodal.mechaikefee")} ${status.exchangeFee}%`}
            </span>
          </div>

          <div className="flex center">
            <span className='text-center'>
              {`${t("buymodal.onrampfee")} ${status.onRampSideFee}%`}
            </span>
          </div>
        </BuyTokenBalancesContainer>

        <div className="flex center mt3">
          <ButtonPrimary onClick={rampPay}
            style={{ color: theme.text, width: '280px' }}
          >
            {t("buymodal.buy")}
          </ButtonPrimary>
        </div>

        <div className="flex center mt2">
          <ButtonPrimary onClick={() => { onClose() }}
            style={{ width: '280px', backgroundColor: theme.boxColor }}
          >
            {t("buymodal.cancel")}
          </ButtonPrimary>
        </div>
      </div>

      {!status.useCacheCard && (
        <div style={{ display: status.step === 2 ? 'block' : 'none' }}>
          <h1 style={{ textAlign: 'center' }}>
            {t("buymodal.title2")}
          </h1>

          <fieldset className="InputField" style={{ padding: '0 8px 8px' }}>
            <legend>{t("buymodal.cardnumber")}  <span style={{ color: isCardNumberComplete ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span></legend>
            <div className="flex wrap center middle">
              <CardNumberElement onChange={(event) => { setIsCardNumberComplete(event.complete) }}
                options={{
                  style: {
                    base: {
                      color: theme.text,
                      fontSize: '1.2rem',
                      fontWeight: '500',
                      iconColor: '#c4f0ff',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#fce883',
                      },
                    }
                  }
                }}
              />
            </div>
          </fieldset>

          {(status.errorCardNumber && status.errorCardNumber != "") && (
            <p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>
              {status.errorCardNumber}
            </p>
          )}

          <InputField required type="text" label={t("buymodal.name")}
            errorLabel={status.errorCardOwner} value={status.cardOwner}
            onChange={(e) => { updateStatus({ cardOwner: e.target.value }) }}
          />

          <div className="row">
            <div className="col-md-6 col-sm-12 mt2">
              <fieldset className="InputField" style={{ padding: '0 8px 8px' }}>
                <legend>
                  {t("buymodal.expire")}
                  <span style={{ color: isCardExpirationComplete ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>
                    &nbsp;*
                  </span>
                </legend>

                <div className="flex wrap center middle">
                  <CardExpiryElement onChange={(event) => { setIsCardExpirationComplete(event.complete) }}
                    options={{
                      style: {
                        base: {
                          color: theme.text,
                          fontSize: '1.2rem',
                          fontWeight: '500',
                          iconColor: '#c4f0ff',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#fce883',
                          },
                        }
                      }
                    }}
                  />
                </div>
              </fieldset>

              {(status.errorCardExpiration && status.errorCardExpiration != "") && (
                <p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>
                  {status.errorCardExpiration}
                </p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mt2">
              <fieldset className="InputField" style={{ padding: '0 8px 8px' }}>
                <legend>
                  {t("buymodal.cvc")}
                  <span style={{ color: isCardCVCComplete ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>
                </legend>

                <div className="flex wrap center middle">
                  <CardCvcElement onChange={(event) => { setIsCardCVCComplete(event.complete) }}
                    options={{
                      style: {
                        base: {
                          color: theme.text,
                          fontSize: '1.2rem',
                          fontWeight: '500',
                          iconColor: '#c4f0ff',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#fce883',
                          },
                        }
                      }
                    }}
                  />
                </div>
              </fieldset>

              {(status.errorCardCVC && status.errorCardCVC != "") && (
                <p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>
                  {status.errorCardCVC}
                </p>
              )}
            </div>
          </div>

          {/* <div className="row">
							<div className="col-md-6 col-sm-12">
							<InputField label={t("buymodal.country")} errorLabel = {status.errorCardCountry} 
								child={
								<div style={{width: '100%'}}>
									<Dropdown
									selectedKey={status.cardCountry}
									hideImg
									values={
										[
										{key: "japan", name: "日本"},
										{key: "us", name: "アメリカ"},
										]
									}
									onChange={(key) => {updateStatus({cardCountry: key})}}
									props={{style: {height: '100%', backgroundColor:'rgba(0, 0, 0, 0)'}}}
									/>
								</div>
								}
							/>
							</div>
						</div> */}

          <div className="flex center mt5">
            <ButtonPrimary style={{ width: '300px' }} onClick={() => { buyToken() }}>
              {t("buymodal.pay")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '300px', backgroundColor: theme.boxColor }} onClick={() => { onClose() }}>
              {t("buymodal.cancel")}
            </ButtonPrimary>
          </div>
        </div>
      )}

      {status.useCacheCard && (
        <div style={{ display: status.step === 3 ? 'block' : 'none' }}>
          <h1 style={{ textAlign: 'center' }}>
            {t("buymodal.process")}
          </h1>

          <ButtonPrimary style={{ backgroundColor: '#36C781', borderRadius: '8px', width: '100%', border: '1px solid #eee' }}>
            {"..." + status.cacheCardNumber + t("buymodal.usecard")}
          </ButtonPrimary>

          <fieldset className="InputField" style={{ padding: '0 8px 8px', marginTop: '2rem' }}>
            <legend>
              {t("buymodal.cvc")}
              <span style={{ color: isCardCVCComplete ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>
            </legend>

            <div className="flex wrap center middle">
              <CardCvcElement onChange={(event) => { setIsCardCVCComplete(event.complete) }}
                options={{
                  style: {
                    base: {
                      color: theme.text,
                      fontSize: '1.2rem',
                      fontWeight: '500',
                      iconColor: '#c4f0ff',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#fce883',
                      },
                    }
                  }
                }}
              />
            </div>
          </fieldset>

          {(status.errorCardCVC && status.errorCardCVC != "") && (
            <p style={{ color: '#ff0000', fontSize: '0.8rem', margin: '4px 8px' }}>
              {status.errorCardCVC}
            </p>
          )}

          <div className='text-center cursor mt3' onClick={() => { updateStatus({ useCacheCard: false, step: 2 }) }}>
            New card?
          </div>

          <div className="flex center mt3">
            <ButtonPrimary style={{ width: '300px' }} type='submit' onClick={handleSubmit}>
              {t("buymodal.pay")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '300px', backgroundColor: theme.boxColor }} onClick={() => { onClose() }}>
              {t("buymodal.cancel")}
            </ButtonPrimary>
          </div>
        </div>
      )}

      <div style={{ display: status.step === 4 ? 'block' : 'none' }}>
        <h1 style={{ textAlign: 'center' }}>
          {t("buymodal.process")}
        </h1>

        <h2 className='text-center mt3 mb3'>
          {(Number(status.paylableTokens.find((token, index) => {
            return status.token === token.symbol
          })?.[lang === 'en' ? 'usd' : 'jpy'] || 0) * Number(status.amount)).toFixed(4)}
          {lang === "en" ? "USD" : "JPY"}
        </h2>

        <div className="flex center mt1">
          <ButtonPrimary style={{ width: '300px' }} type='submit' onClick={handleSubmit}>
            {t("buymodal.pay")}
          </ButtonPrimary>
        </div>

        <div className="flex center mt2">
          <ButtonPrimary style={{ width: '300px', backgroundColor: theme.boxColor }} onClick={() => { onClose() }}>
            {t("buymodal.cancel")}
          </ButtonPrimary>
        </div>
      </div>

      {loading && (
        <Loading opacity={0.5} show={loading} />
      )}
    </div>
  )
}

const BuyTokenBalancesContainer = styled('div')`
  gap: 5px;
  display: flex;
  flex-direction: column;

  span {
    opacity: 0.75;
  }
`