import React, { useEffect } from 'react';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { useWallet } from "use-wallet";
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import useStore from '../../useStore';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import NFTItem from '../../components/NftItem';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/Layout/Layout';
import { parseUnit } from '../../blockchain/bigmath';
import { InputField } from '../../components/InputField';
import Pagination from '../../components/Pagination';
import BuyTokenModal from '../../components/BuyToken';
import { ButtonPrimary } from '../../components/components';
import { Now, ellipsis, timeAgo, tips, validateUrl } from '../../util';
import { ZeroAddress, toChecksumAddress } from '../../blockchain/utils';
import { getMarketplaceContract, getNFTContract, getTokenContract } from '../../blockchain/contract';
import { GET_COLLECTION_INFO, GET_NFTS, GET_NFT_ACTIVITY, GET_PAYLABLE_TOKEN, GET_PRICES, GET_FAVORITED, GET_USER_INFO, GET_ADMIN_WALLET, GET_FEE } from '../../graphql/index';
import Addresses from '../../config/addresses.json';

import avatar from '../../assets/img/avatar.png';
import verifiedImg from '../../assets/img/verifiedImg.png';
import collectionImage from '../../assets/img/collection.webp';

import { Page404 } from '../Other/404Page';
import { CustomTooltip } from 'components/Tooltip';
import { CustomDtePicker, getDateValue } from 'components/datePicker';

interface ViewInterface {
  showSellResetModal: boolean
  showImageModal: boolean
  showOwnerModal: boolean
  showTransferModal: boolean
  showExportModal: boolean
  showBidModal: boolean
  showApproveBidModal: boolean
  showSellModal: boolean
  showCancelSellModal: boolean
  showOfferModal: boolean
  showBuyModal: boolean
  transferAddress: string
  errorTransferAddress: string
  exportAddress: string
  exportToken: string
  exportQuantity: number
  errorexportAddress: string
  collectionid: string
  tokenid: string
  prices: Prices[]
  avatar: string
  owner: string
  ownerBio: string
  ownerTwitter: string
  ownerInstagram: string
  paylableTokens: PaylableTokens[]
  ownerWebsite: string
  notfound: boolean
  collectionInfo: Collection | null
  nftInfo: NFTSearchResult | null
  collectionNfts: NFTSearchResult[]
  loading: boolean
  saleType: 'regular' | 'auction' | "nosale" | ""
  saleprice: number
  saleToken: string
  saleQuantity: number
  saleStart: number
  saleEnd: number
  saleStart2: number
  saleEnd2: number
  errorSaleprice: string
  errorSaleprice2: string
  errorSalestart: string
  errorSaleend: string
  errorSalestart2: string
  errorSaleend2: string
  salestatus: string
  errorSalequantity: string
  saleType2: string,
  saleprice2: number
  saleToken2: string
  buyQuantity: number
  buyStep: number
  buyTokenBalance: number
  balances: Balance[]
  showBuyTokenModal: boolean
  offerQuantity: number
  offerTokenType: string
  offerTokenAmount: number
  offerExpiration: number
  errorOfferQuantity: string
  errorOfferTokenType: string
  errorOfferTokenAmount: string
  errorOfferExpiration: string
  bidTokenAmount: number
  bidTokenType: string
  favorited: boolean
  errorBuyQuantity: string
  errorBidAmount: string
  selectedBidder: Bid | null
  selectedBidderImage: string
  activityInfo: Activity[]
  transferFees: any
  servicefee: number
  containerSize: number
  listings: Activity[]
  events: Activity[]
  bidders: Bid[]
  exportFeeUsd: number
  selectedListPage: number
  selectedOfferPage: number
  selectedEventPage: number
  highBidPrice: number
}

const initStatus: ViewInterface = {
  showSellResetModal: false,
  showOwnerModal: false,
  showImageModal: false,
  showTransferModal: false,
  showOfferModal: false,
  showBidModal: false,
  showCancelSellModal: false,
  showApproveBidModal: false,
  showExportModal: false,
  showSellModal: false,
  transferAddress: '',
  errorTransferAddress: '',
  exportAddress: '',
  exportToken: 'MATIC',
  exportQuantity: 1,
  errorexportAddress: '',
  showBuyModal: false,
  collectionid: window.location.pathname.split("/")?.[2]?.toString() || "",
  tokenid: window.location.pathname.split("/")?.[3]?.toString() || "",
  prices: [],
  owner: '',
  ownerBio: '',
  ownerInstagram: '',
  ownerTwitter: '',
  ownerWebsite: '',
  avatar: '',
  notfound: false,
  collectionInfo: null,
  nftInfo: null,
  paylableTokens: [],
  collectionNfts: [],
  loading: true,
  saleType: 'regular',
  saleprice: 0,
  saleToken: 'MATIC',
  saleQuantity: 1,
  saleStart: 0,
  saleEnd: 0,
  saleStart2: 0,
  saleEnd2: 0,
  errorSalestart: '',
  errorSaleend: '',
  errorSalestart2: '',
  errorSaleend2: '',
  errorSaleprice: '',
  errorSaleprice2: '',
  errorSalequantity: '',
  salestatus: '',
  saleType2: 'regular',
  saleprice2: 0,
  saleToken2: 'MATIC',
  buyQuantity: 1,
  buyTokenBalance: 0,
  buyStep: 1,
  balances: [],
  showBuyTokenModal: false,
  offerQuantity: 1,
  offerTokenType: "MATIC",
  offerTokenAmount: 0,
  offerExpiration: 0,
  errorOfferQuantity: '',
  errorOfferTokenType: '',
  errorOfferTokenAmount: '',
  errorOfferExpiration: '',
  bidTokenAmount: 0,
  bidTokenType: '',
  favorited: false,
  errorBuyQuantity: "",
  errorBidAmount: "",
  selectedBidder: null,
  selectedBidderImage: '',
  activityInfo: [],
  transferFees: {},
  servicefee: 0,
  containerSize: 0,
  listings: [],
  events: [],
  bidders: [],
  exportFeeUsd: 0,
  selectedListPage: 1,
  selectedOfferPage: 1,
  selectedEventPage: 1,
  highBidPrice: 0
}

let tempStatus: ViewInterface = initStatus;

const ViewNFT = () => {
  const { t } = useI18n();
  const wallet = useWallet();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const { darkMode, currentAccountAddress, currentAccountMail, token, balances, isMetamask, logined, updated, call, update, lang } = useStore()

  const [status, setStatus] = React.useState<ViewInterface>({
    showSellResetModal: false,
    showOwnerModal: false,
    showImageModal: false,
    showTransferModal: false,
    showOfferModal: false,
    showBidModal: false,
    showCancelSellModal: false,
    showApproveBidModal: false,
    showExportModal: false,
    showSellModal: false,
    transferAddress: '',
    errorTransferAddress: '',
    exportAddress: '',
    exportToken: 'MATIC',
    exportQuantity: 1,
    errorexportAddress: '',
    showBuyModal: false,
    collectionid: window.location.pathname.split("/")?.[2]?.toString() || "",
    tokenid: window.location.pathname.split("/")?.[3]?.toString() || "",
    prices: [],
    owner: '',
    ownerBio: '',
    ownerInstagram: '',
    ownerTwitter: '',
    ownerWebsite: '',
    avatar: '',
    notfound: false,
    collectionInfo: null,
    nftInfo: null,
    paylableTokens: [],
    collectionNfts: [],
    loading: true,
    saleType: 'regular',
    saleprice: 0,
    saleToken: 'MATIC',
    saleQuantity: 1,
    saleStart: 0,
    saleEnd: 0,
    saleStart2: 0,
    saleEnd2: 0,
    errorSalestart: '',
    errorSaleend: '',
    errorSalestart2: '',
    errorSaleend2: '',
    errorSaleprice: '',
    errorSaleprice2: '',
    errorSalequantity: '',
    salestatus: '',
    saleType2: 'regular',
    saleprice2: 0,
    saleToken2: 'MATIC',
    buyQuantity: 1,
    buyTokenBalance: 0,
    buyStep: 1,
    balances: [],
    showBuyTokenModal: false,
    offerQuantity: 1,
    offerTokenType: "MATIC",
    offerTokenAmount: 0,
    offerExpiration: 0,
    errorOfferQuantity: '',
    errorOfferTokenType: '',
    errorOfferTokenAmount: '',
    errorOfferExpiration: '',
    bidTokenAmount: 0,
    bidTokenType: '',
    favorited: false,
    errorBuyQuantity: "",
    errorBidAmount: "",
    selectedBidder: null,
    selectedBidderImage: '',
    activityInfo: [],
    transferFees: {},
    servicefee: 0,
    containerSize: 0,
    listings: [],
    events: [],
    bidders: [],
    exportFeeUsd: 0,
    selectedListPage: 1,
    selectedOfferPage: 1,
    selectedEventPage: 1,
    highBidPrice: 0
  })

  useEffect(() => {
    tempStatus = initStatus;
  }, [])

  const updateStatus = (params: Partial<ViewInterface | any>) => {
    tempStatus = { ...tempStatus, ...params };
    setStatus(tempStatus);
  }

  const swiperPanelRef = React.useRef<HTMLDivElement>(null)
  const [marketAdminAddress, setMarketAdmin] = React.useState("");

  const { data: marketAdminInfo, loading: marketAdminLoading, error: marketAdminError } = useQuery(GET_ADMIN_WALLET, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (marketAdminLoading || marketAdminError) return;
    const data = marketAdminInfo?.getAdminWallet as any;
    if (!data) return;
    setMarketAdmin(data?.addresses['nft'])
  }, [marketAdminInfo, marketAdminLoading, marketAdminError])


  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (priceLoading || priceLoading) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    let fees = {}
    for (let i = 0; i < data.length; i++) {
      fees[data[i].symbol] = Number(status.servicefee / data[i]?.[lang === 'en' ? 'usd' : 'jpy']).toFixed(6);
    }
    updateStatus({ prices: data, transferFees: fees })
  }, [priceInfo, priceLoading, priceError])

  const { data: favoritedInfo, loading: favoritedLoading, error: favoritedError } = useQuery(GET_FAVORITED, {
    variables: {
      collection: status.collectionid,
      nft: status.tokenid,
      userAddress: currentAccountAddress
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (favoritedLoading || favoritedLoading) return;
    const data = favoritedInfo?.getFavoritedNFT as any;
    if (!data) return;
    updateStatus({ favorited: data?.length > 0 ? true : false })
  }, [favoritedInfo, favoritedLoading, favoritedError])

  const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      address: status.collectionid,
    },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (collectionLoading || collectionError) return;
    const data = collectionInfo?.getCollectionInfo?.[0] as Collection;
    if (!data || (data?.hide && currentAccountAddress !== data?.owner)) return updateStatus({ notfound: true });
    updateStatus({
      collectionInfo: data
    })
  }, [collectionInfo, collectionLoading, collectionError])

  let { data: nftInfo, loading: nftLoading, error: nftError } = useQuery(GET_NFTS, {
    variables: {
      nftcollection: status.collectionid,
      tokenid: status.tokenid,
      limit: 0,
      page: 0,
      price1: 0,
      price2: 0,
      query: "",
      owner: '',
      salestatus: "",
      searchsymbol: "",
      sort: "latest",
      symbols: "",
      fakesalestatus: status.salestatus
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (nftLoading || nftError) return;
    const data = nftInfo?.getNFTs?.[0] as NFTSearchResult;
    if (!data) return updateStatus({ notfound: true });

    let updateData = {
      nftInfo: data,
      saleprice2: data.price,
      saleToken2: data.acceptedToken,
      saleStart2: data?.startTime * 1000 || 0,
      saleEnd2: data?.expiredTime * 1000 || 0,
      notfound: data.owner !== currentAccountAddress && data?.hide,
      buyStep: (data?.multiple === true) ? 1 : 2,
      buyTokenBalance: Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0),
      bidders: data.bidders || [],
      highBidPrice: data.bidders?.sort((a, b) => { return Number(b.created) - Number(a.created) })?.[0]?.price || status.nftInfo?.price
    }
    if (status.showSellModal) {
      updateData['saleType2'] = data?.saleType;
    }
    updateStatus(updateData)
  }, [nftInfo, nftLoading, nftError])

  //get nfts of collection
  let { data: collectionNftInfo, loading: collectionNftLoading, error: collectionNftError } = useQuery(GET_NFTS, {
    variables: {
      nftcollection: status.collectionid,
      tokenid: '',
      limit: 0,
      page: 0,
      price1: 0,
      price2: 0,
      query: "",
      owner: '',
      salestatus: "",
      searchsymbol: "",
      sort: "latest",
      symbols: "",
      fakesalestatus: status.salestatus
    },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (collectionNftLoading || collectionNftError) return;
    const data = collectionNftInfo?.getNFTs || [] as NFTSearchResult[];
    updateStatus({ collectionNfts: data })
  }, [collectionNftInfo, collectionNftLoading, collectionNftError])

  let { data: activityInfo, loading: activityLoading, error: activityError } = useQuery(GET_NFT_ACTIVITY, {
    variables: {
      nftcollection: status.collectionid,
      nftid: status.tokenid,
      type: "Created,Export,Listed,Buy,Sell,Transfer"
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (activityLoading || activityError) return;
    if (activityInfo) {
      let data: Activity[] = activityInfo?.getNFTActivity || [];
      data = data.sort((a, b) => { return Number(b.created) - Number(a.created) })
      let listings = [] as Activity[], events = [] as Activity[];
      data?.forEach((act) => {
        if (act.type === "Listed") {
          listings.push(act)
        } else if (act.type !== "Transfer") {
          events.push(act)
        }
      })
      updateStatus({ activityInfo: data, listings: listings, events: events })
    }
  }, [activityInfo, activityLoading, activityError])

  const { data: creatorInfo, loading: creatorLoading, error: creatorError } = useQuery(GET_USER_INFO, {
    variables: {
      address: status.nftInfo?.owner || currentAccountAddress
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (creatorLoading || creatorError) return;
    const creator = creatorInfo?.getUsersInfo?.[0];
    if (!creator) return;
    updateStatus({
      owner: creator?.name || ellipsis(status.nftInfo?.owner || "", 6, false),
      avatar: creator?.avatar_img,
      ownerBio: creator?.bio,
      ownerTwitter: creator?.twitter,
      ownerInstagram: creator?.instagram,
      ownerWebsite: creator?.link
    })
  }, [creatorInfo, creatorLoading, creatorError])


  const { data: feeInfo, loading: feeLoading, error: feeError } = useQuery(GET_FEE, {
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    if (feeLoading || feeError) return;
    const data = feeInfo?.getFee;
    if (!data) return;
    updateStatus({
      servicefee: data["tradeFee"]
    })
  }, [feeInfo, feeLoading, feeError])

  const { data: bidderInfo, loading: bidderLoading, error: bidderError } = useQuery(GET_USER_INFO, {
    variables: {
      address: status.selectedBidder?.bidder || ""
    },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => {
    if (bidderLoading || bidderError) return;
    const bidder = bidderInfo?.getUsersInfo?.[0];
    if (!bidder) return;
    if (status.selectedBidder?.bidder) {
      updateStatus({
        selectedBidderImage: bidder?.avatar_img
      })
    }
  }, [bidderInfo, bidderLoading, bidderError])

  React.useEffect(() => {
    const collectionid = location.pathname.split("/")?.[2]?.toString()
    const tokenId = location.pathname.split("/")?.[3]?.toString()
    if (!collectionid || !tokenId) return navigate("/search-nft")
    updateStatus({ collectionid: collectionid || '', tokenid: tokenId })
  }, [location]);

  const openOfferModal = () => {
    if (!logined) {
      tips("info", t("action.needlogin"))
      setTimeout(() => {
        update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
        navigate("/login")
      }, 800);
      return;
    }

    updateStatus({
      showOfferModal: true,
      offerExpiration: 0,
      offerTokenAmount: 0,
      offerTokenType: status.nftInfo?.acceptedToken,
      offerQuantity: 1,
      errorOfferExpiration: "",
      errorOfferQuantity: "",
      errorOfferTokenAmount: "",
      errorOfferTokenType: ""
    })
  }

  const openBidModal = () => {
    if (!logined) {
      tips("info", t("action.needlogin"))
      setTimeout(() => {
        update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
        navigate("/login")
      }, 800);
      return;
    }
    updateStatus({
      showBidModal: true,
      bidTokenAmount: 0,
      bidTokenType: status.nftInfo?.acceptedToken
    })
  }

  const setFavorite = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }
      const collection = status.collectionInfo?.address;
      const nft = status.nftInfo?.tokenid;
      const creator = currentAccountAddress;
      const result = await call("/api/nft/favorite", {
        collectionid: collection,
        nftid: nft,
        mail: currentAccountMail,
        creator: creator
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "success": {
            if (status.favorited) {
              tips("success", t("action.nft.removedfavlist"));
              updateStatus({ favorited: !status.favorited, nftInfo: { ...status.nftInfo, favs: (status.nftInfo?.favs || 0) - 1 } })
            } else {
              tips("success", t("action.nft.addedfavlist"));
              updateStatus({ favorited: !status.favorited, nftInfo: { ...status.nftInfo, favs: (status.nftInfo?.favs || 0) + 1 } })
            }
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
      }
    } catch (ex) {
      tips("error", t("action.unknownerror"))
      console.log(ex.message)
    }
  }

  const listNFT = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))

        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);

        return;
      }

      updateStatus({
        errorSaleend: '',
        errorSaleprice: '',
        errorSalequantity: '',
        errorSalestart: ''
      })

      const saleType = status.saleType;
      const saleToken = status.saleToken;
      const salePrice = Number(status.saleprice) || 0;
      const saleQuantity = Number(status.saleQuantity) || 0;
      const saleTokenAddress = status.paylableTokens?.find((token) => token.symbol === saleToken).address || ZeroAddress;
      const saleTokenDecimals = status.paylableTokens?.find((token) => token.symbol === saleToken).decimals || 18;


      const saleStart = Math.round((+new Date(status.saleStart)) / 1000) || 0;
      const saleEnd = Math.round((+new Date(status.saleEnd)) / 1000) || 0;
      const now = Now()

      if (salePrice <= 0) return updateStatus({ errorSaleprice: t("action.nft.errorprice") })
      if (saleQuantity < 1) return updateStatus({ errorSalequantity: t("action.nft.errorquantity") })

      if (saleType === "auction") {
        // if(saleStart <= 0) return updateStatus({errorSalestart: t("action.nft.errordate")})
        if (saleEnd < now) return updateStatus({ errorSaleend: t("action.nft.errordate") })
        if (saleStart > saleEnd) return updateStatus({ errorSaleend: t("action.nft.errEndDate") })
      }

      const collection = toChecksumAddress(status.collectionInfo?.address);
      const nft = status.nftInfo.tokenid;
      update({ loading: true })
      if (isMetamask) {
        try {
          const provider = new ethers.providers.Web3Provider(wallet.ethereum)
          const signer = await provider.getSigner()
          const ca = getNFTContract(collection, signer)
          try {
            const owner = await ca.ownerOf(nft);
            //in user wallet
            if (owner.toUpperCase() === currentAccountAddress.toUpperCase()) {
              try {
                const tx = await ca.approve(Addresses.market, nft)
                await tx.wait()
              } catch (err) {
                return update({ loading: false });
              }
              const ca2 = getMarketplaceContract(Addresses.market, signer)
              const price = parseUnit(salePrice, saleTokenDecimals)
              try {
                if (saleType === "regular") {
                  let tx2 = await ca2.createSell(toChecksumAddress(collection), nft, saleTokenAddress, price, saleStart, saleEnd)
                  await tx2.wait()
                }
                else if (saleType === "auction") {
                  let tx2 = await ca2.createAuction(toChecksumAddress(collection), nft, saleTokenAddress, price, saleStart, saleEnd)
                  await tx2.wait()
                }
              } catch (err) {
                update({ loading: false })
                console.log(err.message);
                return;
              }
              setTimeout(() => {
                const newEvent = {
                  nftCollection: collection,
                  id: nft,
                  owner: currentAccountAddress,
                  type: "Listed",
                  params: [
                    {
                      type: "acceptedToken",
                      value: saleToken
                    },
                    {
                      type: "price",
                      value: salePrice
                    },
                    {
                      type: "from",
                      value: currentAccountAddress
                    },
                    {
                      type: "to",
                      value: ""
                    },
                    {
                      type: "created",
                      value: Now()
                    }
                  ],
                  created: now
                };
                updateStatus({
                  showSellModal: false,
                  nftInfo: { saleType: status.saleType, price: status.saleprice, acceptedToken: saleToken },
                  activityInfo: [
                    newEvent,
                    ...status.activityInfo,
                  ],
                  listings: [
                    newEvent,
                    ...status.listings
                  ],
                  salestatus: status.saleType
                })
                tips("success", t("action.nft.listed"));
                update({ loading: false });
              }, 3000);
            }
            // in marketplace contract
            else if (owner.toUpperCase() === Addresses.market.toUpperCase()) {
              const result = await call("/api/nft/list", {
                collectionid: collection,
                nftid: nft,
                price: salePrice,
                acceptedToken: saleToken,
                startTime: saleStart,
                endTime: saleEnd,
                quantity: saleQuantity,
                type: saleType,
                creator: currentAccountAddress
              },
                { 'authorization': token }
              )
              if (result) {
                switch (result['message']) {
                  case "success": {
                    tips("success", t("action.nft.listed"));
                    setTimeout(() => {
                      const newEvent = {
                        nftCollection: collection,
                        id: nft,
                        owner: currentAccountAddress,
                        type: "Listed",
                        params: [
                          {
                            type: "acceptedToken",
                            value: saleToken
                          },
                          {
                            type: "price",
                            value: salePrice
                          },
                          {
                            type: "from",
                            value: currentAccountAddress
                          },
                          {
                            type: "to",
                            value: ""
                          },
                          {
                            type: "created",
                            value: Now()
                          }
                        ],
                        created: now
                      };
                      updateStatus({
                        showSellModal: false,
                        nftInfo: { ...status.nftInfo, saleType: status.saleType, price: status.saleprice, acceptedToken: saleToken, image: status.nftInfo?.image },
                        salestatus: status.saleType,
                        activityInfo: [
                          newEvent,
                          ...status.activityInfo,
                        ],
                        listing: [
                          newEvent,
                          ...status.listings
                        ]
                      });
                      update({ loading: false });
                    }, 2500);
                    break;
                  }
                  case "internal error": tips("error", t('action.servererror')); update({ loading: false }); break;
                }
              }
            }
            else {
              updateStatus({ showSellModal: false })
              update({ loading: false })
              return tips("error", t("action.nft.notowner"))
            }
          } catch (err2) {
            //not minted
            const result = await call("/api/nft/list", {
              collectionid: collection,
              nftid: nft,
              price: salePrice,
              acceptedToken: saleToken,
              startTime: saleStart,
              endTime: saleEnd,
              quantity: saleQuantity,
              type: saleType,
              creator: currentAccountAddress
            },
              { 'authorization': token }
            )
            if (result) {
              switch (result['message']) {
                case "success": {
                  setTimeout(() => {
                    const newEvent = {
                      nftCollection: collection,
                      id: nft,
                      owner: currentAccountAddress,
                      type: "Listed",
                      params: [
                        {
                          type: "acceptedToken",
                          value: saleToken
                        },
                        {
                          type: "price",
                          value: salePrice
                        },
                        {
                          type: "from",
                          value: currentAccountAddress
                        },
                        {
                          type: "to",
                          value: ""
                        },
                        {
                          type: "created",
                          value: Now()
                        }
                      ],
                      created: now
                    };
                    updateStatus({
                      showSellModal: false,
                      nftInfo: { ...status.nftInfo, saleType: status.saleType, price: status.saleprice, acceptedToken: saleToken },
                      salestatus: status.saleType,
                      activityInfo: [
                        newEvent,
                        ...status.activityInfo,
                      ],
                      listings: [
                        newEvent,
                        ...status.listings
                      ]
                    })
                    update({ loading: false })
                  }, 2500);
                  break;
                }
                case "internal error": tips("error", t("action.servererror")); update({ loading: false }); break;
              }
            }
          }
        } catch (err) {
          console.log(err.message)
          update({ loading: false })
          tips("error", t("action.unknownerror"));
          return;
        }
      } else {
        const result = await call("/api/nft/list", {
          collectionid: collection,
          nftid: nft,
          price: salePrice,
          acceptedToken: saleToken,
          startTime: saleStart,
          endTime: saleEnd,
          quantity: saleQuantity,
          type: saleType,
          creator: currentAccountAddress
        },
          { 'authorization': token }
        )
        if (result) {
          switch (result['message']) {
            case "success": {
              tips("success", t("action.nft.listed"));
              setTimeout(() => {
                const newEvent = {
                  nftCollection: collection,
                  id: nft,
                  owner: currentAccountAddress,
                  type: "Listed",
                  params: [
                    {
                      type: "acceptedToken",
                      value: saleToken
                    },
                    {
                      type: "price",
                      value: salePrice
                    },
                    {
                      type: "from",
                      value: currentAccountAddress
                    },
                    {
                      type: "to",
                      value: ""
                    },
                    {
                      type: "created",
                      value: Now()
                    }
                  ],
                  created: now
                };
                updateStatus({
                  showSellModal: false,
                  nftInfo: {
                    ...status.nftInfo, saleType: status.saleType, price: salePrice, acceptedToken: saleToken,
                    image: status.nftInfo?.image
                  },
                  salestatus: status.saleType,
                  activityInfo: [
                    newEvent,
                    ...status.activityInfo,
                  ],
                  listings: [
                    newEvent,
                    ...status.listings
                  ]
                })
                update({ loading: false })
              }, 2500);
              break;
            }
            case "internal error": {
              tips("error", t("action.unknownerror"));
              update({ loading: false })
              break;
            }
          }
        }
      }
    } catch (ex) {
      console.log(ex.message)
      update({ loading: false })
      return tips("error", t("action.unknownerror"))
    }
  }

  const estimateExportFee = async () => {
    try {
      update({ loading: true })
      const result = await call("/api/nft/get-export-fee", {
        collectionid: status.collectionid,
        nftid: status.tokenid
      }, {
        'authorization': token
      })

      if (result) {
        console.log(result)
        switch (result['message']) {
          case "success": {
            updateStatus({ exportFeeUsd: result['usd'] || 0, showExportModal: true });
          }
        }
      }
    } catch (err) {
      console.log(err.message);
      return tips("error", t("fee estimate error"))
    }

    update({ loading: false })
  }

  const changeSell = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }

      updateStatus({ errorSaleprice2: '' })
      const saleType = status.saleType2 as "regular" | "auction";
      const collection = status.collectionInfo?.address;
      const nft = status.nftInfo.tokenid;
      const saleStart = Math.round((+new Date(status.saleStart2)) / 1000) || 0;
      const saleEnd = Math.round((+new Date(status.saleEnd2)) / 1000) || 0;
      const now = Now()
      const saleToken = status.saleToken2;
      const salePrice = Number(status.saleprice2) || 0;

      if (saleType === "auction") {
        // if(saleStart <= 0) return updateStatus({errorSalestart2: t("action.nft.errordate")})
        if (saleEnd < now || saleEnd <= 0) return updateStatus({ errorSaleend2: t("action.nft.errordate") })
        if (saleStart > saleEnd) return updateStatus({ errorSaleend2: t("action.nft.errEndDate") })
      }

      update({ loading: true })
      const result = await call("/api/nft/list-reset", {
        collectionid: collection,
        nftid: nft,
        price: salePrice,
        acceptedToken: saleToken,
        type: saleType,
        creator: currentAccountAddress,
        saleStart: saleStart,
        saleEnd: saleEnd
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "success": {
            setTimeout(() => {
              const newEvent = {
                nftCollection: collection,
                id: nft,
                owner: currentAccountAddress,
                type: "Listed",
                params: [
                  {
                    type: "acceptedToken",
                    value: saleToken
                  },
                  {
                    type: "price",
                    value: salePrice
                  },
                  {
                    type: "from",
                    value: currentAccountAddress
                  },
                  {
                    type: "to",
                    value: ""
                  },
                  {
                    type: "created",
                    value: Now()
                  }
                ],
                created: now
              };

              updateStatus({
                showSellResetModal: false,
                nftInfo: {
                  ...status.nftInfo, saleType: saleType, price: salePrice, acceptedToken: saleToken, startTime: saleStart, endTime: saleEnd, image: status.nftInfo?.image
                },
                salestatus: saleType,
                activityInfo: [
                  newEvent,
                  ...status.activityInfo,
                ],
                listings: [
                  newEvent,
                  ...status.listings
                ]
              })
            }, 800);
            tips("success", t("action.nft.listed"));
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      console.log(ex.message)
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  const cancelList = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }
      update({ loading: true })
      const collection = status.collectionInfo.address;
      const nft = status.nftInfo.tokenid;
      const result = await call("/api/nft/cancel-list", {
        collectionid: collection,
        nftid: nft,
        isMetamask: isMetamask
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "success": {
            const deadline = result['deadline'];
            const signature = result['signature'];
            if (deadline && signature) {
              const provider = new ethers.providers.Web3Provider(wallet.ethereum)
              const signer = await provider.getSigner();
              const ca = getMarketplaceContract(Addresses.market, signer);
              let tx2 = await ca.cancelSell(toChecksumAddress(collection), nft, currentAccountAddress, marketAdminAddress, deadline, signature)
              await tx2.wait()
              tips("success", t("action.nft.canceledsell"));
              update({ loading: false })
              setTimeout(() => {
                updateStatus({
                  showCancelSellModal: false,
                  nftInfo: {
                    ...status.nftInfo, saleType: "", price: 0, acceptedToken: "", startTime: 0, endTime: 0, image: status.nftInfo?.image
                  },
                  salestatus: ""
                })
              }, 1000);
            }
            else {
              tips("success", t("action.nft.canceledsell"));
              setTimeout(() => {
                updateStatus({
                  showCancelSellModal: false,
                  nftInfo: {
                    ...status.nftInfo, saleType: "", price: 0, acceptedToken: "", startTime: 0, endTime: 0, image: status.nftInfo?.image
                  },
                  salestatus: ""
                })
              }, 1000);
              update({ loading: false })
            }
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
          case "not nft owner": tips("error", t("action.nft.notowner")); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      console.log(ex.message)
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  const transferNFT = async () => {
    try {
      if (!logined) return tips("info", t("action.needlogin"))
      updateStatus({ errorTransferAddress: "" });
      const collection = status.collectionInfo.address;
      const nft = status.nftInfo.tokenid;
      if (!ethers.utils.isAddress(status.transferAddress)) return updateStatus({ errorTransferAddress: t("action.nft.erroraddress") })
      if (status.transferAddress.toUpperCase() === currentAccountAddress.toUpperCase()) return updateStatus({ errorTransferAddress: t("action.nft.erroraddress") })
      update({ loading: true })
      if (isMetamask) {
        const provider = new ethers.providers.Web3Provider(wallet.ethereum)
        const signer = await provider.getSigner()
        const ca = getNFTContract(collection, signer)
        const owner = await ca.ownerOf(nft);
        if (owner.toUpperCase() === currentAccountAddress.toUpperCase()) {
          const toIsMetamask = await call("/api/check-metamask", { address: toChecksumAddress(status.transferAddress) });
          if (toIsMetamask) {
            const tx = await ca.transferFrom(currentAccountAddress, toChecksumAddress(status.transferAddress), nft);
            await tx.wait();
            updateStatus({
              showTransferModal: false, errorTransferAddress: "", salestatus: status?.nftInfo?.saleType, nftInfo: {
                ...status.nftInfo, owner: status.transferAddress
              },
            });
            tips("success", t("action.nft.transfered"));
            return update({ loading: false });
          }
          else {
            console.log("transferTointernal")
            const tx = await ca.approve(toChecksumAddress(Addresses.market), nft);
            await tx.wait();
            const marketCa = getMarketplaceContract(Addresses.market, signer);
            const transferTx = await marketCa.transferNFT(toChecksumAddress(collection), nft, status.transferAddress)
            await transferTx.wait();
            updateStatus({
              showTransferModal: false, errorTransferAddress: "", salestatus: status?.nftInfo?.saleType, nftInfo: {
                ...status.nftInfo, owner: status.transferAddress
              }
            });
            tips("success", t("action.nft.transfered"));
            return update({ loading: false });
          }
        }
        update({ loading: false })
      }
      const result = await call("/api/nft/transfer", {
        collectionid: collection,
        nftid: nft,
        to: status.transferAddress
      },
        { 'authorization': token }
      );
      if (result) {
        switch (result['message']) {
          case "not nft owner": tips("error", t("action.nft.notowner")); break;
          case "not exists to address": tips("warning", t("action.nft.notexistaddress")); break;
          case "success": {
            tips("success", t("action.nft.transfered"));
            updateStatus({
              showTransferModal: false, errorTransferAddress: "", salestatus: status?.nftInfo?.saleType, nftInfo: {
                ...status.nftInfo, owner: status.transferAddress
              }
            })
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      console.log(ex.message)
      update({ loading: false })
      return tips("error", t("action.unknownerror"))
    }
  }

  const exportNFT = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"));

        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);

        return;
      }

      const nft = status.nftInfo?.tokenid;
      const collection = status.collectionInfo?.address;
      updateStatus({ errorexportAddress: "" });

      if (!ethers.utils.isAddress(status.exportAddress)) {
        return updateStatus({ errorexportAddress: t("action.nft.erroraddress") })
      } else if (status.exportAddress.toUpperCase() === currentAccountAddress.toUpperCase()) {
        return updateStatus({ errorexportAddress: t("action.nft.erroraddress") })
      } else if (status.nftInfo?.saleType !== "") {
        return tips("error", t("action.nft.inselling"))
      }

      update({ loading: true });

      if (isMetamask) {
        const provider = new ethers.providers.Web3Provider(wallet.ethereum);
        const signer = await provider.getSigner();

        const ca = getNFTContract(collection, signer);
        const owner = await ca.ownerOf(nft);

        if (owner.toUpperCase() === currentAccountAddress.toUpperCase()) {
          const toIsMetamask = await call("/api/check-metamask", {
            address: toChecksumAddress(status.exportAddress)
          })

          if (toIsMetamask) {
            const tx = await ca.transferFrom(currentAccountAddress, toChecksumAddress(status.exportAddress), nft);
            await tx.wait();

            updateStatus({
              salestatus: status?.nftInfo?.saleType,
              showExportModal: false, errorexportAddress: "",
              nftInfo: { ...status.nftInfo, owner: status.exportAddress },
            })

            tips("success", t("action.nft.exported"));
            return update({ loading: false });
          } else {
            const tx = await ca.transferFrom(currentAccountAddress, toChecksumAddress(Addresses.market), nft);
            await tx.wait();
          }
        }
      }

      const result = await call("/api/nft/export", {
        nftid: nft,
        collectionid: collection,
        to: status.exportAddress,
        feeToken: status.exportToken,
      }, { 'authorization': token })

      if (result) {
        switch (result['message']) {
          case "not nft owner":
            tips("error", t("action.nft.notowner"));
            break;

          case "out of fee":
            tips("warning", t("action.nft.gasfee"));
            break;

          case "success": {
            updateStatus({
              salestatus: status?.nftInfo?.saleType,
              showExportModal: false, errorexportAddress: "",
              nftInfo: { ...status.nftInfo, owner: status.exportAddress }
            })

            tips("success", t("action.nft.exported"));
            break;
          }

          case "internal error":
            tips("error", t("action.servererror"));
            break;
        }
      }

      update({ loading: false })
    } catch (ex) {
      console.log(ex.message)
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  const offerNFT = async () => {
    const collection = status.collectionInfo.address;
    const nft = status.nftInfo.tokenid;
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }
      updateStatus({ errorOfferQuantity: "", errorOfferExpiration: "", errorOfferTokenAmount: "", errorOfferTokenType: "" })
      if (Number(status.offerQuantity) < 1) return updateStatus({ errorOfferQuantity: t("action.nft.errorquantity"), errorOfferExpiration: "", errorOfferTokenAmount: "", errorOfferTokenType: "" })
      if (Number(status.offerTokenAmount) <= 0) return updateStatus({ errorOfferQuantity: "", errorOfferExpiration: "", errorOfferTokenAmount: t("action.nft.errorquantity"), errorOfferTokenType: "" })
      if (Number(status.offerTokenAmount) > Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.offerTokenType?.toUpperCase() })?.balance || 0)) return updateStatus({ errorOfferQuantity: "", errorOfferExpiration: "", errorOfferTokenAmount: t("action.balanceerror"), errorOfferTokenType: "" })
      const expiration = +new Date(status.offerExpiration) / 1000;
      // if(!status.offerExpiration || expiration < Now()) return updateStatus({errorOfferQuantity: "", errorOfferExpiration: t("action.required"), errorOfferTokenAmount: "", errorOfferTokenType: ""})

      const _tokenAddress = status.paylableTokens.find((token) => token.symbol === status.offerTokenType).address || ZeroAddress;
      const _tokenDecimals = status.paylableTokens.find((token) => token.symbol === status.offerTokenType).decimals || 18;
      const price = parseUnit(status.offerTokenAmount, _tokenDecimals) || 0;

      update({ loading: true })
      const result = await call("/api/nft/make-offer", {
        collectionid: collection,
        nftid: nft,
        price: status.offerTokenAmount,
        acceptedToken: status.offerTokenType,
        expiration: expiration,
        quantity: status.offerQuantity
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "not exists user": tips("warning", t("action.nft.notexistuser")); break;
          case "exceed balance": tips("warning", t("action.balanceerror")); break;
          case "success": {
            const deadline = result['deadline'];
            const signature = result['signature'];
            const orderId = result['orderId'];
            if (deadline && signature && orderId) {
              console.log(wallet.ethereum)
              const provider = new ethers.providers.Web3Provider(wallet.ethereum)
              const signer = await provider.getSigner();
              const ca = getMarketplaceContract(Addresses.market, signer);
              if (_tokenAddress === ZeroAddress) {
                const tx = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature, { value: price })
                await tx.wait()
              }
              else {
                const tokenCa = getTokenContract(_tokenAddress, signer)
                const tx = await tokenCa.approve(Addresses.market, price)
                await tx.wait()
                const tx2 = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature)
                await tx2.wait()
              }
              setTimeout(() => {
                let newBid = status.bidders as Bid[];
                newBid.push(
                  {
                    bidder: currentAccountAddress,
                    price: status.offerTokenAmount,
                    acceptedToken: status.offerTokenType,
                    quantity: 1,
                    expiredTime: expiration,
                    created: Now()
                  }
                )
                update({ loading: false, updated: updated + 1 })
                updateStatus({ showOfferModal: false, salestatus: "", nftInfo: { ...status.nftInfo, bidders: newBid }, bidders: newBid })
                tips("success", t("action.nft.offersuccess"));
              }, 3000);
            }
            else if (!isMetamask) {
              let newBid = status.bidders as Bid[];
              newBid.push(
                {
                  bidder: currentAccountAddress,
                  price: status.offerTokenAmount,
                  acceptedToken: status.offerTokenType,
                  quantity: 1,
                  expiredTime: expiration,
                  created: Now()
                })
              updateStatus({ showOfferModal: false, salestatus: "", nftInfo: { ...status.nftInfo, bidders: newBid }, bidders: newBid })
              tips("success", t("action.nft.offersuccess"));
              break;
            }
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
        update({ loading: false, updated: updated + 1 })
        updateStatus({ showOfferModal: false })
      }
    } catch (ex) {
      cancelPayment(collection, nft);
      console.log(ex.message)
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  const cancelPayment = async (collection: string, nft: string) => {
    try {
      const result = await call("/api/nft/cancel-payment", {
        collectionid: collection,
        nftid: nft
      },
        { 'authorization': token }
      )
      // return result;
    } catch (err) {
      console.log(err.message)
      tips("error", t("action.unknownerror"))
    }
  }

  const bidNFT = async () => {
    const collection = status.collectionInfo.address;
    const nft = status.nftInfo.tokenid;
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }

      updateStatus({ errorBidAmount: "" })
      if (Number(status.bidTokenAmount) <= 0) {
        return updateStatus({ errorBidAmount: t("action.nft.erroramount") })
      }

      const tokenBalance = Number(balances?.find((token) => {
        return token.symbol.toUpperCase() === status.bidTokenType?.toUpperCase()
      })?.balance || 0);

      if (status.bidTokenAmount > tokenBalance) {
        return tips("warning", t("action.balanceerror"));
      }

      if (status.bidTokenAmount < status.highBidPrice) {
        const notifyStr = `${status.highBidPrice} ${status.nftInfo?.acceptedToken}`
        return tips("warning", `${t("nftview.sellmodal.startprice")}: ${notifyStr}`);
      }

      if (status.bidTokenType !== status.nftInfo?.acceptedToken) {
        return tips("warning", `${t("nftview.sellmodal.acceptableToken")}: ${status.nftInfo?.acceptedToken}`);
      }

      update({ loading: true })
      const result = await call("/api/nft/bid", {
        collectionid: collection,
        nftid: nft,
        acceptedToken: status.bidTokenType,
        price: status.bidTokenAmount
      }, { 'authorization': token })

      const _tokenAddress = status.paylableTokens.find((token) => token.symbol === status.bidTokenType).address || ZeroAddress;
      const _tokenDecimals = status.paylableTokens.find((token) => token.symbol === status.bidTokenType).decimals || 18;
      const price = parseUnit(status.bidTokenAmount, _tokenDecimals) || 0;

      if (result) {
        switch (result['message']) {
          case "not exists user": tips("warning", t("action.nft.notexistuser")); break;
          case "exceed balance": tips("warning", t("action.balanceerror")); break;
          case "success": {
            const deadline = result['deadline'];
            const signature = result['signature'];
            const orderId = result['orderId'];
            if (deadline && signature && orderId) {
              const provider = new ethers.providers.Web3Provider(wallet.ethereum)
              const signer = await provider.getSigner();
              const ca = getMarketplaceContract(Addresses.market, signer);
              if (_tokenAddress === ZeroAddress) {
                const tx = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature, { value: price })
                await tx.wait()
              }
              else {
                const tokenCa = getTokenContract(_tokenAddress, signer)
                const tx = await tokenCa.approve(Addresses.market, price)
                await tx.wait()
                const tx2 = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature)
                await tx2.wait()
              }
              setTimeout(() => {
                tips("success", t("action.nft.createdbid"));
                let newBid = status.bidders as Bid[];
                newBid.push(
                  {
                    bidder: currentAccountAddress,
                    price: status.bidTokenAmount,
                    acceptedToken: status.bidTokenType,
                    quantity: 1,
                    expiredTime: status.nftInfo?.expiredTime,
                    created: Now()
                  })
                updateStatus({ showBidModal: false, salestatus: "", nftInfo: { ...status.nftInfo, bidders: newBid }, bidders: newBid })
                update({ loading: false, updated: updated + 1 })
              }, 3000);
            }
            else if (!isMetamask) {
              tips("success", t("action.nft.createdbid"));
              let newBid = status.nftInfo?.bidders as Bid[];
              newBid.push(
                {
                  bidder: currentAccountAddress,
                  price: status.bidTokenAmount,
                  acceptedToken: status.bidTokenType,
                  quantity: 1,
                  expiredTime: status.nftInfo?.expiredTime,
                  created: Now()
                })
              updateStatus({ showBidModal: false, salestatus: "", nftInfo: { ...status.nftInfo, bidders: newBid }, bidders: newBid })
              break;
            }
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
        update({ loading: false })
        updateStatus({ showBidModal: false })
      }
    } catch (ex) {
      cancelPayment(collection, nft);
      update({ loading: false })
      tips("error", t("action.unknownerror"))
      console.log(ex.message)
    }
  }

  const buyNFT = async () => {
    const collection = status.collectionInfo.address;
    const nft = status.nftInfo.tokenid;
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }
      updateStatus({ errorBuyQuantity: "" })
      if (Number(status.buyQuantity) < 1) return updateStatus({ errorBuyQuantity: t("action.nft.errorquantity") })
      if (Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0) < Number(status.buyQuantity * status.nftInfo?.price)) return tips("warning", t("action.balanceerror"));
      update({ loading: true })
      const _tokenAddress = status.paylableTokens.find((token) => token.symbol === status.nftInfo?.acceptedToken).address || ZeroAddress;
      const _tokenDecimals = status.paylableTokens.find((token) => token.symbol === status.nftInfo?.acceptedToken).decimals || 18;
      const price = parseUnit(status.nftInfo?.price, _tokenDecimals) || 0;
      const result = await call("/api/nft/buy", {
        collectionid: collection,
        nftid: nft,
        quantity: status.offerQuantity,
        tokenAddress: _tokenAddress,
        tokenDecimals: _tokenDecimals,
        amount: 1,
        price: price
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "processing by other user": tips("warning", t("action.nft.processingbuy")); break;
          case "success": {
            const deadline = result['deadline'];
            const signature = result['signature'];
            const orderId = result['orderId'];
            if (deadline && signature && orderId) {
              const provider = new ethers.providers.Web3Provider(wallet.ethereum)
              const signer = await provider.getSigner();
              const ca = getMarketplaceContract(Addresses.market, signer);
              if (_tokenAddress === ZeroAddress) {
                console.log(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature)
                const tx = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature, { value: price })
                await tx.wait()
              }
              else {
                const tokenCa = getTokenContract(_tokenAddress, signer)
                const tx = await tokenCa.approve(Addresses.market, price)
                await tx.wait()
                const tx2 = await ca.payment(orderId, _tokenAddress, price, marketAdminAddress, deadline, signature)
                await tx2.wait()
              }
              updateStatus({ showBuyModal: false, salestatus: "" })
              update({ loading: false, updated: updated + 1 })
              tips("success", t("action.nft.buysuccess"));
              setTimeout(() => {
                updateStatus({
                  showBuyModal: false,
                  nftInfo: {
                    ...status.nftInfo, saleType: "", price: 0, startTime: 0, endTime: 0, image: status.nftInfo?.image, owner: currentAccountAddress
                  },
                  salestatus: ""
                })
              }, 800);
            }
            else if (!isMetamask) {
              tips("success", t("action.nft.buysuccess"));
            }
            break;
          }
          case "internal error": tips("error", t("action.servererror")); break;
        }
        update({ loading: false })
        updateStatus({ showBuyModal: false })
      }
    } catch (ex) {
      cancelPayment(collection, nft)
      console.log(ex.message)
      updateStatus({ showBuyModal: false })
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  const acceptBid = async () => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }

      const _id = status.selectedBidder?._id;
      const collection = status.collectionInfo.address;
      const nft = status.nftInfo.tokenid;
      let signature = '';

      update({ loading: true })

      if (isMetamask) {
        signature = await sign("Do you want to accept bid?")
        if (!signature) {
          tips("error", "Invalid signature")
          return update({ loading: false })
        }
        const provider = new ethers.providers.Web3Provider(wallet.ethereum)
        const signer = await provider.getSigner()
        const ca = getNFTContract(collection, signer)
        const owner = await ca.ownerOf(nft);
        if (owner.toUpperCase() === currentAccountAddress.toUpperCase()) {
          const tx2 = await ca.transferFrom(currentAccountAddress, Addresses.market, nft)
          await tx2.wait()
        }
      }

      const result = await call("/api/nft/accept-bid", {
        collectionid: collection,
        nftid: nft,
        _id: _id,
        signature: signature
      }, {
        'authorization': token
      })

      if (result) {
        switch (result['message']) {
          case "invalid signature":
            tips("error", t("action.invalidsignature"));
            break;

          case "not owner":
            tips("error", t("action.nft.notowner"));
            break;

          case "could not find bidder":
            tips("error", t("action.nft.notfoundbidder"));
            break;

          case "exceed balance":
            tips("error", t("action.balanceerror"));
            break;

          case "success": {
            tips("success", t("action.nft.accepted"));
            updateStatus({
              showApproveBidModal: false,
              nftInfo: {
                ...status.nftInfo,
                saleType: "",
                price: 0,
                startTime: 0,
                endTime: 0,
                image: status.nftInfo?.image,
                owner: status.selectedBidder?.bidder
              },
              salestatus: ""
            })
            break;
          }

          case "internal error":
            tips("error", t("action.server"));
            break;
        }
      }

      update({ loading: false, updated: updated + 1 });
      window.location.reload();
    } catch (err) {
      tips("error", t("action.nft.acceptbiderror"))
      update({ loading: false })
    }
  }

  const cancelBid = async (_id: string) => {
    try {
      if (!logined) {
        tips("info", t("action.needlogin"))
        setTimeout(() => {
          update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
          navigate("/login")
        }, 800);
        return;
      }
      const collection = status.collectionInfo.address;
      const nft = status.nftInfo.tokenid;
      update({ loading: true });
      let signature = '';
      if (isMetamask) {
        signature = await sign("Do you want to cancel bid?")
        if (!signature) {
          return update({ loading: false })
        }
      }
      const result = await call("/api/nft/cancel-bid", {
        collectionid: collection,
        nftid: nft,
        _id: _id,
        signature: signature
      },
        { 'authorization': token }
      )
      if (result) {
        switch (result['message']) {
          case "not bidder": tips("error", t("action.nft.notbidder")); break;
          case "success": {
            tips("success", t("action.nft.canceledbid"));
            let newBid = [] as any;
            status.bidders?.forEach(bid => {
              if (bid?._id !== _id) newBid.push(bid)
            })
            updateStatus({ nftInfo: { ...status.nftInfo, bidders: newBid }, bidders: newBid })
            break;
          }
          case "invalid signature": tips("error", t("action.invalidsignature")); break;
          case "not exists bid": tips("error", t("action.nft.notfoundbidder")); break;
          case "internal error": tips("error", t("action.servererror")); break;
        }
      }
      update({ loading: false, updated: updated + 1 });
    } catch (err) {
      tips("error", t("action.nft.cancelbiderror"))
      update({ loading: false })
    }
  }

  const sign = async (message: string) => {
    try {
      const signature = await wallet.ethereum.request({
        method: "personal_sign",
        params:
          [
            message + ` \n Wallet address: ${wallet.account}`,
            wallet.account
          ]
      })
      return signature;
    } catch (err) {
      return null;
    }
  }

  React.useEffect(() => {
    const collectionid = window.location.pathname.split("/")?.[2]?.toString() || "";
    const tokenid = window.location.pathname.split("/")?.[3]?.toString() || "";
    if (collectionid && tokenid) {
      call("/api/nft/setView", {
        collectionid: collectionid,
        nftid: tokenid
      })
    }
    updateStatus({ containerSize: swiperPanelRef.current?.clientWidth })
    console.log("init")
  }, [])

  const getAuctionAvailableState = (nft: NFTSearchResult) => {
    let startTime = nft.startTime;
    let endTime = nft.expiredTime;
    let currentTime = Now();

    return startTime < currentTime && currentTime < endTime;
  }

  const getAuctionStartEndTime = (nft: NFTSearchResult) => {
    let startTime = nft.startTime;
    let endTime = nft.expiredTime;
    let currentTime = Now();

    if (startTime > currentTime) {
      return `${t("nftview.salestart")} ${new Date(nft.startTime * 1000).toUTCString()}`
    } else if (endTime > currentTime) {
      return `${t("nftview.saleend")} ${new Date(nft.expiredTime * 1000).toUTCString()}`
    } else return ''
  }

  return (
    <Layout >
      {(!status.notfound && !!status.nftInfo) && (
        <div className="container">
          <div className="row center mt3">
            <div className="col-lg-10 col-md-11 col-sm-12 p2">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <StyledImg>
                    <img alt="nft"
                      src={status.nftInfo?.image}
                      style={{ display: status.loading ? 'none' : 'block' }}
                      onLoad={() => { updateStatus({ loading: false }) }}
                      onLoadedData={() => { updateStatus({ loading: false }) }}
                      onClick={() => { updateStatus({ showImageModal: true }) }}
                    />

                    {status.loading && (
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <p style={{ display: 'block' }}>
                          <Skeleton style={{ width: '100%', height: '100%', aspectRatio: '1 / 1', borderRadius: '24px' }} />
                        </p>
                      </SkeletonTheme>
                    )}
                  </StyledImg>
                </div>

                <div className="col-lg-6 col-md-12">
                  <Link to={"/collection/" + status.collectionInfo?.address}
                    style={{ color: theme.text, textDecoration: 'none', margin: '1rem 0', display: 'block' }}
                  >
                    <div className="flex middle">
                      {status.loading ? (
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <p style={{ display: 'block' }}>
                            <Skeleton style={{ width: '32px', height: '32px', aspectRatio: '1 / 1', borderRadius: '8px' }} />
                          </p>
                        </SkeletonTheme>
                      ) : (
                        <img style={{ width: '32px', height: '32px', borderRadius: '8px' }}
                          src={status.collectionInfo?.metadata.image || status.collectionInfo.metadata?.coverImage || collectionImage}
                          onLoadedData={() => { updateStatus({ loading: false }) }}
                          onLoad={() => { updateStatus({ loading: false }) }}
                        />
                      )}
                      {/* collection.metadata?.coverImage || collection.metadata?.image || collectionImage */}

                      {!status.collectionInfo?.metadata.name ? (
                        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                          <p>
                            <Skeleton height={30} width={70} style={{ marginLeft: '1rem' }} />
                          </p>
                        </SkeletonTheme>
                      ) : (
                        <p className='m0 ml1 mr1'>
                          {status.collectionInfo?.metadata.name}
                        </p>
                      )}

                      {status.collectionInfo?.verified?.status === "verified" && (
                        <img src={verifiedImg} style={{ width: '32px', height: '32px', borderRadius: '8px' }} />
                      )}
                    </div>
                  </Link>

                  <StyledRightButtonBar>
                    <p style={{ color: theme.text, textAlign: 'center', wordBreak: "break-all" }}>
                      {status.nftInfo?.favs} Fav
                    </p>

                    <ButtonPrimary className='round-btn' onClick={() => { setFavorite(); }}
                      style={{ backgroundColor: status.favorited ? "#36C781" : theme.boxColor }}
                    >
                      <Icon icon="Favorite" size={25} height={22} fill={status.favorited ? "#ffffff" : "#ffffff"} />
                    </ButtonPrimary>

                    {/* <div className='round-btn mt3' style={{ backgroundColor: theme.boxColor }}>
                        <Icon icon="Share" size={25} height={22} fill='#ffffff' />

                        <div className="drop-panel">
                          <a target='_blank' className='menu'
                            style={{ color: theme.text, textDecoration: 'none' }}
                            href={status.collectionInfo?.metadata?.links.find((link) => link.name === "facebook")?.link || ""}
                          >
                            <Icon icon="Facebook" size={28} margin={6} />
                            Facebookでシェア
                          </a>

                          <a target='_blank' className='menu'
                            style={{ color: theme.text, textDecoration: 'none' }}
                            href={status.collectionInfo?.metadata?.links.find((link) => link.name === "instagram")?.link || ""}
                          >
                            <Icon icon="Twitter2" size={16} margin={16} />
                            Twitterでシェア
                          </a>

                          <a target='_blank' className='menu'
                            style={{ color: theme.text, textDecoration: 'none' }}
                            href={status.collectionInfo?.metadata?.links.find((link) => link.name === "website")?.link || ""}
                          >
                            <Icon icon="Website" size={16} height={24} margin={16} />
                            リンクをコピー
                          </a>
                        </div>
                      </div> */}
                  </StyledRightButtonBar>

                  <div className="flex middle wrap justify" style={{ alignItems: 'baseline' }}>
                    {status.loading && (
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton height={24} width={270} style={{ margin: '1rem' }} />
                      </SkeletonTheme>
                    )}

                    {!status.loading && (
                      <StyledH1 >{status.nftInfo?.name || status.collectionInfo?.metadata.name + status.tokenid}</StyledH1>
                    )}

                    {status.nftInfo?.views === null && (
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <Skeleton height={24} width={100} style={{ margin: '1rem' }} />
                      </SkeletonTheme>
                    )}

                    {status.nftInfo?.views !== null && (
                      <div className="flex">
                        <StyledText>1 of 1</StyledText>
                        <StyledText style={{ marginLeft: '3rem' }}>{status.nftInfo?.views || 0} view</StyledText>
                      </div>
                    )}
                  </div>

                  <div className="flex middle cursor" onClick={() => { updateStatus({ showOwnerModal: true }) }}>
                    <img alt="avatar" src={status.avatar || avatar}
                      style={{ width: '80px', height: '80px', borderRadius: '50%', margin: '1rem 1rem 1rem 0', objectFit: 'cover' }}
                    />

                    {(!status.owner && !status.nftInfo?.owner) && (
                      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                        <p>
                          <Skeleton count={2} height={24} width={150} style={{ marginRight: '1rem' }} />
                        </p>
                      </SkeletonTheme>
                    )}

                    {status.nftInfo?.owner && (
                      <div>
                        <p className='m0 small-text' style={{ color: theme.textGrey }}>Owner</p>
                        <p className='m0'>{status.owner || ellipsis(status.nftInfo?.owner || "", 6, false)}</p>
                      </div>
                    )}
                  </div>

                  {status.nftInfo?.externalSite && (
                    <StyledLink target={"_blank"} href={status.nftInfo?.externalSite} style={{ color: theme.buttonPrimary }}>
                      {status.nftInfo?.externalSite}
                    </StyledLink>
                  )}

                  <StyledSalePanel darkMode={darkMode}>
                    {(status.nftInfo?.saleType !== "" && status.nftInfo?.expiredTime > 0) && (
                      <div className="header">
                        <Icon icon="Time" margin={24} height={24} />
                        <p className='m0'>
                          {getAuctionStartEndTime(status.nftInfo)}
                        </p>
                      </div>
                    )}

                    <div className="body">
                      {status.nftInfo?.price > 0 && (
                        <p>{t("nftview.currentprice")}</p>
                      )}

                      <div className="flex middle wrap">
                        {status.nftInfo?.price === null && (
                          <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                            <p>
                              <Skeleton height={50} width={150} style={{ marginRight: '1rem' }} />
                            </p>
                          </SkeletonTheme>
                        )}

                        {status.nftInfo?.price > 0 && (
                          <>
                            <h1 style={{ marginRight: '2rem' }}>
                              {status.nftInfo?.saleType === "auction" ? status.highBidPrice : status.nftInfo?.price}

                              <span style={{ color: theme.text }}>
                                {" " + status.nftInfo?.acceptedToken}
                              </span>
                            </h1>

                            <p style={{ color: theme.text }}>
                              {lang === 'en' ? '$' : '￥'}
                              {((status.nftInfo?.price || 0) * (
                                status.prices.find(
                                  v => { return v.symbol === "MATIC" }
                                )?.[lang === 'en' ? 'usd' : 'jpy'] || 0
                              )).toFixed(4)
                              }
                            </p>
                          </>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-6">
                          {status.nftInfo?.owner?.toUpperCase() === currentAccountAddress?.toUpperCase() ?
                            (status.nftInfo?.saleType === "" ? (
                              <ButtonPrimary onClick={() => {
                                updateStatus({
                                  errorSaleend: '',
                                  errorSaleprice: '',
                                  errorSalequantity: '',
                                  errorSalestart: '',
                                  saleEnd: 0,
                                  saleStart: 0,
                                  saleQuantity: 1,
                                  saleprice: 0,
                                  saleType: 'regular',
                                  showSellModal: true
                                })
                              }}
                                style={{ width: '100%', margin: '1rem 0' }} >
                                {t("nftview.sell")}
                              </ButtonPrimary>
                            ) : (
                              <ButtonPrimary
                                onClick={() => {
                                  if (!logined) {
                                    tips("info", t("action.needlogin"))
                                    setTimeout(() => {
                                      update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
                                      navigate("/login")
                                    }, 800);
                                    return;
                                  }
                                  updateStatus({ showCancelSellModal: true })
                                }}
                                style={{
                                  width: '100%',
                                  backgroundColor: "#a7a7a7",
                                  margin: '1rem 0'
                                }}
                              >
                                {t("nftview.cancelsell")}
                              </ButtonPrimary>
                            )) : (
                              status.nftInfo?.saleType === "" ? (
                                <ButtonPrimary onClick={openOfferModal} style={{ width: '100%', margin: '1rem 0' }} >
                                  {t("nftview.makeoffer")}
                                </ButtonPrimary>  //offer in nosale case
                              ) : (
                                status.nftInfo?.saleType === "regular" ? (
                                  <ButtonPrimary style={{ width: '100%', margin: '1rem 0' }}
                                    onClick={() => {
                                      updateStatus({
                                        buyQuantity: 1,
                                        buyTokenBalance: 0,
                                        showBuyModal: true,
                                        buyStep: status.nftInfo?.multiple ? 1 : 2,
                                      })
                                    }}
                                  >
                                    {t("nftview.buy")}
                                  </ButtonPrimary>   //regular buy now
                                ) : (
                                  <ButtonPrimary onClick={openBidModal}
                                    disabled={!getAuctionAvailableState(status.nftInfo)}
                                    style={{ width: '100%', backgroundColor: "#36C781", margin: '1rem 0' }}
                                  >
                                    {t("nftview.bid")}
                                  </ButtonPrimary>
                                ))
                            )
                          }
                        </div>

                        <div className="col-lg-6 col-6">
                          {status.nftInfo?.owner?.toUpperCase() === currentAccountAddress?.toUpperCase() ? (
                            status.nftInfo?.saleType === "" ? (
                              <ButtonPrimary onClick={() => { navigate("/profile") }}
                                style={{ width: '100%', backgroundColor: "#36C781", margin: '1rem 0' }}
                              >
                                {t("nftview.mypage")}
                              </ButtonPrimary>
                            ) : (
                              <ButtonPrimary style={{ width: '100%', margin: '1rem 0' }}
                                onClick={() => {
                                  updateStatus({
                                    showSellResetModal: true,
                                    saleType2: status.nftInfo.saleType,
                                    saleprice2: status.nftInfo.price,
                                    saleToken2: status.nftInfo.acceptedToken
                                  })
                                }}
                              >
                                {t("nftview.changesell")}
                              </ButtonPrimary>
                            )) : (
                            status.nftInfo?.saleType === "regular" && (
                              <ButtonPrimary onClick={openOfferModal} style={{ width: '100%', backgroundColor: "#36C781", margin: '1rem 0' }} >
                                {t("nftview.makeoffer")}
                              </ButtonPrimary>  //make buy offer
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </StyledSalePanel>

                  {(status.nftInfo?.owner?.toUpperCase() === currentAccountAddress.toUpperCase() && status.nftInfo.saleType === "") && (
                    <StyledTransferPanel>
                      <div className="row">
                        {(logined && isMetamask) && (
                          <div className="col-lg-6 col-6 mb2">
                            <ButtonPrimary onClick={() => { updateStatus({ showTransferModal: true }) }}
                              style={{ width: '100%', backgroundColor: '#9b9b9b', margin: '0 0' }}
                            >
                              {t("nftview.transfer")}
                            </ButtonPrimary>
                          </div>
                        )}

                        {(logined && !isMetamask) && (
                          <div className="col-lg-6 col-6 mb2">
                            <ButtonPrimary style={{ width: '100%', backgroundColor: "#9b9b9b", margin: '0 0' }}
                              onClick={() => { updateStatus({ showExportModal: true }); estimateExportFee() }}
                            >
                              {t("nftview.withdraw")}
                            </ButtonPrimary>
                          </div>
                        )}
                      </div>
                    </StyledTransferPanel>
                  )}
                </div>
              </div>

              <StyledNftInfo>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <Accordion style={{}} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p style={{ wordBreak: 'break-all' }}>{t("nftview.description")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <p>{status.nftInfo?.description || ""}</p>

                        {(!status.nftInfo?.description || status.nftInfo?.description.trim() == "") && (
                          <div className='flex center'>
                            <p style={{ color: theme.textGrey, wordBreak: 'break-word' }}>{t("nftview.nodescription")}</p>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ borderRadius: '4px', }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.attribute")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        {(!!status.nftInfo?.attributes?.length && status.nftInfo?.attributes[0]?.key !== "") && (
                          <div className="row">
                            {status.nftInfo?.attributes?.map((attr, key) => (
                              <div key={key} className="col-lg-4 col-md-6 col-sm-12 p0">
                                <NFTAttributeContainer darkMode={darkMode}>
                                  <div className='attribute-header'>{attr?.key}</div>

                                  <CustomTooltip title={<AttributeValue title={attr?.value} />}>
                                    <div className='attribute-value'>{attr?.value}</div>
                                  </CustomTooltip>
                                </NFTAttributeContainer>
                              </div>
                            ))}
                          </div>
                        )}

                        {(!status.nftInfo?.attributes || status.nftInfo?.attributes?.length == 0 || (status.nftInfo?.attributes.length == 1 && status.nftInfo?.attributes[0]?.key == "")) && (
                          <div className='flex center'>
                            <p style={{ color: theme.textGrey }}>{t("nftview.noattribute")}</p>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ borderRadius: '4px', color: theme.text, }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.aboutcreator")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <div className="justify">
                          <div className="flex middle">
                            <img alt="avatar" src={status.avatar || avatar}
                              style={{ width: '80px', height: '80px', borderRadius: '50%', margin: '1rem 1rem 1rem 0', objectFit: 'cover' }}
                            />

                            {(!status.owner && !status.nftInfo?.owner) && (
                              <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
                                <p>
                                  <Skeleton height={80} width={150} style={{ marginRight: '1rem' }} />
                                </p>
                              </SkeletonTheme>
                            )}

                            {status.nftInfo?.owner && (
                              <div>
                                <p className='m0 small-text' style={{ color: theme.textGrey }}>Owner</p>
                                <p className='m0 cursor' onClick={() => { navigate("/" + status.nftInfo?.owner) }}>{status.owner || ellipsis(status.nftInfo?.owner || "", 6, false)}</p>
                              </div>
                            )}
                          </div>

                          <div className="flex right">
                            {status.ownerTwitter && (
                              <a target="_blank" href={status.ownerTwitter}
                                style={{ margin: '2rem', color: theme.text, cursor: 'pointer' }}
                              >
                                <Icon icon="Twitter" />
                              </a>
                            )}

                            {status.ownerInstagram && (
                              <a target="_blank" href={status.ownerInstagram}
                                style={{ margin: '2rem', color: theme.text, cursor: 'pointer' }}
                              >
                                <Icon icon="Instagram" />
                              </a>
                            )}

                            {(status.ownerWebsite && validateUrl(status.ownerWebsite)) &&
                              <a href={status.ownerWebsite} target="_blank"
                                style={{ margin: '2rem', color: theme.text, cursor: 'pointer' }}
                              >
                                <Icon icon="Shape" />
                              </a>
                            }
                          </div>
                        </div>

                        <p>{status.ownerBio || ""}</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ borderRadius: '4px', color: theme.text, }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.detail")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.contractaddress")}</p>
                          <StyledLink target="_blank" href={"/collection/" + status.collectionid}>{ellipsis(status.collectionid, 5, false)}</StyledLink>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>Token ID</p>
                          <StyledLink target="_blank" href={"/assets/" + status.collectionid + "/" + status.tokenid}>{ellipsis(status.tokenid, 12, false)}</StyledLink>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.blockchain")}</p>
                          <p className="m0 mt1 mb1" >{status.nftInfo?.acceptedToken}</p>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.creatorfee")}</p>
                          <p className="m0 mt1 mb1">{(status.collectionInfo?.fee || 0) + "%"}</p>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.ondigital")}</p>
                          <p className="m0 mt1 mb1">{status.nftInfo?.isDigital ? "OK" : "NG"}</p>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.ofimage")}</p>
                          <p className="m0 mt1 mb1">{status.nftInfo?.isRight ? 'OK' : 'NG'}</p>
                        </div>

                        <div className="justify wrap">
                          <p className="m0 mt1 mb1" style={{ color: theme.strokeColor, fontSize: '15px' }}>{t("nftview.copyright")}</p>
                          <p className="m0 mt1 mb1">{status.nftInfo?.isCopyright ? 'OK' : 'NG'}</p>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <Accordion style={{ borderRadius: '4px', color: theme.text, }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.listing")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <StyledListTable>
                          <div className="list-row th">
                            <div>{t("nftview.price")}</div>
                            <div>{t("nftview.tokens")}</div>
                            {/* <div>{t("nftview.jpy")}</div> */}
                            <div>{t("nftview.expires")}</div>
                            <div>{t("nftview.from")}</div>
                            <div>{t("nftview.cancel")}</div>
                          </div>

                          {status.listings?.map((act, index) => {
                            if (index >= (status.selectedListPage - 1) * 5 && index < status.selectedListPage * 5) {
                              return (
                                <div className="list-row" key={"listing-" + index}>
                                  <div>
                                    {act?.params?.find(p => p.type === "price")?.value || 0}
                                    {/* {(act?.params?.find(p => p.type === "price")?.value || 0) + (act?.params?.find(p => p.type === "acceptedToken")?.value || "")} */}
                                  </div>

                                  <div>
                                    {act?.params.find(p => p.type === "acceptedToken")?.value || ""}
                                  </div>

                                  {/* <div>
                                    {lang === 'en' ? '$' : '￥'}
                                    {((Number(act?.params?.find(p => p.type === "price")?.value) || 0) * (
                                      status.prices.find(
                                        v => { return v.symbol === act?.params?.find(p => p.type === "acceptedToken")?.value || "MATIC" }
                                      )?.[lang === 'en' ? 'usd' : 'jpy'] || 0
                                    )).toFixed(2)}
                                  </div> */}

                                  <div>{timeAgo(act.created * 1000)}</div>

                                  <div>
                                    <StyledLink
                                      onClick={() => { navigate("/" + act?.params.find(p => p.type === "from")?.value) }}>
                                      {ellipsis(act?.params.find(p => p.type === "from")?.value, 5, false)}
                                    </StyledLink>
                                  </div>

                                  <div>
                                    {(index == 0 && act?.params.find(p => p.type === "from")?.value?.toUpperCase() == currentAccountAddress?.toUpperCase() && status.saleType !== "nosale" && status.nftInfo?.price > 0) && (
                                      <ButtonPrimary style={{ width: '70px', padding: '4px 1rem' }} onClick={() => { updateStatus({ showCancelSellModal: true }) }}>
                                        Cancel
                                      </ButtonPrimary>
                                    )}
                                  </div>
                                </div>
                              )
                            }

                            return null;
                          })}

                          {(status.listings?.length == 0) && (
                            <div className='flex center'>
                              <p style={{ color: theme.textGrey }}>{t("nftview.nolistings")}</p>
                            </div>
                          )}
                        </StyledListTable>

                        {status.listings?.length > 5 && (
                          <div className="flex center mt2">
                            <Pagination perPage={5}
                              selectedPage={status.selectedListPage}
                              totalCount={status.listings?.length || 0}
                              onSelect={(page: number) => { updateStatus({ selectedListPage: page }) }}
                            />
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ borderRadius: '4px', color: theme.text, }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.offer")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <StyledListTable>
                          <div className="list-row th">
                            <div>{t("nftview.price")}</div>
                            <div>{t("nftview.tokens")}</div>
                            {/* <div>{t("nftview.jpy")}</div> */}
                            <div>{t("nftview.expires")}</div>
                            <div>{t("nftview.from")}</div>
                            <div>{t("nftview.cancel")}</div>
                          </div>

                          {status.bidders?.sort((a, b) => {
                            return Number(b.created) - Number(a.created)
                          })?.map((bidder, index) => {
                            if (index >= (status.selectedOfferPage - 1) * 5 && index < status.selectedOfferPage * 5) {
                              return (
                                <div className="list-row" key={"bid-" + index}>
                                  <div>{bidder.price}</div>
                                  <div>{bidder.acceptedToken}</div>

                                  {/* <div>
                                    {lang === 'en' ? '$' : '￥'}
                                    {((bidder?.price || 0) * (
                                      status.prices.find(
                                        v => { return v.symbol === bidder.acceptedToken }
                                      )?.[lang === 'en' ? 'usd' : 'jpy'] || 0
                                    )).toFixed(4)}
                                  </div> */}

                                  <div>{timeAgo(Number(bidder?.expiredTime || 0) * 1000)}</div>

                                  <div>
                                    <StyledLink
                                      onClick={() => { navigate("/" + bidder.bidder) }}>{ellipsis(bidder.bidder, 5, false)}
                                    </StyledLink>
                                  </div>

                                  <div>
                                    {bidder.bidder === currentAccountAddress && (
                                      <ButtonPrimary style={{ width: '70px', padding: '4px 1rem' }} onClick={() => { cancelBid(bidder?._id) }}>
                                        {t("nftview.offerCancel")}
                                      </ButtonPrimary>
                                    )}

                                    {status.nftInfo?.owner === currentAccountAddress && (
                                      <ButtonPrimary style={{ width: '90px', padding: '4px 1rem' }}
                                        onClick={() => {
                                          updateStatus({ showApproveBidModal: true, selectedBidder: bidder })
                                        }}>
                                        {t("nftview.accept")}
                                      </ButtonPrimary>
                                    )}
                                  </div>
                                </div>
                              )
                            }

                            return null;
                          })}
                        </StyledListTable>

                        {(status.bidders?.length == 0) && (
                          <div className='flex center'>
                            <p style={{ color: theme.textGrey }}>{t("nftview.nooffers")}</p>
                          </div>
                        )}

                        {status.bidders?.length > 5 && (
                          <div className="flex center mt2">
                            <Pagination
                              totalCount={status.bidders?.length || 0}
                              perPage={5}
                              selectedPage={status.selectedOfferPage}
                              onSelect={(page: number) => { updateStatus({ selectedOfferPage: page }) }}
                            />
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Accordion style={{ borderRadius: '4px', color: theme.text, }} defaultExpanded>
                      <AccordionSummary aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        expandIcon={<Icon icon="Down" fill={theme.text} size={18} height={25} />}
                      >
                        <p>{t("nftview.activity")}</p>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <StyledEventTable>
                          <div className="list-row th">
                            <div>{t("nftview.eventname")}</div>
                            <div>{t("nftview.price")}</div>
                            <div>{t("nftview.tokenname")}</div>
                            <div>{t("nftview.from")} </div>
                            <div>{t("nftview.to")}</div>
                            <div>{t("nftview.date")}</div>
                          </div>

                          {status.events?.map((act, index) => {
                            if (index >= (status.selectedEventPage - 1) * 5 && index < status.selectedEventPage * 5) {
                              return (
                                <div className="list-row" key={"event-" + index}>
                                  <div>{act.type}</div>

                                  <div>
                                    {(Number(act?.params?.find(p => p.type === "price")?.value || 0) > 0 ? act?.params?.find(p => p.type === "price")?.value : "") + (act?.params?.find(p => p.type === "acceptedToken")?.value || "")}
                                  </div>

                                  <div>
                                    {act?.params.find(p => p.type === "acceptedToken")?.value || ""}
                                  </div>

                                  <div>
                                    <StyledLink
                                      onClick={() => { navigate("/" + act?.params.find(p => p.type === "from")?.value) }}>
                                      {ellipsis(act?.params?.find(p => p.type === "from")?.value, 5, false)}
                                    </StyledLink>
                                  </div>

                                  <div>
                                    <StyledLink
                                      onClick={() => { navigate("/" + act?.params.find(p => p.type === "to")?.value) }}>
                                      {ellipsis(act?.params?.find(p => p.type === "to")?.value, 5, false)}
                                    </StyledLink>
                                  </div>

                                  <div>{timeAgo(act.created * 1000)}</div>
                                </div>
                              )
                            }

                            return null;
                          })}
                        </StyledEventTable>

                        {status.events?.length > 5 && (
                          <div className="flex center mt2">
                            <Pagination perPage={5}
                              totalCount={status.events?.length || 0}
                              selectedPage={status.selectedEventPage}
                              onSelect={(page: number) => { updateStatus({ selectedEventPage: page }) }}
                            />
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <div style={{ margin: '100px 0' }}>
                  <h2 style={{ color: theme.text }}>
                    {t("nftview.aboutcollection", ellipsis(status.collectionInfo?.metadata.name, window.innerWidth < 768 ? 3 : 15))}
                  </h2>

                  <StyledNfts darkMode={darkMode}>
                    {(status.collectionNfts && status.collectionNfts?.length > 0) && status.collectionNfts?.map((nft, index) => {
                      if (nft.tokenid !== status.tokenid && !nft.hide) {
                        return (
                          <div className='nft' key={"nft-" + index}>
                            <NFTItem
                              nft={nft}
                              id={nft._id}
                              nftCollection={nft.nftCollection}
                              tokenId={nft.tokenid}
                              owner={nft.owner}
                              img={nft.coverImage}
                              name={nft.name}
                              balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                              symbol={nft.acceptedToken}
                              balanceUsd={
                                (lang === 'en' ? '$' : '￥') +
                                (nft.price > 0 && nft.price < 2147483647 ?
                                  ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)) : 0)
                              }
                            />
                          </div>
                        )
                      }

                      return null;
                    })}
                  </StyledNfts>

                  {(status.collectionNfts?.filter((item) => (item.tokenid !== status.tokenid && !item.hide))?.length == 0) && (
                    <h2 style={{ color: theme.grey, textAlign: 'center', margin: '20px auto' }}>{t("nftview.noitems")}</h2>
                  )}

                  <div className="flex center">
                    <ButtonPrimary
                      style={{
                        backgroundColor: "transparent",
                        border: '2px solid #353945',
                        borderRadius: '24px',
                        width: '200px',
                        margin: '26px 0'
                      }}
                      onClick={() => { navigate("/collection/" + status.collectionid) }}
                    >
                      {t("home.seeMore")}
                    </ButtonPrimary>
                  </div>
                </div>
              </StyledNftInfo>
            </div>
          </div>
        </div >
      )}

      {status.notfound && (
        <Page404 />
      )}

      <Modal onClose={() => { updateStatus({ showBuyModal: false }) }} show={status.showBuyModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>
            {t("nftview.buymodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.buymodal.blockchain")}</p>
              </div>
            </div>

            <div>
              <p className='p0 m0 text-right'>
                {status.nftInfo?.price} <span style={{ color: theme.buttonPrimary }}>{status.nftInfo?.acceptedToken}</span>
              </p>

              <p className='p0 m0 text-right'>
                {lang === 'en' ? '$' : '￥'}
                {(status.nftInfo?.price * (status?.prices.find(v => { return v.symbol === "MATIC" })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)}
              </p>
            </div>
          </div>

          {(logined && status.buyStep === 1) && (
            <InputField required type="number"
              value={status.buyQuantity}
              label={t("nftview.buymodal.quantity")}
              infoLabel={t("nftview.buymodal.quantityinfo")}
              onChange={(e) => {
                if (status.nftInfo?.multiple) {
                  updateStatus({ buyQuantity: e.target.value, buyTokenBalance: Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0) })
                }
              }}
            />
          )}

          {(logined && status.buyStep === 2 && Number(balances?.find((token) => token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase())?.balance || 0) < (status.nftInfo?.price * status.buyQuantity)) && (
            <p className='text-center'>{t("nftview.buymodal.insufficient")}</p>
          )}

          {(logined && status.buyStep === 2) && (
            <p className='text-center'>
              {t("nftview.buymodal.balance")}
              {Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0).toFixed(4)}
              {" " + status.nftInfo?.acceptedToken}
            </p>
          )}

          {!logined && (
            <div className='flex center' style={{ color: theme.buttonPrimary }}>
              <p>{t("action.needlogin")}</p>
            </div>
          )}

          <div className="flex center mt1">
            <ButtonPrimary style={{ width: '280px' }}
              onClick={() => {
                if (!logined) {
                  update({ redirectPage: "/assets/" + status.collectionid + "/" + status.tokenid })
                  navigate("/login")
                } else {
                  status.buyStep === 1 ?
                    updateStatus({ buyStep: 2, buyTokenBalance: Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0) })
                    :
                    (Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0) < (status.nftInfo?.price * status.buyQuantity) ? updateStatus({ showBuyTokenModal: true }) : buyNFT())
                }
              }}
            >
              {!logined ? "Login" : (Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.nftInfo?.acceptedToken?.toUpperCase() })?.balance || 0) < Number(Number(status.nftInfo?.price) * Number(status.buyQuantity)) ? t("nftview.buymodal.buytoken") : t("nftview.buymodal.purchase"))}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showBuyModal: false }) }}
            >
              {t("nftview.buymodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showApproveBidModal: false }) }} show={status.showApproveBidModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t("nftview.approvebidmodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.approvebidmodal.blockchain")}</p>
              </div>
            </div>

            <div></div>
          </div>

          <div className="flex center">
            <div>
              <p className='text-center'>
                {t("nftview.approvebidmodal.bidder")}
              </p>

              <div className="flex center">
                <img src={status.selectedBidderImage || avatar} style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
              </div>

              <p className='text-center'>
                {ellipsis(status.selectedBidder?.bidder, 6, false)}
              </p>

              <p className='text-center'>
                {t("nftview.approvebidmodal.bidamount")} {status.selectedBidder?.price || 0} {status.selectedBidder?.acceptedToken}
              </p>
            </div>
          </div>

          <div className="flex center mt1">
            <ButtonPrimary style={{ width: '280px' }} onClick={() => { acceptBid() }}>
              {t("nftview.approvebidmodal.approve")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showApproveBidModal: false }) }}
            >
              {t("nftview.approvebidmodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showCancelSellModal: false }) }} show={status.showCancelSellModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.cancelsellmodal.title")}
          </h1>

          <div className="flex middle center p1">
            <img src={status.nftInfo?.image}
              style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
            />

            <div>
              <p className='p0 m0'>{status.nftInfo?.name}</p>
              <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
              <p className='p0 m0'>{t("nftview.cancelsellmodal.blockchain")}</p>
            </div>
          </div>

          <div className="flex center mt5">
            <ButtonPrimary style={{ width: '290px', backgroundColor: '#a7a7a7' }} onClick={() => { cancelList() }}>
              {t("nftview.cancelsellmodal.cancelsell")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '290px', backgroundColor: theme.boxColor }} onClick={() => { updateStatus({ showCancelSellModal: false }) }}>{t("nftview.cancelsellmodal.cancel")}</ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showSellModal: false }) }} show={status.showSellModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.sellmodal.title")}
          </h1>

          <fieldset className="InputField mt3 mb3">
            <legend>
              {t("nftview.sellmodal.saletype")}
            </legend>

            <div className="flex center middle wrap">
              <ButtonPrimary style={{ backgroundColor: status.saleType === "regular" ? theme.buttonPrimary : theme.boxColor, width: '220px', margin: '4px 4px.5rem' }}
                onClick={() => { updateStatus({ saleType: "regular" }) }}
              >
                {t("nftview.sellmodal.regular")}
              </ButtonPrimary>

              <ButtonPrimary style={{ backgroundColor: status.saleType === "auction" ? theme.buttonPrimary : theme.boxColor, width: '220px', margin: '4px 0.5rem' }}
                onClick={() => { updateStatus({ saleType: 'auction' }) }}
              >
                {t("nftview.sellmodal.auction")}
              </ButtonPrimary>
            </div>
          </fieldset>

          <InputField type="number" required errorLabel={status.errorSaleprice}
            // infoLabel={t("nftview.sellmodal.fee", status.collectionInfo?.fee.toString())} value={status.saleprice}
            label={status.saleType === "regular" ? t("nftview.sellmodal.price") : t("nftview.sellmodal.startprice")}
            onChange={(e) => { updateStatus({ saleprice: e.target.value }) }}
            child={
              <div style={{ width: '80px', marginRight: '8px' }}>
                <Dropdown hideImg
                  onChange={(key) => { updateStatus({ saleToken: key }) }}
                  selectedKey={status.saleToken} values={status.paylableTokens}
                  props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                />
              </div>
            }
          />

          {status.nftInfo?.multiple && (
            <InputField label={t("nftview.sellmodal.quantity")}
              required type="number" errorLabel={status.errorSalequantity}
              infoLabel={t("nftview.sellmodal.quantityinfo", status.nftInfo?.multiple ? '2' : '1')}
              value={status.saleQuantity} onChange={(e) => { updateStatus({ saleQuantity: e.target.value }) }}
              child={<div style={{ backgroundColor: theme.boxColor, color: theme.text, padding: '0.75rem 1rem', fontSize: '0.8rem', borderRadius: '8px', marginRight: '8px' }}
              >
                {t("nftview.sellmodal.individual")}
              </div>}
            />
          )}

          {status.saleType === "auction" && (
            <>
              <div className="hr" />

              <CustomDtePicker required
                errorLabel={status.errorSalestart}
                label={t("nftview.sellmodal.salestart")}
                onValueChange={(v: number) => { updateStatus({ saleStart: v }) }}
                defaultDate={getDateValue(0)}
                minDate={getDateValue(0)}
                value={status.saleStart}
              />

              <CustomDtePicker required
                label={t("nftview.sellmodal.saleend")}
                minDate={status.saleStart || getDateValue(0)}
                onValueChange={(v: number) => { updateStatus({ saleEnd: v }) }}
                errorLabel={status.errorSaleend}
                defaultDate={getDateValue(3)}
                value={status.saleEnd}
              />
            </>
          )}

          <div className="flex center mt3 mb3">
            <div className="col-lg-8">
              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.sellingprice")}</p>
                <p className='m0 mt2'>{status.saleprice} {status.saleToken}</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.servicefee")}</p>
                <p className='m0 mt2'>{status.servicefee} %</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.creatorfee")}</p>
                <p className='m0 mt2'>{status.collectionInfo?.fee} %</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.profit")}</p>
                <p className='m0 mt2'>{Number((status.saleprice - (status.saleprice * (status.collectionInfo?.fee || 0) / 100) - (status.saleprice * status.servicefee / 100)).toFixed(8)) + " " + status.saleToken} </p>
              </div>
            </div>
          </div>

          <div className="flex center mt1">
            <ButtonPrimary style={{ width: '280px' }} onClick={listNFT}>
              {t("nftview.sellmodal.sale")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }} onClick={() => { updateStatus({ showSellModal: false }) }}>
              {t("nftview.sellmodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showBidModal: false }) }} show={status.showBidModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.bidmodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.bidmodal.blockchain")}</p>
              </div>
            </div>

            <div></div>
          </div>

          <p className='text-center'>{t("nftview.bidmodal.maxbid")}
            {status.bidders?.sort((a, b) => {
              return b.price - a.price
            })[0]?.price || status?.nftInfo?.price}
          </p>

          <InputField required type="number"
            label={t("nftview.bidmodal.offerprice")}
            errorLabel={status.errorBidAmount} value={status.bidTokenAmount}
            onChange={(e) => { updateStatus({ bidTokenAmount: e.target.value }) }}
            child={
              <div style={{ width: '80px', marginRight: '8px' }}>
                <Dropdown hideImg
                  onChange={(key) => { updateStatus({ bidTokenType: key }) }}
                  selectedKey={status.bidTokenType} values={status.paylableTokens}
                  props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                />
              </div>
            }
          />

          <p className='text-center'>{t("nftview.bidmodal.acceptedtoken")}
            {Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.bidTokenType?.toUpperCase() })?.balance || 0).toFixed(4)}
            {" " + status.bidTokenType}
          </p>

          {status.bidTokenAmount > Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.bidTokenType?.toUpperCase() })?.balance || 0) && (
            <div className="flex center">
              <ButtonPrimary style={{ backgroundColor: '#36C781', width: '250px' }} onClick={() => { updateStatus({ showBuyTokenModal: true }) }}>
                {t("nftview.bidmodal.buytoken")}
              </ButtonPrimary>
            </div>
          )}

          <div className="flex center mt3">
            <ButtonPrimary style={{ width: '280px' }} onClick={bidNFT}>
              {t("nftview.bidmodal.bid")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showBidModal: false }) }}
            >
              {t("nftview.bidmodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showOfferModal: false }) }} show={status.showOfferModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.offermodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.offermodal.blockchain")}</p>
              </div>
            </div>

            <div></div>
          </div>

          {status.nftInfo?.multiple && (
            <InputField required type="number" min={1}
              label={t("nftview.offermodal.quantity")}
              errorLabel={status.errorOfferQuantity} value={status.offerQuantity}
              onChange={(e) => { if (status.nftInfo?.multiple) { updateStatus({ offerQuantity: e.target.value }) } }}
              child={
                <div style={{ backgroundColor: theme.boxColor, color: theme.text, padding: '0.75rem 1rem', fontSize: '0.8rem', borderRadius: '8px', marginRight: '8px' }}>
                  {t("nftview.offermodal.individual")}
                </div>
              }
            />
          )}

          <InputField required type="number"
            label={t("nftview.offermodal.offerprice")} min={0.0001}
            onChange={(e) => { updateStatus({ offerTokenAmount: e.target.value }) }}
            errorLabel={status.errorOfferTokenAmount} value={status.offerTokenAmount}
            child={
              <div style={{ width: '80px', marginRight: '8px' }}>
                <Dropdown hideImg
                  onChange={(key) => { updateStatus({ offerTokenType: key }) }}
                  selectedKey={status.offerTokenType} values={status.paylableTokens}
                  props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                />
              </div>
            }
          />

          <p className='text-center'>{t("nftview.offermodal.acceptedtoken")}
            {Number(balances?.find((token) => { return token.symbol.toUpperCase() === status.offerTokenType?.toUpperCase() })?.balance || 0).toFixed(4)}
            {" " + status.offerTokenType}
          </p>

          <div className="flex center">
            <ButtonPrimary style={{ backgroundColor: '#36C781', width: '280px' }}
              onClick={() => { updateStatus({ showBuyTokenModal: true }) }}
            >
              {t("nftview.offermodal.buytoken")}
            </ButtonPrimary>
          </div>

          {/* <InputField 
						label={t("nftview.offermodal.expiration")} 
						type="date" 
						value = {status.offerExpiration}
						onValueChange = {(v) => {updateStatus({offerExpiration: v })}}
						errorLabel = {status.errorOfferExpiration}
					/> */}

          <div className="flex center mt5">
            <ButtonPrimary style={{ width: '280px' }} onClick={offerNFT}>
              {t("nftview.offermodal.offernft")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showOfferModal: false }) }}
            >
              {t("nftview.offermodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showTransferModal: false }) }} show={status.showTransferModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.transfermodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.transfermodal.blockchain")}</p>
              </div>
            </div>

            <div>
              <p className='p0 m0 text-right'>{status.nftInfo?.price}
                <span style={{ color: theme.buttonPrimary }}>
                  {status.nftInfo?.acceptedToken}
                </span>
              </p>

              <p className='p0 m0 text-right'>
                {lang === 'en' ? '$' : '￥'}
                {((status.nftInfo?.price || 0) * (status?.prices.find(v => v.symbol === "MATIC")?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)}
              </p>
            </div>
          </div>

          <InputField required
            value={status.transferAddress}
            errorLabel={status.errorTransferAddress}
            label={t("nftview.transfermodal.address")}
            onChange={(e) => { updateStatus({ transferAddress: e.target.value }) }}
          />

          <div className="flex center mt1">
            <ButtonPrimary style={{ width: '280px' }} onClick={transferNFT}>
              {t("nftview.transfermodal.transfer")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showTransferModal: false }) }}
            >
              {t("nftview.transfermodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showExportModal: false }) }} show={status.showExportModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.exportmodal.title")}
          </h1>

          <div className="flex justify middle p1">
            <div className='flex middle'>
              <img src={status.nftInfo?.image}
                style={{ width: '100px', height: '100px', borderRadius: '1rem', marginRight: '1rem', objectFit: 'cover' }}
              />

              <div>
                <p className='p0 m0'>{status.nftInfo?.name}</p>
                <p className='p0 m0'>{status.collectionInfo?.metadata?.name}</p>
                <p className='p0 m0'>{t("nftview.exportmodal.blockchain")}</p>
              </div>
            </div>

            <div>
              <p className='p0 m0 text-right'>
                {status.nftInfo?.price}
                <span style={{ color: theme.buttonPrimary }}>
                  {status.nftInfo?.acceptedToken}
                </span>
              </p>

              <p className='p0 m0 text-right'>
                {lang === 'en' ? '$' : '￥'}
                {((status.nftInfo?.price || 0) * (status?.prices.find(v => v.symbol === "MATIC")?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2)}
              </p>
            </div>
          </div>

          <InputField required
            value={status.exportAddress}
            errorLabel={status.errorexportAddress}
            label={t("nftview.exportmodal.address")}
            onChange={(e) => { updateStatus({ exportAddress: e.target.value }) }}
          />

          <fieldset className="InputField">
            <legend>
              {t("nftview.exportmodal.feetoken")}
            </legend>

            <div className="flex wrap center">
              {status.paylableTokens?.map((token, index) =>
                <ButtonPrimary key={"token-btn-" + index}
                  style={{ backgroundColor: status.exportToken === token.symbol ? "#36C781" : "#a7a7a7", width: '150px', margin: '2px 8px' }}
                  onClick={() => { updateStatus({ exportToken: token.symbol }) }}>
                  {token.symbol}
                </ButtonPrimary>
              )}
            </div>
          </fieldset>

          {status.nftInfo?.multiple && (
            <InputField required type="number"
              label={t("nftview.exportmodal.quantity")} value={status.exportQuantity}
              infoLabel={t("nftview.exportmodal.infoquantity", status.nftInfo?.multiple ? status.exportQuantity.toString() : '1')}
              onChange={(e) => { updateStatus({ exportQuantity: e.target.value }) }}
              child={
                <div style={{ backgroundColor: theme.boxColor, color: theme.text, padding: '0.75rem 1rem', fontSize: '0.8rem', borderRadius: '8px', marginRight: '8px' }}>
                  {t("nftview.exportmodal.individual")}
                </div>
              }
            />
          )}

          <p className='text-center'>{t("nftview.exportmodal.fee")}
            {Number((status.exportFeeUsd / status.paylableTokens?.find(token => token?.symbol === status.exportToken)?.usd).toFixed(6)) + status.exportToken}
          </p>

          <p className='text-center m0 p0'>
            Balance: {Number(Number(balances.find(token => token?.symbol === status.exportToken)?.balance).toFixed(4)) || 0}
          </p>

          <div className="flex center mt2">
            {Number((status.exportFeeUsd / status.paylableTokens?.find(token => token?.symbol === status.exportToken)?.usd)) > Number(Number(balances.find(token => token?.symbol === status.exportToken)?.balance) || 0) && (
              <ButtonPrimary style={{ width: '250px', backgroundColor: "#36C781" }} onClick={() => { updateStatus({ showBuyTokenModal: true }) }}>
                {t("nftview.exportmodal.buytoken")}
              </ButtonPrimary>
            )}
          </div>

          <div className="flex center mt3">
            <ButtonPrimary style={{ width: '280px' }} onClick={exportNFT}>
              {t("nftview.exportmodal.export")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }} onClick={() => { updateStatus({ showExportModal: false }) }}>
              {t("nftview.exportmodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showOwnerModal: false }) }} show={status.showOwnerModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t("nftview.ownermodal.title")}
          </h1>

          <div className="m3">
            <div className="row middle">
              <div className="col-lg-9 col-8">
                <p style={{ marginLeft: '60px' }}>{t("nftview.ownermodal.name")}</p>
              </div>

              <div className="col-lg-3 col-4">
                <p className='text-right'>{t("nftview.ownermodal.ownernumber")}</p>
              </div>
            </div>

            <div style={{ maxHeight: '400px', padding: '1rem', overflowY: 'auto', overflowX: 'hidden' }}>
              <div className="row middle">
                <div className="col-lg-9 col-9">
                  <div className="flex middle cursor">
                    {<img src={status.avatar || avatar} style={{ width: '50px', height: '50px', borderRadius: '50%', padding: '4px', objectFit: 'cover' }} alt="avatar" />}

                    {status.nftInfo?.owner && (
                      <div>
                        <p className='m0 ml1' onClick={() => { navigate("/" + (status.nftInfo?.owner)) }}>
                          {status.owner || ellipsis(status.nftInfo?.owner || "", 6, false)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-3 col-3">
                  <p className='text-right'>
                    1{t("nftview.ownermodal.individual")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex center mt1">
            <ButtonPrimary style={{ width: '280px', backgroundColor: theme.boxColor }}
              onClick={() => { updateStatus({ showOwnerModal: false }) }}
            >
              {t("nftview.ownermodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      <Modal onClose={() => { updateStatus({ showSellResetModal: false }) }} show={status.showSellResetModal} >
        <>
          <h1 style={{ textAlign: 'center' }}>
            <StyledGradientText>NFT</StyledGradientText>{t("nftview.sellresetmodal.title")}
          </h1>

          <fieldset className="InputField mt3 mb3">
            <legend>
              {t("nftview.sellresetmodal.saletype")}
            </legend>

            <div className="row middle">
              <div className="col-lg-6 col-sm-12 flex center">
                <ButtonPrimary onClick={() => { updateStatus({ saleType2: "regular" }) }}
                  style={{ backgroundColor: status.saleType2 !== "auction" ? theme.buttonPrimary : theme.boxColor, width: '100%', margin: '8px 0', maxWidth: '300px', }}
                >
                  {t("nftview.sellresetmodal.regular")}
                </ButtonPrimary>
              </div>

              <div className="col-lg-6 col-sm-12 flex center">
                <ButtonPrimary onClick={() => { updateStatus({ saleType2: "auction" }) }}
                  style={{ backgroundColor: status.saleType2 === "auction" ? theme.buttonPrimary : theme.boxColor, width: '100%', margin: '8px 0', maxWidth: '300px', }}
                >
                  {t("nftview.sellresetmodal.auction")}
                </ButtonPrimary>
              </div>
            </div>
          </fieldset>

          <InputField type="number" required
            label={t("nftview.sellresetmodal.price")}
            errorLabel={status.errorSaleprice2} value={status.saleprice2}
            onChange={(e) => { updateStatus({ saleprice2: e.target.value }) }}
            child={
              <div style={{ width: '80px', marginRight: '8px' }}>
                <Dropdown hideImg
                  onChange={(key) => { updateStatus({ saleToken2: key }) }}
                  selectedKey={status.saleToken2} values={status.paylableTokens}
                  props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                />
              </div>
            }
          />

          {status.saleType2 === "auction" && (
            <>
              <CustomDtePicker required
                label={t("nftview.sellresetmodal.salestart")}
                defaultDate={status.nftInfo.startTime * 1000}
                onValueChange={(v: number) => { updateStatus({ saleStart2: v }) }}
                errorLabel={status.errorSalestart2}
                minDate={getDateValue(0)}
                value={status.saleStart2}
              />

              <CustomDtePicker required
                label={t("nftview.sellresetmodal.saleend")}
                defaultDate={status.nftInfo.expiredTime * 1000}
                onValueChange={(v: number) => { updateStatus({ saleEnd2: v }) }}
                errorLabel={status.errorSaleend2}
                minDate={status.saleStart2}
                value={status.saleEnd2}
              />
            </>
          )}

          <div className="flex center mt3 mb3">
            <div className="col-lg-8">
              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.sellingprice")}</p>
                <p className='m0 mt2'>{status.saleprice2} {status.saleToken2}</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.servicefee")}</p>
                <p className='m0 mt2'>{status.servicefee} %</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.creatorfee")}</p>
                <p className='m0 mt2'>{status.collectionInfo?.fee} %</p>
              </div>

              <div className="flex justify">
                <p className='m0 mt2'>{t("createnft.profit")}</p>
                <p className='m0 mt2'>{Number((status.saleprice2 - (status.saleprice2 * status.collectionInfo?.fee / 100) - (status.saleprice2 * status.servicefee / 100)).toFixed(8)) + " " + status.saleToken2} </p>
              </div>
            </div>
          </div>

          <div className="flex center mt1" />

          <div className="flex center">
            <ButtonPrimary style={{ width: '250px' }} onClick={changeSell}>
              {t("nftview.sellresetmodal.change")}
            </ButtonPrimary>
          </div>

          <div className="flex center mt2">
            <ButtonPrimary style={{ width: '250px', backgroundColor: theme.boxColor }} onClick={() => { updateStatus({ showSellResetModal: false }) }}>
              {t("nftview.sellresetmodal.close")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>

      {status.showImageModal && (
        <StyledImageModal>
          <div className="overlay"
            onClick={() => { updateStatus({ showImageModal: false }) }}
          />

          <div className="modal-container">
            <img alt="image" src={status.nftInfo?.image}
              style={{ margin: 0, width: '100%', height: '100%', aspectRatio: '1/1', borderRadius: '8px', objectFit: 'cover' }}
            />
          </div>
        </StyledImageModal>
      )}

      <BuyTokenModal show={status.showBuyTokenModal}
        onClose={() => { updateStatus({ showBuyTokenModal: false }) }}
      />
    </Layout >
  )
}

const AttributeValue = ({ title }) => {
  return (
    <AttributeStyle>
      <Typography variant="subtitle2">
        {title}
      </Typography>
    </AttributeStyle>
  )
}

const AttributeStyle = styled.div`
  max-width: 200px;
  text-align: center;
`

const NFTAttributeContainer = styled.div<{ darkMode: boolean }>(({ darkMode, theme }) => ({
  borderRadius: 12,
  color: theme.text,
  backgroundColor: darkMode ? '#232323' : '#eee',

  margin: '0.5rem',
  padding: '20px 1rem',
  height: 'calc(100% - 1rem)',

  overflow: 'hidden',
  textAlign: 'center',
  fontFamily: 'system-ui',

  gap: 8,
  display: 'flex',
  flexDirection: 'column',

  '.attribute-header': {
    opacity: 0.8,
    fontSize: '0.9rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },

  '.attribute-value': {
    opacity: 0.9,
    fontSize: '1.2rem',
    fontWeight: 500,
    cursor: 'pointer',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '@media (max-width: 768px)': {
    padding: 10, margin: 8,
    height: 'calc(100% - 16px)'

  }
}))

const StyledNftInfo = styled.div`
	margin: 2rem 0;
	@media (max-width: 768px) {
		margin: 8px 0;
	}
	.MuiAccordion-root{
		background-color: ${({ theme }) => theme.dropColor};
		padding: 1rem 2rem;
		border-radius: 12px!important;
		color: ${({ theme }) => theme.text};
		margin: 2rem 0;
		box-shadow: none!important;
		&.Mui-expanded{
			margin: 2rem 0!important;
		}
		@media (max-width: 768px) {
			padding: 1rem;
			margin: 1rem 0!important;
			&.Mui-expanded{
				margin: 1rem 0!important;
			}
		}
	}
	.MuiAccordionSummary-root {
		background-color: transparent;
		min-height: 50px!important;
		max-height: 50px!important;
		padding: 0!important;
		&.Mui-expanded{
			min-height: 50px!important;
		}
		@media (max-width: 768px) {
			min-height: 40px!important;
			max-height: 40px!important;
			&.Mui-expanded{
				min-height: 40px!important;
			}
		}
		p {
			color:  ${({ theme }) => theme.text};
			font-size: 24px;
			font-weight: bold;
			margin: 0;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}
`

const StyledNfts = styled.div<{ darkMode: boolean }>`
	overflow-x: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: no-wrap;
	::-webkit-scrollbar-thumb {
		background-color: ${({ darkMode }) => darkMode ? 'rgba(10, 10, 10, 0.9)' : 'rgba(200, 200, 200, 0.8)'};
		border-radius: 6px;
	}
	::-webkit-scrollbar {
		height: 8px;
	}
	// @media (max-width: 768px) {
	// 	display: flex;
	// 	flex-wrap: wrap;
	// }
	.nft {
		min-width: 220px;
		max-width: 260px;
		margin: 0px 20px 0px 0px;
		@media (max-width: 768px) {
			margin: 0px 4px 0px 0px;
			width: 50%;
			min-width: 40%;
			padding: 4px;
		}
	}
`

const StyledImg = styled.div`
	padding: 0;
	img{
		width: 100%;
		height: 100%;
		border-radius: 32px;
		aspect-ratio: 1/1;
		object-fit: cover;
	}
	div {
		width: 100%;
		height: 100%;
		border-radius: 32px;
		aspect-ratio: 1/1;
		object-fit: cover;
	}
	@media (max-width: 768px) {
		padding: 0;
		width: 100%;
		img {
			max-height: 300px;
		}
	}
`

const StyledLink = styled.a`
	color:  #9AB8FF;
	font-size: 1rem;
	cursor: pointer;
	text-decoration: none;
	@media (max-width: 768px) {
		font-size: 0.9rem;
	}
`

const StyledH1 = styled.h1`
	color:  ${({ theme }) => theme.text};
	margin: 0;
	padding: 0;
	margin-right: 3rem;
	@media (max-width: 768px) {
		margin-right: 1rem;
		font-size: 1.4rem;
	}
`

const StyledText = styled.p`
	color: ${({ theme }) => theme.text};
	padding: 0;
	margin: 0;
	@media (max-width: 768px) {
		margin-right: 0;
	}
`

const StyledTransferPanel = styled.div`
	padding: 1rem 2rem;
	@media (max-width: 768px) {
		padding: 0 1rem;
		font-size: 12px;
	}
`

const StyledSalePanel = styled.div<{ darkMode: boolean }>`
	margin: 1rem 0;
	border-radius: 8px;
	border: 1px solid ${({ darkMode }) => darkMode ? "#353945" : "#ccc"};
	background-color:  ${({ theme }) => theme.dropColor};
	.header{
		padding: 1rem 2rem;
		@media (max-width: 768px) {
			padding: 1rem;
			font-size: 12px;
		}
		border-bottom: 1px solid ${({ darkMode }) => darkMode ? "#353945" : "#ccc"};
		color: ${({ theme }) => theme.text};
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.body{
		padding: 1rem 2rem;
		@media (max-width: 768px) {
			padding: 1rem;
		}
		h1{
			font-size: 2.4rem;
			margin: 0;
			@media (max-width: 768px) {
				font-size: 26px;
			}
		}
	}
`

const StyledListTable = styled.div`
	overflow: auto;
	max-height: 350px;
	.list-row{
    gap: 5px;
		width: 100%;
		display: flex;
		padding: 1rem 0;
		min-width: 600px;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid  #353945;
		font-size: 15px;

		@media (max-width: 768px) {
			min-width: 650px;
		}

		&.th {
			color:  #777575;
		}

		div:nth-child(1){
			width: 20%;
		}

		div:nth-child(2){
			width: 15%;
		}

		div:nth-child(3){
			width: 30%;
		}

		div:nth-child(4){
			width: 20%;
		}

		div:nth-child(5){
			width: 15%;
		}
	}
`

const StyledEventTable = styled.div`
	overflow: auto;
	max-height: 350px;
	.list-row{
    gap: 5px;
		width: 100%;
		display: flex;
		padding: 1rem 0;
		min-width: 600px;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid  #353945;
		font-size: 15px;

		@media (max-width: 768px) {
			min-width: 650px;
		}

		&.th {
			color:  #777575;
		}

		div:nth-child(1){
			width: 20%;
		}

		div:nth-child(2){
			width: 15%;
		}

		div:nth-child(3){
			width: 30%;
		}

		div:nth-child(4){
			width: 20%;
		}

		div:nth-child(5){
			width: 15%;
		}
	}
`

const StyledGradientText = styled.span`
	background: -webkit-linear-gradient(45deg, #f9f592, #a7f195, #74f9d0, #5e87f1, #e59afb, #f95959);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

const StyledRightButtonBar = styled.div`
	position: fixed;
	right: 1rem;
	top: 150px;
	z-index: 100;
	@media (max-width: 768px) {
		position: absolute;
		top: 100px;;
	}
	.round-btn{
		background-color:  ${({ theme }) => theme.boxColor};
		border-radius: 50%;
		width: 70px;
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		&:hover {
			.drop-panel{
				display: block;
			}	
		}
		.drop-panel{
			position: absolute;
			display: none;
			right: 0;
			width: 230px;
			top: 90%;
			border-radius: 8px;
			background-color:  ${({ theme }) => theme.bgColor};
			border: 1px solid #ffffff;
			overflow:hidden;
			.menu{
				padding: 1rem;
				cursor: pointer;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ffffff;
				&:hover {
					background-color:  ${({ theme }) => theme.boxColor};
				}
			}
		}
	}
`

const StyledImageModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10001;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.overlay {
		position: fixed;
		background-color: ${({ theme }) => theme.modalOverlay};
		width: 100%;
		height: 100%;
		opacity: ${({ theme }) => theme.modalOpacity};
	}
	.modal-container {
		border-radius: 1rem;
		background-color: ${({ theme }) => theme.modalBg};
		color: ${({ theme }) => theme.text};
		padding: 0;
		position: absolute;
		min-width: 300px;
		height: 90%;
		z-index: 10002;
		max-height: 90vh;
		@media (max-width: 1024px) {
			width: 80%;
		}
		@media (max-width: 768px) {
			margin: 0;
			height: 60%;
			width: 90%;
		}
	}
`

export default ViewNFT;