import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function NFTItemLoading() {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
        <Skeleton width={"100%"} height={"auto"} count={1} style={{ aspectRatio: '1 / 1', borderRadius: '24px' }} />
      </SkeletonTheme>

      <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
        <Skeleton count={1} style={{ margin: '8px auto', zIndex: 2 }} />
      </SkeletonTheme>

      <div className="flex justify">
        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
          <Skeleton width={window.innerWidth < 768 ? 40 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px auto' }} />
        </SkeletonTheme>

        <SkeletonTheme baseColor={theme.modalBg} highlightColor={theme.boxColor}>
          <Skeleton width={window.innerWidth < 768 ? 40 : 100} height={window.innerWidth < 768 ? 10 : 20} count={1} style={{ margin: '8px auto' }} />
        </SkeletonTheme>
      </div>
    </div>
  )
}