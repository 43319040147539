import keccak from 'keccak'
import { ethers } from "ethers";
import Config from '../config/config.json';
import { Alchemy } from "alchemy-sdk";
import { getTokenContract } from "./contract";
import { alchemyNetwork } from './providers';

const config = {
	apiKey: Config.AlchemyApiKey,
	network: alchemyNetwork,
	url: `https://${alchemyNetwork}.g.alchemy.com/nft/v2/${Config.AlchemyApiKey}`
}

const alchemy = new Alchemy(config);

function stripHexPrefix(value: string) {
	return value.slice(0, 2) === '0x' || value.slice(0, 2) === '0X' ? value.slice(2) : value
}

export const toChecksumAddress = (address: string) => {
	try {
		if (typeof address !== 'string') return ''
		if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) throw new Error(`Given address "${address}" is not a valid Polygon address.`)

		const stripAddress = stripHexPrefix(address).toLowerCase()
		const keccakHash = keccak('keccak256').update(stripAddress).digest('hex');
		let checksumAddress = '0x';
		for (let i = 0; i < stripAddress.length; i++) {
			checksumAddress +=
				parseInt(keccakHash[i], 16) >= 8
					? stripAddress[i]?.toUpperCase()
					: stripAddress[i]
		}
		return checksumAddress
	} catch (err) {
		console.log(err)
		// console.log(err)
		return address
	}
}

export const getEthBalance = async (provider: any, address: string) => {
	const balance = await provider.getBalance(toChecksumAddress(address));
	return balance;
}

export const getTokenBalance = async (tokenAddress: string, signer: any) => {
	try {
		const contract = getTokenContract(tokenAddress, signer)
		const address = await signer.getAddress()
		const balance = await contract.balanceOf(address)
		return balance;
	} catch (err) {
		return 0;
	}
}

export const gasPrice = async (provider: any) => {
	const price_unit = "gwei";
	const price = ethers.utils.formatUnits(await provider.getGasPrice(), price_unit);
	return price;
}

export const ZeroAddress = "0x0000000000000000000000000000000000000000"

export const getNftsForOwner = async (address: string) => {
	try {
		const nfts = await alchemy.nft.getNftsForOwner(address);
		return nfts
	} catch (err) {
		console.log(err)
		return null
	}
}
