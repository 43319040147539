import React from "react";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Loading } from "./Loading";
import useStore from "../useStore";
import Layout from "./Layout/Layout";
import { Page404 } from "../pages/Other/404Page";

export const ZakZakPage = () => {
  const { reqId }: any = useParams();

  const { token, call } = useStore();
  const [_html, setHtml] = useState('');
  const [notfound, setNotFound] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (!reqId) {
          throw new Error("page not found");
        }

        const result: any = await call(
          "/api/zakzak/payment-page", { reqId: reqId },
          { authorization: token }, true
        )

        if (result?.state === 'success') {
          setHtml(result.html)
        } else {
          throw new Error("page not found");
        }
      } catch (err: any) {
        console.log(err.message)
        setNotFound(true)
      }
    })()
  }, [])

  return (
    <>
      {notfound && (
        <Layout>
          <Page404 />
        </Layout>
      )}

      {(!notfound && _html) && (
        <div className='zakzak-wrapper' dangerouslySetInnerHTML={{ __html: _html }} />
      )}

      {(!notfound && !_html) && (
        <Loading opacity={0.5} show={!_html} />
      )}
    </>
  )
}