import React from "react"
import styled from "styled-components"
import Icon from "./Icon"

interface PageInterface {
  totalCount: number
  perPage: number
  selectedPage: number
  onSelect: Function
}

const Pagination = ({ totalCount, perPage, selectedPage, onSelect }: PageInterface) => {
  const [status, setStatus] = React.useState({
    pageCount: Math.ceil(totalCount / perPage),
  })
  const updateStatus = (params) => setStatus({ ...status, ...params })

  React.useEffect(() => {
    updateStatus({ pageCount: Math.ceil(totalCount / perPage) })
  }, [totalCount, perPage])

  return (
    <StyledPagination>
      <div className={`page ${selectedPage > 1 ? '' : 'disable'}`}
        onClick={() => { selectedPage > 1 ? onSelect(selectedPage - 1) : (() => { })() }}
      >
        <Icon icon='Left' size={10} height={20}></Icon>
      </div>

      {Array.apply(0, { length: status.pageCount }).map((v, index) => {
        let startIndex = selectedPage - 3;
        if (startIndex < 0) startIndex = 0;
        let endIndex = startIndex + 4;
        if (status.pageCount <= 5 || (index <= endIndex && index >= startIndex)) {
          return (
            <div onClick={() => { onSelect(index + 1) }} key={"page" + index}
              className={`page ${selectedPage == index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </div>
          )
        }

        return <></>
      })}

      <div className={`page ${selectedPage < status.pageCount ? '' : 'disable'}`} style={{ transform: 'rotate(180deg)' }}
        onClick={() => { selectedPage < status.pageCount ? onSelect(selectedPage + 1) : (() => { })() }}
      >
        <Icon icon='Left' size={10} height={20}></Icon>
      </div>
    </StyledPagination>
  )
}

export default Pagination

const StyledPagination = styled.div`
	display: flex;
	flex-direction: row;
	border: 1px solid  ${({ theme }) => theme.strokeColor};
	border-radius: 50px;
	overflow: hidden;
	.page {
		cursor: pointer;
		padding: 0.8rem 1.6rem;
		user-select: none;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			background-color: rgba(128, 128, 128, 0.15);
		}
		&.active {
			background-color: rgba(128, 128, 128, 0.3);
		}
		@media (max-width: 768px) {
			padding: 0.6rem 1rem;
		}
		&.disable {
			background-color: rgba(132, 132, 132, 0.1);
		}
	}
`