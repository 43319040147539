import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";

import useStore from '../useStore';
import img from '../assets/img/banner.webp';
import verifiedImg from '../assets/img/verifiedImg.png';
import collectionImg from '../assets/img/collection.webp';

interface CollectonInterface {
  collection: Collection
  props?: any
}

export default function CollectionItem({ collection }: CollectonInterface) {
  const { darkMode, lang } = useStore();
  const theme = useContext(ThemeContext);

  return (
    <Link to={`/collection/${collection.url || collection.address}`} style={{ color: theme.text, textDecoration: 'none' }}>
      <StyledCollection darkMode={darkMode} collectionName={collection.metadata.name}>
        <div className="asset-img">
          <img src={collection.metadata.coverImage || img} />
        </div>

        <div className="asset-info">
          <div className="flex justify" style={{ position: 'relative' }}>
            <div>
              <div className="flex middle">
                <p className="collection-name ">{collection.metadata.name}</p>
                {collection.verified.status == "verified" && (
                  <img src={verifiedImg} alt="verified" style={{ width: '28px', height: '28px' }} />
                )}
              </div>
            </div>

            <div>
              <img src={collection.metadata.image || collectionImg} className="img" />
            </div>
          </div>

          <div className="flex justify">
            <div className="flex  middle">
              <p className='volume mt0'>
                {lang != "jp" && "$"}
                {Number(Number(lang == "jp" ? Number(collection.volumeJpy || 0) : Number(collection.volume || 0)).toFixed(4))}
                {lang == "jp" && "円"}
              </p>

              <p className='items mt0 pl3'>{collection?.items || 0} Items</p>
            </div>

            <p className='mt0 owners'>{collection?.owners || 0} Owners</p>
          </div>
        </div>
      </StyledCollection>
    </Link>
  )
}

const StyledCollection = styled.div<{ darkMode: boolean, collectionName?: string }>`
	cursor: pointer;
	position: relative;
	margin: 1rem 0;
	overflow: hidden;
	width: 100%;
	@media (max-width: 768px) {
		width: 100%!important;
		margin: 4px 0;
	}
	&:hover{
		@media (min-width: 768px) {
			.asset-img {
				img {
					transform: scale(1.2);
				}
			}
		}
	}
	.asset-img{
		height: 160px;
		overflow: hidden;
		border-radius: 12px;
		z-index: -1;
		@media (max-width: 768px) {
			height: 100px;
		}
		img {
			width: 100%;
			height: 100%;
			transition: 0.2s;
			object-fit: cover;
			border-radius: 12px;
		}
	}
	.asset-info{
		transition: 0.3s;
		padding: 14px 0;
		border-radius: 1rem;
		color: #FAFAFA;
		z-index: 100;
		p{
			margin: 0;
			font-size: 14px;
		}
		.collection-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
		}
		.volume {
			color: #78C4A0;		
			font-size: 12px;
		}
		.items {
			color: #F56F63;
			font-size: 12px;
		}
		.owners {
			font-size: 12px;
		}
		.img {
			width: 70px;
			height: 70px;
			border-radius: 8px;
			position: absolute;
			right: 4px;
			top: -56px;
			@media (max-width: 768px) {
				display: none;
			}
		}
	}
	
	&:hover {
		.collection-name {
			text-overflow: initial;
			white-space: nowrap;
			animation: ${({ collectionName }) => (collectionName || "")?.length > 26 ? "marquee 3s linear infinite alternate" : 'none'};
		}
	}
	@-webkit-keyframes marquee {
		0%   { -webkit-transform: translateX(0); }
		100% { -webkit-transform: translateX(-100%); }
	}
		
	@-moz-keyframes  marquee {
		0%   { -webkit-transform: translateX(0); }
		100% { -webkit-transform: translateX(-100%); }
	}
	
	@keyframes marquee {
		0%   { -webkit-transform: translateX(0); }
		100% { -webkit-transform: translateX(-100%); }
	}
`