import React from 'react';
import axios from 'axios';
import decode from "jwt-decode";
import { useContext } from 'react';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components'

import Icon from '../../components/Icon';
import NFTItem from '../../components/NftItem';
import Layout from '../../components/Layout/Layout';
import CollectionItem from '../../components/CollectionItem';
import { ButtonBorder, ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { ellipsis, tips, proxy, copyToClipboard, validateUrl } from '../../util';
import { GET_COLLECTION_INFO, GET_FAVORITE_NFT, GET_NFTS, GET_PRICES, GET_USER_INFO } from '../../graphql/index';

import avatar from '../../assets/img/avatar.png';
import banner from '../../assets/img/banner.webp';

interface ProfileInterface {
  currentName: string
  currentMail: string
  currentAddress: string
  bio: string
  twitter: string
  instagram: string
  link: string
  banner: string
  avatar: string
  phone: string
  verified: string
  selectedBannerFile: null | File
  selectedAvatarFile: null | File
  edit: boolean
  editLink: string
  editName: string
  editBio: string
  showBadgeModal: boolean
  switchType: number
  myOwnerNfts: NFTSearchResult[]
  myCreateNfts: NFTSearchResult[]
  favouriteNfts: NFTSearchResult[]
  myCollections: Collection[]
  prices: Prices[]
  rejectreason: string
}

const Profile = () => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { t } = useI18n()
  const { currentAccountName, token, currentAccountAddress, currentAccountMail, logined, isMetamask, lang, update, call } = useStore()

  const [status, setStatus] = React.useState<ProfileInterface>({
    currentName: '',
    currentMail: '',
    currentAddress: '',
    bio: '',
    twitter: '',
    instagram: '',
    link: '',
    banner: '',
    verified: '',
    avatar: '',
    phone: '',
    selectedBannerFile: null,
    selectedAvatarFile: null,
    edit: false,
    editLink: "",
    editBio: "",
    editName: "",
    showBadgeModal: false,
    switchType: 1,
    myOwnerNfts: [],
    favouriteNfts: [],
    myCreateNfts: [],
    myCollections: [],
    prices: [],
    rejectreason: ""
  })

  const updateStatus = (params: Partial<ProfileInterface>) => setStatus({ ...status, ...params })

  const refBanner = React.useRef<HTMLInputElement>(null);
  const refAvatar = React.useRef<HTMLInputElement>(null);

  //price
  const { data: priceInfo, loading: priceLoading, error: priceError } = useQuery(GET_PRICES, {
    fetchPolicy: 'network-only'
  })
  React.useEffect(() => {
    if (priceLoading || priceError) return;
    const data = priceInfo?.getPrice as Prices[];
    if (!data) return;
    updateStatus({ prices: data })
  }, [priceInfo, priceLoading, priceError])

  //userinfo
  const { data: userInfo, loading: infoLoading, error: infoError } = useQuery(GET_USER_INFO, {
    variables: {
      address: currentAccountAddress,
      edit: status.edit
    },
    fetchPolicy: 'network-only'
  });
  React.useEffect(() => {
    if (infoLoading || infoError) {
      return;
    }
    if (userInfo.getUsersInfo && userInfo.getUsersInfo.length > 0) {
      const data = userInfo.getUsersInfo[0];
      updateStatus({
        currentName: data.name,
        currentAddress: data.address,
        currentMail: data.email,
        bio: data.bio,
        banner: data.banner_img,
        avatar: data.avatar_img,
        phone: data.phone,
        verified: data.verified?.status,
        twitter: data.twitter,
        instagram: data.instagram,
        link: data.link,
        editLink: data.link,
        editName: data.name,
        editBio: data.bio,
        rejectreason: data.verified?.reason
      })
    }
  }, [userInfo, infoLoading, infoError]);

  //my nfts
  const { data: nftInfo, loading: nftLoading, error: nftError } = useQuery(GET_NFTS, {
    variables: {
      query: '',
      nftcollection: '',
      sort: "latest",
      price1: 0,
      price2: 0,
      searchsymbol: '',
      salestatus: '',
      page: 0,
      limit: 0,
      owner: currentAccountAddress,
      creator: '',
      symbols: ''
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000
  });
  React.useEffect(() => {
    if (nftLoading || nftError) return;
    const data = nftInfo?.getNFTs as NFTSearchResult[];
    if (!data) return;
    updateStatus({ myOwnerNfts: data })
  }, [nftInfo, nftLoading, nftError])

  //my create nfts
  const { data: nftCreatedInfo, loading: nftCreatedLoading, error: nftCreatedError } = useQuery(GET_NFTS, {
    variables: {
      query: '',
      nftcollection: '',
      sort: "latest",
      price1: 0,
      price2: 0,
      searchsymbol: '',
      salestatus: '',
      page: 0,
      limit: 0,
      owner: '',
      creator: currentAccountAddress,
      symbols: ''
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000
  });
  React.useEffect(() => {
    if (nftCreatedLoading || nftCreatedError) return;
    const data = nftCreatedInfo?.getNFTs as NFTSearchResult[];
    if (!data) return;
    updateStatus({ myCreateNfts: data });
  }, [nftCreatedInfo, nftCreatedLoading, nftCreatedError])

  //collection
  const { data: collectionInfo, loading: collectionLoading, error: collectionError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      name: '',
      category: '',
      owner: currentAccountAddress
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000
  });
  React.useEffect(() => {
    if (collectionLoading || collectionError) return;
    const result = collectionInfo?.getCollectionInfo;
    if (!result) return;
    updateStatus({ myCollections: result })
  }, [collectionInfo, collectionLoading, collectionError])

  //favoriteNFT
  const { data: favoriteNftInfo, loading: favoriteNftLoading, error: favoriteNftError } = useQuery(GET_FAVORITE_NFT, {
    variables: {
      owner: currentAccountAddress
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000
  });

  React.useEffect(() => {
    if (favoriteNftLoading || favoriteNftError) return;
    const result = favoriteNftInfo?.getFavoriteNFT as NFTSearchResult[];
    if (!result) return;
    updateStatus({ favouriteNfts: result })
  }, [favoriteNftInfo, favoriteNftLoading, favoriteNftError])

  const changeBanner = async (event) => {
    try {
      const file = event.target.files[0];
      if (file?.size > 1024 * 1024) {
        return tips("error", "Max: 1MB")
      }

      var formData = new FormData();
      formData.append("address", currentAccountAddress);
      formData.append("bannerImage", file);
      update({ loading: true })
      var response = await axios.post(proxy + "/api/profile/change-banner", formData, {
        headers: {
          "Content-Type": "multipart/form",
          "Apollo-Require-Preflight": true,
          'authorization': token
        }
      }
      );
      if (response.status === 200 && response.data.message === "success") {
        const url = response.data.result;
        updateStatus({ banner: url })
        tips("success", t("profile.changedsuccess"))
      }
      else {
        tips("error", t("profile.failedchangebanner"))
      }
      update({ loading: false })
    } catch (err) {
      if (err.message === "Request failed with status code 403") {
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, loading: false });
        tips("warning", t("action.needlogin"))
        return;
      }
      update({ loading: false })
    }
  }

  const changeAvatar = async (event) => {
    try {
      const file = event.target.files[0];
      if (file?.size > 1024 * 1024) {
        return tips("error", "Max: 1MB")
      }

      var formData = new FormData();
      formData.append("address", currentAccountAddress);
      formData.append("avatarImage", file);
      update({ loading: true })
      var response = await axios.post(proxy + "/api/profile/change-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form",
          "Apollo-Require-Preflight": true,
          'authorization': token
        }
      }
      );
      if (response.status === 200 && response.data.message === "success") {
        const url = response.data.result;
        updateStatus({ avatar: url })
        update({ currentAccountAvatar: url })
        tips("success", t("profile.changedsuccess"))
      }
      else {
        tips("error", t("profile.failedchangeavatar"))
      }
      update({ loading: false })
    } catch (err) {
      if (err.message === "Request failed with status code 403") {
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, loading: false });
        tips("warning", t("action.needlogin"))
        return;
      }
      update({ loading: false })
    }
  }

  const saveProfile = async () => {
    const name = status.editName;
    const bio = status.editBio;
    let link = status.editLink;
    if (!link.startsWith("http")) {
      link = "http://" + link;
    }
    update({ loading: true })
    const result = await call("/api/change-profile", {
      name: name,
      address: currentAccountAddress,
      link: link,
      bio: bio
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": {
          const token = result['token'];
          var data = decode(token) as any;
          update({
            currentAccountMail: data.email,
            currentAccountName: data.name,
            currentAccountAvatar: data.avatar_img,
            currentAccountAddress: data.address,
            logined: true,
            balances: data.balances
          })
          updateStatus({ edit: false })
          tips("success", t("profile.changedprofile")); break;
        }
        case "Please enter all required data.": tips("error", t("profile.errorinput")); break;
        case "Already exists same name or email.": tips("error", t("profile.existssameuser")); break;
        case "internal error": tips("error", t("action.servererror")); break;
      }
    }
    update({ loading: false })
  }

  const verifyUser = async () => {
    update({ loading: true })
    const result = await call("/api/profile/verify-user", {
      name: currentAccountName,
      email: currentAccountMail
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": {
          updateStatus({ showBadgeModal: false, verified: "pending" })
          tips("success", t("profile.requestedbadge")); break;
        }
        case "already verified": tips("info", t("profile.alreadyrequested")); break;
        case "internal error": tips("error", t("action.servererror")); break;
      }
    }
    update({ loading: false })
  }

  const searchNft = (type: number = 1) => {
    updateStatus({ switchType: type });
  }

  React.useEffect(() => {
    if (!logined) navigate("/login")
  }, [logined])

  return (
    <Layout>
      <StyledCoverImage onClick={() => { refBanner.current?.click() }}>
        {
          <div className="banner">
            <img src={status.banner || banner} alt="banner" style={{ objectFit: 'cover' }} />
            <div className="overlay"></div>
            <input type="file" accept="image/*" hidden ref={refBanner} onChange={(e) => { changeBanner(e) }} />
          </div>
        }
        <ButtonBorder className='edit-btn'>
          {t("profile.editcover")}
        </ButtonBorder>
      </StyledCoverImage>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <StyledUserPanel>
              {
                <div className="avatar" onClick={() => { refAvatar.current?.click() }}>
                  <div className="overlay"></div>
                  <img src={status.avatar || avatar} alt='avatar' style={{ borderRadius: '50%', objectFit: 'cover', userSelect: 'none' }} />
                  {
                    status.verified === "verified" && <div className="icon">
                      <Icon icon="Photo" size={42} />
                    </div>
                  }
                  <input type="file" accept="image/*" hidden ref={refAvatar} onChange={(e) => { changeAvatar(e) }} />
                </div>
              }
              {
                !status.edit && <h2 className='title'>{status.currentName || "User"}</h2>
              }
              {
                status.edit && <input type={"text"} placeholder="name" className="input mt3" style={{ backgroundColor: 'transparent', color: theme.text, border: '1px solid #666', borderRadius: '8px', fontSize: '1.4rem', width: '100%', textAlign: 'center', boxShadow: 'none', outline: 'none' }} value={status.editName} onChange={(e) => { updateStatus({ editName: e.target.value }) }} />
              }
              <div className="flex middle center">
                <p className='address'>{ellipsis(status.currentAddress, 16, false)}</p>
                <div className='cursor' onClick={() => { copyToClipboard(status.currentAddress) }}>
                  <Icon icon="Copy" size={20} marginLeft={12} />
                </div>
              </div>
              {
                !status.edit && <p className='des'>{status.bio}</p>
              }
              {
                status.edit && <textarea placeholder='bio' style={{ backgroundColor: 'transparent', color: theme.textGrey, border: '1px solid #666', borderRadius: '8px', fontSize: '1rem', textAlign: 'center', boxShadow: 'none', width: '100%', outline: 'none' }} value={status.editBio} onChange={(e) => { updateStatus({ editBio: e.target.value }) }} />
              }
              {
                status.edit && <div className='mt3'>
                  {
                    !isMetamask && <>
                      <ButtonPrimary style={{ backgroundColor: '#646464', width: '100%', margin: '1rem 0' }} onClick={() => { navigate("/change-mail") }}>{t("profile.changeemail")}</ButtonPrimary>
                      <ButtonPrimary style={{ backgroundColor: '#646464', width: '100%', margin: '1rem 0' }} onClick={() => { navigate("/change-password") }}>{t("profile.changepassword")}</ButtonPrimary>
                    </>
                  }
                  {
                    status.verified !== "verified" &&
                    <ButtonPrimary
                      style={{
                        backgroundColor: status.verified === "" ? "#36C781" : (status.verified === "pending" ? "rgba(89, 211, 152, 0.5)" : "rgba(211, 89, 89, 0.5)"),
                        width: '100%', margin: '1rem 0', display: 'flex'
                      }}
                      onClick={() => {
                        status.verified === "" && updateStatus({ showBadgeModal: true })
                      }}
                      disabled={status.verified === "pending" || status.verified === "rejected"}
                    >
                      {
                        status.verified === "" ?
                          t("profile.requestbadge") :
                          status.verified === "pending" ? t("profile.requestbadgein") : t("profile.rejectedbadge")
                      }
                    </ButtonPrimary>
                  }
                  {
                    status.verified === "pending" && <p className='text-center'>{t("profile.pendingbadge")}</p>
                  }
                  {
                    status.verified === "rejected" && <p className='text-center'>{t("profile.rejectreason")}{status.rejectreason}</p>
                  }
                  {/* <p className='text-center'>SNS</p>
										<ButtonPrimary style={{backgroundColor: '#1da1f2', borderRadius:'10px', width: '100%', margin: '1rem 0'}} onClick={() => {window.location.href = status.twitter;}}>
											<Icon icon="Twitter" size={24} margin={16}/>
											Twitterへ接続
										</ButtonPrimary>
										<ButtonPrimary style={{backgroundColor: '#cf2e92', borderRadius:'10px', width: '100%', margin: '1rem 0'}} onClick={() => {window.location.href = status.instagram;}}>
											<Icon icon="Instagram" size={24} margin={16}/>
											Instagramへ接続
										</ButtonPrimary> */}
                  <StyledInput>
                    <Icon icon="Shape" margin={34} />
                    <input type="text" placeholder='https://aaa.com' value={status.editLink} onChange={(e) => { updateStatus({ editLink: e.target.value }) }} />
                  </StyledInput>
                  <div className="row mt3">
                    <div className="col-6">
                      <ButtonPrimary style={{ backgroundColor: '#a7a7a7', width: '100%', margin: '0 0' }} onClick={() => { updateStatus({ edit: false, editName: status.currentName }); }}>
                        {t("profile.back")}
                      </ButtonPrimary>
                    </div>
                    <div className="col-6">
                      <ButtonPrimary style={{ width: '100%', margin: '0 0' }} onClick={saveProfile}>
                        {t("profile.save")}
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              }
              {
                !status.edit && <>
                  <div className="flex center">
                    {/* <a className='pointer' style={{color: theme.textGrey}} href={status.twitter} target="_blank"><Icon icon="Twitter" ></Icon></a>
											<a className='pointer' style={{color: theme.textGrey}} href={status.twitter} target="_blank"><Icon icon="Instagram"></Icon></a> */}
                    {
                      status.link && status.link !== "http://" &&
                      <a className='pointer' style={{ color: theme.textGrey, width: '32px' }} href={status.link} target="_blank">
                        <Icon icon="Shape"></Icon>
                      </a>
                    }
                  </div>
                  <div className="flex center mt1">
                    <ButtonBorder style={{ width: '200px' }} onClick={() => { updateStatus({ edit: true }) }}>{t("profile.editprofile")}</ButtonBorder>
                  </div>
                </>
              }
            </StyledUserPanel>
          </div>
          <div className="col-lg-8 col-md-6 col-sm-12  p2">
            <div className="flex mt5 wrap">
              <ButtonPrimary onClick={() => { searchNft(1) }} style={{ padding: '8px 1rem', backgroundColor: status.switchType === 1 ? theme.buttonPrimary : 'transparent' }}>{t("profile.mynft")}</ButtonPrimary>
              <ButtonPrimary onClick={() => { searchNft(2) }} style={{ padding: '8px 1rem', background: status.switchType === 2 ? theme.buttonPrimary : 'transparent' }}>{t("profile.creatednft")}</ButtonPrimary>
              <ButtonPrimary onClick={() => { searchNft(3) }} style={{ padding: '8px 1rem', background: status.switchType === 3 ? theme.buttonPrimary : 'transparent' }}>{t("profile.favorite")}</ButtonPrimary>
              <ButtonPrimary onClick={() => { searchNft(4) }} style={{ padding: '8px 1rem', background: status.switchType === 4 ? theme.buttonPrimary : 'transparent' }}>{t("profile.mycollection")}</ButtonPrimary>
            </div>
            <div className='row p2'>
              {
                status.switchType === 1 && <>
                  {
                    status.myOwnerNfts.length > 0 &&
                    status.myOwnerNfts.map((nft, index) =>
                      <div className='col-lg-3 col-md-4 col-sm-6 col-6 p1' key={"mynft-" + index}>
                        <NFTItem
                          nft={nft}
                          id={nft._id}
                          nftCollection={nft.nftCollection}
                          tokenId={nft.tokenid}
                          owner={nft.owner}
                          img={nft.coverImage}
                          name={nft.name}
                          balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                          symbol={nft.acceptedToken}
                          balanceUsd={
                            (lang === 'en' ? '$' : '￥') +
                            (
                              nft.price > 0 && nft.price < 2147483647 ?
                                ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2))
                                :
                                0
                            )
                          }
                        />
                      </div>
                    )
                  }
                </>
              }
              {
                status.switchType === 2 && <>
                  {
                    status.myCreateNfts.length > 0 &&
                    status.myCreateNfts.map((nft, index) =>
                      <div className='col-lg-3 col-md-4 col-sm-6 col-6 p1' key={"mycreated-" + index}>
                        <NFTItem
                          nft={nft}
                          id={nft._id}
                          nftCollection={nft.nftCollection}
                          tokenId={nft.tokenid}
                          owner={nft.owner}
                          img={nft.coverImage}
                          name={nft.name}
                          balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                          symbol={nft.acceptedToken}
                          balanceUsd={
                            (lang === 'en' ? '$' : '￥') +
                            (
                              nft.price > 0 && nft.price < 2147483647 ?
                                ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2))
                                :
                                0
                            )
                          }
                        />
                      </div>
                    )
                  }
                </>
              }
              {
                status.switchType === 3 && <>
                  {
                    status.favouriteNfts.length > 0 &&
                    status.favouriteNfts.map((nft, index) =>
                      <div className='col-lg-3 col-md-4 col-sm-6 col-6 p1' key={"myfavnft-" + index}>
                        <NFTItem
                          nft={nft}
                          id={nft._id}
                          nftCollection={nft.nftCollection}
                          tokenId={nft.tokenid}
                          owner={nft.owner}
                          img={nft.coverImage}
                          name={nft.name}
                          balance={(nft.price > 0 && nft.price < 2147483647 ? nft.price : 0)}
                          symbol={nft.acceptedToken}
                          balanceUsd={
                            (lang === 'en' ? '$' : '￥') +
                            (
                              nft.price > 0 && nft.price < 2147483647 ?
                                ((nft.price * (status.prices.find(v => { return v.symbol === nft.acceptedToken })?.[lang === 'en' ? 'usd' : 'jpy'] || 0)).toFixed(2))
                                :
                                0
                            )
                          }
                        />
                      </div>
                    )
                  }
                </>
              }
            </div>
            <div className='row p2 pt0 '>
              {
                status.switchType === 4 && <>
                  {
                    status.myCollections.length > 0 &&
                    status.myCollections.map((collection, index) => (
                      <div className='col-lg-4 col-md-4 col-sm-6 col-6 p1 pt0' key={"mycollection-" + index}>
                        <CollectionItem collection={collection} />
                      </div>
                    ))
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>

      {status.showBadgeModal && (
        <StyledModal>
          <div className="overlay" />

          <div className="modal-container">
            <div className="icon row center">
              <Icon icon="Photo" size={80} />
            </div>

            <h1 className='text-center '>
              {t("profile.badgetitle")}
            </h1>

            <p className='ml-auto mr-auto' style={{ maxWidth: '500px' }}>
              {t("profile.badgecontent")}
            </p>

            <div className="row center mt5">
              <ButtonPrimary style={{ width: '100%', maxWidth: '400px' }} onClick={verifyUser}>
                {t("profile.badgetitle")}
              </ButtonPrimary>
            </div>

            <div className="row center mt2">
              <ButtonPrimary onClick={() => { updateStatus({ showBadgeModal: false }) }}
                style={{ width: '100%', maxWidth: '400px', background: 'rgb(31 36 34)' }}
              >
                {t("profile.requestcancel")}
              </ButtonPrimary>
            </div>
          </div>
        </StyledModal>
      )}
    </Layout>
  )
}

export default Profile;

const StyledCoverImage = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	cursor: pointer;
	.banner{
		margin: 0;
		padding: 0;
		height: 300px;
		@media (max-width: 768px) {
			height: 150px;
			margin-top: 30px;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
		img{
			width: 100%;
			max-height: 300px;
			height: 300px;
			@media (max-width: 768px) {
				height: 150px;
			}
		}
		.overlay{
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	.edit-btn{
		position: absolute;
		right: 20%;
		top: 40%;
		@media (max-width: 768px) {
			top: 10%;
			left: 20%;
		}
	}
`

// const StyledNFTRow = styled.div`
// 	display: grid;
// 	justify-content: flex-start;
// 	grid-template-columns: auto auto auto auto;
// 	grid-gap: 20px;
// 	@media (max-width: 1480px) {
// 		grid-template-columns: auto auto auto;
// 		grid-gap: 20px;
// 		padding: 1rem;
// 	}
// 	@media (max-width: 1280px) {
// 		grid-template-columns: auto auto;
// 		grid-gap: 8px;
// 		padding: 8px;
// 	}
// 	@media (max-width: 1024px) {
// 		grid-template-columns: auto auto;
// 		grid-gap: 20px;
// 		padding: 8px;
// 	}
// 	@media (max-width: 987px) {
// 		grid-template-columns: auto;
// 		grid-gap: 10px;
// 		padding: 8px;
// 	}
// 	@media (max-width: 768px) {
// 		grid-template-columns: auto auto;
// 		grid-gap: 6px;
// 		padding: 4px;
// 	}
// `

const StyledCollectionRow = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 10px;
	@media (max-width: 1480px) {
		grid-template-columns: auto auto auto;
		grid-gap: 20px;
		padding: 1rem;
	}
	@media (max-width: 1280px) {
		grid-template-columns: auto auto;
		grid-gap: 8px;
		padding: 8px;
	}
	@media (max-width: 1024px) {
		grid-template-columns: auto;
		grid-gap: 20px;
		padding: 8px;
	}
	@media (max-width: 967px) {
		grid-template-columns: auto;
		grid-gap: 10px;
		padding: 8px;
	}
	@media (max-width: 768px) {
		grid-template-columns: auto auto;
		padding: 8px;
	}
	@media (max-width: 480px) {
		grid-template-columns: auto;
		padding: 8px;
	}
`

const StyledModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10001;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.overlay{
		position: fixed;
		background-color: ${({ theme }) => theme.modalOverlay};
		width: 100%;
		height: 100%;
		opacity: ${({ theme }) => theme.modalOpacity};
	}
	.modal-container{
		border-radius: 1rem;
		background-color: ${({ theme }) => theme.modalBg};
		color: ${({ theme }) => theme.text};
		padding: 1rem 2rem 2rem;
		position: absolute;
		max-width: 600px;
		min-width: 300px;
		margin-left: auto;
		width: 50%;
		z-index: 10002;
		max-height: 90vh;
		overflow-y: auto;
		@media (max-width: 768px) {
			margin: 0;
			width: 95%;
			// border-radius: 0;
			// position: fixed;
			// bottom: 0;
			// max-height: 80vh;
		}
	}
`

const StyledUserPanel = styled.div`
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem;
	border-radius: 2rem;
	text-align: center;
	max-width: 400px;
	z-index: 999;
	margin: 1rem 2rem;
	transform: translateY(-250px);
	@media (max-width: 768px) {
		transform: translateY(0);
		margin: 0 1rem;
		margin-top: -80px;
	}
	.avatar{
		border-radius: 50%;
		width:100px;
		height:100px;
		position: relative;
		margin: 0 auto;
		cursor: pointer;
		img{
			width: 100%;
			height: 100%;
		}
		.overlay{
			display: none;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			z-index: 1000;
			position: absolute;
			left:0;
			top:0;
		}
		.icon{
			border-radius: 50%;
			position: absolute;
			right: -8px;
			top: -8px;
			z-index: 1001;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
	}
	a{
		width: 50px;
		height: 50px;
		margin: 1rem;
		&:hover{
			color: ${({ theme }) => theme.textGrey};
		}
	}
	.title{
		font-size: 2rem;
		@media (max-width: 768px) {
			font-size: 1.2rem;
		}
	}
	.address{
		font-size: 0.8rem;
	}
	.des{
		font-size: 0.8rem;
		opacity: 0.7	;
	}
`

const StyledInput = styled.div`
	position: relative;
	border-bottom: 1px solid ${({ theme }) => theme.strokeColor};
	padding: 1rem 0;
	margin: 1rem 0;
	display: flex;
	flex-direction: row;
	flex: 1 10;
	input{
		border: none;
		background-color: transparent;
		flex: 1;
		color: ${({ theme }) => theme.text};
		&:hover, &:focus{
			border: none;
			outline: none;
			box-shadow: none;
		}
	}
`