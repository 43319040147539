import React from 'react';
import decode from "jwt-decode";
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { Now, tips, validateEmail, validatePassword, validateUsername } from '../../util';

interface LoginInterface {
  name: string
  password: string
  errorname: string
  errorpassword: string
  error: string
}

const Login = () => {
  const navigate = useNavigate();
  const { t } = useI18n()
  const { darkMode, redirectPage, update, logined, call } = useStore()

  const [status, setStatus] = React.useState<LoginInterface>({
    name: '',
    password: '',
    error: '',
    errorname: '',
    errorpassword: '',
  })

  const updateStatus = (params: Partial<LoginInterface>) => setStatus({ ...status, ...params })

  const login = async () => {
    try {
      const name = status.name;
      const password = status.password;
      updateStatus({ error: "", errorname: "", errorpassword: "" })
      if (name.trim() === "" || (!validateEmail(name) && !validateUsername(name))) return updateStatus({ error: "", errorpassword: "", errorname: t("action.login.errorname") });
      if (password.trim() === "" || !validatePassword(password)) return updateStatus({ error: "", errorpassword: t("action.login.errorpassword"), errorname: "" });
      update({ loading: true })
      const result = await call("/api/login", {
        name: name,
        password: password,
      })

      if (result) {
        switch (result['message']) {
          case "success": {
            const token = result['token'];
            var data = decode(token) as any;
            update({
              currentAccountMail: data.email,
              token: token,
              currentAccountName: data.name,
              currentAccountAvatar: data.avatar_img,
              currentAccountAddress: data.address,
              logined: true,
              isMetamask: false,
              isWalletConnect: false,
              balances: data.balances,
              lasttime: Now()
            })
            tips("success", t("action.login.success"));
            setTimeout(() => {
              navigate(redirectPage ? redirectPage : '/')
            }, 500);
            break;
          }

          case "check confirm code": navigate("/signup/resend-code"); break;
          case "Please enter all required data.": updateStatus({ errorpassword: "", errorname: "", error: t("action.login.errorinput") }); break;
          case "No exists user.": updateStatus({ errorpassword: "", errorname: "", error: t("action.login.noexistuser") }); break;
          case "No match password.": updateStatus({ errorpassword: "", errorname: "", error: t("action.login.wrongpassword") }); break;
          case "internal error": updateStatus({ errorpassword: "", errorname: "", error: t("action.servererror") }); break;

          case 'account closed!':
            tips('error', t('action.accountClosed'));
            break
        }
      }
      update({ loading: false })
    } catch (ex) {
      update({ loading: false })
      return tips("error", t("action.unknownerror"))
    }
  }

  React.useEffect(() => {
    if (logined) navigate("/profile")
  }, [])

  return (
    <Layout>
      <div >
        <StyledContainer className="container">
          <StyledLoginPanel darkMode={darkMode}>
            <h3 className='title'>{t("login.title")}</h3>

            <InputField label={t("login.name")} placeholder=""
              errorLabel={status.errorname} value={status.name}
              onChange={(e) => { updateStatus({ name: e.target.value }) }}
            />

            <InputField type="password" placeholder=''
              label={t("login.password")} value={status.password}
              onChange={(e) => { updateStatus({ password: e.target.value }) }}
              errorLabel={status.errorpassword}
            />

            <p style={{ fontSize: '0.8rem' }} className="link" >
              {t("login.forgetpassword")} <Link to="/forget-password">{t("login.resetpassword")}</Link>
            </p>

            <div className="flex center mt3">
              <ButtonPrimary style={{ width: '200px' }} onClick={login}>
                {t("login.signin")}
              </ButtonPrimary>
            </div>

            {status.error !== "" && (
              <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
            )}

            <p className='text-center'>
              {t("login.needaccount")} <Link to="/signup">{t("login.signupbtn")}</Link>
            </p>
          </StyledLoginPanel>
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default Login;


const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledLoginPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`
