import React from 'react';
import axios from 'axios';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import styled, { ThemeContext } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState, useContext } from 'react';

import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Layout from '../../components/Layout/Layout';
import CustomCheckbox from '../../components/Checkbox';
import { ButtonPrimary } from '../../components/components';
import { InputField, LinkInputField } from '../../components/InputField';

import useStore from '../../useStore';
import config from '../../config/config.json';
import { proxy, tips } from '../../util';
import { GET_CATEGORY, GET_COLLECTION_INFO } from '../../graphql/index';
import collection from '../../assets/img/collection.webp';

interface CollectionInterface {
  collectionid: string
  showBadgeModal: boolean
  banner: null | any
  img: null | any
  imageBlob: null | Blob
  bannerBlob: null | Blob
  name: string
  errorName: string
  slugurl: string
  errorSlugurl: string
  errorUrl: string
  description: string
  errorDescription: string
  category: boolean[]
  categorylist: string[]
  errorCategory: "",
  site: string
  errorSite: string
  errorTwitter: string
  discord: string
  errorDiscord: string
  instagram: string
  errorInstagram: string
  fee: number
  errorFee: string
  address: string
  website: string
  twitter: string
  notfound: boolean
  keyword: string
  network: string
  price1: string
  price2: string
  symbol: string
  creator: string
  saleStatus: string[]
  symbolType: string[]
  sort: string
  nfts: NFTSearchResult[]
  datanotfound: boolean
  checked: boolean
  verified: "rejected" | "pending" | "verified" | "",
  reason: string
}

const EditCollection = () => {
  const { t } = useI18n();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { darkMode, update, call, currentAccountAddress, lang, logined, token } = useStore();

  const [status, setStatus] = useState<CollectionInterface>({
    collectionid: location.pathname.split("/")[2].toString(),
    address: '',
    showBadgeModal: false,
    bannerBlob: null,
    imageBlob: null,
    banner: null,
    img: null,
    name: '',
    errorName: '',
    slugurl: '',
    errorSlugurl: '',
    errorUrl: '',
    description: '',
    errorDescription: '',
    categorylist: [],
    category: [],
    errorCategory: "",
    site: '',
    errorSite: '',
    twitter: '',
    errorTwitter: '',
    discord: '',
    errorDiscord: '',
    instagram: '',
    errorInstagram: '',
    fee: 0.5,
    errorFee: '',
    creator: '',
    website: "",
    notfound: false,
    network: "",
    keyword: "",
    price1: '',
    price2: '',
    symbol: 'MATIC',
    saleStatus: [],
    symbolType: [],
    sort: 'latest',
    nfts: [],
    datanotfound: false,
    checked: false,
    verified: "",
    reason: ""
  })

  const updateStatus = (params: Partial<CollectionInterface>) => setStatus({ ...status, ...params })

  const refBanner = useRef<HTMLInputElement>(null);
  const refAvatar = useRef<HTMLInputElement>(null);

  const { data: categoryInfo, loading: categoryLoading, error: categoryError } = useQuery(GET_CATEGORY, {
    variables: { lang: lang },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (categoryLoading || categoryError) return;
    update({ loading: false })
    const data = categoryInfo?.getCategory as Category[];
    if (!data) return updateStatus({ notfound: true });
    const v = [];
    data.forEach((element: Category) => {
      v.push((lang == "en" ? element.en : element.jp) || '')
    });
    updateStatus({
      categorylist: v
    })

  }, [categoryInfo, categoryLoading, categoryError])

  const { data: collectionInfo, loading: infoLoading, error: infoError } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      address: status.collectionid,
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (infoLoading || infoError) return;
    update({ loading: false })
    const data = collectionInfo?.getCollectionInfo?.[0] as Collection;
    if (!data) return updateStatus({ notfound: true });
    let category = new Array<boolean>(status.categorylist.length);
    data.category.forEach(e => {
      category[e] = true;
    })

    updateStatus({
      banner: data?.metadata?.coverImage,
      img: data?.metadata?.image,
      name: data?.metadata?.name,
      address: data?.address,
      description: data?.metadata?.description,
      fee: data?.fee,
      creator: data?.owner,
      slugurl: data?.url,
      category: category,
      checked: data?.pick,
      website: data?.metadata?.links.find((link) =>
        link.name === "website"
      )?.link || "",
      twitter: data?.metadata?.links.find((link) =>
        link.name === "twitter"
      )?.link?.replace("https://twitter.com/", "").replace("https://www.twitter.com/", ""),
      discord: data?.metadata?.links.find((link) =>
        link.name === "discord"
      )?.link?.replace("https://discord.gg/", "").replace("https://www.discord.gg/", ""),
      instagram: data?.metadata?.links.find((link) =>
        link.name === "instagram"
      )?.link?.replace("https://instagram.com/", "").replace("https://www.instagram.com/", ""),
      verified: data?.verified?.status,
      reason: data?.verified?.reason
    })

    if (data?.owner?.toUpperCase() !== currentAccountAddress.toUpperCase() && config.admin_wallet.toUpperCase() !== currentAccountAddress.toUpperCase()) {
      navigate("/collection/" + status.collectionid);
    }
  }, [collectionInfo, infoLoading, infoError])

  const changeBanner = async (event) => {
    const file = event.target.files[0];
    updateStatus({ banner: URL.createObjectURL(file), bannerBlob: file })
  }

  const changeAvatar = async (event) => {
    const file = event.target.files[0];
    updateStatus({ img: URL.createObjectURL(file), imageBlob: file })
  }

  const saveCollection = async () => {
    try {
      const name = status.name;
      const slugurl = status.slugurl;
      const description = status.description;
      const categori = status.category;
      const url = status.website;
      const fee = status.fee;
      let twitter = status.twitter;
      let checked = status.checked;
      let discord = status.discord;
      let instagram = status.instagram;

      if (!!twitter && twitter.length > 0 && !twitter.startsWith("https://twitter.com")) twitter = "https://twitter.com/" + twitter;
      if (!!instagram && instagram.length > 0 && !instagram.startsWith("https://instagram.com")) instagram = "https://instagram.com/" + instagram;
      if (!!discord && discord.length > 0 && !discord.startsWith("https://discord.gg")) discord = "https://discord.gg/" + discord;

      const cts = [] as number[];
      categori.forEach((element, index) => {
        if (element) cts.push(index);
      });
      var formData = new FormData();
      formData.append("coverImageBlob", status.bannerBlob);
      formData.append("imageBlob", status.imageBlob);
      formData.append("coverImage", status.banner);
      formData.append("image", status.img);
      formData.append("name", name);
      formData.append("slugurl", slugurl);
      formData.append("pick", checked ? '1' : '2');
      formData.append("description", description);
      formData.append("categori", cts.toString());
      formData.append("fee", fee.toString());
      if (url) formData.append("url", url);
      if (twitter) formData.append("twitter", twitter);
      if (discord) formData.append("discord", discord);
      if (instagram) formData.append("instagram", instagram);
      formData.append("address", status.address);
      formData.append("creator", currentAccountAddress);
      update({ loading: true })
      var response = await axios.post(proxy + "/api/collection/update-info", formData, {
        headers: {
          "Content-Type": "multipart/form",
          "Apollo-Require-Preflight": true,
          'authorization': token
        }
      }
      ) as any;
      if (response.status === 200) {
        switch (response.data.message) {
          case "success": {
            tips("success", t("action.collection.updatesuccess"));
            navigate("/collection/" + (slugurl || status.address.toUpperCase()))
            break;
          }
          case "gas error": tips("error", t("action.collection.gaserror")); break;
          case "exists same slugurl": tips("error", t("action.collection.existsameslugurl")); break;
          case "gas estimated": tips("error", t("action.collection.gaserror")); break;
          case "No contract creator": tips("error", t("action.collection.notcontractcreator")); break;
          case "out of gas": tips("error", t("action.collection.gaserror")); break;
          case "No exists user.": tips("error", t("action.collection.noexistuser")); break;
          case "internal error": tips("error", t("action.collection.networkerror")); break;
        }
      }
      update({ loading: false });
    } catch (err) {
      console.log("saveCollection", err.message);
      if (err.message === "Request failed with status code 403") {
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, loading: false });
        tips("warning", t("action.collection.login"))
        return;
      }
      update({ loading: false })
    }
  }

  const requestBadge = async () => {
    const result = await call("/api/collection/set-badge", {
      collectionid: status.address,
      owner: currentAccountAddress
    }, { authorization: token })
    if (result && result["message"] === "success") {
      updateStatus({ showBadgeModal: false, verified: "pending" })
      tips("success", t("action.collection.badgesubmited"))
    }
  }

  React.useEffect(() => {
    if (!logined) navigate("/login")
  }, [logined])

  return (
    <Layout>
      <StyledTitle>
        {t("editcollection.title")}
      </StyledTitle>

      <StyledCoverImage onClick={() => { refBanner.current?.click() }}>
        <div className="banner">
          <img src={status.banner || collection} alt="banner" />
          <div className="overlay"></div>
          <input type="file" accept="image/*" hidden ref={refBanner} onChange={(e) => { changeBanner(e) }} />
        </div>

        <div className="edit-btn">
          <ButtonPrimary style={{ backgroundColor: darkMode ? 'rgb(36, 38, 47)' : theme.boxColor, borderRadius: '1rem', width: '300px', padding: '1rem' }}>
            {t("editcollection.selectcover")}
          </ButtonPrimary>

          <p style={{ fontSize: '0.9rem', textAlign: 'center', color: theme.textGrey }}>
            {t("editcollection.role")}
          </p>
        </div>
      </StyledCoverImage>

      <StyledCollectionPanel className="container">
        <div className="row middle ">
          <div className="col-4">
            <div onClick={() => { refAvatar.current?.click() }}
              style={{ borderRadius: '8px', cursor: 'pointer', backgroundColor: theme.boxColor, width: '100%', aspectRatio: '1/1', marginTop: '-20px' }}
            >
              <img src={status.img || collection} style={{ width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover' }} />
              <input type="file" accept="image/*" hidden ref={refAvatar} onChange={(e) => { changeAvatar(e) }} />
            </div>
          </div>

          <div className="col-8">
            <p style={{ fontSize: '0.9rem', color: theme.textGrey }}>{t("editcollection.role")}</p>
            <ButtonPrimary className='select-img'
              onClick={() => { refAvatar.current?.click() }}
              style={{ backgroundColor: darkMode ? 'rgb(36, 38, 47)' : theme.boxColor }}
            >
              {t("editcollection.selectimg")}
            </ButtonPrimary>
          </div>
        </div>

        <InputField required label={t("editcollection.name")} errorLabel={status.errorName}
          value={status.name} onChange={(e) => { updateStatus({ name: e.target.value }) }}
        />

        <LinkInputField required
          value={status.slugurl}
          errorLabel={status.errorSlugurl}
          label={t("createcollection.slugurl")}
          linkType="https://mecha.art/collection/"
          onChange={(v: string) => { updateStatus({ slugurl: v }) }}
        />

        <InputField type="textarea"
          value={status.description}
          errorLabel={status.errorDescription}
          label={t("editcollection.description")}
          onChange={(e) => { updateStatus({ description: e.target.value }) }}
        />

        <fieldset className="InputField mt3 mb3">
          <legend>
            {t("editcollection.category")}
            <span style={{
              fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0,
              color: status.category.length > 0 ? theme.strokeWhite : 'red',
            }}>&nbsp;*</span>
          </legend>

          <div className="flex wrap">
            {status.categorylist.map((item, key) => (
              <ButtonPrimary key={key}
                style={{
                  color: "#FAFAFA",
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                  margin: '4px 4px.5rem',
                  backgroundColor: status.category[key] ? "#36C781" : '#A7A7A7',
                }}
                onClick={() => {
                  let cts = status.category; cts[key] = !status.category[key];
                  updateStatus({ category: cts })
                }}
              >
                {item}
              </ButtonPrimary>
            ))}
          </div>
        </fieldset>

        <div className="flex">
          <CustomCheckbox checked={status.checked} changeEvent={(checked) => { updateStatus({ checked: !status.checked }) }} />
          <p style={{ color: theme.text, cursor: 'pointer' }} onClick={() => { updateStatus({ checked: !status.checked }) }}>{t("editcollection.pick")}</p>
        </div>

        <InputField required type="number"
          placeholder={t("editcollection.feeplaceholder")}
          infoLabel={t("editcollection.feeinfo")} value={status.fee}
          label={t("editcollection.fee")} errorLabel={status.errorFee}
          onChange={(e) => { updateStatus({ fee: e.target.value }) }}
          child={<div style={{
            color: theme.text,
            padding: '8px 1rem',
            fontSize: '0.8rem',
            borderRadius: '8px',
            backgroundColor: theme.boxColor,
          }}>%</div>}
        />

        <InputField value={status.website}
          onChange={(e) => { updateStatus({ website: e.target.value }) }}
          label={t("editcollection.website")}
          errorLabel={status.errorUrl}
        />

        <LinkInputField label="Twitter URL"
          onChange={(v: string) => { updateStatus({ twitter: v }) }}
          errorLabel={status.errorTwitter}
          linkType="https://twitter.com/"
          value={status.twitter}
        />

        <LinkInputField label="Discord URL"
          onChange={(v: string) => { updateStatus({ discord: v }) }}
          errorLabel={status.errorDiscord}
          linkType="https://discord.gg/"
          value={status.discord}
        />

        <LinkInputField label="Instagram URL"
          onChange={(v: string) => { updateStatus({ instagram: v }) }}
          errorLabel={status.errorInstagram}
          linkType="https://instagram.com/"
          value={status.instagram}
        />

        <fieldset className="InputField mt3 mb3">
          <legend>{t("editcollection.badge")}</legend>
          <p>{t("editcollection.badgecontent")}</p>

          {status.verified === "rejected" && (
            <>
              <p className='text-center'>{t("editcollection.rejected")} </p>
              <p className='text-center'>{status.reason}</p>
            </>
          )}

          {(status.verified === "" || status.verified === "rejected") && (
            <div className="flex center mb1">
              <ButtonPrimary style={{ backgroundColor: "#36C781", width: '250px' }}
                onClick={() => { updateStatus({ showBadgeModal: true }) }}
              >
                {t("editcollection.requestbadge")}
              </ButtonPrimary>
            </div>
          )}

          {status.verified === "verified" && (
            <>
              <div className="flex center mb1">
                <Icon icon="Photo" height={56} size={54} />
              </div>

              <p className='text-center'>
                {t("editcollection.verifiedbadge")}
              </p>
            </>
          )}

          {status.verified === "pending" && (
            <div className="flex center mb1">
              <ButtonPrimary disabled style={{ width: '250px' }}>
                {t("editcollection.pendingbadge")}
              </ButtonPrimary>
            </div>
          )}
        </fieldset>

        <div className="flex center mt5">
          <ButtonPrimary style={{ width: '250px' }} onClick={saveCollection}>
            {t("editcollection.save")}
          </ButtonPrimary>
        </div>
      </StyledCollectionPanel>

      <Modal onClose={() => { updateStatus({ showBadgeModal: false }) }} show={status.showBadgeModal} >
        <>
          <div className="icon row center">
            <Icon icon="Photo" size={80} />
          </div>

          <h1 className='text-center'>{t("editcollection.badgerequesttitle")}</h1>
          <p className='text-center'>{t("editcollection.badgerequestcontent")}</p>

          <div className="row center">
            <ButtonPrimary style={{ width: '100%', maxWidth: '400px' }} onClick={requestBadge}>
              {t("editcollection.badgerequest")}
            </ButtonPrimary>
          </div>

          <div className="row center mt3">
            <ButtonPrimary onClick={() => { updateStatus({ showBadgeModal: false }) }}
              style={{ width: '100%', maxWidth: '400px', background: 'rgb(31 36 34)' }}
            >
              {t("editcollection.cancel")}
            </ButtonPrimary>
          </div>
        </>
      </Modal>
    </Layout>
  )
}

export default EditCollection;

const StyledCoverImage = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	cursor: pointer;
	.banner{
		margin: 0;
		padding: 0;
		height: 300px;
		@media (max-width: 768px) {
			height: 150px;
		}
		&:hover{
			.overlay{
				display: block;
			}
		}
		img{
			width: 100%;
			max-height: 300px;
			height: 300px;
			object-fit: cover;
			@media (max-width: 768px) {
				height: 150px;
			}
		}
		.overlay{
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
	.edit-btn{
		position: absolute;
		left: calc(50% - 150px);
		top: 30%;
		@media (max-width: 768px) {
			top: 20%;
			left: calc(50% - 150px);
			font-size: 0.9rem!important;
		}
	}
`

const StyledTitle = styled.div`
	font-size: 2.4rem;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 1.4rem;
	}
`

const StyledCollectionPanel = styled.div`
	border-radius: 1rem;
	color: ${({ theme }) => theme.text}; 
	max-width: 560px;
	margin: -2px auto 100px;
	.select-img {
		border-radius: 1rem;
		width: 280px;
		padding:1rem;
		@media (max-width: 768px) {
			font-size: 0.8rem;
			width: 100%;
			padding: 1rem 8px;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		margin: 30px auto 50px;
		padding: 1rem;
	}
`