import React from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useQuery } from '@apollo/client';
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import Icon from '../../components/Icon';
import NFTItem from '../../components/NftItem';
import Dropdown from '../../components/Dropdown';
import { InputField, LinkInputField } from '../../components/InputField';
import Layout from '../../components/Layout/Layout';
import { ButtonBorder, ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { gasPrice } from '../../blockchain/utils';
import { getNFTContract } from '../../blockchain/contract';
import { Now, proxy, tips, validateSlugUrl, validateUrl } from '../../util';
import { GET_PAYLABLE_TOKEN, GET_COLLECTION_INFO, GET_FEE } from '../../graphql/index';

import config from '../../config/config.json';
import Abis from '../../blockchain/abi/abis.json';
import Bytecode from '../../blockchain/abi/bytecode.json';

import avatar from '../../assets/img/avatar.png';
import preview from '../../assets/img/preview.png';
import collectionImg from '../../assets/img/collection.webp';

interface Property {
  key: string
  value: string
}

interface CreateInterface {
  imageCollectionBlob: Blob | null
  imageBlob: Blob | null
  editImg: null | any
  name: string
  description: string
  quantity: number
  collection: string
  website: string
  isSale: boolean
  saleType: 'regular' | 'auction'
  property: Property[]
  errorName: string
  errorQuantity: string
  errorDescription: string
  errorCollection: string
  errorUrl: string
  errorProperty: string
  myCollections: Collection[] | any
  createCollectionModal: boolean
  editCollectionImg: null | any
  collectionName: string
  collectionSymbol: string
  collectionDescription: string
  collectionUrl: string
  errorCollectionName: string
  errorCollectionSymbol: string
  errorCollectionDescription: string
  errorCollectionUrl: string
  paylableTokens: PaylableTokens[]
  errorPrice: string
  price: number
  acceptedToken: string
  fee: number
  checkDigital: boolean
  checkCopyright: boolean
  checkRight: boolean
  saleStart: number
  saleEnd: number
  errorSaleStart: string
  errorSaleEnd: string
  tokenType: string
  servicefee: number
}

const CreateNFT = () => {
  // @ts-ignore
  const theme = useContext(ThemeContext)
  const { darkMode, currentAccountAddress, token, logined, isMetamask, updated, update, } = useStore()
  const navigate = useNavigate()
  const wallet = useWallet()
  const { t } = useI18n()

  const [status, setStatus] = React.useState<CreateInterface>({
    imageBlob: null,
    imageCollectionBlob: null,
    editImg: null,
    name: '',
    description: '',
    paylableTokens: [],
    quantity: 1,
    collection: '',
    website: '',
    isSale: true,
    property: [{ key: '', value: '' }],
    errorName: '',
    errorQuantity: '',
    errorDescription: '',
    errorCollection: '',
    errorUrl: '',
    errorProperty: '',
    myCollections: [],
    createCollectionModal: false,
    editCollectionImg: null,
    collectionName: '',
    collectionSymbol: '',
    collectionDescription: '',
    collectionUrl: '',
    errorCollectionName: '',
    errorCollectionSymbol: '',
    errorCollectionDescription: '',
    errorCollectionUrl: '',
    errorPrice: '',
    price: 0,
    acceptedToken: "MATIC",
    saleType: 'regular',
    fee: 0,
    checkDigital: true,
    checkCopyright: true,
    checkRight: true,
    saleStart: +new Date(),
    saleEnd: +new Date() + 2592000000,
    errorSaleStart: '',
    errorSaleEnd: '',
    tokenType: "erc721",
    servicefee: 1
  })
  const updateStatus = (params: Partial<CreateInterface>) =>
    setStatus({ ...status, ...params })

  const refFile = React.useRef<HTMLInputElement>(null)
  const refCollectionFile = React.useRef<HTMLInputElement>(null)

  const fakeNft = {
    nftCollection: status.collection,
    _id: "",
    tokenid: "",
    reacts: [],
    views: 0,
    favs: 0,
    saleType: status.saleType,
    multiple: false,
    name: status.name,
    description: "",
    image: status.editImg || preview,
    coverImage: status.editImg || preview,
    externalSite: "",
    attributes: [],
    acceptedToken: status.acceptedToken,
    price: status.price,
    owner: currentAccountAddress,
    isDigital: true,
    isCopyright: true,
    isRight: true,
    expiredTime: 0,
    startTime: status.saleStart,
    endTime: status.saleEnd,
    bidders: [],
    pick: false,
    hide: false,
    collectionname: status.myCollections.find(col => {
      return col.key?.toUpperCase() === status.collection?.toUpperCase()
    })?.name,
    collectionverified: status.myCollections.find(col => {
      return col.key?.toUpperCase() === status.collection?.toUpperCase()
    })?.verified,
  }

  const {
    data: collectionInfo,
    loading: collectionLoading,
    error: collectionError,
  } = useQuery(GET_COLLECTION_INFO, {
    variables: {
      owner: currentAccountAddress,
      updated: updated
    },
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    if (collectionLoading || collectionError) return;
    const result = collectionInfo?.getCollectionInfo;
    if (!result) return;
    const collections = [] as any;
    result?.forEach((element: Collection) => {
      if (!element?.hide) {
        collections.push({
          key: element.address,
          name: element.metadata.name,
          img: element.metadata.image || avatar,
          verified: element.verified?.status,
          fee: element.fee
        })
      }
    })
    updateStatus({ myCollections: collections })
  }, [collectionInfo, collectionLoading, collectionError])

  const { data: paylableTokensInfo, loading: paylableTokensLoading, error: paylableTokensError } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only'
  })
  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return;
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[];
    if (!data) return;
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const { data: feeInfo, loading: feeLoading, error: feeError } = useQuery(GET_FEE, {
    fetchPolicy: 'network-only'
  })
  React.useEffect(() => {
    if (feeLoading || feeError) return;
    const data = feeInfo?.getFee;
    if (!data) return;
    updateStatus({
      servicefee: data["tradeFee"]
    })
  }, [feeInfo, feeLoading, feeError])

  const changeFile = async (event) => {
    const file = event.target.files[0]
    updateStatus({ editImg: URL.createObjectURL(file), imageBlob: file })
  }

  const changeCollectionFile = async (event) => {
    const file = event.target.files[0]
    updateStatus({
      editCollectionImg: URL.createObjectURL(file),
      imageCollectionBlob: file,
    })
  }

  const mintNFT = async (collection: string, tokenId: string) => {
    try {
      update({ loading: true })
      const provider = new ethers.providers.Web3Provider(wallet.ethereum)
      const signer = await provider.getSigner()
      const ca = getNFTContract(collection, signer)
      let tx = await ca.mint(tokenId)
      await tx.wait()
      tips('info', t("action.nft.minted"))
      update({ loading: false })
      navigate('/collection/' + collection)
    } catch (err) {
      console.log(err)
      tips('error', t("action.nft.failedmint"))
      update({ loading: false })
    }
  }

  const createNFT = async () => {
    try {
      if (status.imageBlob === null || status.imageBlob === null) {
        return tips('error', t("action.nft.selectimage"),)
      }

      if (status.imageBlob?.size > 104857600) {
        return tips('error', t("action.nft.largesize"),)
      }

      const saleStart = Math.round((+new Date(status.saleStart)) / 1000) || 0;
      const saleEnd = Math.round((+new Date(status.saleEnd)) / 1000) || 0;

      if (status.isSale && !isMetamask) {
        if (status.price < 0.0001) {
          return updateStatus({
            errorPrice: t("action.nft.errorprice"),
            errorUrl: '',
            errorName: '',
            errorDescription: '',
            errorCollection: '',
            errorProperty: '',
          })
        }

        if (status.saleType === "auction") {
          if (saleStart <= 0) return updateStatus({ errorSaleStart: t("action.invalidvalue") })
          if (saleEnd < Now()) return updateStatus({ errorSaleEnd: t("action.invalidvalue") })
        }
      }

      if (status.name.length < 5 || status.name.length > 50) {
        return updateStatus({
          errorName: t("action.nft.errorname"),
          errorDescription: '',
          errorCollection: '',
          errorProperty: '',
          errorQuantity: '',
          errorUrl: '',
          errorPrice: '',
        })
      }

      if (status.quantity < 1) {
        return updateStatus({
          errorQuantity: t("action.nft.errorquantity"),
          errorUrl: '',
          errorName: '',
          errorDescription: '',
          errorCollection: '',
          errorProperty: '',
          errorPrice: '',
        })
      }

      if (status.description.length > 0 && (status.description.length < 30 || status.description.length > 1500)) {
        return updateStatus({
          errorDescription: t("action.nft.errordescription"),
          errorUrl: '',
          errorName: '',
          errorCollection: '',
          errorProperty: '',
          errorPrice: ''
        })
      }

      if (status.website.length > 0 && !validateUrl(status.website)) {
        return updateStatus({
          errorUrl: t("action.nft.errorurl"),
          errorName: '',
          errorDescription: '',
          errorCollection: '',
          errorProperty: '',
          errorQuantity: '',
          errorPrice: ''
        })
      }

      if (!status.collection || status.collection === '') {
        return updateStatus({
          errorUrl: '',
          errorName: '',
          errorDescription: '',
          errorCollection: t("action.nft.errorcollection"),
          errorProperty: '',
          errorQuantity: '',
          errorPrice: ''
        })
      }
      // if (status.property.length === 0 || (status.property.length === 1 && status.property[0].key === '')) {
      // 	return updateStatus({
      // 		errorUrl: '',
      // 		errorName: '',
      // 		errorDescription: '',
      // 		errorCollection: '',
      // 		errorProperty: 'プロパティを設定してください',
      // 		errorQuantity: '',
      // 		errorPrice: ''
      // 	})
      // }

      var formData = new FormData()
      formData.append('image', status.imageBlob)
      formData.append('isMetamask', isMetamask ? '1' : '0')
      formData.append('name', status.name)
      formData.append('collection', status.collection)
      formData.append('description', status.description)
      formData.append('website', status.website)
      formData.append('supply', status.quantity.toString())
      formData.append('owner', currentAccountAddress)
      formData.append('property', JSON.stringify(status.property))
      formData.append('isSale', status.isSale ? '1' : '0')
      formData.append('saleType', status.saleType)
      formData.append('price', status.price.toString())
      formData.append('acceptedToken', status.acceptedToken.toString())
      formData.append('isDigital', status.checkDigital ? '1' : '0')
      formData.append('isCopyright', status.checkCopyright ? '1' : '0')
      formData.append('isRight', status.checkRight ? '1' : '0')
      formData.append('startTime', saleStart.toString() || '0')
      formData.append('endTime', saleEnd.toString() || '0')

      update({ loading: true })
      var response = (await axios.post(proxy + '/api/nft/create', formData, {
        headers: {
          'Content-Type': 'multipart/form',
          'Apollo-Require-Preflight': true,
          authorization: token,
        },
      })) as any

      if (response.status === 200) {
        switch (response.data.message) {
          case 'success': {
            const tokenId = response.data.tokenId
            navigate('/assets/' + status.collection + "/" + tokenId)
            tips('success', t("action.nft.minted"))
            update({ loading: false })
            break
          }
          case 'uploaded': {
            const tokenId = response.data.id
            if (tokenId) {
              mintNFT(status.collection, tokenId)
            }
            break
          }
          case 'already exists':
            tips('error', t("action.nft.existsnft"))
            update({ loading: false })
            break
          case 'internal error':
            tips('error', t("action.servererror"))
            update({ loading: false })
            break
        }
      }
    } catch (err) {
      if (err.message === 'Request failed with status code 403') {
        update({
          currentAccountMail: '',
          currentAccountName: '',
          currentAccountAvatar: '',
          currentAccountAddress: '',
          token: '',
          logined: false,
          loading: false,
        })
        tips('warning', t("action.needlogin"))
        return
      }
      update({ loading: false })
    }
  }

  const createCollection = async () => {
    try {
      const name = status.collectionName.trim();
      const slugurl = status.collectionUrl.trim();
      const symbol = status.collectionSymbol.trim();
      const description = status.collectionDescription.trim();
      const fee = 0.5;

      if (status.imageCollectionBlob === null) {
        return tips("error", t("action.collection.selectimage"));
      }

      const tempErrorOb = {
        errorCollectionName: "",
        errorCollectionDescription: "",
        errorCollectionSymbol: "",
        errorCollectionUrl: "",
        errorCollection: ""
      }

      updateStatus(tempErrorOb)
      if (name.length < 5 || name.length > 50) {
        return updateStatus({ ...tempErrorOb, errorCollectionName: t("action.collection.errorname") })
      }

      if (symbol.length < 5 || symbol.length > 50) {
        return updateStatus({ ...tempErrorOb, errorCollectionSymbol: t("action.collection.errorsymbol") })
      }

      if (!validateSlugUrl(slugurl)) {
        return updateStatus({ ...tempErrorOb, errorCollectionUrl: t("action.collection.errorslugurl") })
      }

      if (description.length > 0 && (description.length < 30 || description.length > 1500)) {
        return updateStatus({ ...tempErrorOb, errorCollectionDescription: t("action.collection.errordiscription") })
      }

      const cts = [] as number[];
      update({ loading: true })

      let imageUploadStatus = false;
      let collectionImage = '';
      let coverImage = '';

      //upload image
      var formData = new FormData();
      formData.append("coverImage", status.imageCollectionBlob);
      formData.append("image", status.imageCollectionBlob);
      formData.append("slugurl", slugurl);

      var response = await axios.post(proxy + "/api/collection/upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form",
          "Apollo-Require-Preflight": true,
          'authorization': token
        }
      }) as any;

      if (response.status === 200) {
        switch (response.data.message) {
          case "success": {
            imageUploadStatus = true;
            coverImage = response.data?.['coverImage'] || '';
            collectionImage = response.data?.['collectionImage'] || '';
            break;
          }
          case "internal error": {
            imageUploadStatus = false;
            tips("error", "Upload Error");
            console.log(response.data.message);
            break;
          }
          case "exists same slugurl": {
            tips("error", t("action.collection.existsameslugurl"));
            break;
          }
        }
      }

      if (imageUploadStatus) {
        let address = "";
        let hash = null
        if (isMetamask) {
          const ca = await contractDeploy(name, symbol);
          address = ca?.address;
          hash = ca.deployTransaction?.hash;
          if (!address) return tips("error", t("action.collection.deployerror"))
        }

        var formData = new FormData();
        formData.append("coverImage", status.imageCollectionBlob);
        formData.append("image", status.imageCollectionBlob);
        formData.append("address", address);
        formData.append("hash", hash);
        formData.append("isMetamask", isMetamask ? '1' : '0');
        formData.append("symbol", symbol);
        formData.append("name", name);
        formData.append("slugurl", slugurl);
        formData.append("description", description);
        formData.append("categori", cts.toString());
        formData.append("pick", '2');
        formData.append("url", "");
        formData.append("fee", fee.toString());
        formData.append("twitter", "");
        formData.append("discord", "");
        formData.append("instagram", "");
        formData.append("creator", currentAccountAddress);
        formData.append("chainId", config.CHAINID.toString());
        formData.append("cover_image", coverImage);
        formData.append("collection_image", collectionImage);

        var response = await axios.post(proxy + "/api/collection/create", formData, {
          headers: {
            "Content-Type": "multipart/form",
            "Apollo-Require-Preflight": true,
            'authorization': token
          }
        }) as any;

        if (response.status === 200) {
          switch (response.data.message) {
            case "success": {
              tips("success", t("action.collection.createsuccess"));
              updateStatus({ name: name.toString(), createCollectionModal: false })
              update({ updated: updated + 1 })
              break;
            }
            case "gas error": tips("error", t("action.collection.gaserror")); break;
            case "exists same slugurl": tips("error", t("action.collection.existsameslugurl")); break;
            case "gas estimated": tips("error", t("action.collection.gaserror")); break;
            case "No contract creator": tips("error", t("action.collection.notcontractcreator")); break;
            case "out of gas": tips("error", t("action.collection.gaserror")); break;
            case "No exists user.": tips("error", t("action.collection.noexistuser")); break;
            case "internal error": tips("error", t("action.collection.networkerror")); break;
          }
        }
      }

      update({ loading: false });
    } catch (err) {
      if (err.message === "Request failed with status code 403") {
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, loading: false });
        tips("warning", t("action.collection.login"))
        return;
      }

      update({ loading: false })
    }
  }

  const contractDeploy = async (name: string, symbol: string) => {
    try {
      const provider = new ethers.providers.Web3Provider(wallet.ethereum);
      const signer = await provider.getSigner();
      let factory = new ethers.ContractFactory(Abis.NFT, Bytecode.NFT, signer);
      const price = await gasPrice(provider)
      let contract = await factory.deploy(name, symbol
        , { gasPrice: ethers.utils.parseUnits(price, "gwei"), }
      )

      await contract.deployed();
      return contract
    } catch (err) {
      console.log(err.message)
      tips("error", t("action.networkerror"),)
      return null;
    }
  }

  React.useEffect(() => {
    if (!logined) navigate('/login')
  }, [logined])

  return (
    <Layout>
      <div className="container pt3 pb5">
        <StyledTitle>
          <span>NFT </span>{t("createnft.title")}
        </StyledTitle>

        <div className="row justify">
          <div className="col-lg-8 col-md-7 col-sm-12" >
            <StyledUploadPanel onClick={() => { refFile.current?.click() }}
              style={{
                backgroundImage: status.editImg
                  ? `url(${status.editImg})`
                  : '',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div>
                <h2>{t("createnft.upload")}</h2>
                {window.innerWidth > 768 && (
                  <div className="flex center mt3 mb3">
                    <Icon icon="DragUpload" />
                  </div>
                )}

                <p>{t("createnft.role")}</p>
              </div>

              <div className="overlay" />
              <input hidden ref={refFile}
                type="file" accept="image/*"
                onChange={(e) => { changeFile(e) }}
              />
            </StyledUploadPanel>
          </div>

          <div className="col-lg-4 col-md-5 col-sm-12">
            <h2 className="h2 text-center">
              {t("createnft.preview")}
            </h2>

            <div style={{ maxWidth: '280px', margin: '0 auto' }}>
              <NFTItem nft={fakeNft}
                img={status.editImg || preview}
                owner={currentAccountAddress}
                balance="0"
                symbol='MATIC'
                balanceUsd="0"
                id="" tokenId=""
                nftCollection=""
                name={status.name || ' '}
                props={{
                  style: {
                    border: 'none',
                    backgroundColor: theme.boxColor
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="row p1">
          <div className="col-lg-8 col-md-12">
            {!isMetamask && (
              <>
                <div className="flex middle w100">
                  <p style={{ backgroundColor: 'transparent', width: '50px' }}>
                    {t("createnft.sell")}
                  </p>

                  <ButtonPrimary
                    style={{
                      backgroundColor: status.isSale
                        ? theme.buttonPrimary
                        : theme.boxColor,
                      width: '120px',
                      margin: '1rem 0.5rem',
                    }}
                    onClick={() => {
                      updateStatus({ isSale: true })
                    }}
                  >
                    {t("createnft.selling")}
                  </ButtonPrimary>

                  <ButtonPrimary
                    style={{
                      backgroundColor: status.isSale
                        ? theme.boxColor
                        : theme.buttonPrimary,
                      width: '120px',
                      margin: '1rem 0.5rem',
                    }}
                    onClick={() => {
                      updateStatus({ isSale: false })
                    }}
                  >
                    {t("createnft.nosale")}
                  </ButtonPrimary>
                </div>

                {status.isSale && (
                  <div className='row'>
                    <div className="col-lg-8 col-md-12">
                      <fieldset className="InputField mt3 mb3">
                        <legend>{t("createnft.saletype")}</legend>

                        <div className="row middle center">
                          <div className="col-lg-6 col-sm-12">
                            <ButtonPrimary onClick={() => { updateStatus({ saleType: "regular" }) }}
                              style={{ width: '100%', backgroundColor: status.saleType === "regular" ? theme.buttonPrimary : theme.boxColor, margin: '4px auto' }}
                            >
                              {t("createnft.regular")}
                            </ButtonPrimary>
                          </div>

                          <div className="col-lg-6 col-sm-12">
                            <ButtonPrimary onClick={() => { updateStatus({ saleType: "auction" }) }}
                              style={{ backgroundColor: status.saleType === "auction" ? theme.buttonPrimary : theme.boxColor, width: '100%', margin: '4px auto' }}
                            >
                              {t("createnft.auction")}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </fieldset>

                      <InputField required type="number" value={status.price}
                        label={t("createnft.price")} errorLabel={status.errorPrice}
                        onChange={(e) => { updateStatus({ price: e.target.value }) }}
                        child={
                          <div style={{ width: '80px', marginRight: '1rem' }}>
                            <Dropdown hideImg
                              values={status.paylableTokens}
                              selectedKey={status.acceptedToken}
                              onChange={(key) => { updateStatus({ acceptedToken: key }) }}
                              props={{ style: { height: '100%', border: '1px solid' + theme.boxColor } }}
                            />
                          </div>
                        }
                      />

                      {/* <p>{t("createnft.fee", status.fee.toString())}</p> */}

                      {status.saleType === "auction" && (
                        <>
                          <InputField required type="date"
                            onValueChange={(v) => { updateStatus({ saleStart: v }) }}
                            errorLabel={status.errorSaleStart}
                            label={t("createnft.salestart")}
                            value={status.saleStart}
                            defaultDate={0}
                          />

                          <InputField required type="date"
                            onValueChange={(v) => { updateStatus({ saleEnd: v }) }}
                            errorLabel={status.errorSaleEnd}
                            label={t("createnft.saleend")}
                            value={status.saleEnd}
                            defaultDate={30}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className="hr mt3 mb3"
                  style={{ borderColor: theme.strokeColor }}
                >{' '}</div>
              </>
            )}

            <div className="row middle">
              <div className="col-lg-8 col-md-12">
                <InputField required
                  label={t("createnft.name")}
                  errorLabel={status.errorName}
                  placeholder="" value={status.name}
                  onChange={(e) => { updateStatus({ name: e.target.value }) }}
                ></InputField>
              </div>

              {/* <div className="col-lg-3 col-md-6 flex">
                <div className="col-11 p0">
                  <div style={{ border: `1px solid ${theme.strokeColor}`, borderRadius: '8px' }}>
                    <Dropdown selectedKey={t("createnft.erc721")}
                      values={[{ key: "erc721", name: t("createnft.erc721") }]}
                      onChange={(key) => { updateStatus({ tokenType: key, quantity: key === "erc721" ? 1 : status.quantity }) }}
                    />
                  </div>
                </div>

                <div className="col-1 p0">
                  <span style={{ color: theme.strokeWhite, fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>
                </div>
              </div> */}

              {/* <div className="col-lg-3 col-md-6">
                {status.tokenType === "erc1155" && (
                  <InputField required label="量"
                    type="number" min={1} max={100}
                    errorLabel={status.errorQuantity}
                    value={status.quantity}
                    onChange={(e) => {
                      if (status.tokenType === "erc1155") {
                        updateStatus({ quantity: e.target.value })
                      }
                    }}
                    child={
                      <div style={{
                        backgroundColor: theme.boxColor,
                        color: theme.text,
                        padding: '8px',
                        fontSize: '0.8rem',
                        borderRadius: '8px',
                        marginRight: '8px'
                      }}>
                        個
                      </div>
                    }
                  />
                )}
              </div> */}
            </div>

            <div className="row center">
              <div className="col-lg-8 col-md-12">
                <InputField type="textarea"
                  value={status.description}
                  label={t("createnft.description")}
                  errorLabel={status.errorDescription}
                  onChange={(e) => { updateStatus({ description: e.target.value }) }}
                />
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="flex">
                  <div className="col-11 p0">
                    <div style={{
                      borderRadius: '8px',
                      margin: '2rem 0px 0',
                      border: `1px solid ${theme.strokeColor}`,
                    }}>
                      <Dropdown values={status.myCollections}
                        selectedKey={t("createnft.collection")}
                        onChange={(key) => {
                          const fee = status.myCollections.find(col => col.key?.toUpperCase() === key?.toUpperCase())?.fee || 0;
                          updateStatus({
                            collection: key,
                            fee: fee,
                            errorCollection: ''
                          })
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-1 p0">
                    <span style={{ color: status.collection ? theme.strokeWhite : 'red', fontSize: '1.4rem', margin: 0, padding: 0, lineHeight: 0 }}>&nbsp;*</span>
                  </div>
                </div>

                <p style={{ color: 'red', fontSize: '0.8rem' }}>
                  {status.errorCollection}
                </p>

                <div className="text-right cursor mt3"
                  onClick={() => { updateStatus({ createCollectionModal: true }) }}
                >
                  {t("createnft.createcollection")}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-12">
                <InputField value={status.website}
                  onChange={(e) => { updateStatus({ website: e.target.value }) }}
                  infoLabel={t("createnft.websiteinfo")}
                  label={t("createnft.website")}
                  errorLabel={status.errorUrl}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-10 col-md-12">
                <p className="">{t("createnft.attribute")}</p>

                <p style={{ fontSize: '0.8rem' }}>
                  {t("createnft.attributeinfo")}
                </p>

                {status.property?.length > 0 && (
                  status.property.map((element, index) => (
                    <div className="row middle mb3" key={"property" + index}>
                      <div className="col-5">
                        <div className="InputField mb1"
                          style={{
                            position: 'relative', padding: '1rem',
                            border: `1px solid ${theme.strokeWhite}`,
                          }}
                        >
                          <input type='text'
                            value={element.key}
                            placeholder='Character'
                            style={{ color: theme.text }}
                            onChange={(e) => {
                              let oldV = element
                              const value = e.target.value
                              oldV['key'] = value
                              let newProp = status.property
                              newProp[index] = oldV
                              updateStatus({ property: newProp })
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-5">
                        <div className="InputField mb1"
                          style={{
                            position: 'relative', padding: '1rem',
                            border: `1px solid ${theme.strokeWhite}`,
                          }}
                        >
                          <input type='text'
                            placeholder='Value'
                            value={element.value}
                            style={{ color: theme.text }}
                            onChange={(e) => {
                              let oldV = element
                              const value = e.target.value
                              oldV['value'] = value
                              let newProp = status.property
                              newProp[index] = oldV
                              updateStatus({ property: newProp })
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-2">
                        <ButtonBorder style={{ padding: '0.5rem', borderRadius: '8px' }}
                          onClick={() => {
                            let prop = status.property;
                            prop.splice(index, 1);
                            updateStatus({ property: prop });
                          }}
                        >
                          <Icon icon="Close" size={24} />
                        </ButtonBorder>
                      </div>
                    </div>
                  ))
                )}

                <p style={{ color: 'red', fontSize: '0.8rem' }}>
                  {status.errorProperty}
                </p>

                <ButtonPrimary style={{ width: '150px', borderRadius: '8px' }}
                  onClick={() => { updateStatus({ property: [...status.property, { key: '', value: '' }] }) }}
                >
                  {t("createnft.add")}
                </ButtonPrimary>
              </div>
            </div>

            {/* <div className="flex wrap mt3">
              <div className="flex mr3">
                <CustomCheckbox checked={status.checkDigital}
                  changeEvent={(checked) => { updateStatus({ checkDigital: !status.checkDigital }) }}
                />

                <p style={{ color: theme.text, cursor: 'pointer' }}
                  onClick={() => { updateStatus({ checkDigital: !status.checkDigital }) }}
                >
                  {t("createnft.digital")}
                </p>
              </div>

              <div className="flex mr3">
                <CustomCheckbox checked={status.checkRight}
                  changeEvent={(checked) => { updateStatus({ checkRight: !status.checkRight }) }}
                />

                <p style={{ color: theme.text, cursor: 'pointer' }}
                  onClick={() => { updateStatus({ checkRight: !status.checkRight }) }}
                >
                  {t("createnft.right")}
                </p>
              </div>

              <div className="flex mr3">
                <CustomCheckbox checked={status.checkCopyright}
                  changeEvent={(checked) => { updateStatus({ checkCopyright: !status.checkCopyright }) }}
                />

                <p style={{ color: theme.text, cursor: 'pointer' }}
                  onClick={() => { updateStatus({ checkCopyright: !status.checkCopyright }) }}
                >
                  {t("createnft.copyright")}
                </p>
              </div>
            </div> */}

            <div className="flex justify mt3">
              <ButtonBorder style={{ width: '120px' }} onClick={() => { navigate('/search-nft') }}>
                {t("createnft.close")}
              </ButtonBorder>
            </div>

            {(window.innerWidth > 768 && !isMetamask && status.isSale) && (
              <div className="row mt3">
                <div className="col-lg-8">
                  <div className="flex justify">
                    <p className='m0 mt2'>{t("createnft.sellingprice")}</p>
                    <p className='m0 mt2'>{status.price} {status.acceptedToken}</p>
                  </div>

                  <div className="flex justify">
                    <p className='m0 mt2'>{t("createnft.servicefee")}</p>
                    <p className='m0 mt2'>{status.servicefee} %</p>
                  </div>

                  <div className="flex justify">
                    <p className='m0 mt2'>{t("createnft.creatorfee")}</p>
                    <p className='m0 mt2'>{status.fee} %</p>
                  </div>

                  <div className="flex justify">
                    <p className='m0 mt2'>{t("createnft.profit")}</p>
                    <p className='m0 mt2'>
                      {Number((status.price - (status.price * status.fee / 100) - (status.price * status.servicefee / 100)).toFixed(8)) + " " + status.acceptedToken}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className='mt3 row'>
              <div className="col-lg-8 col-sm-12">
                <ButtonPrimary onClick={createNFT}
                  style={{ width: '100%', background: " linear-gradient(144deg, #ef9090 0.00%, #f9f592 20.92%, #a7f195 36.40%, #74f9d0 51.46%, #5e87f1 66.10%, #e59afb 83.67%, #f95959 100.00%)", color: 'black' }}
                >
                  {t("createnft.create")}
                </ButtonPrimary>
              </div>
            </div>

            <StyledCreatePanel>
              {(!isMetamask && status.isSale) && (
                <div className="row">
                  <div className="col-lg-8">
                    <div className="flex justify">
                      <p className='m0 mt2'>{t("createnft.sellingprice")}</p>
                      <p className='m0 mt2'>{status.price} {status.acceptedToken}</p>
                    </div>

                    <div className="flex justify">
                      <p className='m0 mt2'>{t("createnft.servicefee")}</p>
                      <p className='m0 mt2'>{status.servicefee} %</p>
                    </div>

                    <div className="flex justify">
                      <p className='m0 mt2'>{t("createnft.creatorfee")}</p>
                      <p className='m0 mt2'>{status.fee} %</p>
                    </div>

                    <div className="flex justify">
                      <p className='m0 mt2'>{t("createnft.profit")}</p>
                      <p className='m0 mt2'>
                        {Number((status.price - (status.price * status.fee / 100) - (status.price * status.servicefee / 100)).toFixed(8)) + " " + status.acceptedToken}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <ButtonPrimary onClick={createNFT}
                style={{ width: '100%', background: " linear-gradient(144deg, #ef9090 0.00%, #f9f592 20.92%, #a7f195 36.40%, #74f9d0 51.46%, #5e87f1 66.10%, #e59afb 83.67%, #f95959 100.00%)", color: 'black', marginTop: '1rem' }}
              >
                {t("createnft.create")}
              </ButtonPrimary>
            </StyledCreatePanel>
          </div>
        </div>
      </div>

      {status.createCollectionModal && (
        <StyledModal>
          <div className="overlay"
            onClick={() => { updateStatus({ createCollectionModal: false }) }}
          />
          <div className="modal-container">
            <h2 className='text-center'>
              {t("createnft.collectiontitle")}
            </h2>

            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div onClick={() => { refCollectionFile.current?.click() }}
                  style={{
                    borderRadius: '2rem',
                    cursor: 'pointer',
                    backgroundColor: theme.boxColor,
                    width: '180px',
                    height: '180px',
                    margin: '0 auto',
                  }}
                >
                  <img src={status.editCollectionImg || collectionImg}
                    style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                  />
                  <input hidden
                    ref={refCollectionFile}
                    type="file" accept="image/*"
                    onChange={(e) => { changeCollectionFile(e) }}
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <p style={{ fontSize: '0.9rem', color: theme.textGrey }}>
                  {t("createnft.collectionrole")}
                </p>

                <ButtonPrimary onClick={() => { refCollectionFile.current?.click() }}
                  style={{
                    backgroundColor: darkMode
                      ? 'rgb(36, 38, 47)'
                      : theme.boxColor,
                    borderRadius: '1rem',
                    width: '100%',
                    minWidth: '240px',
                    padding: '1rem',
                  }}
                >
                  {t("createnft.selectimg")}
                </ButtonPrimary>
              </div>
            </div>

            <InputField required
              value={status.collectionName}
              label={t("createnft.collectionname")}
              errorLabel={status.errorCollectionName}
              onChange={(e) => { updateStatus({ collectionName: e.target.value }) }}
            />

            <InputField required
              value={status.collectionSymbol}
              label={t("createnft.collectionsymbol")}
              errorLabel={status.errorCollectionSymbol}
              onChange={(e) => { updateStatus({ collectionSymbol: e.target.value }) }}
            />

            <InputField type="textarea"
              label={t("createnft.collectiondes")}
              value={status.collectionDescription}
              errorLabel={status.errorCollectionDescription}
              onChange={(e) => { updateStatus({ collectionDescription: e.target.value }) }}
            />

            <LinkInputField required
              value={status.collectionUrl}
              label={t("createnft.collectionurl")}
              errorLabel={status.errorCollectionUrl}
              linkType="https://mecha.art/collection/"
              placeholder={t("createnft.collectionurlplaceholder")}
              onChange={(v: string) => { updateStatus({ collectionUrl: v }) }}
            />

            <div className="flex center ">
              <ButtonPrimary style={{ width: '250px' }} onClick={createCollection}>
                {t("createnft.collectioncreate")}
              </ButtonPrimary>
            </div>
          </div>
        </StyledModal>
      )}
    </Layout >
  )
}

export default CreateNFT

const StyledCreatePanel = styled.div`
	display: none;
	width: 100vw;
	position: fixed;
	top: 40%;
	left: 0;
	z-index: 1001;
	background-color: ${({ theme }) => theme.modalBg};
	padding: 1rem 2rem;
	@media (max-width: 768px) {
		display: block;
	}
`

const StyledTitle = styled.h2`
	text-align: center;
	font-size: 2.2rem;
	span {
		font-size: 2.6rem;
		background: -webkit-linear-gradient(
			45deg,
			#f9f592,
			#a7f195,
			#74f9d0,
			#5e87f1,
			#e59afb,
			#f95959
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media (max-width: 768px) {
			font-size: 1.6rem;
		}
	}
	@media (max-width: 768px) {
		font-size: 1.4rem;
	}
`

const StyledUploadPanel = styled.div`
	border-radius: 1rem;
	background-color: ${({ theme }) => theme.boxColor};
	width: 100%;
	padding: 2rem;
	height: 400px;
	margin: 1rem 0;
	cursor: pointer;
	position: relative;
	text-align: center;
	color: ${({ theme }) => theme.textGrey};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	@media (max-width: 768px) {
		height: 150px;
		padding: 1rem;
	}
	h2 {
		font-size: 2rem;
		@media (max-width: 768px) {
			font-size: 1.1rem;
		}
	}
	p {
		font-size: 0.8rem;
	}
	&:hover {
		.overlay {
			display: block;
		}
	}
	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1000;
		top: 0;
		left: 0;
		display: none;
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 1rem;
	}
`

const StyledModal = styled.div`
	display: flex;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 10001;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	-webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}
	.overlay {
		position: fixed;
		background-color: ${({ theme }) => theme.modalOverlay};
		width: 100%;
		height: 100%;
		opacity: ${({ theme }) => theme.modalOpacity};
	}
	.modal-container {
		border-radius: 1rem;
		background-color: ${({ theme }) => theme.modalBg};
		color: ${({ theme }) => theme.text};
		padding: 0 2rem 2rem;
		position: absolute;
		max-width: 600px;
		min-width: 300px;
		margin-left: auto;
		width: 50%;
		z-index: 10002;
		max-height: 90vh;
		overflow-y: auto;
		@media (max-width: 1024px) {
			width: 80%;
		}
		@media (max-width: 768px) {
			width: 90%;
		}
	}
`