import React from 'react'
import decode from "jwt-decode";
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { Link, useNavigate } from 'react-router-dom'

import useStore from '../../useStore';
import { Now, tips } from '../../util';
import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';


interface SignupInterface {
  error: string
  confirmCodeMail: string
  confirmCodePhone: string
  errorConfirmCodeMail: string
  errorConfirmCodePhone: string
}

const CheckCode = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { darkMode, call, update, redirectPage } = useStore()

  const [status, setStatus] = React.useState<SignupInterface>({
    error: '',
    confirmCodeMail: '',
    confirmCodePhone: '',
    errorConfirmCodeMail: '',
    errorConfirmCodePhone: ''
  })

  const updateStatus = (params: Partial<SignupInterface>) => setStatus({ ...status, ...params });

  const confirmCode = async () => {
    try {
      const email = window.location.pathname.split("/")[2]?.toString();
      const mailCode = status.confirmCodeMail;
      const phoneCode = status.confirmCodePhone;
      if (mailCode.length !== 6 || Number(mailCode) < 100000 || Number(mailCode) > 1000000) return updateStatus({ error: "", errorConfirmCodeMail: t("action.signup.invalidsmscode"), errorConfirmCodePhone: "" });
      // if(phoneCode.length !== 6 ||  Number(phoneCode) < 100000 || Number(phoneCode) > 1000000)  return updateStatus({ error: "", errorConfirmCodeMail: "", errorConfirmCodePhone: "無効なコード"}); 
      updateStatus({ errorConfirmCodeMail: "", errorConfirmCodePhone: "" })
      update({ loading: true })
      const result = await call("/api/signup/confirm-code", {
        email: email,
        smsCode: mailCode,
        phoneCode: phoneCode
      })
      if (result) {
        switch (result['message']) {
          case "success": {
            const token = result['token'];
            var data = decode(token) as any;
            update({
              currentAccountMail: data.email,
              token: token, currentAccountName: data.name,
              currentAccountAvatar: data.avatar_img,
              currentAccountAddress: data.address,
              logined: true,
              isMetamask: false,
              balances: data.balances,
              lasttime: Now()
            })
            tips("success", t("action.login.success"));
            setTimeout(() => {
              navigate(redirectPage ? redirectPage : '/')
            }, 500);
            break;
          }
          case "No exists user.": tips("error", t("action.signup.noexistuser")); break;
          case "No exists code.": tips("error", t("action.signup.noexistcode")); break;
          case "No match mail code": updateStatus({ errorConfirmCodePhone: "", errorConfirmCodeMail: t("action.signup.invalidsmscode") }); break;
          case "No match sms code.": updateStatus({ errorConfirmCodeMail: "", errorConfirmCodePhone: t("action.signup.invalidsmscode") }); break;
          case "Endtime": updateStatus({ errorConfirmCodeMail: t("action.signup.codeexpired"), errorConfirmCodePhone: t("action.signup.codeexpired") }); break;
          case "Please enter all required data.": updateStatus({ error: t("action.signup.inputerror") }); break;
          case "Already exists same name or email.": updateStatus({ error: t("action.signup.existsameuser") }); break;
          case "internal error": updateStatus({ error: t("action.servererror") }); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      update({ loading: false })
      console.log(ex.message)
      return tips("error", t("action.unknownerror"))
    }
  }

  return (
    <Layout>
      <div>
        <StyledContainer className="container">
          <StyledSignupPanel darkMode={darkMode}>
            <h3 className='title'>{t("checkcode.title")}</h3>
            <p className='text-center'>{t("checkcode.content1")} <br /> {t("checkcode.content2")}</p>
            <p className='small-text text-center'>{t("checkcode.expired")} <br />{t("checkcode.expired2")}</p>

            <InputField type="number" placeholder=""
              onChange={(e) => { updateStatus({ confirmCodeMail: e.target.value }) }}
              errorLabel={status.errorConfirmCodeMail}
              label={t("checkcode.confirmcode")}
              value={status.confirmCodeMail}
            />

            <div className="flex center">
              <Link to="/signup/resend-code" style={{ textAlign: 'center', color: "#6b9beb", textDecoration: 'underline' }}>
                {t("checkcode.resend")}
              </Link>
            </div>

            {/* <InputField type="number" label="電話番号確認コード" errorLabel={status.errorConfirmCodePhone} placeholder="" value={status.confirmCodePhone} onChange={(e) => {updateStatus({confirmCodePhone: e.target.value})}}></InputField>
						<div className="flex center">
							<Link to="/signup/resend-code" style={{textAlign: 'center', color: "#6b9beb", textDecoration: 'underline' }}>電話番号確認コードを再送する</Link>
						</div> */}

            <div className="flex wrap center mt3">
              <ButtonPrimary onClick={() => { navigate("/signup") }}
                style={{ backgroundColor: "#a7a7a7", width: "150px", margin: '8px' }}
              >
                {t("checkcode.return")}
              </ButtonPrimary>

              <ButtonPrimary style={{ width: "150px", margin: '8px' }} onClick={confirmCode}>
                {t("checkcode.confirm")}
              </ButtonPrimary>
            </div>
          </StyledSignupPanel>
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default CheckCode;

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledSignupPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
	margin: 3rem auto;
	.title{
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`