import React from 'react';
import { ethers } from 'ethers';
import decode from 'jwt-decode';
import { useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useQuery } from '@apollo/client';
import { useI18n } from 'react-simple-i18n';
import { ThemeContext } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';

import Modal from './Modal';
import useStore from '../useStore';
import { Now, tips } from '../util';
import config from '../config/config.json';
import { ButtonPrimary } from './components';
import { GET_PAYLABLE_TOKEN } from './../graphql';
import { formatUnit } from '../blockchain/bigmath';
import networks from '../blockchain/networks.json';
import { ZeroAddress, getEthBalance, getTokenBalance } from '../blockchain/utils';

import metamask from '../assets/img/metamask.webp';

interface MetamaskInterface {
  step: "install" | "network" | "sign"
  show: boolean
  paylableTokens: PaylableTokens[]
}

const Metamask = () => {
  const [status, setStatus] = React.useState<MetamaskInterface>({
    step: "install",
    show: false,
    paylableTokens: [],
  })

  const updateStatus = (params: Partial<MetamaskInterface>) =>
    setStatus({ ...status, ...params })
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()
  const wallet = useWallet()
  const { t } = useI18n()
  const {
    currentAccountAddress,
    isMetamask,
    isWalletConnect,
    logined,
    redirectPage,
    call,
    lang,
    update,
  } = useStore()

  const {
    data: paylableTokensInfo,
    loading: paylableTokensLoading,
    error: paylableTokensError,
  } = useQuery(GET_PAYLABLE_TOKEN, {
    fetchPolicy: 'network-only',
  })
  React.useEffect(() => {
    if (paylableTokensLoading || paylableTokensError) return
    const data = paylableTokensInfo?.getPaylableToken as PaylableTokens[]
    if (!data) return
    updateStatus({ paylableTokens: data })
  }, [paylableTokensInfo, paylableTokensLoading, paylableTokensError])

  const changeWalletStatus = async () => {
    detectEthereumProvider().then(() => {
      const link = window.location.pathname;
      if (!link.startsWith('/login') && logined && isMetamask) {
        // if (!wallet.ethereum && logined && isMetamask) return logOut()
        if (Number(wallet.ethereum?.chainId) !== config.CHAINID) {
          if (wallet.status === "connected") {
            updateStatus({ step: "network", show: true })
          }
        } else {
          updateStatus({ show: false, step: "install" })
        }
        if (
          wallet.account &&
          logined &&
          isMetamask &&
          currentAccountAddress.toUpperCase() != wallet.account.toUpperCase()
        ) {
          updateStatus({ step: "sign", show: true })
        }
      }
    })
  }

  const checkBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(wallet.ethereum)
      const signer = await provider.getSigner()
      if (!signer) return
      let _balances = [] as Balance[];
      if (status.paylableTokens?.length > 0) {
        for (let i = 0; i < status.paylableTokens.length; i++) {
          const token = status.paylableTokens[i]
          let balance = 0
          if (token.address === ZeroAddress) {
            balance = await getEthBalance(provider, currentAccountAddress)
          } else {
            balance = await getTokenBalance(token.address, signer)
          }
          _balances.push({
            address: token?.address,
            name: token?.name,
            symbol: token?.symbol,
            balance: formatUnit(balance, token?.decimals),
            decimals: token?.decimals,
            icon: token?.icon,
          })
        }
        update({ balances: _balances })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const detect = async () => {
    await detectEthereumProvider()
  }

  const sign = async () => {
    try {
      update({ loading: true })
      const signature = await wallet?.ethereum?.request({
        method: 'personal_sign',
        params: [
          `Welcome to MechaikeNFT! \n Click to sign in and accept the Terms of Service. \n This request will not trigger a blockchain transaction or cost any gas fees. \n Wallet address: ${wallet.account}`,
          wallet.account,
        ],
      })
      const result = await call('/api/login', {
        address: wallet.account,
        sign: signature,
        metamask: true,
      })

      update({ loading: false })
      if (result) {
        switch (result['message']) {
          case 'success': {
            const token = result['token']
            var data = decode(token) as any
            update({
              currentAccountMail: data.email,
              token: token,
              currentAccountName: data.name,
              currentAccountAvatar: data.avatar_img,
              currentAccountAddress: data.address,
              logined: true,
              isMetamask: data.metamask,
              isWalletConnect: wallet.ethereum?.qrcodeModal ? true : false,
              balances: [],
              lasttime: Now(),
            })
            updateStatus({ show: false })
            tips('success', t('action.login.success'))
            navigate(redirectPage ? redirectPage : '/')
            update({ redirectPage: "" })
            break
          }

          case 'invalid signature':
            tips('error', t('action.invalidsignature'))
            break

          case 'internal error':
            tips('error', t('action.servererror'))
            break

          case 'account closed!':
            tips('error', t('action.accountClosed'))
            break
        }
      }
    } catch (err) {
      update({ loading: false })
      console.log(err.message)
    }
  }

  const switchNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      const ret = await wallet.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + Number(chainId).toString(16) }],
      })
      if (ret === null) updateStatus({ show: false, step: "install" })
      update({ loading: false })
    } catch (error) {
      update({ loading: false })
      addNetwork(config.CHAINID)
    }
  }

  const addNetwork = async (chainId: number) => {
    try {
      update({ loading: true })
      const network = networks.find((data) => data.chainId === chainId)
      await wallet.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
            chainName: network?.name,
            nativeCurrency: {
              name: network?.name,
              symbol: network?.symbol,
              decimals: network?.symbol,
            },
            rpcUrls: [network?.rpc],
            blockExplorerUrls: [network?.explorer],
          },
        ],
      })
      switchNetwork(chainId)
      update({ loading: false })
    } catch (err) {
      update({ loading: false })
      tips('warning', t('action.metamask.selectethereum'))
    }
  }

  const onClose = () => {
    updateStatus({ show: false })
  }

  React.useEffect(() => {
    if (wallet.account && logined && isMetamask) {
      checkBalance()
    }
  }, [currentAccountAddress])

  React.useEffect(() => {
    changeWalletStatus()
  }, [wallet.chainId, wallet.account, wallet.status])

  React.useEffect(() => {
    detect()
    if (logined && isMetamask && !wallet.ethereum) {
      if (isWalletConnect) {
        wallet.connect("walletconnect")
      }
      else {
        wallet.connect()
      }
    }
    if (!wallet.ethereum?.qrcodeModal && wallet.ethereum?.isMetamask) {
      wallet.ethereum?.on('accountsChanged', (addrs) => {
        changeWalletStatus()
      })
      wallet.ethereum?.on('chainChanged', (chainId) => {
        changeWalletStatus()
      })
      wallet.ethereum?.on('networkChanged', (chainId) => {
        changeWalletStatus()
      })
      wallet.ethereum?.on('connect', (chainId) => {
        changeWalletStatus()
      })
    }
    changeWalletStatus()
  }, [])

  return (
    <Modal show={status.show}
      onClose={() => { onClose() }}
    >
      {status.step === "install" && (
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.installtitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.installcontent')}
          </p>

          <div className="flex center mt3">
            <a target="_blank"
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              style={{ textDecoration: 'none', width: '100%', maxWidth: '350px' }}
            >
              <ButtonPrimary onClick={() => { }}
                style={{
                  color: theme.text,
                  width: '100%',
                  maxWidth: '350px',
                  backgroundColor: '#36C781',
                  borderRadius: '12px',
                }}
              >
                <img src={metamask}
                  style={{ width: '34px', marginRight: '8px' }}
                />
                {t('metamask.installcontent')}
              </ButtonPrimary>
            </a>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}

      {status.step === "sign" && (
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.signtitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.signcontent')}
          </p>

          {lang == "jp" ? (
            <p className="text-center ml-auto mr-auto">
              署名をすることで、
              <Link to="/terms" style={{ textDecoration: 'none', color: '#6b9beb' }}>利用規約</Link>と
              <Link to="/privacy" style={{ textDecoration: 'none', color: '#6b9beb' }}>プライバシーポリシー</Link>
              に同意します。
            </p>
          ) : (
            <p className="text-center ml-auto mr-auto">
              By signing, I agree to the
              <Link to="/terms" style={{ textDecoration: 'none', color: '#6b9beb' }}> Terms of Use </Link> and
              <Link to="/privacy" style={{ textDecoration: 'none', color: '#6b9beb' }}> Privacy Policy </Link>
            </p>
          )}

          <div className="flex center mt5">
            <ButtonPrimary onClick={() => { sign() }}
              style={{
                color: theme.text,
                width: '100%',
                maxWidth: '350px',
                backgroundColor: '#36C781',
                borderRadius: '12px',
              }}
            >
              <img src={metamask}
                style={{ width: '34px', marginRight: '8px' }}
              />
              {t('metamask.asksign')}
            </ButtonPrimary>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}

      {status.step === "network" && (
        <>
          <h1 style={{ textAlign: 'center' }}>
            {t('metamask.switchnetworktitle')}
          </h1>

          <p style={{ maxWidth: '400px' }}
            className="text-center ml-auto mr-auto"
          >
            {t('metamask.switchnetworkcontent')} <br></br>
            {t('metamask.switchnetworkcontent2')}
          </p>

          <div className="flex center mt3">
            <ButtonPrimary onClick={() => { switchNetwork(config.CHAINID) }}
              style={{
                color: theme.text,
                width: '100%',
                maxWidth: '350px',
                backgroundColor: '#36C781',
                borderRadius: '12px',
              }}
            >
              <img src={metamask}
                style={{ width: '34px', marginRight: '8px' }}
              />
              {t('metamask.switchbtncontent1')} <br></br>
              {t('metamask.switchbtncontent2')}
            </ButtonPrimary>
          </div>

          <div className="flex center mt3 mb3">
            <ButtonPrimary onClick={() => { onClose() }}
              style={{ width: '280px', backgroundColor: theme.boxColor }}
            >
              {t('metamask.cancel')}
            </ButtonPrimary>
          </div>
        </>
      )}
    </Modal>
  )
}

export default Metamask
