import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { Link, useNavigate } from 'react-router-dom';

import useStore from '../../useStore';
import { tips, validateEmail } from '../../util';
import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';

interface ResendCodeInterface {
  email: string
  erroremail: string
  error: string
}

const ResendCode = () => {
  const { darkMode, call, lang, update } = useStore()
  const navigate = useNavigate();
  const { t } = useI18n()

  const [status, setStatus] = React.useState<ResendCodeInterface>({
    email: '',
    error: '',
    erroremail: ''
  })

  const updateStatus = (params: Partial<ResendCodeInterface>) => setStatus({ ...status, ...params })

  const resend = async () => {
    try {
      const email = status.email;
      if (email.trim() === "" || !validateEmail(email)) return updateStatus({ erroremail: t("action.signup.erroremail"), error: "" });
      update({ loading: true })
      const result = await call("/api/signup/resend-code", {
        email: email,
        lang: lang
      })
      if (result) {
        switch (result['message']) {
          case "success": navigate("/check-code/" + email); break;
          case "already confirmed code": updateStatus({ error: t("resendcode.alreadyconfirmed") }); break;
          case "could not send message to mail": updateStatus({ error: t("action.signup.smserror") }); break;
          case "Please enter all required data.": updateStatus({ error: t("action.signup.inputerror") }); break;
          case "Already exists same name or email.": updateStatus({ error: t("action.signup.existsameuser") }); break;
          case "internal error": updateStatus({ error: t("action.servererror") }); break;
        }
      }
      update({ loading: false })
    } catch (ex) {
      update({ loading: false })
      tips("error", t("action.unknownerror"))
    }
  }

  return (
    <Layout>
      <div >
        <StyledContainer className="container">
          <StyledResendCodePanel darkMode={darkMode}>
            <h3 className='title'>
              {t("resendcode.title")}
            </h3>

            <p className='text-center'>
              {t("resendcode.content1")} <br></br> {t("resendcode.content2")}
            </p>

            <InputField label={t("resendcode.email")} placeholder=""
              value={status.email} errorLabel={status.erroremail}
              onChange={(e) => { updateStatus({ email: e.target.value }) }}
            />

            <div className="row center mt3 mb5">
              <div className="col-lg-6 col-sm-12">
                <ButtonPrimary onClick={() => { navigate("/signup") }}
                  style={{ width: '100%', margin: '8px auto', minWidth: '180px', maxWidth: '300px', backgroundColor: "#a7a7a7" }}
                >
                  {t("resendcode.return")}
                </ButtonPrimary>
              </div>

              <div className="col-lg-6 col-sm-12">
                <ButtonPrimary onClick={() => { resend() }}
                  style={{ width: '100%', margin: '8px auto', minWidth: '180px', maxWidth: '300px' }}
                >
                  {t("resendcode.resend")}
                </ButtonPrimary>
              </div>
            </div>

            {status.error !== "" && (
              <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
            )}

            <div className="flex center mt3">
              <Link to="/signup" className='text-center'>{t("resendcode.register")}</Link>
            </div>
          </StyledResendCodePanel>
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default ResendCode;

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledResendCodePanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
	margin: 3rem auto;
	.title{
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`