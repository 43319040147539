import { gql } from "@apollo/client";

const GET_PRICES = gql`
	query GetPrice($token: String) {
		getPrice(token: $token) {
			address
			name
			symbol
			decimals
			icon
			usd
			jpy
			eur
		}
	}
`

const GET_ADMIN_WALLET = gql`
	query GetAdminWallet {
		getAdminWallet {
			addresses {
				marketplace
				exchange
				nft
				treasury
			}
		}
	}
`

const GET_FEE = gql`
	query GetFee {
		getFee {
			tradeFee
			exchangeFee
			nftpaymentFee
			onRampSideFee
		}
	}
`

const GET_TOP_CREATOR = gql`
	query getTopCreator {
		getTopCreator {
			banner_img
			email
			items
			name
			phone
			verified
			volume
			avatar_img
			address
		}
  	}
`

const GET_TOP_SELL_NFT = gql`
	query GetTopSellNFT {
		getTopSellNFT {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			image
			coverImage
			externalSite
			attributes {	
				key
				value
			}
			updatedBlockNum
			error
			owner
			creator
			acceptedToken
			price
			expiredTime
			hide
			pick
			isDigital
			isCopyright
			isRight
			bidders {
				bidder
				price
				acceptedToken
				quantity
				startTime
				expiredTime
				created
				_id
			}
			collectionname
			collectionverified
		}
	}
`

const GET_MAIN_NFTS = gql`
	query GetMainNFTs {
		getMainNFTs {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			image
			coverImage
			externalSite
			attributes {	
				key
				value
			}
			updatedBlockNum
			error
			owner
			creator
			acceptedToken
			price
			expiredTime
			hide
			pick
			isDigital
			isCopyright
			isRight
			bidders {
				bidder
				price
				acceptedToken
				quantity
				startTime
				expiredTime
				_id
				created
			}
			collectionname
			collectionverified
		}
	}
`

const GET_ALERT = gql`
	query GetAlert($address: String) {
		getAlert(address: $address) {
			_id
			title
			from
			content
			created
			status
			type
			collectionid
			tokenId
			img
		}
	}
`

const GET_USER_INFO = gql`
	query GetUsersInfo($username: String, $address: String, $badge: String, $token: String) {
		getUsersInfo(username: $username, address: $address, token: $token, badge: $badge) {
			address
			name
			bio
			email
			phone
			twitter
			instagram
			link
			avatar_img
			banner_img
			verified {
				status
				reason
			}
			allow {
				status
				reason
			}
			balances {
					address
					name
					symbol
					balance
					decimals
					icon
			}
			metamask
			confirmedcode
		}
	}
`

const GET_CATEGORY = gql`
	query GetCategory {
		getCategory {
			key
			jp
			en
		}
	}
`

const GET_USER_BALANCE = gql`
	query GetUserBalance($address: String) {
		getUserBalance(address: $address) {
			address
			name
			symbol
			balance
			decimals
			icon
		}
	}
`

const GET_SEARCH = gql`
	query GetSearch($keyword: String) {
		getSearch(keyword: $keyword) {
			item {
				_id
				nftCollection
				tokenid
				reacts
				views
				favs
				name
				description
				saleType
				multiple
				image
				coverImage
				externalSite
				attributes {	
					key
					value
				}
				updatedBlockNum
				error
				owner
				creator
				acceptedToken
				price
				expiredTime
				hide
				pick
				isDigital
				isCopyright
				isRight
				bidders {
					bidder
					price
					acceptedToken
					quantity
					startTime
					expiredTime
					_id
					created
				}
				collectionname
				collectionverified
			}
			collection {
				address
				owner
				isLazyMint
				fee
				error
				url
				category
				pick
				chainId
				hide
				metadata {
					name
					links {
					name
					link
					}
					image
					description
					coverImage
				}
				created
				verified {
					status
					reason
				}
				items
				owners
				volume
				floor
				bestoffer
			}
			user {
				address
				name
				bio
				email
				phone
				twitter
				instagram
				link
				avatar_img
				banner_img
				verified {
					status
					reason
				}
				allow {
					status
					reason
				}
				balances {
						address
						name
						symbol
						balance
						decimals
						icon
				}
				metamask
				confirmedcode
			}
		}
	}
`

const GET_POPULAR_COLLECTION = gql`
	query GetPopularCollection($days: Float) {
		getPopularCollection(days: $days) {
			address
			owner
			isLazyMint
			fee
			error
			url
			category
			pick
			chainId
			hide
			metadata {
				name
				links {
				  name
				  link
				}
				image
				description
				coverImage
			}
			created
			verified {
				status
				reason
			}
			items
			owners
			volume
			floor
			bestoffer
		}
	}
`

const GET_COLLECTION_INFO = gql`
	query GetCollectionInfo($address: String, $badge: String, $owner: String, $name: String, $acceptedToken: String, $page: Float, $limit: Float, $sort: String, $category: String) {
		getCollectionInfo(address: $address, badge: $badge, name: $name, owner:$owner, acceptedToken: $acceptedToken, page: $page, limit: $limit, sort: $sort, category: $category) {
			address
			owner
			isLazyMint
			fee
			error
			url
			category
			pick
			chainId
			hide
			metadata {
				name
				links {
				  name
				  link
				}
				image
				description
				coverImage
			}
			created
			verified {
				status
				reason
			}
			items
			owners
			volume
			volumeJpy
			floor
			bestoffer
		}
	}
`

const GET_FAVORITED = gql`
	query GetFavoritedNFT($collection: String, $nft: String, $userAddress: String) {
		getFavoritedNFT(collection: $collection, nft: $nft, userAddress: $userAddress) {
			collectionid
			nftid
			userEmail
			userAddress
		}
	}
`

const GET_PAYLABLE_TOKEN = gql`
	query GetPaylableToken {
		getPaylableToken {
			name
			symbol
			decimals
			icon
			address
			usd
			jpy
			eur
		}
	}
`

const GET_NFTS = gql`
	query GetNFTs($nftcollection: String, $owner: String, $creator: String, $query: String, $tokenid: String, $page: Float, $limit: Float, $sort: String, $price1: Float, $price2: Float, $searchsymbol: String, $salestatus: String, $symbols: String) {
		getNFTs(nftcollection: $nftcollection, query: $query, owner: $owner, creator: $creator, tokenid: $tokenid, page: $page, limit: $limit, sort: $sort, price1: $price1, price2: $price2, searchsymbol: $searchsymbol, salestatus: $salestatus,  symbols: $symbols) {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			multiple
			image
			coverImage
			externalSite
			attributes {	
				key
				value
			}
			updatedBlockNum
			error
			owner
			creator
			acceptedToken
			price
			startTime
			expiredTime
			hide
			pick
			isDigital
			isCopyright
			isRight
			bidders {
				bidder
				price
				acceptedToken
				quantity
				startTime
				expiredTime
				_id
				created
			}
			collectionname
			collectionverified
		}
	}
`

const GET_NFT_ACTIVITY = gql`
	query GetNFTActivity($nftcollection: String, $nftid: String, $type: String) {
		getNFTActivity(nftcollection: $nftcollection, nftid: $nftid, type: $type) {
			tokenid
			nftCollection
			created
			params {
				type
				value
			}
			type
		}
	}
`

const GET_FAVORITE_NFT = gql`
	query GetFavoriteNFT($owner: String) {
		getFavoriteNFT(owner: $owner) {
			_id
			nftCollection
			tokenid
			reacts
			views
			favs
			name
			description
			saleType
			image
			coverImage
			externalSite
			attributes {
				key
				value
			}
			updatedBlockNum
			error
			owner
			acceptedToken
			isDigital
			isCopyright
			isRight
			price
			expiredTime
			startTime
			expiredTime
			bidders {
				bidder
				price
				acceptedToken
				quantity
				startTime
				expiredTime
				_id
				created
			}
			collectionname
			collectionverified
		}
	}
`


export {
	GET_PRICES,
	GET_ADMIN_WALLET,
	GET_FEE, GET_NFTS,
	GET_SEARCH,
	GET_TOP_SELL_NFT,
	GET_MAIN_NFTS,
	GET_TOP_CREATOR,
	GET_NFT_ACTIVITY,
	GET_FAVORITE_NFT,
	GET_CATEGORY,
	GET_USER_INFO,
	GET_USER_BALANCE,
	GET_FAVORITED,
	GET_ALERT,
	GET_COLLECTION_INFO,
	GET_PAYLABLE_TOKEN,
	GET_POPULAR_COLLECTION,
};
