import React from 'react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import './assets/style/index.scss';
import './assets/style/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Login from './pages/User/Login';
import Signup from './pages/User/Signup';
import LoginMail from './pages/User/LoginWithMail';
import ForgetPassword from './pages/User/ForgetPassword';
import ChangePassword from './pages/User/ChangePassword';
import SignupCheckCode from './pages/User/SignupCheckCode';
import ResendCode from './pages/User/ResendCode';
import Profile from './pages/User/Profile';
import ChangeMail from './pages/User/ChangeMail';
import CreateNFTOption from './pages/NFT/CreateOption';
import NftView from './pages/NFT/View';
import ImportNft from './pages/NFT/Import';
import CreateNFT from './pages/NFT/Create';
import SearchNft from './pages/NFT/Search';
import NftExplorer from './pages/NFT/Explorer';
import EditCollection from './pages/Collection/Edit';
import CreateCollection from './pages/Collection/Create';
import ImportCollection from './pages/Collection/Import';
import SearchCollection from './pages/Collection/Search';
import CreateCollectionOption from './pages/Collection/CreateOption';
import Terms from './pages/Other/Terms';
import Home from './pages/Other/Homepage';
import Privacy from './pages/Other/Privacy';
import Account from './pages/Other/Account';
import { Loading } from './components/Loading';
import { ZakZakPage } from './components/zakzakPage';
// import { NFTPaymentPage } from './components/nftPayment';
// import { NFTpaymentConfirm } from './components/nftPaymentConfirm';
import { CryptoRampCheckPage } from 'components/cryptorampcheck';
import { RAMPPaymentPage } from 'components/ramppayment';
import { RAMPPaymentCheckPage } from 'components/rampPaymentCheck';

import { Now } from './util';
import useStore from './useStore';
import config from './config/config.json';
import langData from './translations';

const httpLink = createHttpLink({
  uri: config.REACT_APP_GRAPQLENDPOINT,
  fetchOptions: {
    timeout: 100 * 1000 // 5 seconds timeout
  }
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

function App() {
  const { loading, logined, lasttime, updated, lang, update } = useStore()
  useEffect(() => {
    update({ loading: false, redirectPage: "" })
    if (logined) {
      if (lasttime + 43200 < Now()) { //session expiration 12 hours, logout
        update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", currentAccountAddress: "", token: "", logined: false, isMetamask: false, balances: [], isWalletConnect: false });
      }
      update({ lasttime: Now(), updated: updated + 1 })
    }
  }, [])

  return (
    <I18nProvider i18n={createI18n(langData, { lang: lang })}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login-email" element={<LoginMail />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/check-code/*" element={<SignupCheckCode />} />
            <Route path="/signup/resend-code" element={<ResendCode />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/change-mail" element={<ChangeMail />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/items/select-options" element={<CreateNFTOption />} />
            <Route path="/items/import" element={<ImportNft />} />
            <Route path="/items/create" element={<CreateNFT />} />
            <Route path="/collections/select-options" element={<CreateCollectionOption />} />
            <Route path="/create-collection" element={<CreateCollection />} />
            {/* <Route path="/create-collection/import" element={<ImportCollection />} /> */}
            <Route path="/search-collection" element={<SearchCollection />} />
            <Route path="/search-nft" element={<SearchNft />} />
            <Route path="/collection/*/edit" element={<EditCollection />} />
            <Route path="/collection/*" element={<NftExplorer />} />
            <Route path="/collections" element={<SearchCollection />} />
            <Route path="/edit-collection/*" element={<EditCollection />} />
            <Route path="/assets/*" element={<NftView />} />
            <Route path="/home" element={<Home />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/zakzak/:reqId" element={<ZakZakPage />} />
            <Route path="/check-cryptoramp/:reqId" element={<CryptoRampCheckPage />} />
            {/* <Route path="/nftpayment/:reqId" element={<NFTPaymentPage />} />
            <Route path="/create-nftpayment/:reqId" element={<NFTpaymentConfirm />} /> */}
            <Route path='/create-ramppayment/:reqId' element={<RAMPPaymentPage />} />
            <Route path='/check-ramppayment/:reqId' element={<RAMPPaymentCheckPage />} />
            <Route path="*" element={<Account />} />
          </Routes>

          {/* <Toaster position="bottom-right" reverseOrder={false}/> */}
          <Loading opacity={0.5} show={!!loading} />
          <ToastContainer autoClose={10000} />
        </BrowserRouter>
      </ApolloProvider>
    </I18nProvider>
  )
}

export default App
