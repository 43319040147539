import { useContext } from "react";
import { Checkbox } from "@mui/material";
import { ThemeContext } from 'styled-components';

interface SwitchInterface {
  checked?: boolean
  changeEvent: Function
  style?: any
}

export default function CustomCheckbox({ checked, changeEvent, style }: SwitchInterface) {
  const theme = useContext(ThemeContext);

  return (
    <Checkbox sx={{
      '& .MuiSvgIcon-root': { fontSize: 32 }, color: theme.strokeColor, '&.Mui-checked': {
        color: theme.text, ...style
      }
    }} onChange={(e) => { changeEvent(e.target.checked) }} checked={checked} />
  );
}
