import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'react-simple-i18n';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout/Layout';
import { InputField } from '../../components/InputField';
import { ButtonPrimary } from '../../components/components';

import useStore from '../../useStore';
import { tips, validatePassword } from '../../util';

interface ChangePasswordInterface {
  currentPassword: string
  confirmCode: string
  newpassword: string
  newpassword2: string
  errorCurrentpassword: string
  errorConfirmcode: string
  errorNewPassword: string
  errorNewPassword2: string
  error: string
  step: number
}

const ChangePassword = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { darkMode, token, call, currentAccountMail, logined, lang, update } = useStore()

  const [status, setStatus] = React.useState<ChangePasswordInterface>({
    currentPassword: '',
    confirmCode: '',
    newpassword: '',
    newpassword2: '',
    errorCurrentpassword: '',
    errorConfirmcode: '',
    errorNewPassword: '',
    errorNewPassword2: '',
    error: '',
    step: 1
  })

  const updateStatus = (params: Partial<ChangePasswordInterface>) => setStatus({ ...status, ...params })

  const logout = () => {
    update({ currentAccountMail: "", currentAccountName: "", currentAccountAvatar: "", balances: [], currentAccountAddress: "", token: "", logined: false, isMetamask: false, lasttime: 0 });
    navigate("/");
  }

  const checkCurrentPassword = async () => {
    const password = status.currentPassword;
    if (password.trim() === "" || !validatePassword(password)) return updateStatus({ error: "", errorCurrentpassword: t("changepassword.errorpassword"), errorConfirmcode: "", errorNewPassword: "", errorNewPassword2: "" });
    update({ loading: true })
    const result = await call("/api/change-password/check-password", {
      password: password,
      email: currentAccountMail,
      lang: lang
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": updateStatus({ step: 2, error: "" }); break;
        case "could not send message by mail": updateStatus({ error: t("changepassword.failedsendmsg") }); break;
        case "Please enter all required data.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.errorinput") }); break;
        case "No exists user.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.noexistuser") }); logout(); break;
        case "No match old password.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.wrongpassword") }); break;
        case "internal error": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("action.servererror") }); break;
      }
    }
    update({ loading: false })
  }

  const checkConfirmCode = async () => {
    const code = status.confirmCode;
    if (code.length !== 6 || Number(code) < 100000 || Number(code) > 1000000) return updateStatus({ error: "", errorCurrentpassword: "", errorConfirmcode: t("changepassword.invalidcode"), errorNewPassword: "", errorNewPassword2: "" });
    updateStatus({ errorNewPassword: "", errorNewPassword2: "" })
    update({ loading: true })
    const result = await call("/api/change-password/check-code", {
      code: code,
      email: currentAccountMail
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": updateStatus({ step: 3, error: "" }); break;
        case "Please enter all required data.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.errorinput") }); break;
        case "No exists code.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.noexistcode") }); break;
        case "No match code.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.invalidcode") }); break;
        case "Endtime": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.codeexpired") }); break;
        case "internal error": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("action.servererror") }); break;
      }
    }
    update({ loading: false })
  }

  const resendConfirmCode = async () => {
    if (status.step === 1) return tips("wraning", "Not confirmed password");
    update({ loading: true })
    const result = await call("/api/change-password/resend-code", {
      email: currentAccountMail,
      lang: lang
    }, { authorization: token })
    if (result && result["message"] === "success") {
      tips("success", t("changepassword.sentcode"))
    }
    update({ loading: false })
  }

  const resetPassword = async () => {
    const password = status.newpassword;
    const confirmpassword = status.newpassword2;
    if (password.trim() === "" || !validatePassword(password)) return updateStatus({ error: "", errorCurrentpassword: "", errorNewPassword: t("changepassword.errorpassword"), errorNewPassword2: "" });
    if (confirmpassword.trim() === "" || !validatePassword(confirmpassword)) return updateStatus({ error: "", errorCurrentpassword: "", errorNewPassword2: t("changepassword.errorpassword"), errorNewPassword: "" });
    if (password !== confirmpassword) return updateStatus({ error: "", errorConfirmcode: "", errorNewPassword: "", errorNewPassword2: t("changepassword.errorpassword") });
    update({ loading: true })
    const result = await call("/api/change-password/reset-password", {
      password: password,
      email: currentAccountMail,
      code: status.confirmCode
    }, { authorization: token })
    if (result) {
      switch (result['message']) {
        case "success": tips("success", t("changepassword.changed")); setTimeout(() => { navigate("/profile") }, 500); break;
        case "Please enter all required data.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.errorinput") }); break;
        case "No exists code.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.noexistcode") }); break;
        case "Not verified code": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.notverifiedcode") }); break;
        case "No match code.": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.invalidcode") }); break;
        case "Endtime": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t("changepassword.codeexpired") }); break;
        case "internal error": updateStatus({ errorCurrentpassword: "", errorNewPassword: "", errorNewPassword2: "", errorConfirmcode: "", error: t('action.servererror') }); break;
      }
    }
    update({ loading: false })
  }

  React.useEffect(() => {
    if (!logined) navigate("/")
  }, [logined])

  return (
    <Layout>
      <div >
        <StyledContainer className="container">
          {status.step === 1 && (
            <StyledChangePasswordPanel darkMode={darkMode}>
              <h3 className='title'>{t("changepassword.title")}</h3>
              <p className='text-center'>{t("changepassword.content")}</p>

              <InputField type="password" placeholder=""
                onChange={(e) => { updateStatus({ currentPassword: e.target.value }) }}
                errorLabel={status.errorCurrentpassword}
                label={t("changepassword.password")}
                value={status.currentPassword}
              />

              <div className="row center mt3 mb5">
                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={() => { navigate("/profile") }}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px', backgroundColor: '#a7a7a7' }}
                  >
                    {t("changepassword.profile")}
                  </ButtonPrimary>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={checkCurrentPassword}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px' }}
                  >
                    {t("changepassword.checkpassword")}
                  </ButtonPrimary>
                </div>
              </div>

              {
                status.error !== "" && <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
              }
            </StyledChangePasswordPanel>
          )}

          {status.step === 2 && (
            <StyledChangePasswordPanel darkMode={darkMode}>
              <h3 className='title'>{t("changepassword.title")}</h3>
              <p className='text-center'>{t("changepassword.content2")}</p>
              <p className='small'>{t("changepassword.content3", currentAccountMail)}</p>

              <InputField placeholder="" value={status.confirmCode}
                onChange={(e) => { updateStatus({ confirmCode: e.target.value }) }}
                label={t("changepassword.confirmcode")}
                errorLabel={status.errorConfirmcode}
              />

              <div className="row center mt3 mb5">
                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={resendConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px', backgroundColor: '#a7a7a7' }}
                  >
                    {t("changepassword.resendconfirmcode")}
                  </ButtonPrimary>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <ButtonPrimary onClick={checkConfirmCode}
                    style={{ width: '80%', margin: '8px auto', minWidth: '200px' }}
                  >
                    {t("changepassword.checkcode")}
                  </ButtonPrimary>
                </div>
              </div>

              {status.error !== "" && (
                <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
              )}
            </StyledChangePasswordPanel>
          )}

          {status.step === 3 && (
            <StyledChangePasswordPanel darkMode={darkMode}>
              <h3 className='title'>{t("changepassword.title3")}</h3>
              <p className='text-center'>{t("changepassword.contentpanel3")}</p>

              <InputField type="password" placeholder=""
                onChange={(e) => { updateStatus({ newpassword: e.target.value }) }}
                label={t("changepassword.newpassword")}
                errorLabel={status.errorNewPassword}
                value={status.newpassword}
              />

              <InputField type="password" placeholder=""
                onChange={(e) => { updateStatus({ newpassword2: e.target.value }) }}
                label={t("changepassword.newpassword2")}
                errorLabel={status.errorNewPassword2}
                value={status.newpassword2}
              />

              <div className="flex center mt3 mb5">
                <ButtonPrimary style={{ width: '50%', margin: '0 8px' }} onClick={resetPassword}>
                  {t("changepassword.resetpassword")}
                </ButtonPrimary>
              </div>

              {status.error !== "" && (
                <p style={{ textAlign: 'center', color: 'red' }}>{status.error}</p>
              )}
            </StyledChangePasswordPanel>
          )}
        </StyledContainer>
      </div>
    </Layout>
  )
}

export default ChangePassword

const StyledContainer = styled.div`
	padding: 3rem 0 8rem;
	@media (max-width: 1024px) {
		padding: 1rem 0;
	}
	@media (max-width: 768px) {
		padding: 0;
	}
`

const StyledChangePasswordPanel = styled.div<{ darkMode: boolean }>`
	border-radius: 2rem;
	background-color: ${({ theme }) => theme.boxColor};
	padding: 2rem 5rem;
	width: 90%;
	max-width: 600px;
	color: ${({ theme }) => theme.text};
	margin: 3rem auto;
	.title{
		color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};
		text-align: center;
		font-size: 2rem;
		margin: 0;
		@media (max-width: 768px) {
			font-size: 1.5rem;
		}
	}
	a{
		text-align: center;
		color: #6b9beb;
	}
	@media (max-width: 768px) {
		padding: 2rem 1rem;
		margin: 1.5rem auto 5rem;
		a{
			display: block;
		}
		.link{
			text-align: center;
		}
	}
`