import React from "react";
import styled from 'styled-components';
import { useQuery } from "@apollo/client";
import { useI18n } from 'react-simple-i18n';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import previewImg from "assets/img/nftpayment-preview.gif";

import useStore from "../useStore";
import Layout from "./Layout/Layout";
import { Loading } from "./Loading";
import { ButtonPrimary } from "./components";
import { Page404 } from "../pages/Other/404Page";
import { GET_FEE } from "../graphql/index";

export const RAMPPaymentPage = () => {
  const { t } = useI18n();
  const { reqId }: any = useParams();
  const theme = useContext(ThemeContext);
  const { darkMode, call, update, token } = useStore();

  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [nftPaymentFee, setNftPaymentFee] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const result: any = await call(
          "/api/purchase/prepayment", { order_id: reqId }, { authorization: token }, true
        )

        if (result.state === "success") {
          setEmail(result.payer_email);
          setAmount((result.fiat_amount / 100));
        } else {
          setNotFound(true)
        }

      } catch (err: any) {
        console.log(err.message)
        setNotFound(true)
      }
    })()
  }, [])

  const { data: feeInfo, loading: feeLoading, error: feeError } = useQuery(GET_FEE, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (!feeLoading && !feeError && !!feeInfo?.getFee) {
      setNftPaymentFee(feeInfo.getFee.nftpaymentFee);
    }
  }, [feeInfo, feeLoading, feeError])



  const onPay = async () => {
    try {
      setLoading(true);
      const result: any = await call(
        "/api/purchase/payment", { orderid: reqId }, { authorization: token }, true
      );
      if (result?.state === "success") {
        window.location.replace(result.redirectUrl);
      } else {
        setLoading(false);
        setNotFound(true)
      }
    } catch (err: any) {
      console.log(err.message)
      setLoading(false);
      setNotFound(true);
    }
  }

  const onCancel = async () => {
    const result: any = await call(
      "/api/purchase/cancel", { orderid: reqId }, { authorization: token }, true
    )
    if (result?.state === "success") {
      window.location.replace(result.redirectUrl);
    } else {
      window.history.back();
    }
  }

  return (
    <Layout>
      {!notFound && (
        <PaymentConfirmWrapper>
          <PaymentConfirmContainer>
            <StyledTitle>
              <span>NFT </span>{t("rampPayment.title")}
            </StyledTitle>

            <Stack direction="column" gap={3}>
              <Stack>
                <Typography sx={{ textAlign: 'center' }}>
                  {t("rampPayment.description")}
                </Typography>
              </Stack>

              <Stack direction="column" gap={2}>
                <Stack direction={{ xs: "column", sm: "row" }} gap={2} sx={{ justifyContent: "space-between", alignItems: 'center' }}>
                  <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
                    <StyledNFTCard>
                      <img alt="" src={previewImg} />
                    </StyledNFTCard>

                    <Stack direction="column" gap={1}>
                      <Typography> {t("rampPayment.collectionname")}</Typography>

                      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                        <StyledNFTCard style={{ height: "30px", borderRadius: "8px" }}>
                          <img alt="" src={previewImg} />
                        </StyledNFTCard>

                        <Typography>{t("rampPayment.collectionname")}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="row">
                    <Typography variant="h6">
                      <span>$</span> {amount}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack sx={{ opacity: 0.8 }}>
                  <Typography variant="body2" textAlign="center">
                    {t("rampPayment.commitionTitle", `${nftPaymentFee}%`)}
                  </Typography>
                </Stack>
              </Stack>


              <Stack direction="column" gap={2} sx={{ alignItems: "center" }}>
                <ButtonPrimary onClick={onPay}
                  style={{ width: "80%", maxWidth: "500px", minWidth: "200px", backgroundColor: "#F56F63", color: theme.text }}
                >
                  {t("rampPayment.paybtn")}
                </ButtonPrimary>


                <ButtonPrimary onClick={onCancel}
                  style={{ width: "80%", maxWidth: "500px", minWidth: "200px", backgroundColor: "#24262F", color: theme.text }}
                >
                  {t("rampPayment.cancelbtn")}
                </ButtonPrimary>
              </Stack>
            </Stack>
          </PaymentConfirmContainer>
        </PaymentConfirmWrapper>
      )}

      {notFound && (
        <Page404 />
      )}

      <Loading opacity={0.5} show={loading} />
    </Layout >

  )
}

// const StyledContainer = styled.div`
// 	padding: 3rem 0 8rem;

// 	@media (max-width: 1024px) {
// 		padding: 1rem 0;
// 	}

// 	@media (max-width: 768px) {
// 		padding: 0;
// 	}
// `

// const StyledSignupPanel = styled.div<{ darkMode: boolean }>`
//   width: 90%;
//   padding: 2rem 5rem;
//   max-width: 600px;
//   margin: 3rem auto;

// 	border-radius: 2rem;
// 	background-color: ${({ theme }) => theme.boxColor};
// 	color: ${({ theme, darkMode }) => darkMode ? theme.white : theme.black};

// 	.title{
// 		margin: 0;
// 		font-size: 2rem;
// 		text-align: center;

// 		@media (max-width: 768px) {
// 			font-size: 1.5rem;
// 		}
// 	}

// 	a{
// 		text-align: center;
// 		color: #6b9beb;
// 	}

// 	@media (max-width: 768px) {
// 		padding: 2rem 1rem;
// 		margin: 1.5rem auto 5rem;

// 		a{
// 			display: block;
// 		}

// 		.link{
// 			text-align: center;
// 		}
// 	}
// `

const PaymentConfirmWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
`

const PaymentConfirmContainer = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 60px;
  max-width: 600px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.modalBg};
  border-radius: 1.5rem;

  .purchaseAmount-wrapper {
    padding: 1rem 2rem;
    border: 1px solid #ffffff;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
  }
  
  h2, h1 {
    margin: unset;
  }

  @media (max-width: 700px) {
    padding: 30px 35px;
  }
`

const StyledTitle = styled.h2`
	text-align: center;
	font-size: 3rem;
	span {
		font-size: 3rem;
		background: -webkit-linear-gradient(
			45deg,
			#f9f592,
			#a7f195,
			#74f9d0,
			#5e87f1,
			#e59afb,
			#f95959
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media (max-width: 768px) {
			font-size: 2rem;
		}
	}
	@media (max-width: 768px) {
		font-size: 2rem;
	}
`

const StyledNFTCard = styled.div`
	cursor: pointer;
	transition: 0.2s ease-in;
	color: ${({ theme }) => theme.text};
  
  height: 100px;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 1 / 1;
	text-decoration: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    aspect-ratio: 1 / 1;
  }

  @media (max-width: 768px) {
    border-radius: 12px;
    img {
      border-radius: 12px;
    }
  }

  &:hover {
    img {		
      @media (min-width: 768px) {
        transform: scale(1.2);
      }
    }
  }
`
